import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import "../../style/home.scss";
const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "machineName",
    headerName: "Machine Name",
    width: 150,
    editable: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "pieceCount",
    headerName: "Piece Count",
    width: 150,
    editable: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "throughput",
    headerName: "Throughput(PPH)",
    width: 150,
    editable: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "stops",
    headerName: "#stops",
    width: 150,
    editable: true,
    headerAlign: "center",
    align: "center",
  },
];

const rows = [
  {
    id: 1,
    machineName: "UMC001",
    pieceCount: "451",
    throughput: "0",
    stops: "2",
  },
  {
    id: 2,
    machineName: "UMC002",
    pieceCount: "420",
    throughput: "0",
    stops: "1",
  },
];
function CustomToolbar() {
  return (
    <GridToolbarContainer className="dataGridExport">
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
export default function DataGridDemo({ isModalopen }) {
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <DataGrid
        className={isModalopen ? "machineTable" : "machineTable modalForPage"}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 3,
            },
          },
        }}
        pageSizeOptions={[5]}
        slots={{
          toolbar: isModalopen && CustomToolbar,
        }}
      />
    </Box>
  );
}
