import React from "react";
import { Navigate } from "react-router-dom";
import DashboardKpi from "../pages/KPI/DashboardKpi";
import CustomerRoles from "../pages/Customer-portal/roles";
import CustomerUsers from "../pages/Customer-portal/User/index";
import CustomerStatusMonitor from "../pages/Customer-portal/statusMonitor";
import WorkOrder from "../pages/Customer-portal/workorder/WorkOrderHomePage";
import WOCreate from "../pages/Customer-portal/workorder/Create/index";
import ReceiptTicketCreation from "../pages/Customer-portal/workorder/Create/ReceiptTicketCreation";

const MailOwnerRoutes = [
  { path: "/customer-portal", element: <DashboardKpi /> },
  { path: "/customer-portal/roles", element: <CustomerRoles /> },
  { path: "/customer-portal/users", element: <CustomerUsers /> },
  {
    path: "/customer-portal/status-monitor",
    element: <CustomerStatusMonitor />,
  },
  { path: "/customer-portal/workorder", element: <WorkOrder /> },
  { path: "/customer-portal/wocreate", element: <WOCreate /> },
  {
    path: "/customer-portal/receiptCreation/:id",
    element: <ReceiptTicketCreation />,
  },
  { path: "/customer-portal/woupdate/:id", element: <WOCreate /> },

  { path: "*", element: <Navigate to="/customer-portal" /> },
];
export default MailOwnerRoutes;
