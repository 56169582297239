import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import { Box, Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../components/Loader";
import { customerIcons } from "./icons";
import { apiBaseUrl } from "../../config";
import CustomPagination from "../../components/customPagination/custompagination";
import RolesModal from "./modals/rolesmodal";
import DeleteModal from "./modals/deletepermissionModal";
import { RolesInitialState } from "./types";
import MOPermission from "./moPermission";
import { RolesService } from "../../services/RolesService";
const initialState: RolesInitialState = {
  open: false,
  editModal: false,
  deleteModal: false,
  deleteId: "",
  editId: 0,
  roles: {},
  loader: false,
  roleList: [],
  page: 1,
  pageSize: 10,
  rowCount: 0,
  permissions: false,
  permissionList: {},
};
const reducer = (state: RolesInitialState, action: any) => {
  switch (action.type) {
    case "UPDATE":
      return { ...state, [action.field]: action.payload };
    case "UPDATE_ROLES":
      return {
        ...state,
        roles: {
          ...state.roles,
          [action.field]: action.payload,
        },
      };
    default:
      return state;
  }
};
const Roles: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const token = localStorage.getItem("auth");

  const handleOpen = useCallback(
    () => dispatch({ type: "UPDATE", field: "open", payload: true }),
    []
  );
  const handleClose = useCallback(() => {
    dispatch({ type: "UPDATE", field: "open", payload: false });
    dispatch({ type: "UPDATE", field: "roles", payload: {} });
  }, [dispatch]);
  const handleEditOpen = (id) => {
    dispatch({ type: "UPDATE", field: "roles", payload: state.roleList[id] });
    dispatch({ type: "UPDATE", field: "editModal", payload: true });
    dispatch({ type: "UPDATE", field: "editId", payload: id });
  };
  const handleEditClose = () => {
    dispatch({ type: "UPDATE", field: "editModal", payload: false });
    dispatch({ type: "UPDATE", field: "roles", payload: {} });
  };
  const handleDeleteOpen = (id) => {
    dispatch({ type: "UPDATE", field: "deleteModal", payload: true });
    dispatch({ type: "UPDATE", field: "deleteId", payload: id });
  };
  const handleDeleteClose = () =>
    dispatch({ type: "UPDATE", field: "deleteModal", payload: false });
  const permissionOpen = () =>
    dispatch({ type: "UPDATE", field: "permissions", payload: true });

  const permissionClose = () =>
    dispatch({ type: "UPDATE", field: "permissions", payload: false });

  const success = () => toast.error("Name should be more than 3 characters!");
  const error = () => toast.error("Role Already exists");
  const posted = () => toast.success("Role Created");
  const edited = () => toast.success("Role Updated Successfully");
  const deleted = () => toast.success("Role Deleted Successfully");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch({ type: "UPDATE_ROLES", field: name, payload: value });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("role_name", state.roles.role_name);
    formData.append("is_active_flag", state.roles.account_status_id);

    if (state.roles.role_name.trim().length < 3) {
      success();
    } else if (
      state.roleList.find((item) => item.role_name === state.roles.role_name)
    ) {
      error();
    } else {
      try {
        const response = await axios.post(
          `${apiBaseUrl}mail_owner_role_creation`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*", //NOSONAR
              Authorization: "Bearer " + token,
            },
          }
        );
        if (response.status === 200) {
          posted();
          getRoleDetails();
        }
      } catch (err) {
        toast.error(err.message);
      }
      handleClose();
    }
  };
  const getRoleDetails = async () => {
    try {
      dispatch({ type: "UPDATE", field: "loader", payload: true });
      const response = await axios.get(
        `${apiBaseUrl}get_only_mail_owner_roles_list_v2`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            page: state.page,
            page_size: state.pageSize,
            auto_error: true,
          },
        }
      );
      const updatedRoles = response.data.mail_owner_roles_list.items.map(
        (item) => ({
          id: item.role_id,
          role_id: item.role_id,
          role_name: item.role_name,
          account_status_id: item.is_active_flag,
          account_status: item.active_or_inactive,
        })
      );
      dispatch({ type: "UPDATE", field: "roleList", payload: updatedRoles });
      dispatch({
        type: "UPDATE",
        field: "rowCount",
        payload: response.data.mail_owner_roles_list.total_record,
      });
      dispatch({ type: "UPDATE", field: "loader", payload: false });
    } catch (err) {
      toast.error(err.message);
      dispatch({ type: "UPDATE", field: "loader", payload: false });
    }
  };
  const deleteData = async () => {
    try {
      const formData = new FormData();
      formData.append("role_id", state.deleteId?.toString());
      const response = await RolesService.deleteMSPRoles(formData);
      if (response) {
        deleted();
        getRoleDetails();
      }
    } catch (err) {
      toast.error(err.message);
    }
    handleDeleteClose();
  };
  const editData = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (state.roles.role_name.trim().length < 3) {
      success();
    } else {
      try {
        const formData = new FormData();
        formData.append("role_id", state.roles.role_id);
        formData.append("role_name", state.roles.role_name);
        formData.append("is_active_flag", state.roles.account_status_id);

        const response = await axios.put(
          `${apiBaseUrl}mail_owner_role_update`,
          formData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          edited();
          getRoleDetails();
        }
      } catch (err) {
        toast.error(err.message);
      }
      handleEditClose();
    }
  };
  const Permission = async () => {
    const role_id = localStorage.getItem("role_id");

    const response = await axios.get(
      `${apiBaseUrl}mail_owner_role_group_function_mapping_list?role_id=${role_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      response.data.mo_role_group_function_mapping_list?.map((obj) => {
        obj.role_function_name === "Mail Owner Roles" &&
          dispatch({ type: "UPDATE", field: "permissionList", payload: obj });
      });
    }
  };
  const handlePageChange = (newPage: number) => {
    dispatch({ type: "UPDATE", field: "page", payload: newPage });
  };
  const handlePageSizeChange = (newPageSize: number) => {
    dispatch({ type: "UPDATE", field: "pageSize", payload: newPageSize });
    dispatch({ type: "UPDATE", field: "page", payload: 1 });
  };
  const columns = [
    { field: "id", headerName: "ID" },
    { field: "role_name", headerName: "Name of Role", width: 300 },
    {
      field: "account_status",
      headerName: "Account Status",
      // renderCell: () => <Box className="accountStatus">Active</Box>,
      width: 300,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => {
        const index = state.roleList.findIndex((x) => x.id === params.row.id);
        return (
          <Box display="flex" justifyContent="center" gap={2}>
            {state.permissionList.mo_role_group_function_mapping_list
              ?.role_update_flag === 1 && (
              <Button
                onClick={() => handleEditOpen(index)}
                className="closeButton"
              >
                <img
                  src={customerIcons.EditIcon}
                  alt="editicon"
                  className="actionIcons"
                />
              </Button>
            )}
            {state.permissionList.mo_role_group_function_mapping_list
              ?.role_delete_flag === 1 && (
              <Button
                onClick={() => handleDeleteOpen(params.row.id)}
                className="closeButton"
              >
                <img
                  src={customerIcons.DeleteIcon}
                  alt="deleteicon"
                  className="actionIcons"
                />
              </Button>
            )}
          </Box>
        );
      },
      width: 300,
    },
  ];
  useEffect(() => {
    getRoleDetails();
    Permission();
  }, [state.page, state.pageSize]);

  return (
    <Box>
      <div className="roles">
        {state.loader && <Loader />}
        <RolesModal
          open={state.open}
          onClose={handleClose}
          onSubmit={handleSubmit}
          roles={state.roles}
          handleChange={handleChange}
          title="MO Role Creation"
          buttonText="Create"
        />
        <RolesModal
          open={state.editModal}
          onClose={handleEditClose}
          onSubmit={editData}
          roles={state.roles}
          handleChange={handleChange}
          title="MO Role Update "
          buttonText="Update"
        />
        {state.permissions && (
          <MOPermission open={state.permissions} close={permissionClose} />
        )}
        <DeleteModal
          open={state.deleteModal}
          onClose={handleDeleteClose}
          onDelete={deleteData}
        />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <h1 style={{ margin: 0 }}>Role</h1>
          <Box>
            {state.permissionList.mo_role_group_function_mapping_list
              ?.role_create_flag === 1 && (
              <Button className="Btn" variant="contained" onClick={handleOpen}>
                <img src={customerIcons.AddRoleIcon} alt="addroleicon" />{" "}
                <b>Create MO Role</b>
              </Button>
            )}

            <Button
              className="Btn"
              variant="contained"
              onClick={permissionOpen}
            >
              <img src={customerIcons.AddRoleIcon} alt="addroleicon" />
              <b>MO Role Permissions</b>
            </Button>
          </Box>
        </Box>
        <Grid container>
          <Grid item md={12}>
            <Box
              style={{
                height: "calc(100vh - 200px)",
                width: "100%",
                margin: "20px 0",
              }}
              className="rolesTable"
            >
              <DataGrid
                className="RolesTable"
                rows={state.roleList}
                columns={columns}
                columnVisibilityModel={{
                  id: false,
                }}
                slots={{
                  noRowsOverlay: () => null,
                  pagination: (props) => (
                    <CustomPagination
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                      page={state.page}
                      pageSize={state.pageSize}
                      rowCount={state.rowCount}
                    />
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default Roles;
