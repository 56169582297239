import React from "react";
import { Navigate } from "react-router-dom";
import DashboardKpi from "../pages/KPI/DashboardKpi";
import Roles from "../pages/Eii/RolesManagement/index";
import EIIUserCreation from "../pages/Eii/User/index";
import Permissions from "../pages/Eii/Permissions/RolesPermissions";
import Machines from "../pages/Eii/Machine";
import Mspcreation from "../pages/Eii/MspCreation/index";
const EiiRoutes = [
  { path: "/eii", element: <DashboardKpi /> },
  { path: "/eii/roles", element: <Roles /> },
  { path: "/eii/mspcreation", element: <Mspcreation /> },
  { path: "/eii/usercreation", element: <EIIUserCreation /> },
  { path: "/eii/permissions", element: <Permissions /> },
  { path: "/eii/machine", element: <Machines /> },
  { path: "*", element: <Navigate to="/eii" /> },
];
export default EiiRoutes;
