import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  Switch,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../../../config";
import { useParams } from "react-router-dom";
import axios from "axios";
const CustomSwitch = styled(Switch)(({ theme, checked }) => ({
  "& .MuiSwitch-switchBase": {
    color: checked ? "#00304f" : "grey",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: checked ? "#00304f" : "grey",
  },
}));

export default function CreateWorkOrder({
  handleChange,
  detailValue,
  updateTime,
  errorMessage,
}) {
  const baseurl = apiBaseUrl;
  const id = useParams();
  const handleZipCodeChange = async (e) => {
    const inputZipCode = e.target.value;

    if (inputZipCode.length === 5) {
      try {
        const response = await axios.get(
          `${baseurl}get_postal_code?code=${inputZipCode}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth")}`,
            },
          }
        );

        if (response.data && response.data.length > 0) {
          const { city, state } = response.data[0];

          const postalCodes = {
            postal_code: inputZipCode,
            city,
            state,
          };

          handleChange(e, "site_details", "postal_code", postalCodes);
        } else {
          toast.error("Postal code not found");
        }
      } catch (error) {
        toast.error("unable to fetch address details");
      }
    }
  };

  const handleDateChanges = (fieldName, newDate) => {
    const year = String(newDate.$H).padStart(2, "0");
    const month = String(newDate.$m).padStart(2, "0");
    const day = String(newDate.$s).padStart(2, "0");
    const formattedDate = `${year}:${month}:${day}`;
    updateTime({ key: "site_details", field: fieldName, value: formattedDate });
  };

  useEffect(() => {}, [detailValue.is_active]);

  console.log("sitedetails", detailValue);

  return (
    <Box className="sitedetails_tab site-details">
      <Box className="sitedetails-header" sx={{ mb: 3 }}>
        <Typography>
          <b>Site Details</b>
        </Typography>
      </Box>
      <Box
        className="sitedetails-body"
        component="form"
        noValidate
        sx={{ mb: 3, px: 2 }}
      >
        <Box display="flex" alignItems="flex-start" gap={2} flexWrap="wrap">
          <Grid container lg={6} spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                label="MSP Name"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="msp_name"
                value={localStorage.getItem("user_name")}
                error={!!errorMessage.msp_name}
                helperText={errorMessage.msp_name}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                label="Site Name"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 30 }}
                InputLabelProps={{ shrink: true }}
                error={!!errorMessage.site_name}
                helperText={errorMessage.site_name}
                fullWidth
                name="site_name"
                onChange={(e) => handleChange(e, "site_details")}
                value={detailValue?.site_name}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                label="Site Address Line 1"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
                fullWidth
                name="site_address_line_1"
                onChange={(e) => handleChange(e, "site_details")}
                value={detailValue?.site_address_line_1}
                error={!!errorMessage.site_address_line_1}
                helperText={errorMessage.site_address_line_1}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                label="Site Address Line 2"
                name="site_address_line_2"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 50 }}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleChange(e, "site_details")}
                fullWidth
                value={detailValue?.site_address_line_2}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                label="Postal Code"
                variant="outlined"
                name="postal_code"
                size="small"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
                fullWidth
                onChange={(e) => handleZipCodeChange(e, "postal_code")}
                defaultValue={detailValue?.postal_code}
                error={!!errorMessage.postal_code}
                helperText={errorMessage.postal_code}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                label="City"
                variant="outlined"
                name="city"
                size="small"
                inputProps={{ maxLength: 50 }}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleChange(e, "site_details")}
                fullWidth
                value={detailValue?.city}
                error={!!errorMessage.city}
                helperText={errorMessage.city}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                label="State"
                variant="outlined"
                name="state"
                size="small"
                inputProps={{ maxLength: 50 }}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleChange(e, "site_details")}
                fullWidth
                value={detailValue?.state}
                error={!!errorMessage.state}
                helperText={errorMessage.state}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TextField
                id="outlined-basic"
                label="Longtitude / Latitude"
                name="location"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 50 }}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleChange(e, "site_details")}
                fullWidth
                value={detailValue?.location}
              />
            </Grid>
          </Grid>
          <Grid container lg={6} spacing={2}>
            <Grid item xs={12} md={6} lg={12}>
              <FormControl fullWidth size="small">
                <InputLabel
                  id="demo-simple-select-label"
                  className="selectLabel"
                  shrink
                  sx={{ pr: 1 }}
                >
                  Operation
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Operation"
                  name="operation_id"
                  value={detailValue?.operation_id}
                  onChange={(e) => handleChange(e, "site_details")}
                >
                  <MenuItem value={1}>Accounting</MenuItem>
                  <MenuItem value={2}>Production </MenuItem>
                  <MenuItem value={3}>Accounting and Production</MenuItem>
                </Select>
                {errorMessage.operation_id && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errorMessage.operation_id}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>{" "}
            <Grid item xs={12} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  fullWidth
                  label="Working Hours Shift 1 From"
                  name="shift1_from_time"
                  value={
                    detailValue?.shift1_from_time
                      ? dayjs(detailValue.shift1_from_time, "HH:mm:ss")
                      : null
                  }
                  onChange={(newTime) =>
                    handleDateChanges("shift1_from_time", newTime)
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                    },
                  }}
                />
                {errorMessage.shift1_from_time && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errorMessage.shift1_from_time}
                  </FormHelperText>
                )}
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  fullWidth
                  label="Working Hours Shift 1 To"
                  value={
                    detailValue?.shift1_to_time
                      ? dayjs(detailValue?.shift1_to_time, "HH:mm:ss")
                      : null
                  }
                  onChange={(newTime) =>
                    handleDateChanges("shift1_to_time", newTime)
                  }
                  name="shift1_to_time"
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                    },
                  }}
                />
                {errorMessage.shift1_to_time && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errorMessage.shift1_to_time}
                  </FormHelperText>
                )}
              </LocalizationProvider>
            </Grid>
            {detailValue?.is_24_hours_active === 1 && (
              <>
                <Grid item xs={12} md={6} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      fullWidth
                      label="Working Hours Shift 2 From"
                      name="shift2_from_time"
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          InputLabelProps: { shrink: true },
                        },
                      }}
                      onChange={(newTime) =>
                        handleDateChanges("shift2_from_time", newTime)
                      }
                      value={
                        detailValue?.shift2_from_time
                          ? dayjs(detailValue?.shift2_from_time, "HH:mm:ss")
                          : null
                      }
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      fullWidth
                      label="Working Hours Shift 2 To"
                      name="shift2_to_time"
                      value={
                        detailValue?.shift2_to_time
                          ? dayjs(detailValue?.shift2_to_time, "HH:mm:ss")
                          : null
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          InputLabelProps: { shrink: true },
                        },
                      }}
                      onChange={(newTime) =>
                        handleDateChanges("shift2_to_time", newTime)
                      }
                    />
                    {errorMessage.shift2_from_time && (
                      <FormHelperText sx={{ color: "red" }}>
                        {errorMessage.shift2_from_time}
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      fullWidth
                      label="Working Hours Shift 3 From"
                      name="shift3_from_time"
                      value={
                        detailValue?.shift3_from_time
                          ? dayjs(detailValue?.shift3_from_time, "HH:mm:ss")
                          : null
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          InputLabelProps: { shrink: true },
                        },
                      }}
                      onChange={(newTime) =>
                        handleDateChanges("shift3_from_time", newTime)
                      }
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      fullWidth
                      label="Working Hours Shift 3 To"
                      name="shift3_to_time"
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          InputLabelProps: { shrink: true },
                        },
                      }}
                      onChange={(newTime) =>
                        handleDateChanges("shift3_to_time", newTime)
                      }
                      value={
                        detailValue?.shift3_to_time
                          ? dayjs(detailValue?.shift3_to_time, "HH:mm:ss")
                          : null
                      }
                    />
                    {errorMessage.shift3_from_time && (
                      <FormHelperText sx={{ color: "red" }}>
                        {errorMessage.shift3_from_time}
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container lg={12}>
            <Grid item lg={3} md={4} sm={6}>
              <FormControlLabel
                className="site-details-checkboxes"
                control={
                  <CustomSwitch
                    checked={detailValue.is_active === 1}
                    onChange={(e) =>
                      handleChange(
                        {
                          target: {
                            name: e.target.name,
                            value: e.target.checked ? 1 : 0,
                          },
                        },
                        "site_details"
                      )
                    }
                    name="is_active"
                  />
                }
                label={detailValue.is_active === 1 ? "Enabled" : "Disabled"}
              ></FormControlLabel>
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <FormControlLabel
                className="site-details-checkboxes"
                control={
                  <Checkbox
                    checked={detailValue?.is_admin_site === 1}
                    defaultChecked={detailValue?.is_admin_site === 1}
                    name="is_admin_site"
                    onChange={(e) =>
                      handleChange(
                        {
                          target: {
                            name: e.target.name,
                            value: e.target.checked ? 1 : 0,
                          },
                        },
                        "site_details"
                      )
                    }
                  />
                }
                label="Admin Site"
                name="adminsite"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <FormControlLabel
                className="site-details-checkboxes"
                control={
                  <Checkbox
                    checked={detailValue?.is_24_hours_active === 1}
                    defaultChecked={detailValue?.is_24_hours_active === 1}
                    name="is_24_hours_active"
                    onChange={(e) =>
                      handleChange(
                        {
                          target: {
                            name: e.target.name,
                            value: e.target.checked ? 1 : 0,
                          },
                        },
                        "site_details"
                      )
                    }
                  />
                }
                label="Working 24/7"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
