import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  Switch,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  handleWorkOrderChange,
  updateWO,
} from "../../../../store/WorkOrderCreation";
import dayjs from "dayjs";
import axios from "axios";
import { apiBaseUrl } from "../../../../config";
import { useParams } from "react-router-dom";

export default function CreateWorkOrder({ openWorkOrderModal }) {
  const [siteList, setSiteList] = useState([]);
  const [error, setError] = useState(false);

  const CustomerName = localStorage.getItem("user_name");
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    msp_id,
    msp_name,
    site_id,
    eps_account_number,
    reference_field_id,
    work_order_type_id,
    job_name,
    po_number,
    comments,
    work_order_upload_option_id,
    is_additional_services_present,
    pieces,
    trays,
    pallets,
    mailing_date,
    pick_up_date,
    special_processing_notes,
  } = useSelector((state) => state.workOrder);

  const handleChange = (fieldName) => (event) => {
    setError(true);
    const value =
      event?.target?.type === "checkbox"
        ? event?.target?.checked
        : event?.target?.value;
    dispatch(handleWorkOrderChange({ field: fieldName, value }));
  };

  const handleDateChanges = (fieldName, newDate) => {
    const year = newDate.$y;
    const month = String(newDate.$M + 1).padStart(2, "0");
    const day = String(newDate.$D).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    dispatch(handleWorkOrderChange({ field: fieldName, value: formattedDate }));
  };

  useEffect(() => {
    const mspSelected = JSON.parse(localStorage.getItem("switchMsp"));

    if (mspSelected) {
      axios
        .get(
          `${apiBaseUrl}sites_list_for_work_order?msp_id=${mspSelected.msp_id}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
            },
          }
        )
        .then((response) => {
          setSiteList(response.data.site_list);
        })
        .catch((e) => {
          console.log("Err==>", e);
        });
    }
    if (id) {
      axios
        .get(`${apiBaseUrl}mo_specific_work_order?work_order_id=${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          if (mspSelected) {
            response.data.work_order_details.msp_id =
              mspSelected.msp_account_guid;
            response.data.work_order_details.msp_name = mspSelected.msp_name;
          }
          dispatch(updateWO(response.data.work_order_details));
        })
        .catch((e) => {
          console.log("Err==>", e);
        });
    } else {
      if (mspSelected) {
        dispatch(
          handleWorkOrderChange({
            field: "msp_id",
            value: mspSelected.msp_account_guid,
          })
        );
        dispatch(
          handleWorkOrderChange({
            field: "msp_name",
            value: mspSelected.msp_name,
          })
        );
      }
    }
  }, [msp_id]);

  return (
    <Box className="work_order_home_page">
      <div className="create_page">
        <div className="workOrder">
          <Box className="workOrder_header">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography variant="p">Create Work Order </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box className="customerDetails" sx={{ mt: 4 }}>
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Customer Details</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" className="customer-details">
                  Customer Name : <b>{CustomerName}</b>
                </Typography>
                <Typography variant="h6" className="customer-details">
                  MSP Name : <b>{msp_name || "Please select a MSP"}</b>
                </Typography>
                <Grid container>
                  <Grid item md={4} lg={4}>
                    <FormControl
                      size="small"
                      sx={{ mt: "10px", ml: "20px" }}
                      fullWidth
                      required
                    >
                      <InputLabel id="demo-simple-select-label">
                        Site
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Site"
                        onChange={handleChange("site_id")}
                        value={site_id || ""}
                      >
                        {siteList?.map((item) => (
                          <MenuItem key={item.site_guid} value={item.site_guid}>
                            {item.site_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className="customerDetails workinfo">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Work Order Info</b>
                </Typography>
              </Box>
              <Box className="container">
                <Grid container spacing={2} sx={{ mt: 0.5 }}>
                  <Grid item md={6} lg={6}>
                    <Grid item mb={2}>
                      <TextField
                        variant="outlined"
                        name="job_name"
                        label="Job Name"
                        size="small"
                        fullWidth
                        required
                        value={job_name || ""}
                        onChange={handleChange("job_name")}
                      />
                    </Grid>
                    <Grid item mb={2}>
                      <TextField
                        variant="outlined"
                        name="po_number"
                        label="PO Number"
                        required
                        size="small"
                        fullWidth
                        value={po_number || ""}
                        onChange={handleChange("po_number")}
                      />
                    </Grid>

                    <Grid item>
                      <TextField
                        variant="outlined"
                        label="Comments"
                        name="comments"
                        size="small"
                        multiline
                        rows={4}
                        value={comments || ""}
                        fullWidth
                        onChange={handleChange("comments")}
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={6} lg={6}>
                    <Grid item mb={2}>
                      <TextField
                        variant="outlined"
                        name="eps_account_number"
                        label="EPS Account Number"
                        required
                        size="small"
                        fullWidth
                        value={eps_account_number || ""}
                        onChange={handleChange("eps_account_number")}
                      />
                    </Grid>

                    <Grid item mb={2}>
                      <TextField
                        variant="outlined"
                        name="reference_field_id"
                        label="EPS Reference Field"
                        size="small"
                        value={reference_field_id || ""}
                        onChange={handleChange("reference_field_id")}
                        fullWidth
                      />
                    </Grid>

                    <Grid item>
                      <FormControl size="small" fullWidth required>
                        <InputLabel id="demo-simple-select-label">
                          Work Order Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Work Order Type"
                          name="work_order_type_id"
                          required
                          value={work_order_type_id || ""}
                          onChange={handleChange("work_order_type_id")}
                        >
                          <MenuItem value={1}>Delivery</MenuItem>
                          <MenuItem value={2}>Pickup Of Mail</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box className="customerDetails uploads">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Do you have data to upload ?</b>
                </Typography>
              </Box>
              <Box className="container" sx={{ mt: 2 }}>
                {" "}
                <Grid container>
                  <Grid item md={4} lg={4}>
                    <FormControl size="small" fullWidth required>
                      <InputLabel id="demo-simple-select-label">
                        Work Order Upload Option
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Work Order Upload Option"
                        name="work_order_upload_option_id"
                        onChange={handleChange("work_order_upload_option_id")}
                        required
                        value={work_order_upload_option_id || ""}
                      >
                        <MenuItem value={1}>None</MenuItem>
                        <MenuItem
                          value={2}
                          onClick={() => openWorkOrderModal()}
                        >
                          Data Exchange
                        </MenuItem>
                        <MenuItem
                          value={3}
                          onClick={() => openWorkOrderModal()}
                        >
                          Mail.dat File
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className="customerDetails lineitems">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Line Items</b>
                </Typography>
              </Box>
              <Box className="container" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item md={6} lg={6}>
                    <Grid item mb={2}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        size="small"
                      >
                        <DatePicker
                          value={
                            mailing_date
                              ? dayjs(mailing_date)
                              : dayjs(new Date())
                          }
                          name="mailing_date"
                          onChange={(newDate) =>
                            handleDateChanges("mailing_date", newDate)
                          }
                          renderInput={(params) => (
                            <TextField {...params} required />
                          )}
                          minDate={dayjs(new Date())}
                          slotProps={{
                            textField: {
                              size: "small",
                              fullWidth: true,
                            },
                          }}
                          label="Mailing Date"
                          className="lineItemsFields"
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item mb={2}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        size="small"
                      >
                        <DatePicker
                          value={
                            pick_up_date
                              ? dayjs(pick_up_date)
                              : dayjs(new Date())
                          }
                          name="pick_up_date"
                          onChange={(newDate) =>
                            handleDateChanges("pick_up_date", newDate)
                          }
                          renderInput={(params) => (
                            <TextField {...params} required />
                          )}
                          minDate={dayjs(new Date())}
                          slotProps={{
                            textField: {
                              size: "small",
                              fullWidth: true,
                            },
                          }}
                          label={
                            work_order_type_id === 1
                              ? "Delivery Date"
                              : "Pickup Date"
                          }
                          className="lineItemsFields"
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item>
                      <TextField
                        name="special_processing_notes"
                        label="Special Processing Notes"
                        size="small"
                        multiline
                        rows={4}
                        fullWidth
                        value={special_processing_notes || ""}
                        onChange={handleChange("special_processing_notes")}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={6} lg={6}>
                    <Grid item mb={2}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="pieces"
                        label="Pieces"
                        type="number"
                        value={pieces || ""}
                        inputProps={{ min: 0 }}
                        onChange={handleChange("pieces")}
                      />
                    </Grid>
                    <Grid item mb={2}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="trays"
                        label="Trays"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={trays || ""}
                        onChange={handleChange("trays")}
                        disabled={pieces < 200}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="pallets"
                        label="Pallets"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={pallets || ""}
                        onChange={handleChange("pallets")}
                        disabled={trays < 8}
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={6} lg={6} alignItems="center">
                    <FormControl component="fieldset">
                      <FormControlLabel
                        required
                        control={
                          <Switch
                            checked={is_additional_services_present}
                            inputProps={{ "aria-label": "controlled" }}
                            name="is_additional_services_present"
                            onChange={handleChange(
                              "is_additional_services_present"
                            )}
                          />
                        }
                        label="Additional Services?"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </Box>
  );
}
