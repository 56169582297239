import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import dayjs from "dayjs";
import Backdrop from "@mui/material/Backdrop";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import axios from "axios";
import isEmpty from "is-empty";
import { toast } from "react-toastify";
import CloseIcon from "../../../assets/icons/Close Square.svg";
import { apiBaseUrl } from "../../../config";
import Loader from "../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchMachineData } from "../../../store/MachineList";
import CustomPhoneInput from "../../../components/customPhoneInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "1050px",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export default function MachineUpdate({ openEdit, closeEdit, editList }) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("auth");
  const [expanded, setExpanded] = useState("");
  const [modalHeight, setModalHeight] = useState("70vh");
  const [machines, setMachines] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [dates, setDates] = useState({
    expected_service: "",
    production_date_range: "",
    last_preventive_maintenance: "",
    next_scheduled_preventive: "",
    date: "",
  });
  const [submit, setSubmit] = useState(false);

  const {
    ownersList,
    manufacturerList,
    modelList,
    primaryList,
    secondaryList,
    verifierList,
    bintypeList,
    doubleDetectorList,
    imbPrinterList,
    coaPrinterList,
    scalerList,
    lablerList,
    graphicsList,
    endorsementList,
    feederList,
    maintenenceList,
    loading,
  } = useSelector((state) => state.machines);

  useEffect(() => {
    dispatch(fetchMachineData());

    setMachines(editList);
  }, []);

  useEffect(() => {
    try {
      if (machines?.owner_id && ownersList.length > 0) {
        let locationId = ownersList.find(
          (val) => val.account_guid == machines?.owner_id
        );

        axios
          .get(`${apiBaseUrl}sites_dropdown`, {
            params: { msp_id: locationId.account_id },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setSiteDetails(res.data);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              toast.error("Unauthorized");
            }
          });
      }
    } catch (error) {
      console.log("Err===>", error);
    }
  }, [machines?.owner_id, ownersList]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setModalHeight(isExpanded ? "85vh" : "70vh");
  };

  const handleDateChange = (key, newDate) => {
    setDates((prevDates) => ({
      ...prevDates,
      [key]: newDate,
    }));
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setMachines((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const editData = async () => {
    setSubmit(true);

    if (
      !machines ||
      !machines.machine_name ||
      !machines.serial ||
      !machines.service_number ||
      !machines.mass_id ||
      !machines.move_update_id ||
      !machines.number_of_bins ||
      !machines.max_throughput ||
      !machines.production_throughput ||
      !machines.percentage_realization_production ||
      !machines.preventive_maintenance_time_hrs ||
      !machines.logs_attached ||
      !machines.log ||
      !machines.first_name ||
      !machines.last_name ||
      !machines.email ||
      !machines.phone ||
      !machines.priority ||
      !machines.actual_production_throughput
    ) {
      toast.error("Please fill all the fields");

      return false;
    }

    const data = {
      owner_id: machines.owner_id,
      location_id: machines.location,
      serial: machines.serial,
      machine_name: machines.machine_name,
      machine_id: machines.machine_id,
      manufacturer_id: machines.manufacturer_id,
      model_id: machines.model_id,
      service_number: machines.service_number,
      mass_id: machines.mass_id,
      move_update_id: machines.move_update_id,
      verifier_id: machines.verifier_id,
      scale_id: machines.scale_id,
      primary_ocr_id: machines.primary_ocr_id,
      secondary_ocr_id: machines.secondary_ocr_id,
      number_of_bins: parseInt(machines.number_of_bins),
      bin_type_id: machines.bin_type_id,
      doubles_detector_id: machines.doubles_detector_id,
      thickness_detector_id: machines.thickness_detector_id,
      imb_printer_id: machines.imb_printer_id,
      coa_printer_id: machines.coa_printer_id,
      endorsement_printer_id: machines.endorsement_printer_id,
      graphics_printer_id: machines.graphics_printer_id,
      labeler_id: machines.labeler_id,
      feeder_id: machines.feeder_id,
      max_throughput: parseInt(machines.max_throughput),
      expected_capable_for_service: dates.expected_service
        ? dayjs(dates.expected_service).format("YYYY-MM-DD hh:mm:ss")
        : machines.capable_service,
      production_throughput: parseInt(machines.production_throughput),
      actual_production_throughput: parseInt(
        machines.actual_production_throughput
      ),
      production_date_range: dates.production_date_range
        ? dayjs(dates.production_date_range).format("YYYY-MM-DD")
        : machines.production_date_range,
      percentage_realization_production: parseInt(
        machines.percentage_realization_production
      ),
      machine_enabled_disable_for_production:
        machines.machine_enabled_disable_for_production,
      last_preventive_maintenance_completed: dates.last_preventive_maintenance
        ? dayjs(dates.last_preventive_maintenance).format("YYYY-MM-DD")
        : machines.last_preventive,
      next_scheduled_preventive_maintenance: dates.next_scheduled_preventive
        ? dayjs(dates.next_scheduled_preventive).format("YYYY-MM-DD")
        : machines.next_preventive,
      preventive_maintenance_time_hrs: parseInt(
        machines.preventive_maintenance_time_hrs
      ),
      date: dates.date ? dayjs(dates.date).format("YYYY-MM-DD") : machines.date,
      logs_attached: parseInt(machines.logs_attached),
      log: machines.log,
      first_name: machines.first_name,
      last_name: machines.last_name,
      shift_id: machines.shift_id,
      email: machines.email,
      phone: machines.phone,
      priority: machines.priority,
    };

    function convertToFormData(data) {
      const formData = new FormData();

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }

      return formData;
    }

    const formData = convertToFormData(data);

    try {
      await axios
        .put(`${apiBaseUrl}msp_machine_update`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Machine Created Successfully");
            closeEdit("success");
          }
        })
        .catch((error) => {
          toast.error(error.data?.detail || "Something went wrong");
        });
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleEditPhoneNumber = (value) => {
    setMachines((prevState) => ({ ...prevState, phone: value }));
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openEdit}
        className="modal-wrap site-creation-modal roleCreateModal"
        onClose={closeEdit}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openEdit}>
          <Box
            sx={style}
            className="accordionModal machine-update"
            // style={{ height: modalHeight }}
          >
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              className="mspRoleHeader"
            >
              <b> Machine Update</b>
              <Button className="closeButton" onClick={closeEdit}>
                <img src={CloseIcon} alt="closeIcon" className="closeicon" />
              </Button>
            </Typography>
            {loading && <Loader />}

            <Box
              sx={{ width: "100%", typography: "body1" }}
              className="accordionDetails"
            >
              {/* common fields */}
              <Grid container sx={{ mt: 0 }}>
                <Grid
                  item
                  xs={12}
                  lg={3}
                  xl={3}
                  md={3}
                  className="machineDetails"
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Owner</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Owner"
                      value={!isEmpty(machines) ? machines.owner_id : ""}
                      name="owner_id"
                      onChange={handleChange}
                      disabled
                    >
                      {ownersList.map((owners) => (
                        <MenuItem
                          value={owners.account_guid}
                          key={owners.account_guid}
                        >
                          {owners.account_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={3}
                  xl={3}
                  md={3}
                  className="machineDetails"
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Location"
                      name="location"
                      onChange={handleChange}
                      value={!isEmpty(machines) ? machines.location : ""}
                      disabled
                    >
                      {siteDetails.map((sites) => (
                        <MenuItem value={sites.site_guid} key={sites.site_guid}>
                          {sites.site_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={3}
                  xl={3}
                  md={3}
                  className="machineDetails"
                >
                  <TextField
                    fullWidth
                    id="outlined-multiline-flexible"
                    label="Machine Name"
                    size="small"
                    inputProps={{ maxLength: 32 }}
                    name="machine_name"
                    onChange={handleChange}
                    disabled
                    value={!isEmpty(machines) ? machines.machine_name : ""}
                  />
                  {!machines?.machine_name && (
                    <span className="errorMessage">
                      Machine Name is required
                    </span>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={3}
                  xl={3}
                  md={3}
                  className="machineDetails"
                >
                  <TextField
                    fullWidth
                    id="outlined-multiline-flexible"
                    label="Serial"
                    size="small"
                    inputProps={{ maxLength: 50 }}
                    name="serial"
                    disabled
                    value={!isEmpty(machines) ? machines.serial : ""}
                    onChange={handleChange}
                  />
                  {submit && !machines.serial && (
                    <span className="errorMessage">Serial is required</span>
                  )}
                </Grid>
              </Grid>
              {/* config  */}
              <Accordion
                sx={{ my: 2 }}
                className="Accordion"
                expanded={expanded === "panel1"}
                onChange={handleAccordionChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon className="accordion_icon" />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="accordian_header"
                  sx={{
                    mb: 2,
                    background: "#693e75",
                    borderLeft: "7px solid #a88aae",
                  }}
                >
                  <Typography>
                    <b>Machine Config</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ mt: 2 }}>
                  <Grid container spacing={2}>
                    {/* Manufacturer */}
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <Box className="machineFields">
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Manufacturer
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Manufacturer"
                              name="manufacturer_id"
                              onChange={handleChange}
                              disabled
                              value={
                                !isEmpty(machines)
                                  ? machines.manufacturer_id
                                  : ""
                              }
                            >
                              {manufacturerList.map((manufacturer) => (
                                <MenuItem
                                  value={manufacturer.machine_manufacturer_id}
                                  key={manufacturer.machine_manufacturer_id}
                                >
                                  {manufacturer.name_of_machine_oem}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Model
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Model"
                              name="model_id"
                              size="small"
                              disabled
                              onChange={handleChange}
                              value={
                                !isEmpty(machines) ? machines.model_id : ""
                              }
                            >
                              {modelList.map((model) => (
                                <MenuItem
                                  value={model.machine_model_id}
                                  key={model.machine_model_id}
                                >
                                  {model.machine_model_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Service Number"
                            size="small"
                            inputProps={{ maxLength: 32 }}
                            name="service_number"
                            value={
                              !isEmpty(machines) ? machines.service_number : ""
                            }
                            onChange={handleChange}
                          />
                          {submit && !machines.service_number && (
                            <span className="errorMessage">
                              Service Number is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Mass ID"
                            size="small"
                            disabled
                            inputProps={{ maxLength: 4 }}
                            name="mass_id"
                            value={!isEmpty(machines) ? machines.mass_id : ""}
                            onChange={handleChange}
                          />
                          {submit && !machines.mass_id && (
                            <span className="errorMessage">
                              Mass Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Move Update ID"
                            size="small"
                            inputProps={{ maxLength: 4 }}
                            name="move_update_id"
                            value={
                              !isEmpty(machines) ? machines.move_update_id : ""
                            }
                            onChange={handleChange}
                          />
                          {submit && !machines.move_update_id && (
                            <span className="errorMessage">
                              Move Update ID is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Verifier
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Verifier"
                              name="verifier_id"
                              value={
                                !isEmpty(machines) ? machines.verifier_id : ""
                              }
                              size="small"
                              onChange={handleChange}
                            >
                              {verifierList.map((verify) => (
                                <MenuItem
                                  value={verify.machine_verifier_model_id}
                                  key={verify.machine_verifier_model_id}
                                >
                                  {verify.machine_verifier_model}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Scale
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Scale"
                              name="scale_id"
                              size="small"
                              onChange={handleChange}
                              value={
                                !isEmpty(machines) ? machines.scale_id : ""
                              }
                            >
                              {scalerList.map((scale) => (
                                <MenuItem
                                  value={scale.machine_scale_id}
                                  key={scale.machine_scale_id}
                                >
                                  {scale.name_of_machine_scale}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Box>
                    </Grid>
                    {/*   OCR */}
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <Box className="machineFields">
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Primary OCR
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Primary OCR"
                              name="primary_ocr_id"
                              size="small"
                              onChange={handleChange}
                              value={
                                !isEmpty(machines)
                                  ? machines.primary_ocr_id
                                  : ""
                              }
                            >
                              {primaryList.map((primary) => (
                                <MenuItem
                                  value={primary.machine_primary_ocr_id}
                                  key={primary.machine_primary_ocr_id}
                                >
                                  {primary.name_of_machine_primary_ocr}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Secondary OCR
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Secondary OCR"
                              name="secondary_ocr_id"
                              size="small"
                              value={
                                !isEmpty(machines)
                                  ? machines.secondary_ocr_id
                                  : ""
                              }
                              onChange={handleChange}
                            >
                              {secondaryList.map((secondary) => (
                                <MenuItem
                                  value={secondary.machine_secondary_ocr_id}
                                  key={secondary.machine_secondary_ocr_id}
                                >
                                  {secondary.name_of_machine_secondary_ocr}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Number of Bins"
                            size="small"
                            inputProps={{ maxLength: 4 }}
                            name="number_of_bins"
                            onChange={handleChange}
                            value={
                              !isEmpty(machines) ? machines.number_of_bins : ""
                            }
                          />

                          {submit && !machines.number_of_bins && (
                            <span className="errorMessage">
                              Number of Bin is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Bin Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label=" Bin Type"
                              name="bin_type_id"
                              size="small"
                              value={
                                !isEmpty(machines) ? machines.bin_type_id : ""
                              }
                              onChange={handleChange}
                            >
                              {bintypeList.map((bin) => (
                                <MenuItem
                                  value={bin.machine_bin_type_id}
                                  key={bin.machine_bin_type_id}
                                >
                                  {bin.machine_bin_type}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Doubles Detector
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Doubles Detectors"
                              name="doubles_detector_id"
                              size="small"
                              onChange={handleChange}
                              value={
                                !isEmpty(machines)
                                  ? machines.doubles_detector_id
                                  : ""
                              }
                            >
                              {doubleDetectorList.map((detect) => (
                                <MenuItem
                                  value={detect.doubles_detector_id}
                                  key={detect.doubles_detector_id}
                                >
                                  {detect.name_of_doubles_detector}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Thickness Detector
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Thickness Detectors"
                              name="thickness_detector_id"
                              size="small"
                              onChange={handleChange}
                              value={
                                !isEmpty(machines)
                                  ? machines.thickness_detector_id
                                  : ""
                              }
                            >
                              <MenuItem value="1">Yes</MenuItem>
                              <MenuItem value="2">N/A</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Box>
                    </Grid>
                    {/* Printer */}
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <Box className="machineFields">
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              IMB Printer
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Doubles Detectors"
                              name="imb_printer_id"
                              size="small"
                              onChange={handleChange}
                              value={
                                !isEmpty(machines)
                                  ? machines.imb_printer_id
                                  : ""
                              }
                            >
                              {imbPrinterList.map((imb) => (
                                <MenuItem
                                  value={imb.imb_printer_id}
                                  key={imb.imb_printer_id}
                                >
                                  {imb.name_of_imb_printer}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              COA Printer
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="COA Printer"
                              name="coa_printer_id"
                              size="small"
                              onChange={handleChange}
                              value={
                                !isEmpty(machines)
                                  ? machines.coa_printer_id
                                  : ""
                              }
                            >
                              {coaPrinterList.map((coa) => (
                                <MenuItem
                                  value={coa.machine_coa_printer_id}
                                  key={coa.machine_coa_printer_id}
                                >
                                  {coa.name_of_machine_coa_printer}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Endorsement Printer
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Endorsement Printer"
                              name="endorsement_printer_id"
                              size="small"
                              onChange={handleChange}
                              value={
                                !isEmpty(machines)
                                  ? machines.endorsement_printer_id
                                  : ""
                              }
                            >
                              {endorsementList.map((dorsement) => (
                                <MenuItem
                                  value={
                                    dorsement.machine_endorsement_printer_id
                                  }
                                  key={dorsement.machine_endorsement_printer_id}
                                >
                                  {dorsement.machine_endorsement_printer}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Graphics Printer
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Graphics Printer"
                              name="graphics_printer_id"
                              size="small"
                              onChange={handleChange}
                              value={
                                !isEmpty(machines)
                                  ? machines.graphics_printer_id
                                  : ""
                              }
                            >
                              {graphicsList.map((graphics) => (
                                <MenuItem
                                  value={graphics.graphics_printer_id}
                                  key={graphics.graphics_printer_id}
                                >
                                  {graphics.name_of_graphics_printer}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Labeler
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Labeler"
                              name="labeler_id"
                              size="small"
                              onChange={handleChange}
                              value={
                                !isEmpty(machines) ? machines.labeler_id : ""
                              }
                            >
                              {lablerList.map((labler) => (
                                <MenuItem
                                  value={labler.machine_labeler_id}
                                  key={labler.machine_labeler_id}
                                >
                                  {labler.name_of_machine_labeler}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Feeder
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Endorsement Printer"
                              name="feeder_id"
                              size="small"
                              onChange={handleChange}
                              value={
                                !isEmpty(machines) ? machines.feeder_id : ""
                              }
                            >
                              {feederList.map((feeder) => (
                                <MenuItem
                                  value={feeder.machine_feeder_id}
                                  key={feeder.machine_feeder_id}
                                >
                                  {feeder.name_of_machine_feeder}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {/* Productivity */}
              <Accordion
                sx={{ my: 2 }}
                className="Accordion"
                expanded={expanded === "panel2"}
                onChange={handleAccordionChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon className="accordion_icon" />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className="accordian_header"
                  sx={{
                    mb: 2,
                    background: "#693e75",
                    borderLeft: "7px solid #a88aae",
                  }}
                >
                  <Typography>
                    <b>Machine Productivity</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ mt: 2 }}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <Box className="machineFields">
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Max Throughput"
                            size="small"
                            type="number"
                            inputProps={{ maxLength: 32 }}
                            name="max_throughput"
                            onChange={handleChange}
                            value={
                              !isEmpty(machines) ? machines.max_throughput : ""
                            }
                          />
                          {submit && !machines.max_throughput && (
                            <span className="errorMessage">
                              Max Throughput is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <Grid lg={12} sx={{ pr: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                label="Expected Capable for Service"
                                name="capable_service"
                                onChange={(expected_service) =>
                                  handleDateChange(
                                    "expected_service",
                                    expected_service
                                  )
                                }
                                value={
                                  machines
                                    ? dayjs(machines.capable_service)
                                    : ""
                                }
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    fullWidth: true,
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                minDate={dayjs(new Date())}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <Box className="machineFields">
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Production Throughput"
                            size="small"
                            type="number"
                            inputProps={{ maxLength: 32 }}
                            name="production_throughput"
                            onChange={handleChange}
                            value={
                              !isEmpty(machines)
                                ? machines.production_throughput
                                : ""
                            }
                          />
                          {submit && !machines.production_throughput && (
                            <span className="errorMessage">
                              Production Throughput is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Actual Production Throughput"
                            size="small"
                            type="number"
                            disabled
                            inputProps={{ maxLength: 32 }}
                            name="actual_production_throughput"
                            onChange={handleChange}
                            value={
                              !isEmpty(machines)
                                ? machines.actual_production_throughput
                                : ""
                            }
                          />
                          {submit && !machines.actual_production_throughput && (
                            <span className="errorMessage">
                              Actual Production Throughput is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          {/* <h5 style={{ margin: "0 0 5px 0" }}>
                            Production Date Range
                          </h5> */}
                          <Grid container>
                            <Grid lg={12} sx={{ pr: 1 }}>
                              {" "}
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Production Date Range"
                                  name="production_date_range"
                                  value={
                                    machines
                                      ? dayjs(machines.production_date_range)
                                      : ""
                                  }
                                  onChange={(newDate) =>
                                    handleDateChange("from_date", newDate)
                                  }
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      fullWidth: true,
                                    },
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  minDate={dayjs(new Date())}
                                />
                              </LocalizationProvider>
                            </Grid>
                            {/* <Grid lg={6} sx={{ pl: 1 }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  fullWidth
                                  label="To Date"
                                  name="to_date"
                                  onChange={(newDate) =>
                                    handleDateChange("to_date", newDate)
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      fullWidth: true,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid> */}
                          </Grid>
                        </Grid>

                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Percentage realization production"
                            size="small"
                            type="number"
                            inputProps={{ maxLength: 32 }}
                            name="percentage_realization_production"
                            onChange={handleChange}
                            value={
                              !isEmpty(machines)
                                ? machines.percentage_realization_production
                                : ""
                            }
                          />
                          {submit &&
                            !machines.percentage_realization_production && (
                              <span className="errorMessage">
                                Percentage Production is required
                              </span>
                            )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Machine Enabled / Disable for Production{" "}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Disable for Production"
                              name="machine_enabled_disable_for_production"
                              size="small"
                              onChange={handleChange}
                              value={
                                !isEmpty(machines)
                                  ? machines.machine_enabled_disable_for_production
                                  : ""
                              }
                            >
                              <MenuItem value="Enabled">Enable</MenuItem>
                              <MenuItem value="Disabled">Disabled</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <Box className="machineFields">
                        <Grid item className="machineDetails">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              fullWidth
                              label="Last Preventive Maintenance completed"
                              onChange={(newDate) =>
                                handleDateChange(
                                  "last_preventive_maintenance",
                                  newDate
                                )
                              }
                              value={
                                machines ? dayjs(machines.last_preventive) : ""
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              name="last_preventive"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              minDate={dayjs(new Date())}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item className="machineDetails">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              fullWidth
                              label="Next Scheduled Preventive Maintenance"
                              value={
                                machines ? dayjs(machines.next_preventive) : ""
                              }
                              onChange={(newDate) =>
                                handleDateChange(
                                  "next_scheduled_preventive",
                                  newDate
                                )
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              name="next_preventive"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              minDate={dayjs(new Date())}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Preventive Maintenance Time (hrs.)"
                            size="small"
                            value={
                              !isEmpty(machines)
                                ? machines.preventive_maintenance_time_hrs
                                : ""
                            }
                            type="number"
                            inputProps={{ maxLength: 3 }}
                            name="preventive_maintenance_time_hrs"
                            onChange={handleChange}
                          />
                          {submit &&
                            !machines.preventive_maintenance_time_hrs && (
                              <span className="errorMessage">
                                Maintenance Hours is required
                              </span>
                            )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {/* Log */}
              <Accordion
                sx={{ my: 2 }}
                className="Accordion"
                expanded={expanded === "panel3"}
                onChange={handleAccordionChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon className="accordion_icon" />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="accordian_header"
                  sx={{
                    mb: 2,
                    background: "#693e75",
                    borderLeft: "7px solid #a88aae",
                  }}
                >
                  <Typography>
                    <b>Machine Log</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ mt: 2 }}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          label="Date"
                          value={machines ? dayjs(machines.date) : ""}
                          onChange={(newDate) =>
                            handleDateChange("date", newDate)
                          }
                          renderInput={(params) => <TextField {...params} />}
                          name="name"
                          slotProps={{
                            textField: {
                              size: "small",
                              fullWidth: true,
                            },
                          }}
                          minDate={dayjs(new Date())}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <TextField
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="Attach Log"
                        size="small"
                        inputProps={{ maxLength: 64 }}
                        logs_attached
                        name="logs_attached"
                        value={!isEmpty(machines) ? machines.logs_attached : ""}
                        onChange={handleChange}
                      />
                      {submit && !machines.logs_attached && (
                        <span className="errorMessage">
                          Logs Attached is required
                        </span>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <TextField
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="Comments"
                        multiline
                        maxRows={4}
                        size="small"
                        name="log"
                        value={!isEmpty(machines) ? machines.log : ""}
                        onChange={handleChange}
                      />
                      {submit && !machines.log && (
                        <span className="errorMessage">log is required</span>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {/* contacts */}
              <Accordion
                sx={{ my: 2 }}
                className="Accordion"
                expanded={expanded === "panel4"}
                onChange={handleAccordionChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon className="accordion_icon" />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className="accordian_header"
                  sx={{
                    mb: 2,
                    background: "#693e75",
                    borderLeft: "7px solid #a88aae",
                  }}
                >
                  <Typography>
                    <b>Maintenance Contacts</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ mt: 2 }}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Shift
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Shift"
                          name="shift_id"
                          onChange={handleChange}
                          value={!isEmpty(machines) ? machines.shift_id : ""}
                          className="shiftclass"
                        >
                          {maintenenceList.map((shift) => (
                            <MenuItem
                              value={shift.machine_maintenance_shift_id}
                              key={shift.machine_maintenance_shift_id}
                            >
                              {shift.machine_maintenance_shift}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      item
                      className="machineDetails"
                    >
                      <TextField
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="First Name"
                        size="small"
                        inputProps={{ maxLength: 32 }}
                        name="first_name"
                        onChange={handleChange}
                        value={!isEmpty(machines) ? machines.first_name : ""}
                      />
                      {submit && !machines.first_name && (
                        <span className="errorMessage">
                          First Name is required
                        </span>
                      )}
                    </Grid>
                    <Grid
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      item
                      className="machineDetails"
                    >
                      <TextField
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="Last Name"
                        size="small"
                        inputProps={{ maxLength: 32 }}
                        name="last_name"
                        onChange={handleChange}
                        value={!isEmpty(machines) ? machines.last_name : ""}
                      />
                      {submit && !machines.last_name && (
                        <span className="errorMessage">
                          Last Name is required
                        </span>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <TextField
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="Email"
                        size="small"
                        inputProps={{ maxLength: 64 }}
                        name="email"
                        onChange={handleChange}
                        value={!isEmpty(machines) ? machines.email : ""}
                        type="email"
                      />
                      {submit && !machines.email && (
                        <span className="errorMessage">Email is required</span>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <CustomPhoneInput
                        sx={{ mt: 2 }}
                        defaultCountry="us"
                        onlyCountries={["us"]}
                        disableCountryCode
                        country="us"
                        disableDropdown={true}
                        editable={machines.phone}
                        name="phone"
                        onChange={handleEditPhoneNumber}
                      />
                      {submit && !machines.phone && (
                        <span className="errorMessage">
                          Phonenumber is required
                        </span>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <TextField
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="Priority"
                        size="small"
                        inputProps={{ maxLength: 2 }}
                        name="priority"
                        value={!isEmpty(machines) ? machines.priority : ""}
                        onChange={handleChange}
                      />
                      {submit && !machines.priority && (
                        <span className="errorMessage">
                          Priority is required
                        </span>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box display="flex" justifyContent="end" gap={2}>
              <Button variant="outlined" type="submit" onClick={closeEdit}>
                Cancel
              </Button>
              <Button
                onClick={editData}
                variant="outlined"
                className="Btn"
                type="submit"
              >
                Update
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
