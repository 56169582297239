import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import "../style/style.css";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "../style/home.scss";
import {
  Avatar,
  Badge,
  Box,
  Collapse,
  Drawer,
  Menu,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Logo from "../assets/images/Apian_Combo-Logo_White 1.png";
import CopyrightsFooter from "../copyRightsFooter";
import ApianBee from "../assets/images/updatedApianBee.png";
import notficationIcon from "../assets/icons/notification.svg";
import SettingsIcon from "../assets/icons/settings.svg";
import ProfileIcon from "../assets/icons/profile.svg";
import SignoutIcon from "../assets/icons/signout.svg";
import BillingIcon from "../assets/icons/File Check.svg";
import ReportsIcon from "../assets/icons/5.svg";
import mspIcons from "../utils/icons";
import axios from "axios";
import { apiBaseUrl } from "../config";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { handleWorkOrderChange } from "../store/WorkOrderCreation";
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 260;
const sideMenus = [
  {
    id: "1",
    name: "Configuration",
    icon: mspIcons.Configuration,
    activeIcon: mspIcons.ConfigurationActive,
    path: "/msp/user",
    submenu: [
      {
        id: "8-1",
        name: "MSP Role Maintenance",
        icon: mspIcons.RoleMaintenance,
        activeIcon: mspIcons.RoleMaintenanceActive,
        path: "/msp/msproles",
      },
      {
        id: "1-1",
        name: "MSP Users",
        icon: mspIcons.User,
        activeIcon: mspIcons.UserActive,
        path: "/msp/user",
      },
      {
        id: "2-1",
        name: "Sites",
        icon: mspIcons.Sites,
        activeIcon: mspIcons.SitesActive,
        path: "/msp/sites",
      },
      {
        id: "3-1",
        name: "Machines",
        icon: mspIcons.Machines,
        activeIcon: mspIcons.MachinesActive,
        path: "/msp/machine",
      },

      {
        id: "4-1",
        name: "Mail Owners",
        icon: mspIcons.MailOwner,
        activeIcon: mspIcons.MailOwnerActive,
        path: "/msp/mail-owners",
      },
      {
        id: "5-1",
        name: "Sort Profiles",
        icon: mspIcons.SortProfile,
        activeIcon: mspIcons.SortProfileActive,
        path: "/msp/sort-profiles",
      },
      {
        id: "6-1",
        name: "Mailing Archiving",
        icon: mspIcons.MailArchiving,
        activeIcon: mspIcons.MailArchivingActive,
        path: "/msp/mailing-archiving",
      },
      {
        id: "7-1",
        name: "Mailings",
        icon: mspIcons.Mailings,
        activeIcon: mspIcons.MailingsActive,
        path: "/msp/mailings",
      },
    ],
  },
  {
    id: "2",
    name: "Tools",
    icon: mspIcons.Tools,
    activeIcon: mspIcons.ToolsActive,
    path: "/msp/tray-tags",
    submenu: [
      {
        id: "8-2",
        name: "Tray Tags",
        icon: mspIcons.TrayTags,
        activeIcon: mspIcons.TrayTagsActive,
        path: "/msp/tray-tags",
      },
      {
        id: "9-2",
        name: "Pallet Placard",
        icon: mspIcons.PalletPlacard,
        activeIcon: mspIcons.PalletPlacardActive,
        path: "/msp/pallet-placard",
      },
      {
        id: "10-2",
        name: "Helm",
        icon: mspIcons.Helm,
        activeIcon: mspIcons.HelmActive,
        path: "/msp/helm",
      },
    ],
  },
  {
    id: "3",
    name: "Reporting",
    icon: mspIcons.Reporting,
    activeIcon: mspIcons.ReportingActive,
    path: "/msp/postal-reports",
    submenu: [
      {
        id: "11-3",
        name: "Postal Reports",
        icon: mspIcons.PostalReports,
        activeIcon: mspIcons.PostalReportsActive,
        path: "/msp/postal-reports",
      },
      {
        id: "12-3",
        name: "Billing Reports",
        icon: mspIcons.BillingReports,
        activeIcon: mspIcons.BillingReportsActive,
        path: "/msp/billing-reports",
      },
      {
        id: "13-3",
        name: "Productivity Reports",
        icon: mspIcons.ProductivityReports,
        activeIcon: mspIcons.ProductivityReportsActive,
        path: "/msp/productivity-reports",
      },
      {
        id: "14-3",
        name: "Machine Reports",
        icon: mspIcons.MachineReports,
        activeIcon: mspIcons.MachineReportActive,
        path: "/msp/machine-reports",
      },
    ],
  },
  {
    id: "4",
    name: "Production",
    icon: mspIcons.Production,
    activeIcon: mspIcons.ProductionActive,
    path: "/msp/upload-file",
    submenu: [
      {
        id: "15-4",
        name: "Job",
        icon: mspIcons.Jobs,
        activeIcon: mspIcons.JobsActive,
        path: "/msp/upload-file",
      },
      {
        id: "16-4",
        name: "Generate Mail.Dat",
        icon: mspIcons.GenerateDat,
        activeIcon: mspIcons.GenerateDatActive,
        path: "/msp/generate",
      },
      {
        id: "17-4",
        name: "Receiving",
        icon: mspIcons.Receiving,
        activeIcon: mspIcons.ReceivingActive,
        path: "/msp/receiving",
      },
      {
        id: "18-4",
        name: "Pieces",
        icon: mspIcons.Pieces,
        activeIcon: mspIcons.PiecesActive,
        path: "/msp/routers",
        submenu: [],
      },
      // {
      //   id: "19-4",
      //   name: "Work Order",
      //   icon: mspIcons.MachineReports,
      //   activeIcon: mspIcons.MachineReportActive,
      //   path: "/msp/workordersheet",
      //   submenu: [],
      // },
    ],
  },
  {
    id: "5",
    name: "Editor",
    icon: mspIcons.Reporting,
    activeIcon: mspIcons.ReportingActive,
    path: "/msp/editor",
    submenu: [
      {
        id: "5-1",
        name: "Account Profile Editor",
        icon: ProfileIcon,
        activeIcon: ProfileIcon,
        path: "/msp/editor",
      },
      {
        id: "5-2",
        name: "Site Editor",
        icon: ProfileIcon,
        activeIcon: ProfileIcon,
        path: "/msp/site-editor",
      },
      {
        id: "5-3",
        name: "Mailing Editor",
        icon: ProfileIcon,
        activeIcon: ProfileIcon,
        path: "/msp/mailing-editor",
      },
      {
        id: "5-4",
        name: "Sort Profile Editor",
        icon: ProfileIcon,
        activeIcon: ProfileIcon,
        path: "/msp/sort-editor",
      },
    ],
  },
];

const customerMenus = [
  {
    id: "1",
    name: "Configuration",
    icon: mspIcons.Configuration,
    activeIcon: mspIcons.ConfigurationActive,
    path: "/customer-portal/roles",
    submenu: [
      {
        id: "4-1",
        name: "Mail Owner Roles",
        icon: mspIcons.RoleMaintenance,
        activeIcon: mspIcons.RoleMaintenanceActive,
        path: "/customer-portal/roles",
      },
      {
        id: "4-2",
        name: "Mail Owner Users",
        icon: mspIcons.User,
        activeIcon: mspIcons.UserActive,
        path: "/customer-portal/users",
      },
    ],
  },
  {
    id: "2",
    name: "Reporting",
    icon: mspIcons.Reporting,
    activeIcon: mspIcons.ReportingActive,
    path: "/customer-portal/upload",
    submenu: [
      {
        id: "4-3",
        name: "Status Monitor",
        icon: mspIcons.MachineReports,
        activeIcon: mspIcons.MachineReportActive,
        path: "/customer-portal/status-monitor",
      },
    ],
  },
  {
    id: "3",
    name: "Production",
    icon: mspIcons.Production,
    activeIcon: mspIcons.ProductionActive,
    path: "/customer-portal/upload",
    submenu: [
      {
        id: "4-4",
        name: "Work Order",
        icon: mspIcons.MachineReports,
        activeIcon: mspIcons.MachineReportActive,
        path: "/customer-portal/workorder",
      },
      // {
      //   id: "4-5",
      //   name: "Receipt Ticket",
      //   icon: mspIcons.MachineReports,
      //   activeIcon: mspIcons.MachineReportActive,
      //   path: "/customer-portal/receiptCreation",
      // },
    ],
  },
];

const eiiMenus = [
  {
    id: "1",
    name: "Configuration",
    icon: mspIcons.Configuration,
    activeIcon: mspIcons.ConfigurationActive,
    path: "/eii",
    submenu: [
      {
        id: "1-1",
        name: "EII Role Maintenance",
        icon: mspIcons.RoleMaintenance,
        activeIcon: mspIcons.RoleMaintenanceActive,
        path: "/eii/roles",
      },
      {
        id: "2-1",
        name: "MSP Creation",
        icon: mspIcons.MSPCreation,
        activeIcon: mspIcons.MSPCreationActive,

        path: "/eii/mspcreation",
      },
      {
        id: "3-1",
        name: "EII User",
        icon: mspIcons.EiiUser,
        activeIcon: mspIcons.EiiUserActive,

        path: "/eii/usercreation",
      },
      {
        id: "4-1",
        name: "Machines",
        icon: mspIcons.Machines,
        activeIcon: mspIcons.MachinesActive,
        path: "/eii/machine",
      },
    ],
  },
  {
    id: "2",
    name: "Tools",
    icon: mspIcons.Tools,
    activeIcon: mspIcons.ToolsActive,
    submenu: [],
    path: "/eii",
  },
  {
    id: "3",
    name: "Reporting",
    icon: mspIcons.Reporting,
    activeIcon: mspIcons.ReportingActive,
    path: "/eii",
    submenu: [
      {
        id: "3-1",
        name: "Reports",
        icon: ReportsIcon,
        activeIcon: ReportsIcon,

        path: "/eii",
      },
    ],
  },
  {
    id: "4",
    name: "Production",
    icon: mspIcons.Production,
    activeIcon: mspIcons.ProductionActive,
    path: "/eii",
    submenu: [
      {
        id: "4-1",
        name: "Billing",
        icon: BillingIcon,
        activeIcon: BillingIcon,

        path: "/eii",
      },
    ],
  },
];

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const LogoWrap = styled("div")(() => ({
  height: 64,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& > img": {
    height: "auto",
    width: "100%",
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawers = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Layout({ element }) {
  const navigate = useNavigate();

  const [eiiFilteredMenu, setEiifilteredMenu] = useState([]);
  const [mspFilteredMenu, setMspFilteredMenu] = useState([]);
  const [moFilteredMenu, setMoFilteredMenu] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [subMenu, setSubMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [getId, setGetId] = React.useState<string | null>(null);
  const [getSubmenuId, setGetSubmenuId] = React.useState<string | null>(null);
  const [header, setHeader] = React.useState(null);
  const [mspList, setMspList] = React.useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const useTypedSelector: TypedUseSelectorHook<any> = useSelector;

  const { msp_id } = useTypedSelector((state) => state.workOrder);
  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openOption = () => {
    setSubMenu(!subMenu);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (path?: string) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    if (path) navigate(path);
  };

  const name = localStorage.getItem("msp_name");

  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
        >
          <Avatar
            alt="Remy Sharp"
            src="https://mui.com/static/images/avatar/1.jpg"
          />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  const subDomain = localStorage.getItem("role_type");

  let toolbarClassName;

  if (subDomain === "MSP") {
    toolbarClassName = "mspToolbar";
  } else if (subDomain === "MAIL_OWNER") {
    toolbarClassName = "customerToolbar";
  } else {
    toolbarClassName = "eiiToolbar";
  }

  let color;
  let background;

  if (subDomain === "EII") {
    color = "#fff";
    background = "#00344E";
  } else if (subDomain === "MSP") {
    color = "#00344e";
    background = "#fff";
  } else {
    color = "#fff";
    background = "#43A2CD";
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={() => handleMenuClose()}
      className={"profileMenu"}
    >
      <MenuItem>
        <div className="profileEmail">
          <p>{localStorage.getItem("user_name")}</p>
          <p style={{ color: "#43A2CD", fontSize: "14px" }}>
            {localStorage.getItem("email")}
          </p>
        </div>
      </MenuItem>

      <Divider />
      <MenuItem>
        <Box display="flex" alignItems="center" gap="10px">
          <img src={SettingsIcon} alt="settingsicon" />
          <span>Settings</span>
        </Box>
      </MenuItem>
      <MenuItem>
        <Box display="flex" alignItems="center" gap="10px">
          <img src={ProfileIcon} alt="profileicon" />
          <span>Profile</span>
        </Box>
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          localStorage.clear();
          navigate("/sign-in");
        }}
      >
        <Box display="flex" alignItems="center" gap="10px">
          <img src={SignoutIcon} alt="singouticon" />
          <span> Sign out</span>
        </Box>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    if (subDomain == "EII") {
      EiiPermission();
    } else if (subDomain == "MSP") {
      MspPermission();
    } else {
      MOPermission();
      switchMspList();
    }
  }, [subDomain]);

  const EiiPermission = async () => {
    const token = localStorage.getItem("auth");
    const role_id = localStorage.getItem("role_id");
    const response = await axios.get(
      `${apiBaseUrl}eii_role_group_function_mapping_list?role_id=${role_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      const allowedFunctions = new Set(
        response?.data?.eii_role_group_function_mapping_list
          .filter((mapping) => {
            const {
              role_read_flag,
              role_delete_flag,
              role_create_flag,
              role_update_flag,
            } = mapping.msp_role_group_function_mapping_list;
            return (
              role_read_flag !== 0 ||
              role_delete_flag !== 0 ||
              role_create_flag !== 0 ||
              role_update_flag !== 0
            );
          })
          .map((mapping) => mapping.role_function_name)
      );

      const filteredMenu = eiiMenus
        .map((menuItem) => {
          const filteredSubmenu = menuItem.submenu.filter((subItem) =>
            allowedFunctions.has(subItem.name)
          );
          return { ...menuItem, submenu: filteredSubmenu };
        })
        .filter((menuItem) => menuItem.submenu.length > 0);
      setEiifilteredMenu(filteredMenu);
    }
  };
  const MspPermission = async () => {
    const token = localStorage.getItem("auth");
    const role_id = localStorage.getItem("role_id");
    const response = await axios.get(
      `${apiBaseUrl}msp_role_group_function_mapping_list?role_id=${role_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      const allowedFunctions = new Set(
        response.data.msp_role_group_function_mapping_list
          .filter((mapping) => {
            const {
              role_read_flag,
              role_delete_flag,
              role_create_flag,
              role_update_flag,
            } = mapping.msp_role_group_function_mapping_list;
            return (
              role_read_flag !== 0 ||
              role_delete_flag !== 0 ||
              role_create_flag !== 0 ||
              role_update_flag !== 0
            );
          })
          .map((mapping) => mapping.role_function_name)
      );

      const filteredMenu = sideMenus
        .map((menuItem) => {
          const filteredSubmenu = menuItem.submenu.filter((subItem) =>
            allowedFunctions.has(subItem.name)
          );
          return { ...menuItem, submenu: filteredSubmenu };
        })
        .filter((menuItem) => menuItem.submenu.length > 0);
      setMspFilteredMenu(filteredMenu);
    }
  };
  const MOPermission = async () => {
    const token = localStorage.getItem("auth");
    const role_id = localStorage.getItem("role_id");
    const response = await axios.get(
      `${apiBaseUrl}mail_owner_role_group_function_mapping_list?role_id=${role_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      const allowedFunctions = new Set(
        response.data.mo_role_group_function_mapping_list
          .filter((mapping) => {
            const {
              role_read_flag,
              role_delete_flag,
              role_create_flag,
              role_update_flag,
            } = mapping.mo_role_group_function_mapping_list;
            return (
              role_read_flag !== 0 ||
              role_delete_flag !== 0 ||
              role_create_flag !== 0 ||
              role_update_flag !== 0
            );
          })
          .map((mapping) => mapping.role_function_name)
      );

      const filteredMenu = customerMenus
        .map((menuItem) => {
          const filteredSubmenu = menuItem.submenu.filter((subItem) =>
            allowedFunctions.has(subItem.name)
          );
          return { ...menuItem, submenu: filteredSubmenu };
        })
        .filter((menuItem) => menuItem.submenu.length > 0);
      setMoFilteredMenu(filteredMenu);
    }
  };
  const switchMspList = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}get_mo_msp_list`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      });
      if (response) {
        setMspList(response.data);
      }
    } catch (error) {
      console.log("Err==>", error);
    }
  };
  const handleSwitchChange = (e) => {
    const selectedMsp = mspList?.find(
      (item) => item.msp_account_guid === e.target.value
    );
console.log("mspSwitch" ,selectedMsp );

    if (selectedMsp) {
      localStorage.setItem("switchMsp", JSON.stringify(selectedMsp));
      dispatch(
        handleWorkOrderChange({
          field: "msp_id",
          value: selectedMsp?.msp_account_guid,
        })
      );
      dispatch(
        handleWorkOrderChange({
          field: "msp_name",
          value: selectedMsp?.msp_name,
        })
      );
    } else {
      console.error("MSP not found");
    }
  };
  return (
    <Box sx={{ display: "flex" }} className="dashboard">
      <CssBaseline />
      <AppBar position="fixed" open={open} className="appBar">
        <Toolbar className={toolbarClassName}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClose}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open ? { display: "block" } : { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {`${subDomain} Dashboard`}
          </Typography>
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />

          <Box
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          >
            {subDomain === "MAIL_OWNER" && (
              <FormControl className="switch-msp-select">
                <InputLabel id="demo-simple-select-label">
                  Switch MSP
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="for_name"
                  size="small"
                  onChange={(e) => {
                    handleSwitchChange(e);
                  }}
                  value={msp_id || ""}
                  name="for_name"
                >
                  {mspList?.map((item) => (
                    <MenuItem
                      key={item.msp_account_guid}
                      value={item.msp_account_guid}
                    >
                      {item.msp_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <img
              src={notficationIcon}
              className="actionIcons"
              alt="notificationIcon"
            />
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              sx={{ marginLeft: "10px" }}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  color: color,
                  background: background,
                }}
              >
                {localStorage
                  .getItem("user_name")
                  .split(" ")
                  .map((word) => word[0])
                  .join("")}
              </Avatar>
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawers variant="permanent" open={open} className="drawers">
        <DrawerHeader className="drawerHeader">
          <LogoWrap>
            {subDomain === "MSP" && (
              <div>
                <h2 style={{ margin: "0" }}>{name}</h2>
              </div>
            )}
            {subDomain === "EII" && (
              <img src={Logo} alt="logo" style={{ width: "100%" }} />
            )}
            {subDomain === "MAIL_OWNER" && (
              <div>
                <h2 style={{ margin: "0" }}>{name}</h2>
              </div>
            )}
          </LogoWrap>
        </DrawerHeader>
        <Divider />
        <List className="menuList">
          {subDomain === "MAIL_OWNER" &&
            moFilteredMenu.map((item, i) => (
              <ListItem
                className="eiiMenus"
                key={item.id}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    flexWrap: "wrap",
                  }}
                  onClick={() => {
                    // item.path && navigate(item.path);
                    setGetId(item.id);
                    const header = customerMenus.find(
                      (headings) => headings.id === item.id
                    );
                    openOption();
                    setHeader(header);
                    setGetSubmenuId("");
                  }}
                  className={
                    getSubmenuId
                      ? "inActive"
                      : getId === item.id
                      ? "active"
                      : "inActive"
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "inherit",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src={getId === item.id ? item.activeIcon : item.icon}
                      alt="submenu icons"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {item.submenu &&
                    (item.id === getId && subMenu ? (
                      <ExpandLess onClick={openOption} />
                    ) : (
                      <ExpandMore onClick={openOption} />
                    ))}{" "}
                </ListItemButton>
                {item.submenu?.map((option: any) => (
                  <Collapse
                    in={item.id === getId && subMenu}
                    timeout="auto"
                    unmountOnExit
                    key={option.id}
                  >
                    <List
                      component="div"
                      className="submenuList"
                      disablePadding
                    >
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(option.path);
                          setGetSubmenuId(option.id);
                          const header = item.submenu.find(
                            (headings: any) => headings.id === option.id
                          );
                          setHeader(header);
                        }}
                        className={
                          getSubmenuId === option.id ? "active " : "inActive"
                        }
                      >
                        <ListItemIcon sx={{ color: "white", minWidth: "30px" }}>
                          <img
                            src={
                              getSubmenuId === option.id
                                ? option.activeIcon
                                : option.icon
                            }
                            alt="optionIcon"
                            title={option.name}
                          />{" "}
                        </ListItemIcon>
                        <ListItemText
                          primary={option.name}
                          sx={{ opacity: open ? 1 : 0 }}
                          // onClick={closeOption}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                ))}
              </ListItem>
            ))}
          {subDomain === "MSP" &&
            mspFilteredMenu.map((item, i) => (
              <ListItem
                className="eiiMenus"
                key={i}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    flexWrap: "wrap",
                  }}
                  onClick={() => {
                    // navigate(item.path);
                    setGetId(item.id);
                    const header = sideMenus.find(
                      (headings) => headings.id === item.id
                    );
                    openOption();
                    setHeader(header);
                    setGetSubmenuId("");
                  }}
                  className={
                    getSubmenuId
                      ? "inActive"
                      : getId === item.id
                      ? "active"
                      : "inActive"
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "inherit",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src={getId === item.id ? item.activeIcon : item.icon}
                      alt="activeIcon"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {item?.submenu &&
                    (item.id === getId && subMenu ? (
                      <ExpandLess onClick={openOption} />
                    ) : (
                      <ExpandMore onClick={openOption} />
                    ))}{" "}
                </ListItemButton>
                {item?.submenu?.map((option: any) => (
                  <Collapse
                    in={item.id === getId && subMenu}
                    timeout="auto"
                    unmountOnExit
                    key={option.id}
                  >
                    <List
                      component="div"
                      className="submenuList"
                      disablePadding
                    >
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(option.path);
                          setGetSubmenuId(option.id);
                          const header = item.submenu.find(
                            (headings: any) => headings.id === option.id
                          );
                          setHeader(header);
                        }}
                        className={
                          getSubmenuId === option.id ? "active " : "inActive"
                        }
                      >
                        <ListItemIcon sx={{ color: "white", minWidth: "30px" }}>
                          <img
                            src={
                              getSubmenuId === option.id
                                ? option.activeIcon
                                : option.icon
                            }
                            alt=""
                            title={option.name}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={option.name}
                          sx={{ opacity: open ? 1 : 0 }}
                          // onClick={closeOption}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                ))}
              </ListItem>
            ))}

          {subDomain === "EII" &&
            eiiFilteredMenu.map((item) => (
              <ListItem
                className="eiiMenus"
                key={item.id}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    flexWrap: "wrap",
                  }}
                  onClick={() => {
                    // navigate(item.path);
                    setGetId(item.id);
                    const header = eiiMenus.find(
                      (headings) => headings.id === item.id
                    );
                    openOption();
                    setHeader(header);
                    setGetSubmenuId("");
                  }}
                  className={
                    getSubmenuId
                      ? "inActive"
                      : getId === item.id
                      ? "active"
                      : "inActive"
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "inherit",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src={getId === item.id ? item.activeIcon : item.icon}
                      alt="itemIcon"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {item.submenu &&
                    (item.id === getId && subMenu ? (
                      <ExpandLess onClick={openOption} />
                    ) : (
                      <ExpandMore onClick={openOption} />
                    ))}{" "}
                </ListItemButton>
                {item.submenu?.map((option: any) => (
                  <Collapse
                    in={item.id === getId && subMenu}
                    timeout="auto"
                    unmountOnExit
                    key={option.id}
                  >
                    <List
                      component="div"
                      className="submenuList"
                      disablePadding
                    >
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(option.path);
                          setGetSubmenuId(option.id);
                          const header = item.submenu.find(
                            (headings: any) => headings.id === option.id
                          );
                          setHeader(header);
                        }}
                        className={
                          getSubmenuId === option.id ? "active " : "inActive"
                        }
                      >
                        <ListItemIcon sx={{ color: "white", minWidth: "30px" }}>
                          <img
                            src={
                              getSubmenuId === option.id
                                ? option.activeIcon
                                : option.icon
                            }
                            alt="submenuIcon"
                            title={option.name}
                          />{" "}
                        </ListItemIcon>
                        <ListItemText
                          primary={option.name}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                ))}
              </ListItem>
            ))}
        </List>
        <Box>
          <img
            src={ApianBee}
            alt="apianBee"
            style={
              open
                ? { position: "fixed", bottom: "15px", left: "15px" }
                : {
                    display: "none",
                  }
            }
          />
        </Box>
      </Drawers>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          background: "#ffffff",
          minHeight: "100vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <DrawerHeader />
        <Outlet />
        {element}
        <CopyrightsFooter />
      </Box>
    </Box>
  );
}
