import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { MachineService } from "../services/MachineService";
import { sitesService } from "../services/sitesService";

const initialState = {
  loading: false,
  ownersList: [],
  machineList: [],
  manufacturerList: [],
  modelList: [],
  primaryList: [],
  secondaryList: [],
  verifierList: [],
  bintypeList: [],
  doubleDetectorList: [],
  imbPrinterList: [],
  coaPrinterList: [],
  scalerList: [],
  lablerList: [],
  graphicsList: [],
  endorsementList: [],
  feederList: [],
  maintenenceList: [],
  siteList: [],
  maintenanceId: [],
  error: null,
  machineTableList: [],
  reorderedList: []
};

const Machines = createSlice({
  name: "machines",
  initialState: initialState,
  reducers: {
    fetchDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDataSuccess: (state, action) => {
      state.loading = false;
      state.ownersList = action.payload.ownersList;
      state.machineList = action.payload.machineList;
      state.manufacturerList = action.payload.manufacturerList;
      state.modelList = action.payload.modelList;
      state.primaryList = action.payload.primaryList;
      state.secondaryList = action.payload.secondaryList;
      state.verifierList = action.payload.verifierList;
      state.bintypeList = action.payload.bintypeList;
      state.doubleDetectorList = action.payload.doubleDetectorList;
      state.imbPrinterList = action.payload.imbPrinterList;
      state.coaPrinterList = action.payload.coaPrinterList;
      state.scalerList = action.payload.scalerList;
      state.lablerList = action.payload.lablerList;
      state.graphicsList = action.payload.graphicsList;
      state.endorsementList = action.payload.endorsementList;
      state.feederList = action.payload.feederList;
      state.maintenenceList = action.payload.maintenenceList;
      state.siteList = action.payload.siteList;
      state.maintenanceId = action.payload.maintenanceId;
      state.error = null;
    },
    fetchDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    checkIfExist: (state, action) => {
      state.loading = false;
    },
    machineTableFilter: (state, action) => {
      state.machineTableList = action.payload
    },
    reorderedTableFilter: (state, action) => {
      state.reorderedList = action.payload
    }
  },
});

export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataError,
  checkIfExist,
  machineTableFilter,
  reorderedTableFilter
} = Machines.actions;
export default Machines.reducer;

export const fetchMachineData = () => async (dispatch, getState) => {
  dispatch(fetchDataStart());
  const state = getState();
  try {
    if (
      state.machines.ownersList.length !== 0 ||
      state.machines.machineList.length !== 0 ||
      state.machines.manufacturerList.length !== 0 ||
      state.machines.modelList.length !== 0 ||
      state.machines.primaryList.length !== 0 ||
      state.machines.secondaryList.length !== 0 ||
      state.machines.verifierList.length !== 0 ||
      state.machines.bintypeList.length !== 0 ||
      state.machines.doubleDetectorList.length !== 0 ||
      state.machines.imbPrinterList.length !== 0 ||
      state.machines.coaPrinterList.length !== 0 ||
      state.machines.scalerList.length !== 0 ||
      state.machines.lablerList.length !== 0 ||
      state.machines.graphicsList.length !== 0 ||
      state.machines.endorsementList.length !== 0 ||
      state.machines.feederList.length !== 0 ||
      state.machines.maintenenceList.length !== 0 ||
      state.machines.siteList.length !== 0 ||
      state.machines.maintenanceId.length !== 0
    ) {
      dispatch(checkIfExist());
      return false;
    } else {
      const [
        ownerlistResponse,
        machineListResponse,
        manufacturerListResponse,
        modelListResponse,
        primaryListResponse,
        secondaryListResponse,
        verifierListResponse,
        bintypeListResponse,
        doubledetectorListResponse,
        imbPrinterListResponse,
        coaprinterListResponse,
        scaleListResponse,
        lablerListResponse,
        graphicsListResponse,
        endorsementListResponse,
        feederListResponse,
        maintenenceListResponse,
        maintenanceIdListResponse,
      ] = await axios.all([
        MachineService.getOwnerListDetails(),
        MachineService.getMachinesList(),
        MachineService.getMachineManufacturerList(),
        MachineService.getMachineModelList(),
        MachineService.getMachinePrimaryList(),
        MachineService.getMachineSecondaryList(),
        MachineService.getMachineVerifierList(),
        MachineService.getMachineBintypeList(),
        MachineService.getMachineDoubleDetectorList(),
        MachineService.getMachineImbPrinterList(),
        MachineService.getMachineCoaPrinterList(),
        MachineService.getMachineScaleList(),
        MachineService.getMachinelabelerList(),
        MachineService.getMachinegraphicsList(),
        MachineService.getMachineEndorsementList(),
        MachineService.getMachineFeederList(),
        MachineService.getMachineMaintenanceList(),
        MachineService.getOwnerDetails(),
      ]);

      dispatch(
        fetchDataSuccess({
          ownersList: ownerlistResponse?.data?.msp_account_list,
          machineList: machineListResponse.data,
          manufacturerList:
            manufacturerListResponse?.data?.machine_manufacturer_list,
          modelList: modelListResponse?.data?.machine_models_list,
          primaryList: primaryListResponse.data.machine_primary_ocr_list,
          secondaryList:
            secondaryListResponse?.data?.machine_secondary_ocr_list,
          verifierList:
            verifierListResponse?.data?.machine_verifier_models_list,
          bintypeList: bintypeListResponse.data.machine_bin_type_list,
          doubleDetectorList:
            doubledetectorListResponse.data.machine_doubles_detector_list,
          imbPrinterList: imbPrinterListResponse.data.machine_imb_printer_list,
          coaPrinterList: coaprinterListResponse.data.machine_coa_printer_list,
          scalerList: scaleListResponse.data.machine_scale_list,
          lablerList: lablerListResponse.data.machine_labeler_list,
          graphicsList: graphicsListResponse.data.machine_graphics_printer_list,
          endorsementList:
            endorsementListResponse.data.machine_endorsement_printer_list,
          feederList: feederListResponse.data.machine_feeder_list,
          maintenenceList:
            maintenenceListResponse.data.machine_maintenance_shift,
          maintenanceId:
            maintenanceIdListResponse.data.machine_maintenance_contact,
        })
      );
    }
  } catch (error) {
    dispatch(fetchDataError(error));
  }
};

export const fetchMachineTable = (checkedItems) => (dispatch, getState) => {
  dispatch(machineTableFilter(checkedItems));
};

export const fetchReorderedList = (reorderedItems) => (dispatch, getState) => {
  dispatch(reorderedTableFilter(reorderedItems));
};

