import {
  get,
  deleteRequest,
  postFormData,
  putFormData,
  post,
} from "./BaseService";
import { apiBaseUrl } from "../config";

export const RolesService = {
  getEiiRolesList,
  deleteEiiRoles,
  AddEiiRoles,
  UpdateEiiRoles,
  getPermissonRolesList,
  getEiiRolesFunctionsList,
  createEiiRoleMapping,
  getEiiRolePermissions,
  getEiiRoles,
  deleteMSPRoles,
  AddMSPRoles,
  getMoRoles,
  getMoRolePermissions,
};

function getEiiRolesList() {
  const url = `get_eii_roles_list`;
  return get(apiBaseUrl + url);
}

function getEiiRoles() {
  const url = `eii_roles_list`;
  return get(apiBaseUrl + url);
}

function getEiiRolesFunctionsList() {
  const url = `get_eii_role_function`;
  return get(apiBaseUrl + url);
}

function getPermissonRolesList() {
  const url = `get_eii_roles_list`;
  return get(apiBaseUrl + url);
}

function AddEiiRoles(data) {
  const url = `eii_role_creation`;
  return postFormData(apiBaseUrl + url, data);
}

function UpdateEiiRoles(data) {
  const url = `eii_role_update`;
  return putFormData(apiBaseUrl + url, data);
}

function deleteEiiRoles(data) {
  const url = `eii_role_deletion`;
  return deleteRequest(apiBaseUrl + url, data);
}
function createEiiRoleMapping(data, roleId) {
  const queryParams = data
    .map((obj) =>
      Object.keys(obj)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        )
        .join("&")
    )
    .join("&");
  const url =
    `create_eii_role_group_function_mapping?role_id=${roleId}&` + queryParams;
  return post(apiBaseUrl + url);
}
function getEiiRolePermissions(roleId) {
  const url = `eii_role_group_function_mapping_list?role_id=${roleId}`;
  return get(apiBaseUrl + url);
}

// MSP Roles

function AddMSPRoles(data) {
  const url = `msp_role_creation`;
  return postFormData(apiBaseUrl + url, data);
}

function deleteMSPRoles(data) {
  const url = `msp_role_deletion`;
  return deleteRequest(apiBaseUrl + url, data);
}
//Mail owner Roles
function getMoRoles() {
  const url = `get_only_mail_owner_roles_list_for_user`;
  return get(apiBaseUrl + url);
}
function getMoRolePermissions(roleId) {
  const url = `mail_owner_role_group_function_mapping_list?role_id=${roleId}`;
  return get(apiBaseUrl + url);
}
