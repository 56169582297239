import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  Box,
} from "@mui/material";
import { apiBaseUrl } from "../../../../config";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export default function SiteEditorMachine({ detailValue }) {
  const [siteEditorMachinesList, setSiteEditorMachinesList] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const { id } = useParams();

  const baseUrl = apiBaseUrl;

  useEffect(() => {
    if (id) {
      getMachineDetailsList();
    }
  }, [id]);

  const getMachineDetailsList = async () => {
    try {
      axios
        .get(`${baseUrl}get_site_machine_list`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
          params: {
            site_guid: id,
            // page: page + 1,
            // page_size: pageSize,
          },
        })
        .then((response) => {
          setSiteEditorMachinesList(response.data);
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageSize(event.target.value);
  };

  return (
    <div className="machineTable contact-details site-details-tab">
      <Box className="sitedetails-header" sx={{ mb: 3 }}>
        <Typography>
          <b>Site Machines</b>
        </Typography>
      </Box>
      {detailValue?.site_id ? (
        <Box>
          <TableContainer
            component={Paper}
            className="machineDataTable site-editor-table"
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Machine Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {siteEditorMachinesList?.length > 0 ? (
                  siteEditorMachinesList?.map((row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {row?.machine_name}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Typography variant="h6" align="center">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={10}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      ) : (
        <Box className="notassigned">
          <Typography variant="h5">Machines are not yet assigned</Typography>
        </Box>
      )}
    </div>
  );
}
