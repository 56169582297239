import React, { useState, useEffect, useReducer, useRef } from "react";
import {
  Box,
  Checkbox,
  Modal,
  Fade,
  Typography,
  Grid,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Backdrop,
} from "@mui/material";
import "../../../style/style.css";
import CloseIcon from "../../../assets/icons/Close Square.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { apiBaseUrl } from "../../../config";
import { RolesService } from "../../../services/RolesService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "50%",
  maxWidth: "780px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};
const initialState = [
  {
    role_group_id: "",
    role_function_id: "",
    role_create_flag: 0,
    role_read_flag: 0,
    role_update_flag: 0,
    role_delete_flag: 0,
  },
];
const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_ROLES":
      let findIndex = state.findIndex(
        (val) =>
          val.role_function_id === action.values?.role_function_id &&
          val.role_group_id === action.values?.role_group_id
      );
      if (findIndex != -1) {
        let updateValue = { ...state[findIndex], ...action.values };
        state[findIndex] = updateValue;
        return [...state];
      } else if (state[0].role_function_id == "") {
        return [{ ...state[0], ...action.values }];
      } else {
        return [
          ...state,
          ...[
            {
              ...{
                role_group_id: "",
                role_function_id: "",
                role_create_flag: 0,
                role_read_flag: 0,
                role_update_flag: 0,
                role_delete_flag: 0,
              },
              ...action.values,
            },
          ],
        ];
      }
    case "UPDATE":
      return [...action.values];
    default:
      return state;
  }
};

export default function RolesPermissions(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [roleList, setRoleList] = React.useState([]);
  const [rows, setRow] = useState([]);
  const [roleIds, setRoleIDs] = useState(0);
  const token = localStorage.getItem("auth");

  const fetchData = async () => {
    try {
      const roleListRequest = axios.get(`${apiBaseUrl}eii_roles_list`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const functionsListRequest = RolesService.getEiiRolesFunctionsList();

      const [roleListResponse, functionsListResponse] = await axios.all([
        roleListRequest,
        functionsListRequest,
      ]);

      setRoleList(roleListResponse.data);
      setRow(functionsListResponse.data.role_eii_function_list);
    } catch (err) {
      toast.error(err.message);
    }
  };

  React.useEffect(() => {
    fetchData();
    // roleId(1);
  }, []);

  const handleSubmit = () => {
    try {
      const mapping = RolesService.createEiiRoleMapping(
        state,
        roleIdRef.current?.value
      ).then((res) => {
        if (res.status === 200) {
          toast.success("Permissions Granted Successfully");
          props.getRolePermissions();
        }
      });
    } catch (error) {
      console.log("Err===>", error);
    }
    props.close();
  };

  const handlePermissionChange = (values) => {
    dispatch({ type: "UPDATE_ROLES", values: values });
  };
  const roleId = async (id) => {
    try {
      const res = await RolesService.getEiiRolePermissions(id);
      if (res.data.eii_role_group_function_mapping_list) {
        let details = res.data?.eii_role_group_function_mapping_list?.map(
          (val) => {
            return val.msp_role_group_function_mapping_list;
          }
        );
        const cleanedArr = details.map(
          ({
            role_id,
            created_by,
            created_on,
            updated_by,
            updated_on,
            ...rest
          }) => rest
        );
        dispatch({ type: "UPDATE", values: cleanedArr });
      } else {
        dispatch({ type: "UPDATE", values: initialState });
      }
      setRoleIDs(id);
    } catch (error) {
      console.log("Err==>", error);
    }
  };
  const roleIdRef = useRef(0);
  return (
    <div className="permissionModal">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        className="modal-wrap site-creation-modal roleCreateModal"
        onClose={props.close}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} className="accordionModal">
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              className="mspRoleHeader"
            >
              <b> Role Permissions</b>
              <img
                src={CloseIcon}
                onClick={props.close}
                className="closeicon"
              />
            </Typography>

            <Box>
              <Grid container>
                <Grid
                  item
                  lg={12}
                  xl={12}
                  sm={12}
                  md={12}
                  className="roles-list-selector"
                >
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      id="demo-simple-select-helper-label"
                      sx={{ color: "black", background: "white", px: 1 }}
                    >
                      List of Roles
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Role"
                      inputRef={roleIdRef}
                    >
                      {roleList?.map((rolelist) => (
                        <MenuItem
                          disableRipple
                          onClick={() => roleId(rolelist.role_id)}
                          value={rolelist.role_id}
                        >
                          {rolelist.role_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <TableContainer
                component={Paper}
                sx={{ mt: 2 }}
                className="permission-table"
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                  className="permissions-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ py: 2 }}>Groups</TableCell>
                      <TableCell sx={{ py: 2 }}>Modules</TableCell>
                      <TableCell sx={{ py: 2 }} align="center">
                        All
                      </TableCell>
                      <TableCell sx={{ py: 2 }} align="center">
                        Read
                      </TableCell>
                      <TableCell sx={{ py: 2 }} align="center">
                        Create
                      </TableCell>
                      <TableCell sx={{ py: 2 }} align="center">
                        Update
                      </TableCell>
                      <TableCell sx={{ py: 2 }} align="center">
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => {
                      let selectedRows = state.find((val) => {
                        if (
                          val.role_group_id ==
                            row.role_function.role_group_id &&
                          val.role_function_id ==
                            row.role_function.role_function_id
                        ) {
                          return val;
                        }
                      });
                      return (
                        <TableRow
                          key={row.role_function_id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" sx={{ py: 1 }}>
                            {row.role_group_name}
                          </TableCell>
                          <TableCell component="th" scope="row" sx={{ py: 1 }}>
                            {row.role_function.role_function_name}
                          </TableCell>
                          <TableCell sx={{ py: 1 }} align="center">
                            <Checkbox
                              name="All"
                              value="All"
                              disableRipple
                              className="permissioncheckbox"
                              checked={
                                selectedRows &&
                                selectedRows?.role_read_flag == 1 &&
                                selectedRows?.role_create_flag == 1 &&
                                selectedRows?.role_update_flag == 1 &&
                                selectedRows?.role_delete_flag == 1
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                handlePermissionChange({
                                  role_group_id: row.role_group_id,
                                  role_function_id:
                                    row.role_function.role_function_id,
                                  role_read_flag: e.target.checked ? 1 : 0,
                                  role_create_flag: e.target.checked ? 1 : 0,
                                  role_update_flag: e.target.checked ? 1 : 0,
                                  role_delete_flag: e.target.checked ? 1 : 0,
                                })
                              }
                            />
                          </TableCell>
                          <TableCell sx={{ py: 1 }} align="center">
                            <Checkbox
                              name="read"
                              value="read"
                              disableRipple
                              className="permissioncheckbox"
                              checked={
                                selectedRows &&
                                selectedRows?.role_read_flag == 1
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                handlePermissionChange({
                                  role_group_id: row.role_group_id,
                                  role_function_id:
                                    row.role_function.role_function_id,
                                  role_read_flag: e.target.checked ? 1 : 0,
                                })
                              }
                            />
                          </TableCell>
                          <TableCell sx={{ py: 1 }} align="center">
                            <Checkbox
                              name="create"
                              value="create"
                              disableRipple
                              checked={
                                selectedRows &&
                                selectedRows?.role_create_flag == 1
                                  ? true
                                  : false
                              }
                              className="permissioncheckbox"
                              onClick={(e) =>
                                handlePermissionChange({
                                  role_group_id: row.role_group_id,
                                  role_function_id:
                                    row.role_function.role_function_id,
                                  role_create_flag: e.target.checked ? 1 : 0,
                                })
                              }
                            />
                          </TableCell>
                          <TableCell sx={{ py: 1 }} align="center">
                            <Checkbox
                              name="update"
                              value="update"
                              disableRipple
                              className="permissioncheckbox"
                              checked={
                                selectedRows &&
                                selectedRows?.role_update_flag == 1
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                handlePermissionChange({
                                  role_group_id: row.role_group_id,
                                  role_function_id:
                                    row.role_function.role_function_id,
                                  role_update_flag: e.target.checked ? 1 : 0,
                                })
                              }
                            />
                          </TableCell>
                          <TableCell sx={{ py: 1 }} align="center">
                            <Checkbox
                              name="delete"
                              value="delete"
                              disableRipple
                              className="permissioncheckbox"
                              checked={
                                selectedRows &&
                                selectedRows?.role_delete_flag == 1
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                handlePermissionChange({
                                  role_group_id: row.role_group_id,
                                  role_function_id:
                                    row.role_function.role_function_id,
                                  role_delete_flag: e.target.checked ? 1 : 0,
                                })
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box display="flex" justifyContent="end" gap={2} sx={{ mb: 2 }}>
              <Button variant="outlined" type="submit" onClick={props.close}>
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant="outlined"
                className="Btn"
                type="submit"
              >
                Submit{" "}
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
