import React, { useEffect, useState } from "react";
import { Container, Typography, Button, Box, Paper, Grid } from "@mui/material";
import axios from "axios";
import { apiBaseUrl } from "../config";
import { toast } from "react-toastify";

const WelcomePage = () => {
  const [pageDetails, setPageDetails] = useState("")

  useEffect(() => {
    axios({
      method: "GET",
      url: `${apiBaseUrl}get_token_info`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("demo_token")}`,
      },
    })
      .then((res) => {
        const { data } = res; 
        setPageDetails(data)
      })
      .catch((err) => {
        console.log("err===>", err);
        toast.error(err.response.data.detail);
      });
  }, []);
  return (
    <Container maxWidth="md">
      <Paper
        elevation={3}
        style={{ padding: "2rem", marginTop: "4rem", textAlign: "center" }}
      >
        <Typography variant="h3" gutterBottom>
          Welcome
        </Typography>

        <h4>Account Id: {pageDetails.account_id}</h4>
        <h4>Machine Id: {pageDetails.machine_id}</h4>
      </Paper>
    </Container>
  );
};

export default WelcomePage;
