import React, { useEffect, useRef, useState, forwardRef } from "react";
import "../../../style/home.scss";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Grid,
  Snackbar,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import "react-phone-input-2/lib/style.css";
import { DataGrid } from "@mui/x-data-grid";
import isEmpty from "is-empty";
import { MspService } from "../../../services/MspService";
import { toast } from "react-toastify";
import AddMspIcon from "../../../assets/icons/Masked_Icon.svg";
import CloseIcon from "../../../assets/icons/Close Square.svg";
import CopyIcon from "../../../assets/icons/File.svg";
import EditIcon from "../../../assets/icons/edit.svg";
import DeleteIcon from "../../../assets/icons/Trash Bin Trash.svg";
import DummyProfileImage from "../../../assets/images/hello_robot.png";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import CustomPagination from "../../../components/customPagination/custompagination";
import { styled } from "@mui/system";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "700px",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
  borderRadius: "10px",
  overflow: "auto",
  height: "auto",
};

const initialState = {
  msp_name: "",
  first_name: "",
  last_name: "",
  contact_no: "",
  email: "",
  site_url: "",
  enroll_plan: "",
  password: "",
  sites: [],
  state: "",
  city: "",
  postal_code: "",
  address_1: "",
  address_2: "",
};
const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
  "& .form-control": {
    width: "100%",
    height: "40px",
    fontSize: "14px",
    marginTop: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  "& .form-control.error": {
    border: "1px solid red",
  },
}));

const CustomPhoneInput = forwardRef(({ viewable, editable, ...props }, ref) => {
  return (
    <StyledPhoneInput
      {...props}
      specialLabel="Contact Person Number"
      value={viewable ? viewable : editable ? editable : ""}
      inputProps={{ readOnly: viewable ? true : false }}
      inputComponent={({ ...inputProps }) => (
        <TextField
          {...inputProps}
          inputRef={ref}
          size="small"
          sx={{ my: 2 }}
          required
          label="Contact Person Number"
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
});
export default function MailOwnerCreation() {
  const navigate = useNavigate();
  const mailMounted = useRef(false);
  const [open, setOpen] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [guid, setGuid] = React.useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [imageBlob, setImageBlob] = React.useState(null);
  const [createMsp, setCreateMsp] = React.useState(initialState);
  const [editMsp, setEditMsp] = React.useState({
    msp_name: "",
    site_url: "",
    enroll_plan: "",
    first_name: "",
    last_name: "",
    contact_phone: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postal_code: "",
    site_id: [],
  });
  const [sitesList, setSitesList] = useState([]);
  const [viewModal, setViewModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [details, setDetails] = React.useState([]);
  const [reloadAfterDelete, setReloadAfterDelete] = React.useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [moAccountInfo, setMoAccountInfo] = useState({});
  const [moAccount, setMoAccount] = useState(false);

  //View Modal
  const handleViewOpen = (guid, viewableData) => {
    setViewModal(true);
    setSelectedRow({
      company_name: viewableData.contact_name,
      site_url: viewableData.site_url,
      enroll_plan:
        viewableData.enroll_plan == 1 ? "Shared plan" : "Dedicated plan",
      first_name: viewableData.first_name,
      last_name: viewableData.last_name,
      contact_phone: viewableData.contact_phone,
      role_id: viewableData.role_id,
      address_1: viewableData.address_1,
      address_2: viewableData.address_2,
      city: viewableData.city,
      site: viewableData.site,
      state: viewableData.state,
      postal_code: viewableData.postal_code,
      site_guid: viewableData?.site_guid.map((site) => site?.site_id),
    });
  };
  const handleViewClose = () => {
    setViewModal(false);
    if (imageBlob) {
      URL.revokeObjectURL(imageBlob);
      setImageBlob(null);
    }
  };
  // edit modal
  const handleEditOpen = (guid, editableData) => {
    setEditMsp({
      msp_name: editableData.company_name,
      first_name: editableData.first_name,
      last_name: editableData.last_name,

      contact_phone: editableData.contact_phone,
      role_id: editableData.role_id,
      address_1: editableData.address_1,
      address_2: editableData.address_2,
      city: editableData.city,
      state: editableData.state,
      postal_code: editableData.postal_code,
      site_guid: editableData?.site_guid.map((site) => site?.site_id),
    });
    setEditModal(true);
    setGuid(guid);
  };

  const handleEditClose = () => {
    setEditModal(false);
    if (imageBlob) {
      URL.revokeObjectURL(imageBlob);
      setImageBlob(null);
    }
  };

  // delete modal

  const handleDeleteClose = () => setDeleteModal(false);

  const handleOpen = () => {
    setOpen(true);
    generateRandomPassword();
  };
  const handleClose = () => {
    setOpen(false);
    setMoAccount(false);
    setCreateMsp(initialState);
    if (imageBlob) {
      URL.revokeObjectURL(imageBlob);
      setImageBlob(null);
    }
  };

  const columns = [
    { field: "id", headerName: "Id" },
    {
      field: "company_name",
      headerName: "MO Name",
      headerClassName: "pinned-header",
      cellClassName: "pinned-cell",
      pinned: true,
      width: 300,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
          <img
            src={
              params.row.image
                ? `data:image/png;base64,${params.row.image}`
                : DummyProfileImage
            }
            className="mspListImage"
            alt="contact_logo"
          />
          <div>{params.row.company_name}</div>
        </Box>
      ),
    },
    { field: "admin_email", headerName: "Admin Email", width: 500 },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" gap={2}>
          <Button
            onClick={() => {
              handleViewOpen(params.row.guid, params.row);
            }}
          >
            <img src={CopyIcon} className="actionIcons" alt="viewIcon" />
          </Button>
          <Button
            onClick={() => {
              handleEditOpen(params.row.guid, params.row);
            }}
          >
            <img src={EditIcon} className="actionIcons" alt="editIcon" />
          </Button>
          <Button
            onClick={() => {
              setDeleteModal(true);
              setGuid(params.row.guid);
            }}
          >
            <img src={DeleteIcon} className="actionIcons" alt="deleteIcon" />
          </Button>
        </Box>
      ),
    },
  ];

  function handleCreateMspChange(event) {
    const { name, value } = event.target;

    setCreateMsp((prevState) => ({ ...prevState, [name]: value }));
    if (name === "msp_name") {
      setCreateMsp((prevState) => ({ ...prevState, site_url: value }));
    }
  }

  function handleEditChange(event) {
    const { name, value } = event.target;
    if (name === "company_name" && value.length < 5) {
      toast.error("MSP name should have atleast 5 characters ");
    }
    setEditMsp((prevState) => ({ ...prevState, [name]: value }));
  }
  const handleSelectChange = (event) => {
    const { value } = event.target;
    setCreateMsp((prevFormData) => ({
      ...prevFormData,
      sites: value,
    }));
  };

  const handleEditSelectChange = (event) => {
    const { value } = event.target;
    debugger;
    setEditMsp((prevFormData) => ({
      ...prevFormData,
      site_guid: value,
    }));
  };

  const MspUsersList = () => {
    try {
      MspService.MSPSitesList()
        .then((res) => {
          setSitesList(res.data);
        })
        .catch((e) => toast.error("Something went wrong"));
    } catch (e) {
      console.log("Err===>", e);
    }
  };

  const handleSubmit = async () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!createMsp.email || !emailRegex.test(createMsp.email)) {
        toast.error("Please enter the valid email");
        return false;
      }

      const formData = new FormData();
      formData.append("mail_owner_name", createMsp.msp_name);
      formData.append("first_name", createMsp.first_name);
      formData.append("last_name", createMsp.last_name);
      formData.append("contact_no", createMsp.contact_no);
      formData.append("email", createMsp.email);
      formData.append("password", createMsp.password);
      formData.append("state", createMsp.state);
      formData.append("city", createMsp.city);
      formData.append("postal_code", createMsp.postal_code);
      formData.append("address_1", createMsp.address_1);
      formData.append("address_2", createMsp.address_2);
      formData.append("site_ids_str", createMsp.sites?.join(","));

      MspService.mocreation(formData)
        .then(() => {
          setReloadAfterDelete(!reloadAfterDelete);
          toast.success("MO created successfully:)");
          handleClose();
        })
        .catch((e) => toast.error(`${e?.data?.detail}`));
    } catch (e) {
      console.error("Err===>", e);
    }
  };

  const editData = async (e, guid) => {
    if (
      !editMsp.msp_name ||
      !editMsp.first_name ||
      !editMsp.last_name ||
      !editMsp.contact_phone
    ) {
      toast.error("All fields are required");
      return;
    }
    try {
      e.preventDefault();
      const formData = new FormData();
      formData.append("mail_owner_name", editMsp.msp_name);
      formData.append("mo_account_guid", guid);

      formData.append("first_name", editMsp.first_name);
      formData.append("last_name", editMsp.last_name);
      formData.append("contact_phone", editMsp.contact_phone);

      formData.append("address_1", editMsp.address_1);
      formData.append("address_2", editMsp.address_2);
      formData.append("state", editMsp.state);
      formData.append("city", editMsp.city);
      formData.append("postal_code", editMsp.postal_code);
      formData.append("site_ids_str", editMsp.site_guid?.join(","));

      MspService.moUpdation(formData)
        .then(() => {
          setReloadAfterDelete(!reloadAfterDelete);
          toast.success("MO updated successfully :)");
          handleEditClose();
        })
        .catch((e) => toast.error(e.data.detail));
    } catch (e) {
      console.log("Err===>", e);
    }
  };
  const popData = async (guid) => {
    try {
      const formData = new FormData();
      formData.append("account_guid", guid);
      await MspService.moAccountDelete(formData).then(() => {
        setReloadAfterDelete(!reloadAfterDelete);
        toast.success("MO deleted successfully :)");
      });
    } catch (error) {
      toast.error("Error deleting MO");
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setLoader(true);
        const response = await MspService.moAccountList({
          page: page,
          page_size: pageSize,
        });
        if (response && isMounted) {
          const data = response.data.mail_owner_account_list;

          const updateDetails = data.map((item, index) => ({
            id: item.account_id,
            company_name: item.account_name,
            first_name: item.first_name,
            last_name: item.last_name,
            contact_phone: item.contact_phone,
            admin_email: item.contact_email,
            guid: item.account_guid,
            site_guid: item.site_details,
            role_id: item?.role_name?.role_id,
            contact_name: item?.contact_name,
            address_1: item?.address_1,
            address_2: item?.address_2,
            city: item?.city,
            state: item?.state,
            postal_code: item?.postal_code,
          }));
          if (isMounted) {
            setRowCount(response.data.total_record);
            setDetails(updateDetails);
            setLoader(false);
          }
        }
      } catch (error) {
        console.error("Err===>", error);
        setLoader(false);
        if (error?.status == 401) {
          toast.error("Token expired");
          navigate("/sign-in");
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, [reloadAfterDelete, page, pageSize]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  const handlePhoneNumber = (value) => {
    const isValidNumber = /^\d{10}$/.test(value);
    if (!isValidNumber) {
      setIsValid(isValidNumber);
      setCreateMsp((prevState) => ({ ...prevState, contact_no: value }));
    } else {
      setCreateMsp((prevState) => ({ ...prevState, contact_no: value }));
    }
  };
  const handleEditPhoneNumber = (value) => {
    const isValidNumber = /^\d{10}$/.test(value);
    if (!isValidNumber) {
      setIsValid(isValidNumber);
      setEditMsp((prevState) => ({ ...prevState, contact_phone: value }));
    } else {
      setEditMsp((prevState) => ({ ...prevState, contact_phone: value }));
    }
  };
  const generateRandomPassword = () => {
    let Genpassword = Math.random().toString(20).substring(2, 8);
    setCreateMsp((prevState) => ({ ...prevState, password: Genpassword }));
  };

  const checkMoDetail = async (email) => {
    try {
      await MspService.moDetails(email).then((res) => {
        if (res?.data?.account_id) {
          setMoAccountInfo(res.data);
          setMoAccount(true);
          setCreateMsp((prevState) => ({
            ...prevState,
            contact_no: res.data.contact_phone,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            msp_name: res.data.account_name,
          }));
        } else {
          toast.warn(res?.data?.detail, { autoClose: 3000 });
          setMoAccount(false);
        }
      });
    } catch (error) {
      setMoAccount(false);
      toast.error(error?.data?.detail || "Error while fetching MO detail", {
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (mailMounted.current) {
        checkMoDetail(createMsp?.email);
      }
      mailMounted.current = true;
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [createMsp?.email]);

  useEffect(() => MspUsersList(), []);

  return (
    <div className="roles mspcreation">
      {/* Creation Modal */}
      {loader && <Loader />}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h6"
            sx={{ mb: 2 }}
            className="mspRoleHeader"
          >
            Mail Owner Creation
            <Button className="closeButton" onClick={handleClose}>
              <img src={CloseIcon} className="closeicon" alt="closeIcon" />
            </Button>
          </Typography>
          <Box component="form" noValidate className="mspDetailsForm">
            <Grid container spacing={1} sx={{ mt: 1 }}>
              <Grid item lg={12} md={12} sm={12}>
                <TextField
                  size="small"
                  type="email"
                  label="Admin Email ID"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  name="email"
                  onChange={handleCreateMspChange}
                  required
                  inputProps={{
                    maxLength: 120,
                  }}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="First Name"
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                  name="first_name"
                  inputProps={{
                    maxLength: 50,
                  }}
                  disabled={moAccount}
                  onChange={handleCreateMspChange}
                  value={!isEmpty(createMsp) ? createMsp?.first_name : ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="Last Name"
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                  name="last_name"
                  disabled={moAccount}
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={handleCreateMspChange}
                  value={!isEmpty(createMsp) ? createMsp?.last_name : ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} sx={{ p: 0 }}>
                <TextField
                  size="small"
                  label="MO Name"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  name="msp_name"
                  required
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={handleCreateMspChange}
                  disabled={moAccount}
                  value={!isEmpty(createMsp) ? createMsp?.msp_name : ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <FormControl fullWidth required>
                  <InputLabel shrink id="demo-simple-select-label">
                    Sites
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    className="checkboxList"
                    id="users-select"
                    multiple
                    size="small"
                    label="Sites"
                    value={createMsp.sites}
                    onChange={handleSelectChange}
                    input={<OutlinedInput label="Sites" />}
                    renderValue={(selected) => {
                      return selected
                        .map(
                          (id) =>
                            sitesList.find((site) => site.site_guid === id)
                              ?.site_name
                        )
                        .join(",");
                    }}
                  >
                    {sitesList.length &&
                      sitesList.map((site) => (
                        <MenuItem
                          className="userModal"
                          key={site.site_id}
                          value={site.site_guid}
                        >
                          <Checkbox
                            checked={
                              createMsp.sites.indexOf(site.site_guid) > -1
                            }
                          />
                          <ListItemText primary={site.site_name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <CustomPhoneInput
                  defaultCountry="us"
                  onlyCountries={["us"]}
                  disableCountryCode
                  country="us"
                  disableDropdown={true}
                  onChange={handlePhoneNumber}
                  disabled={moAccount}
                  inputProps={{
                    name: "contact_no",
                    required: true,
                    value: `${
                      !isEmpty(createMsp) ? createMsp?.contact_no : ""
                    }`,
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} sx={{ m: 0 }}>
                <TextField
                  className="mo_creation"
                  size="small"
                  label="MO Role "
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  name="role_id"
                  disabled
                  defaultValue="MO Admin"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    label="Address 1"
                    multiline
                    maxRows={3}
                    InputLabelProps={{
                      shrink: true,
                      value: `${
                        !isEmpty(createMsp) ? createMsp?.address_1 : ""
                      }`,
                    }}
                    name="address_1"
                    onChange={handleCreateMspChange}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={6} xl={6}>
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    label="Address 2"
                    multiline
                    maxRows={3}
                    InputLabelProps={{
                      shrink: true,
                      value: `${
                        !isEmpty(createMsp) ? createMsp?.address_2 : ""
                      }`,
                    }}
                    name="address_2"
                    onChange={handleCreateMspChange}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={6} xl={6}>
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    label="City"
                    InputLabelProps={{
                      shrink: true,
                      value: `${!isEmpty(createMsp) ? createMsp?.city : ""}`,
                    }}
                    name="city"
                    onChange={handleCreateMspChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    label="State"
                    InputLabelProps={{
                      shrink: true,
                      value: `${!isEmpty(createMsp) ? createMsp?.state : ""}`,
                    }}
                    inputProps={{
                      maxLength: 2,
                      style: { textTransform: "uppercase" },
                    }}
                    name="state"
                    onChange={handleCreateMspChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    label="Postal Code"
                    InputLabelProps={{
                      shrink: true,
                      value: `${
                        !isEmpty(createMsp) ? createMsp?.postal_code : ""
                      }`,
                    }}
                    name="postal_code"
                    onChange={handleCreateMspChange}
                  />
                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  disabled
                  helperText="Auto Generated Password"
                  size="small"
                  label="Password"
                  value={createMsp.password}
                  fullWidth
                  name="password"
                  onChange={handleCreateMspChange}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <IconButton
                  //         edge="end"
                  //         onClick={generateRandomPassword}
                  //         sx={{ mr: 1 }}
                  //       >
                  //         <ReplayIcon />
                  //       </IconButton>
                  //       <IconButton onClick={handleCopyClipboard}>
                  //         <ContentCopyIcon />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="end"
            gap={2}
            sx={{ paddingRight: "40px", mb: 2 }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              type="submit"
              disableRipple
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="createButton"
              disabled={
                !createMsp.first_name ||
                !createMsp.last_name ||
                createMsp.contact_no.length !== 10 ||
                !createMsp.email ||
                !createMsp.msp_name ||
                !createMsp.address_1 ||
                !createMsp.address_2 ||
                !createMsp.city ||
                !createMsp.state ||
                !createMsp.postal_code
              }
              onClick={() => {
                handleSubmit();
              }}
              sx={{ backgroundColor: "#00344e" }}
              disableRipple
            >
              Create
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Edit Modal */}
      <Modal
        open={editModal}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal mo-user-update"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h6"
            sx={{ mb: 2 }}
            className="mspRoleHeader"
          >
            Update Mail Owner
            <Button onClick={handleEditClose} className="closeButton">
              <img src={CloseIcon} className="closeicon" alt="closeIcon" />
            </Button>
          </Typography>
          <Box component="form" noValidate className="mspDetailsForm">
            <Grid
              container
              spacing={1}
              sx={{ mt: 1 }}
              className="mo-user-modal"
            >
              <Grid item lg={12} md={12} sm={12}>
                <TextField
                  size="small"
                  label="MO Name"
                  fullWidth
                  name="msp_name"
                  onChange={handleEditChange}
                  defaultValue={!isEmpty(editMsp) ? editMsp?.msp_name : ""}
                  value={!isEmpty(editMsp) ? editMsp?.msp_name : ""}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12}>
                <TextField
                  size="small"
                  label="MO Role"
                  fullWidth
                  name="role_id"
                  disabled
                  defaultValue="MO Admin"
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="First Name"
                  required
                  fullWidth
                  name="first_name"
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={handleEditChange}
                  defaultValue={!isEmpty(editMsp) ? editMsp?.first_name : ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="Last Name"
                  required
                  fullWidth
                  name="last_name"
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={handleEditChange}
                  defaultValue={!isEmpty(editMsp) ? editMsp?.last_name : ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <CustomPhoneInput
                  defaultCountry="us"
                  onlyCountries={["us"]}
                  disableCountryCode
                  country="us"
                  disableDropdown={true}
                  onChange={handleEditPhoneNumber}
                  inputProps={{
                    name: "contact_no",
                    required: true,
                  }}
                  editable={!isEmpty(editMsp) ? editMsp?.contact_phone : ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="Address 1"
                  required
                  sx={{ my: 1 }}
                  fullWidth
                  name="address_1"
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={handleEditChange}
                  defaultValue={!isEmpty(editMsp) ? editMsp?.address_1 : ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="Address 2"
                  required
                  fullWidth
                  name="address_2"
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={handleEditChange}
                  defaultValue={!isEmpty(editMsp) ? editMsp?.address_2 : ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="State"
                  required
                  fullWidth
                  name="state"
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={handleEditChange}
                  defaultValue={!isEmpty(editMsp) ? editMsp?.state : ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="City"
                  required
                  fullWidth
                  name="city"
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={handleEditChange}
                  defaultValue={!isEmpty(editMsp) ? editMsp?.city : ""}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="Postal Code"
                  required
                  fullWidth
                  name="postal_code"
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={handleEditChange}
                  defaultValue={!isEmpty(editMsp) ? editMsp?.postal_code : ""}
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <Box>
                  <FormControl fullWidth required>
                    <InputLabel shrink id="demo-simple-select-label">
                      Sites
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      className="checkboxList"
                      id="users-select"
                      multiple
                      size="small"
                      label="Sites"
                      value={
                        !isEmpty(editMsp) && editMsp?.site_guid
                          ? editMsp?.site_guid
                          : []
                      }
                      onChange={(e) => handleEditSelectChange(e)}
                      input={<OutlinedInput label="Sites" />}
                      renderValue={(selected) => {
                        return selected
                          .map(
                            (id) =>
                              sitesList.find((site) => site?.site_guid == id)
                                ?.site_name
                          )
                          .join(", ");
                      }}
                    >
                      {sitesList.length > 0 &&
                        sitesList.map((site) => (
                          <MenuItem
                            className="userModal"
                            key={site.site_id}
                            value={site.site_guid}
                          >
                            <Checkbox
                              checked={
                                editMsp?.site_guid &&
                                editMsp?.site_guid.indexOf(site.site_guid) > -1
                              }
                            />
                            <ListItemText primary={site.site_name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="end"
            gap={2}
            sx={{ paddingRight: "40px", mb: 2 }}
          >
            <Button
              onClick={handleEditClose}
              variant="outlined"
              type="submit"
              disableRipple
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              className="createButton"
              onClick={(e) => {
                editData(e, guid);
              }}
              sx={{
                backgroundColor: "#00344e",
              }}
              disableRipple
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete Modal */}
      <Modal
        open={deleteModal}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal"
      >
        <Box sx={style} height="auto" width="30%">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 1, p: 2 }}
          >
            <b>Confirm Delete ? </b>
          </Typography>
          <Box sx={{ mt: 1, px: 2 }}>
            <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
              <Button
                onClick={handleDeleteClose}
                variant="outlined"
                type="submit"
              >
                No
              </Button>
              <Button
                onClick={() => {
                  popData(guid);
                  handleDeleteClose();
                }}
                className="createButton"
                variant="contained"
                type="submit"
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* View Modal */}
      <Modal
        open={viewModal}
        onClose={handleViewClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal mo-view-modal"
      >
        <Box sx={style} height="80vh" width="50%">
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h6"
            sx={{ mb: 2 }}
            className="mspRoleHeader"
          >
            View Mail Owner
            <Button className="closeButton" onClick={handleViewClose}>
              {" "}
              <img src={CloseIcon} className="closeicon" alt="closeicon" />
            </Button>
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            className="mspDetailsForm"
          >
            <TextField
              size="small"
              label="MO Name"
              sx={{ my: 2, width: "100%" }}
              InputProps={{
                readOnly: true,
              }}
              defaultValue={
                !isEmpty(selectedRow) ? selectedRow.company_name : ""
              }
            />

            <TextField
              size="small"
              label="MO Role"
              sx={{ my: 2, width: "100%" }}
              InputProps={{
                readOnly: true,
              }}
              defaultValue="MO Admin"
            />
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="First Name"
                  required
                  sx={{ my: 2, width: "100%" }}
                  name="first_name"
                  inputProps={{
                    readOnly: true,
                  }}
                  defaultValue={
                    !isEmpty(selectedRow) ? selectedRow.first_name : ""
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="Last Name"
                  required
                  sx={{ my: 2, width: "100%" }}
                  name="last_name"
                  inputProps={{
                    readOnly: true,
                  }}
                  defaultValue={
                    !isEmpty(selectedRow) ? selectedRow.last_name : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    label="Address 1"
                    multiline
                    maxRows={3}
                    inputProps={{
                      readOnly: true,
                    }}
                    sx={{ my: 2, width: "100%" }}
                    name="address_1"
                    defaultValue={
                      !isEmpty(selectedRow) ? selectedRow.address_1 : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    label="Address 2"
                    multiline
                    maxRows={3}
                    inputProps={{
                      readOnly: true,
                    }}
                    sx={{ my: 2, width: "100%" }}
                    name="address_2"
                    defaultValue={
                      !isEmpty(selectedRow) ? selectedRow.address_2 : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    label="City"
                    multiline
                    maxRows={3}
                    inputProps={{
                      readOnly: true,
                    }}
                    sx={{ my: 2, width: "100%" }}
                    name="city"
                    defaultValue={!isEmpty(selectedRow) ? selectedRow.city : ""}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    label="State"
                    multiline
                    maxRows={3}
                    inputProps={{
                      readOnly: true,
                      style: { textTransform: "uppercase" },
                    }}
                    sx={{ my: 2, width: "100%" }}
                    name="state"
                    defaultValue={
                      !isEmpty(selectedRow) ? selectedRow.state : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <Box>
                  <TextField
                    fullWidth
                    size="small"
                    label="Postal Code"
                    multiline
                    maxRows={3}
                    inputProps={{
                      readOnly: true,
                    }}
                    sx={{ my: 2, width: "100%" }}
                    name="postal_code"
                    defaultValue={
                      !isEmpty(selectedRow) ? selectedRow.postal_code : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <Box className="view-modal-mo">
                  <CustomPhoneInput
                    defaultCountry="us"
                    onlyCountries={["us"]}
                    disableCountryCode
                    country="us"
                    disableDropdown={true}
                    inputProps={{
                      name: "contact_no",
                      required: true,
                      readOnly: selectedRow && 1,
                    }}
                    viewable={
                      !isEmpty(selectedRow) ? selectedRow.contact_phone : ""
                    }
                  />
                </Box>
              </Grid>
              <Grid item lg={12} md={12}>
                <Box>
                  <FormControl fullWidth sx={{ my: 2, width: "100%" }} required>
                    <InputLabel shrink id="demo-simple-select-label">
                      Sites
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      className="checkboxList"
                      id="users-select"
                      multiple
                      size="small"
                      label="Sites"
                      value={
                        !isEmpty(selectedRow) ? selectedRow?.site_guid : []
                      }
                      onChange={(e) => {
                        handleEditSelectChange(e);
                      }}
                      input={<OutlinedInput label="Sites" />}
                      inputProps={{ readOnly: true }}
                      renderValue={(selected) => {
                        return selected
                          .map(
                            (id) =>
                              sitesList.find((site) => site.site_guid === id)
                                ?.site_name
                          )
                          .join(", ");
                      }}
                    >
                      {sitesList.length &&
                        sitesList.map((site) => (
                          <MenuItem
                            className="userModal"
                            key={site.site_id}
                            value={site.site_guid}
                          >
                            <Checkbox
                              checked={
                                createMsp.sites.indexOf(site.site_guid) > -1
                              }
                            />
                            <ListItemText primary={site.site_name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="end"
            gap={2}
            sx={{ paddingRight: "40px", mb: 2 }}
          >
            <Button
              onClick={handleViewClose}
              variant="contained"
              type="submit"
              disableRipple
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          // sx={{ fontWeight: "bold", background: "#00344e" }}
          onClick={handleOpen}
          className="Btn"
        >
          <img
            src={AddMspIcon}
            height={25}
            width={25}
            style={{ marginRight: "10px" }}
            alt="add_image"
          />
          <span>
            {" "}
            <b>Mail Owner Creation</b>
          </span>{" "}
        </Button>
      </Box>

      <Grid container>
        <Grid item md={12}>
          <Box
            style={{ height: 400, width: "100%", margin: "20px 0" }}
            className="rolesTable"
          >
            <DataGrid
              className="RolesTable"
              rows={details}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
              localeText={{
                noRowsLabel: "No records Found",
              }}
              slots={{
                pagination: (props) => (
                  <CustomPagination
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    page={page}
                    pageSize={pageSize}
                    rowCount={rowCount}
                  />
                ),
              }}
              sortModel={[
                {
                  field: "id",
                  sort: "desc",
                },
              ]}
              columnVisibilityModel={{
                id: false,
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        key={{}}
        open={snackOpen}
        onClose={() => setSnackOpen(false)}
        message="Password copied to clipboard"
        autoHideDuration={2000}
      />
    </div>
  );
}
