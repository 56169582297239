import React from "react";
import { Navigate } from "react-router-dom";
import DashboardKpi from "../pages/KPI/DashboardKpi";
import Mailings from "../pages/MSP/Mailings/mailings";
import UploadFolder from "../pages/MSP/Upload-Folder";
import MSPRoleMaintenence from "../pages/MSP/RoleMaintenance/MSPRoles";
import SiteManage from "../pages/MSP/Sites/SiteManage";
import MSPMachine from "../pages/MSP/Machine";
import GenerateMailDatLayout from "../pages/MSP/Upload-Folder/GenerateMailDat";
import MSPUser from "../pages/MSP/User/index";
import MailOwnerCreation from "../pages/MSP/MailOwner/mailOwner";
import SiteInfo from "../pages/MSP/Sites/SiteInfo";
import ReceiveModule from "../pages/MSP/Receiving Module/index";
import ReceiptTicketCreation from "../pages/MSP/Receiving Module/ReceiptTicketCreation";
import WorkOrderSheet from "../pages/MSP/Receiving Module/WorkOrderSheet";
import RouterCreation from "../pages/MSP/RouterCreation/index";
import WoCreation from "../pages/MSP/Receiving Module/mspWoCreation/index";
import ProfileEditor from "../pages/MSP/ProfileEditor";
import PrintWorkOrderSheet from "../pages/MSP/Receiving Module/printWorkOrderSheet";
import CreateRouter from "../pages/MSP/RouterCreation/CreateRouter";
import AssignRoutes from "../pages/MSP/RouterCreation/assignRoutes";
import SiteEditor from "../pages/MSP/SiteEditor/index";
import SitesEditorStepper from "../pages/MSP/SiteEditor/SitesEditorStepper";
import MailingEditor from "../pages/MSP/MailingEditor/index";
import SortEditor from "../pages/MSP/SortEditor/index";
import SortEditorDetails from "../pages/MSP/SortEditor/SortEditorDetails";

const MSPRoutes = [
  { path: "/msp", element: <DashboardKpi /> },
  { path: "/msp/mailings", element: <Mailings /> },
  { path: "/msp/upload-file", element: <UploadFolder /> },
  { path: "/msp/msproles", element: <MSPRoleMaintenence /> },
  { path: "/msp/sites", element: <SiteManage /> },
  { path: "/msp/machine", element: <MSPMachine /> },
  { path: "/msp/generate", element: <GenerateMailDatLayout /> },
  { path: "/msp/user", element: <MSPUser /> },
  { path: "/msp/mail-owners", element: <MailOwnerCreation /> },
  { path: "/msp/siteinfo", element: <SiteInfo /> },
  { path: "/msp/receiving", element: <ReceiveModule /> },
  { path: "/msp/routers", element: <RouterCreation /> },
  { path: "/msp/createrouter", element: <CreateRouter /> },
  { path: "/msp/assignroute", element: <AssignRoutes /> },

  {
    path: "/msp/receiptticketcreation/:id",
    element: <ReceiptTicketCreation />,
  },

  { path: "/msp/workordersheet/:id", element: <WorkOrderSheet /> },
  { path: "/msp/previewworkordersheet/:id", element: <PrintWorkOrderSheet /> },

  { path: "/msp/wocreate", element: <WoCreation /> },
  { path: "/msp/previewworkorder/:id", element: <WoCreation /> },

  { path: "/msp/editor", element: <ProfileEditor /> },
  { path: "/msp/site-editor", element: <SiteEditor /> },
  { path: "/msp/sites-stepper", element: <SitesEditorStepper /> },
  { path: "/msp/sites-stepper/:id", element: <SitesEditorStepper /> },

  { path: "/msp/mailing-editor", element: <MailingEditor /> },
  { path: "/msp/sort-editor", element: <SortEditor /> },
  { path: "/msp/sort-profiles/details", element: <SortEditorDetails /> },

  { path: "*", element: <Navigate to="/msp" /> },
];
export default MSPRoutes;
