import React, { useEffect, useReducer, useState } from "react";
import {
  Modal,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Box,
  Grid,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow:
    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
  borderRadius: "10px",
  p: 2,
};

const initialState = {
  sort_profile_name: "",
  bin_type: "",
  no_bin: "",
  class: "",
  product: "",
  no_of_tier: "",
  bin_layout: "",
  side: 1,
  no_of_columns: "",
  double_detect: "yes",
  rejected_bin: "yes",
  out_of_profile_bin: "yes",
  preallocate_bin: "no",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "HANDLE CHANGE":
      return { ...state, [action.field]: action.payload };
    case "UPDATE_ROLES":
      return {
        ...state,
        roles: {
          ...state.roles,
          [action.field]: action.payload,
        },
      };
    default:
      return state;
  }
};

const SortEditorModal = ({ open, close }) => {
  const [fromDetails, dispatch] = useReducer(reducer, initialState);
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const [checkboxes, setCheckboxes] = useState({
    create_mailing: false,
    create_new_template: false,
  });

  useEffect(() => {
    if (fromDetails.no_of_columns && fromDetails.no_of_tier) {
      let calculateColumns =
        Number(fromDetails.no_of_columns) * Number(fromDetails.no_of_tier);
      dispatch({
        type: "HANDLE CHANGE",
        field: "no_bin",
        payload: calculateColumns,
      });
    }
  }, [fromDetails.no_of_columns, fromDetails.no_of_tier]);

  const handleCheckboxChange = (event) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch({ type: "HANDLE CHANGE", field: name, payload: value });
  };

  const handleSubmit = () => {
    setSubmit(true);
    if (
      !fromDetails.bin_type ||
      !fromDetails.no_bin ||
      !fromDetails.class ||
      !fromDetails.product ||
      !fromDetails.no_of_columns ||
      !fromDetails.no_of_tier
    ) {
      return false;
    }
    localStorage.setItem("sort", JSON.stringify(fromDetails));

    navigate("/msp/sort-profiles/details");
  };

  return (
    <div className="SortEditor">
      <Modal
        open={open}
        onClose={() => close()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal mailingModal"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="create_mailing"
                    checked={checkboxes.create_mailing}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Create New Sort Profile"
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    name="create_from_existing_template"
                    checked={checkboxes.create_from_existing_template}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Copy from Existing Profile"
              />
            </Grid>
          </Grid>

          {checkboxes.create_mailing && (
            <Box className="create-new-SE">
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                  <TextField
                    id="outlined-basic"
                    label="Sort Profile Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                    value={
                      fromDetails.bin_type +
                      "-" +
                      fromDetails.no_bin +
                      "-" +
                      fromDetails.class +
                      "-" +
                      fromDetails.product
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={3} xl={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Machine Bin Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Machine Bin Type"
                      name="bin_type"
                      defaultValue={fromDetails.bin_type}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <MenuItem value={"Linear"}>Linear</MenuItem>
                      <MenuItem value={"M-Series (3T)"}>M-Series (3T)</MenuItem>
                      <MenuItem value={"X-Class (1T)"}>X-Class (1T)</MenuItem>
                      <MenuItem value={"X-Class (2T)"}>X-Class (2T)</MenuItem>
                      <MenuItem value={"X-Class (3T)"}>X-Class (3T)</MenuItem>
                      <MenuItem value={"X-Class (1T)"}>
                        X-Class Linear 2T
                      </MenuItem>
                      <MenuItem value={"Cross Belt"}>Cross Belt</MenuItem>
                      <MenuItem value={"Shoe Sorter"}>Shoe Sorter</MenuItem>
                      <MenuItem value={"Fourth"}>Fourth</MenuItem>
                      <MenuItem value={"Y-Series(3T)"}>Y-Series(3T)</MenuItem>
                    </Select>
                  </FormControl>
                  {!fromDetails.bin_type && submit && (
                    <p className="validation-text">
                      Please select the bin type
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={3} xl={3}>
                  <TextField
                    id="outlined-basic"
                    label="Number of Bins"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    type="number"
                    name="no_bin"
                    defaultValue={fromDetails.no_bin}
                    value={fromDetails.no_bin}
                    disabled
                    fullWidth
                  />
                  {!fromDetails.no_bin && submit && (
                    <p className="validation-text">
                      Please enter the bins count
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={4} xl={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Class</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Class"
                      name="class"
                      defaultValue={fromDetails.class}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <MenuItem value={"First Class"}>First Class</MenuItem>
                      <MenuItem value={"Marketing"}>Marketing</MenuItem>
                      <MenuItem value={"BPM"}>BPM</MenuItem>
                      <MenuItem value={"Library"}>Library</MenuItem>
                      <MenuItem value={"Media"}>Media</MenuItem>
                    </Select>
                  </FormControl>
                  {!fromDetails.class && submit && (
                    <p className="validation-text">Please select the class</p>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={4} xl={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Product
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Product"
                      name="product"
                      defaultValue={fromDetails.product}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <MenuItem value={"Card"}>Card</MenuItem>
                      <MenuItem value={"Letter"}>Letter</MenuItem>
                      <MenuItem value={"Parcel"}>Parcel</MenuItem>
                      <MenuItem value={"Flat"}>Flat</MenuItem>
                    </Select>
                  </FormControl>
                  {!fromDetails.product && submit && (
                    <p className="validation-text">Please select the product</p>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={4} xl={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      No of Tier
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Product"
                      name="no_of_tier"
                      defaultValue={fromDetails.no_of_tier}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <MenuItem value={1}>1T</MenuItem>
                      <MenuItem value={2}>2T</MenuItem>
                      <MenuItem value={3}>3T</MenuItem>
                      <MenuItem value={4}>4T</MenuItem>
                    </Select>
                  </FormControl>
                  {!fromDetails.no_of_tier && submit && (
                    <p className="validation-text">Please select the tier</p>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={4} xl={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Bin Layout
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Product"
                      name="bin_layout"
                      defaultValue={fromDetails.bin_layout}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <MenuItem value={"verticalTop"}>Vertical Top</MenuItem>
                      <MenuItem value={"verticalBottom"}>
                        Vertical Bottom
                      </MenuItem>
                      <MenuItem value={"horizontalTop"}>
                        Horizontal Top
                      </MenuItem>
                      <MenuItem value={"horizontalBottom"}>
                        Horizontal Bottom
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {!fromDetails.bin_layout && submit && (
                    <p className="validation-text">
                      Please select the bin layout
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={4} xl={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Side</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Product"
                      name="side"
                      defaultValue={fromDetails.side}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <MenuItem value={1}>Single Side</MenuItem>
                      <MenuItem value={2}>Dual Side</MenuItem>
                    </Select>
                  </FormControl>
                  {!fromDetails.side && submit && (
                    <p className="validation-text">Please select the side</p>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={4} xl={4}>
                  <TextField
                    id="outlined-basic"
                    label="Number of Columns"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    type="number"
                    name="no_of_columns"
                    value={fromDetails.no_of_columns}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    fullWidth
                  />
                  {!fromDetails.no_of_columns && submit && (
                    <p className="validation-text">
                      Please select the columns count
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl sx={{ p: 1 }} className="radio-buttons">
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="radio-button-sortEditor"
                    >
                      Double Detect
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="double_detect"
                      value={fromDetails.double_detect}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                        className="radio-option-button"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                        className="radio-option-button"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl sx={{ p: 1 }} className="radio-buttons">
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="radio-button-sortEditor"
                      name="rejected_bin"
                    >
                      Barcode Rejects Bin
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="rejected_bin"
                      value={fromDetails.rejected_bin}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                        size="small"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl sx={{ p: 1 }} className="radio-buttons">
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="radio-button-sortEditor"
                    >
                      Out of Profile Bin
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="out_of_profile_bin"
                      value={fromDetails.out_of_profile_bin}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl sx={{ p: 1 }} className="radio-buttons">
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="radio-button-sortEditor"
                    >
                      Preallocate ADC Bin
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="preallocate_bin"
                      value={fromDetails.preallocate_bin}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Box className="mailing-modal-control" sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={close}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="Btn"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default SortEditorModal;
