import React, { useEffect, useReducer, forwardRef, useState } from "react";
import "./user.scss";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "../../../assets/icons/Close Square.svg";
import axios from "axios";
import { apiBaseUrl } from "../../../config";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { InitialStateProps, CustomInputProps, UserModalProps } from "./type";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
  "& .form-control": {
    width: "100%",
    height: "40px",
    fontSize: "14px",
    marginTop: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  "& .form-control.error": {
    border: "1px solid red",
  },
}));

const CustomPhoneInput = forwardRef(
  ({ viewable, editable, ...props }: CustomInputProps, ref) => (
    <StyledPhoneInput
      {...props}
      specialLabel="Contact Number"
      value={viewable || editable || ""}
      //@ts-ignore
      inputComponent={({ ...inputProps }) => (
        <TextField
          {...inputProps}
          inputRef={ref}
          size="small"
          sx={{ my: 2 }}
          required
          label="Contact Number"
          variant="outlined"
          fullWidth
        />
      )}
    />
  )
);

const initialState: InitialStateProps = {
  image_base64: "",
  first_name: "",
  last_name: "",
  contact_email: "",
  contact_number: "",
  password: "",
  role_name: "",
  image: "",
  is_logged_user: false,
  site_guid: "",
};

const reducer = (state: InitialStateProps, action: any) => {
  switch (action.type) {
    case "HANDLE CHANGE":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "UPDATE":
      return { ...action.payload };
    default:
      return state;
  }
};

const UserModal: React.FC<UserModalProps> = ({
  open,
  onClose,
  rolesDetails,
  userDetails,
}) => {
  const [user, dispatch] = useReducer(reducer, initialState);
  const [sitesDropdown, setSiteDropdown] = useState([]);

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "HANDLE CHANGE",
      field: "image",
      payload: e.target.files[0],
    });
  };

  const generateRandomPassword = () => {
    const Genpassword = Math.random().toString(36).slice(-8);
    dispatch({
      type: "HANDLE CHANGE",
      field: "password",
      payload: Genpassword,
    });
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<any>
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    dispatch({
      type: "HANDLE CHANGE",
      field: name,
      payload: value,
    });
  };

  const handlePhoneNumber = (value: string) => {
    dispatch({
      type: "HANDLE CHANGE",
      field: "contact_number",
      payload: value,
    });
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      if (
        !user.first_name ||
        !user.last_name ||
        (!userDetails && !user.contact_email) ||
        !user.role_name ||
        !user.contact_number ||
        !user.site_guid
      ) {
        toast.error("Please enter the details");
        return false;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidNumber = /^\d{10}$/.test(user.contact_number);

      if (!userDetails && !emailRegex.test(user.contact_email)) {
        toast.error("Please enter the valid email");
        return false;
      }

      if (!isValidNumber) {
        toast.error("Please enter the valid phone number");
        return false;
      }

      const formData = new FormData();
      formData.append("first_name", user.first_name);
      formData.append("last_name", user.last_name);
      formData.append("contact_number", user.contact_number);
      formData.append("role", user.role_name);
      formData.append("email", user.contact_email);
      formData.append("site_guid", user.site_guid);
      user.image && formData.append("profile_image", user.image);

      let url = `${apiBaseUrl}create_msp_user`;
      let method = "POST";

      if (userDetails) {
        url = `${apiBaseUrl}update_user`;
        method = "PUT";
        formData.append("account_guid", user.account_guid);
      } else {
        formData.append("password", user.password);
      }
      const tokens = localStorage.getItem("auth");
      //@ts-ignore
      const response = await axios({
        method: method,
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${tokens}`,
        },
      });

      if (response.status === 200) {
        toast.success(
          userDetails
            ? "User Updated Successfully"
            : "User Created Successfully"
        );
        onClose("success");
      }
    } catch (error) {
      toast.error(error.response?.data?.detail || "Something went wrong");
    }
  };

  const fetchSites = () => {
    try {
      axios
        .get(`${apiBaseUrl}sites_dropdown`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("auth")}` },
        })
        .then((res) => {
          setSiteDropdown(res.data);
        });
    } catch (error) {
      console.log("Err==>", error);
    }
  };

  useEffect(() => {
    if (userDetails) {
      userDetails.site_guid = userDetails.site_id;
      console.log("user===>", userDetails);
      console.log("rolesDetails==>", rolesDetails)
      debugger;
      dispatch({
        type: "UPDATE",
        payload: userDetails,
      });
    }
    generateRandomPassword();
    fetchSites();
  }, []);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="roleCreateModal msp-user-creation "
    >
      <Box className="user-create-modal ">
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{ mb: 1 }}
          className="mspRoleHeader"
        >
          <b>{userDetails ? "Update User" : "User Creation"}</b>
          <Button onClick={() => onClose()} className="closeButton">
            {" "}
            <img alt="closeIcon" src={CloseIcon} className="closeicon" />
          </Button>
        </Typography>
        <Box component="form" noValidate className="mspDetailsForm">
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item lg={6} md={6} sm={12}>
              <TextField
                size="small"
                label="First Name"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="first_name"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={user.first_name}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <TextField
                size="small"
                label="Last Name"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="last_name"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={user.last_name}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <FormControl fullWidth size="small" className="msp-role">
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  size="small"
                  name="role_name"
                  value={user.role_name}
                  defaultValue={user.role_name}
                  disabled={user.is_logged_user}
                >
                  {userDetails && user.is_logged_user && (
                    <MenuItem key={1} value={"Super Admin"}>
                      {"Super Admin"}
                    </MenuItem>
                  )}
                  {rolesDetails?.map((item) => (
                    <MenuItem key={item.role_id} value={item.role_name}>
                      {item.role_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <CustomPhoneInput
                sx={{ mt: 0 }}
                defaultCountry="us"
                onlyCountries={["us"]}
                disableCountryCode
                country="us"
                disableDropdown={true}
                editable={user.contact_number}
                name="contact_number"
                onChange={(value) => {
                  handlePhoneNumber(value);
                }}
              />
            </Grid>
          </Grid>

          <Button
            sx={{ my: 2 }}
            component="label"
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            className="uploadBtn"
          >
            Upload Profile *
            <VisuallyHiddenInput onChange={uploadImage} required type="file" />
          </Button>

          {user.image && (
            <Box my={2}>
              <Box className="imgPreviewContainer">
                <img
                  src={`${URL.createObjectURL(user.image)}`}
                  alt="uploadedImage"
                />
              </Box>
            </Box>
          )}

          {!user.image && user.image_base64 && (
            <Box my={2}>
              <Box className="imgPreviewContainer">
                <img
                  alt="logo"
                  src={`data:image/png;base64,${user.image_base64}`}
                  className="mspListImage"
                />
              </Box>
            </Box>
          )}
          <FormControl fullWidth size="small" sx={{ my: 2 }}>
            <InputLabel id="demo-simple-select-label">Site</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Site"
              onChange={(e) => {
                handleChange(e);
              }}
              size="small"
              name="site_guid"
              value={user.site_guid}
            >
              {sitesDropdown?.map((item) => (
                <MenuItem key={item.site_guid} value={item.site_guid}>
                  {item.site_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!userDetails && (
            <>
              <TextField
                size="small"
                label="Email Address"
                InputLabelProps={{ shrink: true }}
                sx={{ my: 2, width: "100%" }}
                name="contact_email"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <TextField
                disabled
                helperText="Auto Generated Password"
                size="small"
                label="Password"
                value={user.password}
                sx={{ mt: 2, mb: 1, width: "100%" }}
                name="password"
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <IconButton
                //         edge="end"
                //         onClick={generateRandomPassword}
                //         sx={{ mr: 1 }}
                //       >
                //         <ReplayIcon />
                //       </IconButton>
                //       <IconButton onClick={handleCopyClipboard}>
                //         <ContentCopyIcon />
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
              />
            </>
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="end"
          gap={2}
          sx={{ paddingRight: "40px", mb: 2 }}
        >
          <Button onClick={() => onClose()} variant="outlined" type="submit">
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={(e) => handleSubmit(e)}
            className="closeicon"
          >
            {userDetails ? "Update" : "Create"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default UserModal;
