import React, { useState } from "react";
import {
  Modal,
  FormControl,
  Box,
  Grid,
  Button,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  bgcolor: "background.paper",
  boxShadow:
    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
  borderRadius: "10px",
  p: 2,
};

const BinRangeModal = ({ open, close }) => {
  const [binRange, setBingRange] = useState();
  const [submit, setSubmit] = useState(false);

  const handleSubmit = () => {
    setSubmit(true);
    if (!binRange) {
      return false;
    } else {
      close(binRange);
    }
  };
  return (
    <div className="SortEditor">
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal mailingModal"
      >
        <Box sx={style}>
          <Box className="create-new-SE">
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Bin Range
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Machine Bin Type"
                    name="binRange"
                    onChange={(e) => {
                      setBingRange(e.target.value);
                    }}
                  >
                    <MenuItem value={"sequential"}>Sequential</MenuItem>
                    <MenuItem value={"roundRobin"}>Round Robin</MenuItem>
                  </Select>
                </FormControl>
                {!binRange && submit && (
                  <p className="validation-text">Please select the bin type</p>
                )}
              </Grid>
            </Grid>
            <Box className="mailing-modal-control" sx={{ mt: 2 }}>
              <Button variant="outlined" onClick={() => {close()}}>
                Cancel
              </Button>
              <Button
                variant="contained"
                className="Btn"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default BinRangeModal;
