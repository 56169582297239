import * as React from "react";
import {
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { GridToolbarContainer } from "@mui/x-data-grid";

export default function CustomToolBar({ toolbarWished }) {
  return (
    <GridToolbarContainer className="gridToolbarContainer">
      {toolbarWished.columns && <GridToolbarColumnsButton />}
      {toolbarWished.filter && <GridToolbarFilterButton />}
      {toolbarWished.density && <GridToolbarDensitySelector />}
      {toolbarWished.export && <GridToolbarExport className="exportBtn" />}
    </GridToolbarContainer>
  );
}
