import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const plant_range = [
  {
    id: 1,
    plant: "MID-ISLAND NY 005",
    range: "005",
  },
  {
    id: 2,
    plant: "SAJ JUAN PR 006",
    range: "006-009",
  },
  {
    id: 3,
    plant: "SPRINGFIELD MA 010",
    range: "010-011, 013",
  },
  {
    id: 4,
    plant: "SPITTSFIELD MA 012",
    range: "012",
  },
  {
    id: 5,
    plant: "CENTRAL MA 015",
    range: "012",
  },
];

const three_range = [
  {
    id: 1,
    plant: " NY 005",
    range: "005",
  },
  {
    id: 2,
    plant: " PR 006",
    range: "006",
  },
  {
    id: 3,
    plant: "MA 010",
    range: " 013",
  },
  {
    id: 4,
    plant: " 012",
    range: "012",
  },
  {
    id: 5,
    plant: "015",
    range: "012",
  },
];

export default function SortEditorTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="sort-editor-tab">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          <Tab label="5 DIGIT" {...a11yProps(0)} />
          <Tab label="3 DIGIT" {...a11yProps(1)} />
          <Tab label="AADC" {...a11yProps(2)} />
          <Tab label="SCF" {...a11yProps(3)} />
          <Tab label="BMC" {...a11yProps(4)} />
          <Tab label="UNUSED ZIPS" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction} className="tab-1">
        <TableContainer component={Paper} className="plan-range-datas">
          <Table
            sx={{ minWidth: 350, width: "100%" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ py: 0, px: 1 }}>Plant </TableCell>
                <TableCell sx={{ py: 0, px: 1 }}>Range</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plant_range.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ py: 0, px: 1 }}>{row.plant}</TableCell>
                  <TableCell sx={{ py: 0, px: 1 }}>{row.range}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <TableContainer component={Paper} className="plan-range-datas">
          <Table
            sx={{ minWidth: 350, width: "100%" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ py: 0, px: 1 }}>Plant </TableCell>
                <TableCell sx={{ py: 0, px: 1 }}>Range</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {three_range.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ py: 0, px: 1 }}>{row.plant}</TableCell>
                  <TableCell sx={{ py: 0, px: 1 }}>{row.range}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        Under Construction
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        Under Construction
      </TabPanel>
      <TabPanel value={value} index={4} dir={theme.direction}>
        Under Construction
      </TabPanel>
      <TabPanel value={value} index={5} dir={theme.direction}>
        Under Construction
      </TabPanel>
    </Box>
  );
}
