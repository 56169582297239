import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import "../../style/home.scss";
const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "machineName",
    headerName: "Machine Name",
    width: 150,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    editable: true,
  },
];

const rows = [
  { id: 1, machineName: "UMC001", status: "Sorted" },
  { id: 2, machineName: "UMC002", status: "Pending" },
  { id: 3, machineName: "UMC003", status: "Inprogress" },
  { id: 4, machineName: "UMC004", status: "Sorted" },
  { id: 5, machineName: "UMC005", status: "Pending" },
  { id: 6, machineName: "UMC006", status: "Inprogress" },
  { id: 7, machineName: "UMC007", status: "Sorted" },
  { id: 8, machineName: "UMC008", status: "Pending" },
  { id: 9, machineName: "UMC009", status: "Inprogress" },
];
function CustomToolbar() {
  return (
    <GridToolbarContainer className="dataGridExport">
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
export default function DataGridDemo({ dataGridref, isModalopen }) {
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <DataGrid
        ref={dataGridref}
        className={
          isModalopen ? `machineStatusGrid ` : `machineStatusGrid modalForPage`
        }
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 3,
            },
          },
        }}
        pageSizeOptions={[3]}
        slots={{
          toolbar: isModalopen && CustomToolbar,
        }}
      />
    </Box>
  );
}
