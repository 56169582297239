import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  loading: false,
  error: null,
  work_order_id: null,
  file_type: "",
  file_name: "",
  file_upload: "",
  msp_id: "",
  msp_name: "",
  site_id: "",
  mo_account_id: "",
  eps_account_number: "",
  eps_reference_field: "",
  work_order_type_id: 0,
  job_name: "",
  po_number: "",
  owner_id: "",
  comments: "",
  work_order_upload_option_id: 1,
  is_additional_services_present: 0,
  pieces: 0,
  trays: 0,
  pallets: 0,
  mailing_date: moment(new Date()).format("YYYY-MM-DD"),
  pick_up_date: moment(new Date()).format("YYYY-MM-DD"),
  special_processing_notes: "",
  work_order_type: "",
  work_order_upload_option: "",
};

const WorkOrderCreation = createSlice({
  name: "workOrder",
  initialState: initialState,
  reducers: {
    fetchDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDataStop: (state) => {
      state.loading = false;
      state.error = null;
    },
    handleWorkOrderChange: (state, action) => {
      state[action.payload.field] = action.payload.value;
    },

    fetchDataError: (state, action) => {
      if(action.payload.response.data.detail && action.payload.response.data.detail.length > 0){
        state.loading = false;
        state.error =  action.payload.response.data.detail[0].msg;
      }
    },
    reset: () => {
      return initialState;
    },
    updateWO: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  fetchDataStart,
  fetchDataStop,
  fetchDataError,
  handleWorkOrderChange,
  reset,
  updateWO,
} = WorkOrderCreation.actions;
export default WorkOrderCreation.reducer;
