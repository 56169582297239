import React, { useState, useEffect, useReducer } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  Select,
  Button,
  TableContainer,
  TablePagination,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Link, useNavigate } from "react-router-dom";
import "./workOrder.scss";
import Loader from "../../../components/Loader";
import { workOrderService } from "../../../services/WorkorderService";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "../../../assets/icons/edit.svg";
import axios from "axios";
import { apiBaseUrl } from "../../../config";
import dayjs from "dayjs";
import { reset } from "../../../store/WorkOrderCreation";
import { useDispatch } from "react-redux";
const initialState = {
  mo_work_order_id: "",
  po_number: "",
  job_name: "",
  work_order_status_id: "",
  date_from: "",
  date_to: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "RESET":
      return { ...initialState };
    default:
      return state;
  }
};
const fieldMapping = {
  date_from: { fields: "date_from", values: "" },
  date_to: { fields: "date_to", values: "" },
  mo_work_order_id: { fields: "mo_work_order_id", values: "" },
  po_number: { fields: "po_number", values: "" },
  job_name: { fields: "job_name", values: "" },
  work_order_status_id: { fields: "work_order_status_id", values: "" },
};

export default function WorkOrderHomePage() {
  const [workOrderList, setWorkOrderList] = useState([]);
  const [loader, setLoader] = React.useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [itemStatus, setItemStatus] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [moPermission, setMoPermission] = useState([]);

  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageSize(event.target.value);
  };
  const getPermissions = () => {
    try {
      const token = localStorage.getItem("auth");
      const role_id = localStorage.getItem("role_id");
      axios
        .get(
          `${apiBaseUrl}mail_owner_role_group_function_mapping_list?role_id=${role_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          res.data.mo_role_group_function_mapping_list?.map((obj) => {
            obj.role_function_name === "Work Order" && setMoPermission(obj);
          });
        });
    } catch (error) {
      console.log("Err==>", error);
    }
  };

  const getWorkOrderList = () => {
    try {
      setLoader(true);

      workOrderService
        .workOrderList({
          page: page + 1,
          page_size: pageSize,
        })

        .then((res) => {
          const workOrderList = res.data.work_order_list;
          setWorkOrderList(workOrderList);
          setCount(res.data.total_count);
          setLoader(false);
        })
        .catch((e) => {
          toast.error("Something went wrong");
          setLoader(false);
        });
    } catch (error) {
      console.error("Err===>", error);
      setLoader(false);
    }
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: "SET_FIELD",
      field: name,
      value: value,
    });
  };

  const handleDateChanges = (fieldName, newDate) => {
    const year = newDate.$y;
    const month = String(newDate.$M + 1).padStart(2, "0");
    const day = String(newDate.$D).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    dispatch({
      type: "SET_FIELD",
      field: fieldName,
      value: formattedDate,
    });
  };

  const handleSearchReset = () => {
    dispatch({
      type: "RESET",
    });
    getWorkOrderList();
  };

  const handleSearchSubmit = async () => {

    const params = [];
    const keys = [];

    Object.keys(state).forEach((key) => {
      if (state[key]) {
        if (fieldMapping[key]) {
          params.push(`fields=${fieldMapping[key].fields}`);
          keys.push(`values=${state[key]}`);
        }
      }
    });
    params.push(`page=${page + 1}`);
    params.push(`page_size=${pageSize}`);
    params.push(`auto_error=true`);

    const queryString = [...params, ...keys].join("&");

    try {
      const response = await axios.get(
        `${apiBaseUrl}get_work_order?${queryString}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        }
      );
      if (response) {
        setWorkOrderList(response.data.work_order_list);
        setCount(response.data.total_count);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error fetching work orders:", error);
    }
  };

  const fetchStatusDropdown = async () => {
    try {
      axios(`${apiBaseUrl}get_work_order_status`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
        .then((response) => {
          setItemStatus(response.data.work_order_status_list);
        })
        .catch((e) => console.log("Err===>", e));
    } catch (error) {
      console.log("Err==>", error);
    }
  };
  const createNewWo = () => {
    reduxDispatch(reset());
    navigate("/customer-portal/wocreate");
  };

  useEffect(() => {
    handleSearchSubmit();
  }, [page, pageSize]);

  useEffect(() => {
    fetchStatusDropdown();
    getPermissions();
  }, []);
  return (
    <Box className="work_order_home_page">
      {moPermission?.mo_role_group_function_mapping_list?.role_create_flag ===
        1 && (
        <Box className="workOrder_add_button">
          {" "}
          <Button className="Btn" onClick={createNewWo}>
            + Add New WO
          </Button>
        </Box>
      )}

      {loader && <Loader />}
      <div className="workOrder">
        <Box>
          <Box className="workOrder_header">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography variant="p">Work Order Home</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className="customerDetails" sx={{ mt: 4 }}>
            <Box className="customerDetailsHeader">
              <Typography variant="p">
                <b>Customer Details</b>
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" className="customer-details">
                Customer Name : <b>{localStorage.getItem("user_name")}</b>
              </Typography>
            </Box>
          </Box>

          <Accordion className="customerDetails">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="customerDetailsSearch"
            >
              <b>Search Parameter</b>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="customerDetails searchparameter">
                <Box className="container">
                  <Grid container spacing={2} sx={{ mt: 0.5 }}>
                    <Grid item xs={6}>
                      <Box className="searchParam">
                        <Grid item xs={3}>
                          <label
                            className="searchparamLabel"
                            htmlFor="woNumber"
                          >
                            WO Number
                          </label>{" "}
                        </Grid>
                        <Grid item xs={7}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="mo_work_order_id"
                            value={state.mo_work_order_id}
                            onChange={(e) => handleSearchChange(e)}
                          />
                        </Grid>
                      </Box>

                      <Box className="searchParam">
                        <Grid item xs={3}>
                          <label
                            className="searchparamLabel"
                            htmlFor="job_name"
                          >
                            Job Name
                          </label>{" "}
                        </Grid>
                        <Grid item xs={7}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="job_name"
                            value={state.job_name}
                            onChange={(e) => handleSearchChange(e)}
                          />
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="searchParam">
                        <Grid item xs={3}>
                          <label
                            className="searchparamLabel"
                            htmlFor="poNumber"
                          >
                            PO Number
                          </label>{" "}
                        </Grid>
                        <Grid item xs={7}>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="po_number"
                            value={state.po_number}
                            onChange={(e) => handleSearchChange(e)}
                          />
                        </Grid>
                      </Box>
                      <Box className="searchParam">
                        <Grid item xs={3}>
                          <label
                            className="searchparamLabel"
                            htmlFor="item_status"
                          >
                            Item Status
                          </label>{" "}
                        </Grid>
                        <Grid item xs={7}>
                          <FormControl fullWidth size="small">
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              defaultValue={10}
                              name="work_order_status_id"
                              value={state.work_order_status_id}
                              onChange={(e) => handleSearchChange(e)}
                            >
                              {itemStatus &&
                                itemStatus.map((item) => (
                                  <MenuItem
                                    key={item.work_order_status_id}
                                    value={item.work_order_status_id}
                                  >
                                    {item.work_order_status_name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 0.5 }}>
                    <Grid item xs={6}>
                      <Box className="searchParam">
                        <Grid item xs={3}>
                          <label
                            className="searchparamLabel"
                            htmlFor="datefrom"
                          >
                            Date From
                          </label>
                        </Grid>
                        <Grid item xs={7}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            size="small"
                          >
                            <DatePicker
                              slotProps={{
                                textField: {
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              name="date_from"
                              value={
                                state.date_from
                                  ? dayjs(state.date_from)
                                  : dayjs(new Date())
                              }
                              onChange={(newDate) =>
                                handleDateChanges("date_from", newDate)
                              }
                              label="Date From"
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="searchParam">
                        <Grid item xs={3}>
                          <label className="searchparamLabel" htmlFor="dateto">
                            Date To
                          </label>
                        </Grid>
                        <Grid item xs={7}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            size="small"
                          >
                            <DatePicker
                              slotProps={{
                                textField: {
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              name="date_to"
                              onChange={(newDate) =>
                                handleDateChanges("date_to", newDate)
                              }
                              value={
                                state.date_to
                                  ? dayjs(state.date_to)
                                  : dayjs(new Date())
                              }
                              label="Date To"
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Box>
                      <Grid item xs={11}>
                        <Box display="flex" justifyContent="end" sx={{ mt: 2 }}>
                          {" "}
                          <Button
                            sx={{ mr: 2 }}
                            className="Btn"
                            onClick={(e) => handleSearchSubmit(e)}
                          >
                            Search
                          </Button>
                          <Button
                            sx={{ mr: 5 }}
                            variant="outlined"
                            onClick={() => handleSearchReset()}
                          >
                            Reset
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Box className="customerDetails results">
            <Box className="customerDetailsHeader">
              <Typography variant="p">
                <b>List Of Work Orders</b>
              </Typography>
            </Box>
            <Box
              sx={{ height: 300, width: "100%", mt: 2, overflow: "auto" }}
              className="printTable"
            >
              <TableContainer component={Paper} className="machineDataTable">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          position: "sticky",
                          left: 0,
                          top: 0 /* Don't forget this, required for the stickiness */,
                          boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
                          zIndex: 800,
                        }}
                      >
                        Mail Owner Name
                      </TableCell>
                      <TableCell>Mailing Date </TableCell>
                      <TableCell>PO Number</TableCell>
                      <TableCell>Pallets</TableCell>
                      <TableCell>Pieces</TableCell>
                      <TableCell>Trays</TableCell>
                      <TableCell>Receipt Ticket No</TableCell>
                      <TableCell>Status</TableCell>

                      <TableCell
                        align="left"
                        style={{
                          position: "sticky",
                          right: 0,
                          zIndex: 800,
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workOrderList?.length > 0 ? (
                      workOrderList?.map((row, index) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={{
                              position: "sticky",
                              left: 0,
                              background: "white",
                              zIndex: 800,
                              top: 0 /* Don't forget this, required for the stickiness */,
                              boxShadow: " 0 2px 2px -1px rgba(0, 0, 0, 0.4)",
                            }}
                          >
                            {row.mail_owner_name}
                          </TableCell>
                          <TableCell>{row.mailing_date}</TableCell>
                          <TableCell>{row.po_number}</TableCell>

                          <TableCell>{row.pallets}</TableCell>

                          <TableCell>{row.pieces}</TableCell>
                          <TableCell>{row.trays}</TableCell>

                          <TableCell align="center">
                            {row?.receipt_ticket_id ? (
                              row?.receipt_ticket_id
                            ) : (
                              <Link
                                to={`/customer-portal/receiptCreation/${row.mo_work_order_id}`}
                              >
                                <Button className="closeButton">
                                  <AddCircleOutlineIcon sx={{ mr: 1 }} /> Add
                                </Button>
                              </Link>
                            )}
                          </TableCell>
                          <TableCell>
                            <p className="recordStatus">
                              {row.work_order_status_name}
                            </p>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{
                              position: "sticky",
                              right: 0,
                              zIndex: 800,
                              background: "white",
                            }}
                          >
                            {row.work_order_status_name == "Registered" && (
                              <div className="actionIcons">
                                <span>
                                  <Button
                                    className="closeButton"
                                    onClick={() =>
                                      navigate(
                                        `/customer-portal/woupdate/${row.mo_work_order_id}`
                                      )
                                    }
                                  >
                                    <img
                                      src={EditIcon}
                                      className="actionIcons"
                                      alt="editicon"
                                    />
                                  </Button>
                                </span>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <Typography variant="h6" align="center">
                            No records found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
        </Box>
      </div>
    </Box>
  );
}
