import {
  Modal,
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import "./routercreation.scss";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { apiBaseUrl } from "../../../config";
import CloseIcon from "../../../assets/icons/Close Square.svg";

const ViewModal = ({ open, onClose, viewableDetail }) => {
  const fetchWorkOrder = async () => {
    const isAuth = localStorage.getItem("auth");

    const response = await axios.get(
      `${apiBaseUrl}mail_owner_created_work_order`,
      {
        headers: {
          Authorization: `Bearer ${isAuth}`,
        },
      }
    );
    return response.data.mail_owner_created_work_order_list;
  };
  const { data: woDropdown } = useQuery({
    queryKey: ["workOrders"],
    queryFn: fetchWorkOrder,
  });

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="roleCreateModal"
    >
      <Box className="route-create-modal">
        <Box component="form">
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            sx={{ mb: 3 }}
            className="mspRoleHeader"
          >
            <b>View Route</b>
            <Button onClick={() => onClose()} className="closeButton">
              {" "}
              <img alt="closeIcon" src={CloseIcon} className="closeicon" />
            </Button>
          </Typography>

          <Box className="routerContainer">
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item lg={5} md={5} sm={12}>
                <TextField
                  size="small"
                  label="Route Name"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "100%" }}
                  name="mo_route_name"
                  value={viewableDetail && viewableDetail.mo_route_name}
                />
              </Grid>
            </Grid>

            {viewableDetail?.stops.map((div, index) => (
              <Box my={3} className="routerfield">
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item lg={11} md={11}>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} sm={12}>
                        <TextField
                          sx={{ my: 2 }}
                          fullWidth
                          id={`msp-site-${div.id}`}
                          label="Mail Owner"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={div?.mail_owner_name || ""}
                          name={`msp_site_${div.id}`}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12}>
                        <TextField
                          sx={{ my: 2 }}
                          fullWidth
                          id={`msp-site-${div.id}`}
                          label="Mail Owner"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={div?.job_name || ""}
                          name={`msp_site_${div.id}`}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12}>
                        <TextField
                          sx={{ my: 2 }}
                          fullWidth
                          id={`msp-site-${div.id}`}
                          label="MSP Site"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={div?.site_name || ""}
                          name={`msp_site_${div.id}`}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          sx={{ mb: 2 }}
                          label="Address"
                          variant="outlined"
                          multiline
                          value={div?.street || ""}
                          name={`address_${div.id}`}
                          rows={3}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12}>
                        <TextField
                          sx={{ my: 2 }}
                          fullWidth
                          id={`city-${div.id}`}
                          label="City"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={div?.city || ""}
                          name={`city_${div.id}`}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12}>
                        <TextField
                          sx={{ my: 2 }}
                          fullWidth
                          id={`state-${div.id}`}
                          label="State"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={div?.state || ""}
                          name={`state_${div.id}`}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12}>
                        <TextField
                          sx={{ my: 2 }}
                          fullWidth
                          id={`postal-code-${div.id}`}
                          label="Postal Code"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={div?.postal_code || ""}
                          name={`postal_code_${div.id}`}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item lg={1} md={1}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          className="remove"
          onClick={() => handleRemoveDiv(div.id)}
          sx={{ p: 0 }}
        >
          <Tooltip title="Delete Route">
            <DeleteIcon className="deleteButton" />
          </Tooltip>
        </Button>
      </Box>
    </Grid> */}
                </Grid>
              </Box>
            ))}

            {/* <Box display="flex" justifyContent="end" sx={{ my: 4 }}>
            <Tooltip title="Add More Routes">
              <Button className="Btn" onClick={handleAddDiv}>
                <AddCircleOutlineIcon /> Add
              </Button>
            </Tooltip>
          </Box> */}

            <Box display="flex" justifyContent="end" gap={2} sx={{ mb: 2 }}>
              <Button
                variant="outlined"
                type="button"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="Btn"
                onClick={() => onClose()}
              >
                Ok
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewModal;
