import { React, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Button,
  Typography,
  FormControl,
  OutlinedInput,
  IconButton,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import FileUpload from "../../../assets/images/fileUpload.png";
import { MspService } from "../../../services/MspService";
import { useNavigate } from "react-router-dom";
import PubSub from "pubsub-js";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";
import "../../../style/style.css";
import ZipIcon from "../../../assets/images/zip.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { convertTimeZone } from "../../../utils/formateDate";
import Loader from "../../../components/Loader";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomToolBar from "../../../components/customToolbar/customToolbar";
import CustomPagination from "../../../components/customPagination/custompagination";
const UploadDiv = makeStyles({
  root: {
    position: "relative",
    border: "3px dotted grey",
    textAlign: "center",
    padding: "20px 50px",
    borderRadius: "5px",
  },
  image: {
    width: "100px",
    height: "100px",
  },
  input: {
    display: "block",
    position: "absolute",
    top: 0,
    bottom: 0,
    opacity: 0,
    cursor: "pointer",
    left: 0,
    right: 0,
    height: "100%",
  },
  p: {
    margin: 0,
    color: "grey",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  failed: {
    padding: "7px 15px",
    backgroundColor: "#F9B3B9",
    borderRadius: "5px",
    color: "#F04452",
    textAlign: "center",
  },
  uploaded: {
    padding: "7px 15px",
    backgroundColor: "#9BE6CC",
    borderRadius: "5px",
    color: "#369260",
    textAlign: "center",
    display: "inline-block",
  },
  manifested: {
    padding: "7px 15px",
    backgroundColor: "#ABC7F9",
    borderRadius: "5px",
    color: "#072f74",
    textAlign: "center",
    display: "inline-block",
  },
  notstarted: {
    padding: "7px 15px",
    backgroundColor: "#FEEAAF",
    borderRadius: "5px",
    color: "#BF982B",
    textAlign: "center",
    display: "inline-block",
  },
  tableHead: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "blue",
    },
  },
}));

// const columns = [
//   { field: "job_id", headerName: "JOB ID" },
//   { field: "filename", headerName: "FILE NAME" },
//   { field: "created_on", headerName: "CREATED ON" },
//   { field: "action", headerName: "ACTION" },
// ];

const rows = [
  {
    status: "Uploaded",
    id: 1,
    transactionId: "TXN123",
    company: "ABC Corp",
    site: "Site A",
    reference: "REF001",
    dateCreated: "04-01-2024",
    dateUploaded: "04-02-2024",
    env: "Production",
    records: 100,
    manifest: "Manifest A",
  },
  {
    status: "Failed",

    id: 2,
    transactionId: "TXN124",
    company: "XYZ Inc",
    site: "Site B",
    reference: "REF002",
    dateCreated: "04-03-2024",
    dateUploaded: "04-04-2024",
    env: "Testing",
    records: 150,
    manifest: "Manifest B",
  },
  {
    status: "Not Started",
    id: 3,
    transactionId: "TXN123",
    company: "ABC Corp",
    site: "Site A",
    reference: "REF001",
    dateCreated: "04-05-2024",
    dateUploaded: "04-06-2024",
    env: "Production",
    records: 100,
    manifest: "Manifest A",
  },
  {
    status: "Manifested",
    id: 4,
    transactionId: "TXN123",
    company: "ABC Corp",
    site: "Site A",
    reference: "REF001",
    dateCreated: "04-07-2024",
    dateUploaded: "04-08-2024",
    env: "Production",
    records: 100,
    manifest: "Manifest A",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "blue",
    color: "white",
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function GenerateMailDatLayout() {
  const classes = UploadDiv();
  const classes1 = useStyles();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [formDetails, setFormDetails] = useState({});
  const navigate = useNavigate();
  const [fileSize, setFileSize] = useState(null);
  const [loader, setLoader] = useState(false);
  const downloadGenerated = async (jobId) => {
    const formData = new FormData();
    formData.append("job_id", jobId);
    try {
      // PubSub.publish("msg", true);
      await MspService.downloadEvent(formData)
        .then(
          (downloadResponse) => {
            if (downloadResponse) {
              const url = window.URL.createObjectURL(
                new Blob([downloadResponse.data])
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "GeneratedJobs.zip");
              document.body.appendChild(link);
              link.click();
              toast.success("Generated successfully!!!");
            }
          },

          (error) => {
            console.log("Err===>", error);
            if (error?.status == "401") {
              toast.error(
                "Your session has expired. Please log in again to continue."
              );
              localStorage.setItem(
                "auth",
                JSON.stringify({
                  isAuth: false,
                  token: "",
                  userId: "",
                  companyId: "",
                  roleId: "",
                })
              );
              navigate("/login", { replace: true });
            } else {
              toast.error(error.data.detail);
            }
            PubSub.publish("msg", false);
          }
        )
        .catch((error) => {
          PubSub.publish("msg", false);
          toast.error(error);
        });
    } catch (error) {
      console.log("Err===>", error);
      PubSub.publish("msg", false);
      toast.error(error);
    }
  };
  const columns = [
    { field: "job_id", headerName: "JOB ID", width: 150 },
    { field: "filename", headerName: "FILE NAME", width: 300 },
    {
      field: "created_on",
      headerName: "CREATED ON",
      width: 250,
      valueGetter: (params) => convertTimeZone(params.row.created_on),
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 150,
      renderCell: (params) =>
        params.row.status === "Manifested" && (
          <Button
            type="button"
            variant="contained"
            onClick={() => downloadGenerated(params.row.job_id)}
          >
            Generate
          </Button>
        ),
    },
  ];
  const renderStatus = (status) => {
    let cellStyle;
    switch (status) {
      case "Failed":
        cellStyle = classes1.failed;
        break;
      case "Uploaded":
        cellStyle = classes1.uploaded;
        break;
      case "Manifested":
        cellStyle = classes1.manifested;
        break;
      case "Not Yet Started":
        cellStyle = classes1.notstarted;
        break;
      default:
        cellStyle = undefined;
    }
    return <div className={cellStyle}>{status}</div>;
  };

  useEffect(() => {
    getUploadFolderEvent();
  }, [page, pageSize]);

  const [jobList, setJobList] = useState([]);

  const getUploadFolderEvent = async () => {
    try {
      setLoader(true);
      // PubSub.publish("msg", true);
      await MspService.getUploadFolder({ page: page, page_size: pageSize })
        .then(
          (res) => {
            if (res && res?.status) {
              setRowCount(res.data.total_record);
              setJobList(res.data.upload_folder_list);
              setLoader(false);

              // PubSub.publish("msg", false);
            }
          },
          (error) => {
            if (error?.status == "401") {
              toast.error(
                "Your session has expired. Please log in again to continue."
              );

              localStorage.setItem(
                "auth",
                JSON.stringify({
                  isAuth: false,
                  token: "",
                  userId: "",
                  companyId: "",
                  roleId: "",
                })
              );
              setLoader(false);
              navigate("/login", { replace: true });
            } else {
              toast.error(error?.data?.detail || error);
              setLoader(false);
            }
            PubSub.publish("msg", false);
          }
        )
        .catch((error) => {
          PubSub.publish("msg", false);
          toast.error(error);
          setLoader(false);
        });
    } catch (error) {
      PubSub.publish("msg", false);
      toast.error(error);
      setLoader(false);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1); // Reset to first page
  };
  return (
    <>
      {loader && <Loader />}
      <Box
        style={{ height: 400, width: "100%", margin: "20px 0" }}
        className="roles rolesTable"
      >
        {/* <TableContainer component={Paper} sx={{ whiteSpace: "nowrap" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={classes1.tableHead}>
                {columns.map((column) => {
                  return <StyledTableCell>{column.headerName}</StyledTableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {jobList.map((row) => (
                <StyledTableRow
                  key={row.job_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell>{row.job_id}</StyledTableCell>
                  <StyledTableCell>{row.filename}</StyledTableCell>
                  <StyledTableCell>
                    {convertTimeZone(row.created_on)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.status === "Manifested" && (
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => downloadGenerated(row.job_id)}
                      >
                        Generate
                      </Button>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
        <DataGrid
          className="datagrid gridToolbar"
          localeText={{
            noRowsLabel: "No records Found",
          }}
          components={{
            Toolbar: CustomToolBar,
            Pagination: (props) => (
              <CustomPagination
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
              />
            ),
          }}
          componentsProps={{
            toolbar: {
              toolbarWished: {
                columns: true,
                filter: true,
                density: true,
                export: true,
              },
            },
          }}
          rows={jobList.map((job, index) => ({ ...job, id: index }))}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
}
