import { get, postFormData } from "./BaseService";
import { apiBaseUrl } from "../config";

export const workOrderService = {
  workOrderList,
  moticketReceiptCreate,
};

function workOrderList(params) {
  const url = `get_work_order`;
  return get(apiBaseUrl + url, params);
}

function moticketReceiptCreate(data) {
  const url = `receipt_ticket_creation`;
  return postFormData(apiBaseUrl + url, data);
}
