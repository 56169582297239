import React from "react";
import { Box } from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";

export default function Alert() {
  return (
    <Box className="contact-details alert-tab">
      <Box style={{ textAlign: "center" }}>
        <EngineeringIcon sx={{ fontSize: "100px", color: "dimgrey" }} />
        <p>
          <b>Under Construction</b>
        </p>
      </Box>
    </Box>
  );
}
