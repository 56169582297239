import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import AddMspIcon from "../../../assets/icons/Masked_Icon.svg";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { MachineService } from "../../../services/MachineService";
import MachineCreate from "./MachineCreate";
import MachineUpdate from "./MachineUpdate";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RolesService } from "../../../services/RolesService";
import MachinesTable from "./MachinesTable";
import "./Machines.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "85vh",
  borderRadius: "10px",
};

export default function Machines() {
  const [open, setOpen] = useState(false);
  const [machines, setMachines] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [permissionList, setPermissionList] = useState({});
  const [deleteId, setDeleteId] = useState();
  const [refresh, setRefresh] = useState();
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    getRolePermissions();
  }, []);

  const getRolePermissions = async () => {
    try {
      const response = await RolesService.getEiiRolePermissions(
        localStorage.getItem("role_id")
      );
      if (response.status === 200) {
        response.data.eii_role_group_function_mapping_list?.map((obj) => {
          obj.role_function_name === "Machines" && setPermissionList(obj);
        });
      }
    } catch (error) {
      console.log("Err==>", error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenEdit = (data) => {
    setOpenEdit(true);
    setMachines(data);
  };

  const handleDeleteOpen = (id) => {
    setDeleteModal(true);
    setDeleteId(id);
  };

  const handleDelete = async () => {
    try {
      const formData = new FormData();
      formData.append("machine_id", deleteId);
      MachineService.deleteMachineDetails(formData).then((res) => {
        if (res.status === 200) {
          toast.success("Inactivated Successfully");
        }
      });
    } catch (error) {
      console.log("Err===>", error);
    }
    handleDeleteClose();
  };

  const handleDeleteClose = () => setDeleteModal(false);

  const handleClose = (data) => {
    setOpen(false);
    setOpenEdit(false);
    data && setRefresh(Date.now());
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        {permissionList.msp_role_group_function_mapping_list
          ?.role_create_flag === 1 && (
          <Button
            variant="contained"
            onClick={() => handleOpen()}
            className="Btn"
          >
            <img
              src={AddMspIcon}
              height={25}
              width={25}
              style={{ marginRight: "10px" }}
              alt="add_machine"
            />
            Add Machine
          </Button>
        )}
      </Stack>
      <Box
        style={{ height: 400, width: "100%", margin: "20px 0" }}
        className="roles rolesTable"
      >
        <MachinesTable
          handleOpenEdit={handleOpenEdit}
          handleDeleteOpen={handleDeleteOpen}
          refresh={refresh}
          permissionList={permissionList}
        />
      </Box>
      {open && (
        <MachineCreate show={open} close={(data) => handleClose(data)} />
      )}

      {openEdit && (
        <MachineUpdate
          openEdit={openEdit}
          closeEdit={(data) => handleClose(data)}
          editList={machines}
        />
      )}
      {/* Delete Modal */}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onClose={handleDeleteClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="roleCreateModal"
        >
          <Box sx={style} style={{ height: "200px", width: "auto" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 1, p: 2 }}
            >
              <b>Are you want to Delete ? </b>
            </Typography>
            <Box sx={{ mt: 1, px: 2 }}>
              <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
                <Button
                  onClick={handleDeleteClose}
                  variant="outlined"
                  // className="Btn"
                  type="submit"
                >
                  No
                </Button>
                <Button
                  onClick={() => handleDelete()}
                  variant="outlined"
                  className="Btn"
                  type="submit"
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
