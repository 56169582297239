import moment from "moment-timezone";

export const convertTimeZone = (dateVal) => {
  var utcTimestamp = "";
  if (isNaN(dateVal)) {
    utcTimestamp = dateVal;
  } else {
    utcTimestamp = Date.parse(dateVal + "Z");
  }

  const localDate = moment
    .utc(utcTimestamp)
    .local()
    .format("MM/DD/YYYY hh:mm A");

  return localDate;
};

export const dateLocal = (val) => {
  const timestamp = val;
  const localDateTime = moment(timestamp, "DD-MM-YYYY");
  return localDateTime.local().format("MM/DD/YYYY");
};
