import EditIcon from "../../assets/icons/edit.svg";
import DeleteIcon from "../../assets/icons/Trash Bin Trash.svg";
import AddRoleIcon from "../../assets/icons/Masked_Icon.svg";
import CloseIcon from "../../assets/icons/Close Square.svg";

export const customerIcons = {
  EditIcon,
  DeleteIcon,
  AddRoleIcon,
  CloseIcon,
};
