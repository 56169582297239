import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const exportChartAsPdf = async (selectorName) => {
  try {
    const pdf = new jsPDF({ orientation: "landscape" });
    const holder = document.querySelector(`#${selectorName}`);
    const rect = holder.getBoundingClientRect();
    const x = Math.floor(rect.width * 0.264583);
    const y = Math.floor(rect.height * 0.264583);
    const canvas = holder && (await html2canvas(holder));
    const imgData = canvas.toDataURL("image/png");
    const preDown = pdf.addImage(imgData, "png", 20, 20, x, y);
    preDown.save(`${selectorName}.pdf`);
  } catch (error) {
    console.log("Err===>", error);
  }
};

export const exportChartAsPng = async (selectorName) => {
  try {
    const holder = document.querySelector(`#${selectorName}`);
    const canvas = await html2canvas(holder);
    const imgData = canvas.toDataURL("image/png");

    const link = document.createElement("a");
    link.href = imgData;
    link.download = `${selectorName}.png`;
    link.click();
  } catch (error) {
    console.log("Err===>", error);
  }
};
