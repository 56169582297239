import React, { useEffect, useReducer, forwardRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "../../../assets/icons/Close Square.svg";
import axios from "axios";
import { apiBaseUrl } from "../../../config";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import "./user.scss";
import { CustomInputProps, InitialStateProps, UserModalProps } from "./type";
import { SelectChangeEvent } from "@mui/material/Select";
import { MspService } from "../../../services/MspService";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
  "& .form-control": {
    width: "100%",
    height: "40px",
    fontSize: "14px",
    marginTop: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  "& .form-control.error": {
    border: "1px solid red",
  },
}));

const CustomPhoneInput = forwardRef(
  ({ viewable, editable, ...props }: CustomInputProps, ref) => (
    <StyledPhoneInput
      {...props}
      specialLabel="Contact Number"
      value={viewable || editable || ""}
      //@ts-ignore
      inputComponent={({ ...inputProps }) => (
        <TextField
          {...inputProps}
          inputRef={ref}
          size="small"
          sx={{ my: 2 }}
          required
          label="Contact Number"
          variant="outlined"
          fullWidth
        />
      )}
    />
  )
);

const initialState: InitialStateProps = {
  image_base64: "",
  first_name: "",
  last_name: "",
  contact_email: "",
  contact_number: "",
  password: "",
  role_name: "",
  image: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  postal_code: "",
  site_ids_str: "",
  site_details: [],
};

const reducer = (state: InitialStateProps, action: any) => {
  switch (action.type) {
    case "HANDLE CHANGE":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "UPDATE":
      return { ...action.payload };
    default:
      return state;
  }
};

const UserModal: React.FC<UserModalProps> = ({
  open,
  onClose,
  rolesDetails,
  userDetails,
}) => {
  const [user, dispatch] = useReducer(reducer, initialState);
  const [binaryString, setBinaryString] = useState("");
  const [sitesList, setSitesList] = useState([]);

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "HANDLE CHANGE",
      field: "image",
      payload: e.target.files[0],
    });
  };

  const generateRandomPassword = () => {
    let Genpassword = Math.random().toString(20).substring(2, 8);
    dispatch({
      type: "HANDLE CHANGE",
      field: "password",
      payload: Genpassword,
    });
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<any>
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    dispatch({
      type: "HANDLE CHANGE",
      field: name,
      payload: value,
    });
  };

  const handlePhoneNumber = (value: string | number) => {
    dispatch({
      type: "HANDLE CHANGE",
      field: "contact_number",
      payload: value,
    });
  };
  function base64ToFile(base64, filename) {
    if (!base64.includes(",")) {
      return null;
    }

    const arr = base64.split(",");
    if (arr.length !== 2) {
      return null;
    }

    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const handleSubmit = async () => {
    try {
      if (
        !user.first_name ||
        !user.last_name ||
        (!userDetails && !user.contact_email) ||
        !user.role_name ||
        !user.contact_number ||
        !user.address_1 ||
        !user.address_2 ||
        !user.city ||
        !user.state ||
        !user.postal_code
      ) {
        toast.error("Please enter the details");
        return false;
      }

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isValidNumber = /^\d{10}$/.test(user.contact_number);

      if (!userDetails && !emailRegex.test(user.contact_email)) {
        toast.error("Please enter the valid email");
        return false;
      }

      if (!isValidNumber) {
        toast.error("Please enter the valid phone number");
        return false;
      }

      const formData = new FormData();
      formData.append("first_name", user.first_name);
      formData.append("last_name", user.last_name);
      formData.append("contact_number", user.contact_number);
      formData.append("role", user.role_name);
      formData.append("email", user.contact_email);
      formData.append("address_1", user.address_1);
      formData.append("address_2", user.address_2);
      formData.append("city", user.city);
      formData.append("state", user.state);
      formData.append("postal_code", user.postal_code);
      formData.append("site_ids_str", user.site_ids_str);

      formData.append("email", user.contact_email);

      user.image && formData.append("profile_image", user.image);
      if (user.image_base64 && !user.image) {
        const filename = "example.png";
        const file = base64ToFile(
          `data:image/png;base64,${user.image_base64}`,
          filename
        );
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        const fileList = dataTransfer.files;

        const blob = URL.createObjectURL(fileList[0]);
        formData.append("profile_image", fileList[0]);
      }

      let url = `${apiBaseUrl}mail_owner_user_creation`;
      let method = "POST";

      if (userDetails) {
        url = `${apiBaseUrl}mail_owner_user_update`;
        method = "PUT";
        formData.append("account_guid", user.account_guid);
      } else {
        formData.append("password", user.password);
      }

      const tokens = localStorage.getItem("auth");
      //@ts-ignore
      const response = await axios({
        method: method,
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${tokens}`,
        },
      });

      if (response.status === 200) {
        toast.success(
          userDetails
            ? "User Updated Successfully"
            : "User Created Successfully"
        );

        onClose("success");
      }
    } catch (error) {
      toast.error(error.response?.data?.detail || "Something went wrong");
    }
  };
  const MspUsersList = () => {
    try {
      MspService.MOSitesList()
        .then((res) => {
          console.log("res", res);

          setSitesList(res.data);
        })
        .catch((e) => toast.error("Something went wrong"));
    } catch (e) {
      console.log("Err===>", e);
    }
  };
  const handleSelectChange = (event) => {
    const { value } = event.target;
    dispatch({
      type: "HANDLE CHANGE",
      field: "site_ids_str",
      payload: value,
    });
  };
  useEffect(() => {
    if (userDetails?.first_name) {
      userDetails.site_ids_str = userDetails.site_details.map(
        (val) => val.site_id
      );
      dispatch({
        type: "UPDATE",
        payload: userDetails,
      });
    }
    MspUsersList();
    generateRandomPassword();
  }, []);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="roleCreateModal"
    >
      <Box className="user-create-update-modal">
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{ mb: 3 }}
          className="mspRoleHeader"
        >
          <b>{userDetails ? "Update User" : "User Creation"}</b>
          <Button onClick={() => onClose()} className="closeButton">
            {" "}
            <img alt="closeIcon" src={CloseIcon} className="closeicon" />
          </Button>
        </Typography>
        <Box
          component="form"
          noValidate
          className="mspDetailsForm wo-user-input-data"
        >
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item lg={6} md={6} sm={12}>
              <TextField
                fullWidth
                size="small"
                label="First Name"
                InputLabelProps={{ shrink: true }}
                name="first_name"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={user.first_name}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <TextField
                fullWidth
                size="small"
                label="Last Name"
                InputLabelProps={{ shrink: true }}
                name="last_name"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={user.last_name}
                className="lastname"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  size="small"
                  name="role_name"
                  value={user.role_name}
                >
                  {rolesDetails?.map((item) => (
                    <MenuItem key={item.role_id} value={item.role_name}>
                      {item.role_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={12} className="wo-user-contact-field">
              <CustomPhoneInput
                defaultCountry="us"
                onlyCountries={["us"]}
                disableCountryCode
                country="us"
                disableDropdown={true}
                editable={user.contact_number}
                name="contact_number"
                onChange={(value) => {
                  handlePhoneNumber(value);
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <Button
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                className="uploadBtn"
              >
                Upload Profile *
                <VisuallyHiddenInput
                  onChange={uploadImage}
                  required
                  type="file"
                />
              </Button>
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              {user.image && (
                <Box my={2}>
                  <Box className="imgPreviewContainer">
                    <img
                      src={`${URL.createObjectURL(user.image)}`}
                      alt="uploadedImage"
                    />
                  </Box>
                </Box>
              )}

              {!user.image && user.image_base64 && (
                <Box my={2}>
                  <Box className="imgPreviewContainer">
                    <img
                      alt="mspListImage"
                      src={`data:image/png;base64,${user.image_base64}`}
                      className="mspListImage"
                    />
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid item xs={12} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                size="small"
                label="Address Line One"
                InputLabelProps={{ shrink: true }}
                name="address_1"
                value={user.address_1}
                multiline
                maxRows={4}
                onChange={(e) => handleChange(e)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                size="small"
                label="Address Line 2"
                InputLabelProps={{ shrink: true }}
                name="address_2"
                value={user.address_2}
                multiline
                maxRows={4}
                onChange={(e) => handleChange(e)}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                size="small"
                label="City"
                value={user.city}
                InputLabelProps={{ shrink: true }}
                name="city"
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                size="small"
                label="State"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 2,
                  style: { textTransform: "uppercase" },
                }}
                name="state"
                onChange={(e) => handleChange(e)}
                value={user.state}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              <TextField
                sx={{ my: 2 }}
                fullWidth
                size="small"
                label="Postal Code"
                InputLabelProps={{ shrink: true }}
                name="postal_code"
                onChange={(e) => handleChange(e)}
                value={user.postal_code}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <FormControl fullWidth required>
                <InputLabel shrink id="demo-simple-select-label">
                  Sites
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  className="checkboxList"
                  id="users-select"
                  multiple
                  size="small"
                  label="Sites"
                  value={user?.site_ids_str ? user.site_ids_str : []}
                  onChange={(e) => handleSelectChange(e)}
                  input={<OutlinedInput label="Sites" />}
                  renderValue={(selected) => {
                    return selected
                      .map(
                        (id) =>
                          sitesList.find((site) => site.site_guid === id)
                            ?.site_name
                      )
                      .join(",");
                  }}
                >
                  {sitesList.length > 0 &&
                    sitesList.map((site) => (
                      <MenuItem
                        className="userModal"
                        key={site.site_id}
                        value={site.site_guid}
                      >
                        <Checkbox
                          checked={
                            user.site_ids_str &&
                            user.site_ids_str.indexOf(site.site_guid) > -1
                          }
                        />
                        <ListItemText primary={site.site_name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {!userDetails && (
              <>
                <Grid item lg={12} md={12} sm={12}>
                  <TextField
                    size="small"
                    label="Email Address"
                    InputLabelProps={{ shrink: true }}
                    sx={{ my: 2, width: "100%" }}
                    name="contact_email"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Grid>

                <Grid item lg={12} md={12} sm={12}>
                  <TextField
                    fullWidth
                    disabled
                    helperText="Auto Generated Password"
                    size="small"
                    label="Password"
                    value={user.password}
                    name="password"
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <IconButton
                    //         edge="end"
                    //         onClick={generateRandomPassword}
                    //         sx={{ mr: 1 }}
                    //       >
                    //         <ReplayIcon />
                    //       </IconButton>
                    //       <IconButton onClick={handleCopyClipboard}>
                    //         <ContentCopyIcon />
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <Box
          display="flex"
          justifyContent="end"
          gap={2}
          sx={{ paddingRight: "40px", mb: 2 }}
        >
          <Button onClick={() => onClose()} variant="outlined" type="submit">
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={() => handleSubmit()}
            className="closeicon"
          >
            {userDetails ? "Update" : "Create"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserModal;
