import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Chip, Tooltip, Badge } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Trash Bin Trash.svg";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import MailAssignModal from "../../MSP/ProfileEditor/mailingEditorModal";
import { styled } from "@mui/material/styles";

function createData(
  totalCount,
  mailing_name,
  mailing_date,
  status,
  product,
  classdata,
  machine,
  sort_profile,
  worked_assign
) {
  return {
    totalCount,
    mailing_name,
    mailing_date,
    status,
    product,
    classdata,
    machine,
    sort_profile,
    worked_assign,
    history: [
      {
        name: "Order Name -1",
        approved: "Yes",
        pick_up_date: "2020-02-05",
        mailing_date: "2020-01-05",
        assigned_machine: 3,
      },
      {
        name: "Order Name -2",
        approved: "No",
        pick_up_date: "2020-22-05",
        mailing_date: "2020-21-05",
        assigned_machine: 2,
      },
    ],
  };
}

function Row(props) {
  const { row, modalOpen } = props;
  const [open, setOpen] = React.useState(false);

  const getStatusContent = (status) => {
    switch (status) {
      case "Yet To Assign":
        return (
          <Chip label="Yet To Assign" variant="outlined" color="warning" />
        );

      case "Open":
        return (
          <Box>
            <Chip label="Open" color="success" variant="outlined" />
          </Box>
        );
      case "Closed":
        return <Chip label="Closed" color="error" variant="outlined" />;
      default:
        return <Typography color="textSecondary">Unknown Status</Typography>;
    }
  };

  const getWorkAssign = (status) => {
    switch (status) {
      case "Yes":
        return (
          <Chip
            className="work_assign_status"
            variant="outlined"
            label="Yes"
            color="success"
          />
        );

      case "No":
        return (
          <Box>
            <Chip
              className="work_assign_status"
              variant="outlined"
              label="No"
              color="error"
            />
          </Box>
        );

      default:
        return <Typography color="textSecondary">Unknown Status</Typography>;
    }
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -12,
      top: 10,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      fontSize: "13px",
      background: "brown",
      color: "white",
    },
  }));
  const StyledChip = styled(Chip)(({ theme }) => ({
    "& .chips .MuiChip-root": {
      borderRadius: "8px",
    },
  }));
  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className="mailingview_table"
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <StyledBadge badgeContent={row.totalCount}>
            {row.mailing_name}
          </StyledBadge>
        </TableCell>
        <TableCell>{row.mailing_date}</TableCell>
        <TableCell>{getStatusContent(row.status)}</TableCell>
        <TableCell>{row.product}</TableCell>
        <TableCell>{row.classdata}</TableCell>
        <TableCell>{row.machine}</TableCell>
        <TableCell>{row.sort_profile}</TableCell>
        <TableCell>
          {getWorkAssign(row.worked_assign)}
          {/* <StyledChip className="chips" label={row.worked_assign} /> */}
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center" gap="10px">
            <Tooltip title="Edit">
              <EditIcon />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteIcon />
            </Tooltip>
            {row.status === "Yet To Assign" && (
              <Tooltip title="Mail Assigning">
                <AssignmentTurnedInOutlinedIcon
                  sx={{ color: "#00304f" }}
                  onClick={() => modalOpen()}
                />
              </Tooltip>
            )}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 1, paddingTop: 1 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: "10px 10px 10px 70px" }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ p: 1 }}>Work Order Name</TableCell>
                    <TableCell>Work Order Approved</TableCell>
                    <TableCell>Mail Picked up Date</TableCell>
                    <TableCell>Mail Received Date</TableCell>
                    <TableCell>Assigned to Machine</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell sx={{ py: 2 }} component="th" scope="row">
                        {historyRow.name}
                      </TableCell>
                      <TableCell>{historyRow.approved}</TableCell>
                      <TableCell>{historyRow.pick_up_date}</TableCell>
                      <TableCell>{historyRow.mailing_date}</TableCell>
                      <TableCell>{historyRow.assigned_machine}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    mailing_name: PropTypes.string.isRequired,
    mailing_date: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        assigned_machine: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        pick_up_date: PropTypes.string.isRequired,
        mailing_date: PropTypes.string.isRequired,
        approved: PropTypes.string.isRequired,
      })
    ).isRequired,
    product: PropTypes.number.isRequired,
    class: PropTypes.string.isRequired,
    classdata: PropTypes.string.isRequired,
    machine: PropTypes.string.isRequired,
    sort_profile: PropTypes.string.isRequired,
    worked_assign: PropTypes.string.isRequired,
  }).isRequired,
};

const rows = [
  createData(
    "2",
    "M1",
    "2023-06-12",
    "Yet To Assign",
    "Card",
    "First Class",
    "Machine-1",
    "Profile 1",
    "No"
  ),
  createData(
    "0",
    "M2",
    "2023-07-15",
    "Closed",
    "Letter",
    "BPM",
    "Machine-2",
    "Profile 2",
    "Yes"
  ),
  createData(
    "1",
    "M3",
    "2023-12-22",
    "Open",
    "Parcel",
    "First Class",
    "Machine-3",
    "Profile 3",
    "Yes"
  ),
];

export default function MailingTable() {
  const [assignModalopen, setAssignModalOpen] = React.useState(false);

  const handleClose = () => {
    setAssignModalOpen(false);
  };
  const handleOpen = () => {
    setAssignModalOpen(true);
  };
  return (
    <Box>
      <TableContainer
        component={Paper}
        className="mailing-editor-table"
        sx={{ my: 2 }}
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Mailing Name</TableCell>
              <TableCell>Mailing Date</TableCell>
              <TableCell> Status of Mailing</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Class</TableCell>
              <TableCell>Machine</TableCell>
              <TableCell>Sort Profile</TableCell>
              <TableCell>Work Assigned</TableCell>
              <TableCell>Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.name} row={row} modalOpen={handleOpen} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {assignModalopen && (
        <MailAssignModal handleOpen={handleOpen} onClose={handleClose} />
      )}
    </Box>
  );
}
