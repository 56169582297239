import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Divider,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiBaseUrl } from "../config";
import { toast } from "react-toastify";

const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    machine_secret_key: "",
  });

  const [qrcode, setQrcode] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formObj = new FormData();
    formObj.append("email", formData.email);
    formObj.append("password", formData.password);
    formObj.append("machine_secret_key", formData.machine_secret_key);
    fetch(`${apiBaseUrl}signup_with_email`, {
      method: "POST",
      body: formObj,
    })
      .then(async (response) => {
        if (!response.body) {
          throw new Error("ReadableStream not supported in this browser.");
        }

        const reader = response.body.getReader(); // Get the stream reader
        let chunks = [];

        // Read the stream
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          chunks.push(value);
        }

        const blob = new Blob(chunks, { type: "image/png" });
        const imageUrl = URL.createObjectURL(blob);

        // Update the state with the new image URL
        setQrcode(imageUrl);
      })
      .catch((err) => {
        console.error("Error:", err);
        toast.error(err.message || "An error occurred");
      });
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(135deg, #00bfa5, #1e88e5)",
      }}
    >
      <Container maxWidth="xs">
        <Paper
          elevation={6}
          sx={{
            padding: "2rem",
            borderRadius: "15px",
            backgroundColor: "#ffffff",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ color: "#333", fontWeight: "bold", mb: 3 }}
          >
            Two Factor Authenticaion
          </Typography>
          {qrcode ? (
            <>
              <img src={qrcode} alt="qr code" style={{ width: "100%" }} />
              <p>
                <b>Note:</b>
              </p>
              <p>
                You can scan the QR code using either the{" "}
                <b>Google Authenticator</b> app or the{" "}
                <b>Microsoft Authenticator</b> app.
              </p>
              <Button
                onClick={() => navigate("/auth-two-factor-login")}
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: "#1976d2",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                  padding: "5px",
                  fontSize: "15px",
                  fontWeight: "bold",
                  textTransform: "none",
                }}
              >
                Back to Log in
              </Button>
            </>
          ) : (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    size="small"
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    value={formData.email}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    required
                    sx={{
                      "& label.Mui-focused": {
                        color: "#1976d2",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ddd",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1976d2",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#1976d2",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    size="small"
                    type="password"
                    value={formData.password}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    required
                    sx={{
                      "& label.Mui-focused": {
                        color: "#1976d2",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ddd",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1976d2",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#1976d2",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Machine Secret Key"
                    name="machine_secret_key"
                    size="small"
                    value={formData.machine_secret_key}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    required
                    sx={{
                      "& label.Mui-focused": {
                        color: "#1976d2",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ddd",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1976d2",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#1976d2",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      backgroundColor: "#1976d2",
                      "&:hover": {
                        backgroundColor: "#1565c0",
                      },
                      padding: "5px",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Sign Up
                  </Button>
                </Grid>
                <Grid item xs={12} className="or-content-box">
                  <Typography className="or-text">OR</Typography>
                  <Divider
                    sx={{
                      borderColor: "lightgrey",
                      borderWidth: "1px",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => navigate("/authorization")}
                    fullWidth
                    variant="contained"
                    sx={{
                      backgroundColor: "#1976d2",
                      "&:hover": {
                        backgroundColor: "#1565c0",
                      },
                      padding: "5px",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Log in
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default LoginPage;
