import * as React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const CopyrightsFoot = styled("footer")(({ theme }) => ({
  background: "linear-gradient(180deg,#092431 100%,#00344E 39.88%)",
  textAlign: "right",
  margin: "0 -24px",
  position: "fixed",
  bottom: 0,
  right: 0,
  left: 0,
  color: theme.palette.common.white,
  padding: "5px 50px 5px 0",
  zIndex: 6,
}));
export default function CopyrightsFooter() {
  return (
    <CopyrightsFoot>
      <Typography variant="caption">Copyright © Apian Sort 2024</Typography>
    </CopyrightsFoot>
  );
}
