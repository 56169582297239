import React from "react";
import { Grid, TextField, Button, Box } from "@mui/material";

export default function SiteInfo() {
  return (
    <div className="siteinfo">
      <div className="siteinfoDetails">
        <h3 className="infoHeader">Site Information</h3>
        <Grid container spacing={2}>
          <Grid
            item
            xs={10}
            display="flex"
            gap={1}
            alignItems="center"
            className="companyDetails"
            sx={{ mt: 3 }}
          >
            <Grid item xs={3} sx={{ my: 1 }}>
              <label>Company Name</label> <br />
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3} sx={{ my: 1 }}>
              <label>Phone Number</label> <br />
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3} sx={{ my: 1 }}>
              <label>Facility ID</label> <br />
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3} sx={{ mt: 3 }}>
              <Button
                sx={{ color: "white", width: "100%" }}
                variant="outlined"
                className="profileBtn"
              >
                {" "}
                Allow Job Sheet Scanning
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={10} className="companyDetails">
            <label>Address</label> <br />
            <TextField
              sx={{ width: "100%", my: 1 }}
              id="outlined-basic"
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={10}
            display="flex"
            gap={1}
            alignItems="center"
            className="companyDetails"
          >
            <Grid item xs={3} sx={{ my: 1 }}>
              <label>City</label> <br />
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3} sx={{ my: 1 }}>
              <label>State</label> <br />
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3} sx={{ my: 1 }}>
              <label>Zipcode</label> <br />
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <label>IDEA lliance Key</label> <br />
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic"
                size="small"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={10}
            display="flex"
            gap={1}
            alignItems="center"
            className="companyDetails"
          >
            <Grid item xs={6} sx={{ my: 1 }}>
              <label>Contact Name</label> <br />
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sx={{ my: 1 }}>
              <label>Email</label> <br />
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic"
                size="small"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="siteinfoDetails">
        <h3 className="infoHeader">USPS Settings</h3>
        <Grid container spacing={2}>
          <Grid item xs={10} display="flex" gap={1} alignItems="center">
            <Grid item xs={2} sx={{ my: 1 }}>
              <label>City</label> <br />
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2} sx={{ my: 1 }}>
              <label>State</label> <br />
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2} sx={{ my: 1 }}>
              <label>Zipcode</label> <br />
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3} sx={{ mt: 3 }}>
              <Button
                sx={{ color: "white", width: "100%" }}
                variant="outlined"
                className="profileBtn"
              >
                {" "}
                Is Entry Post Office an SCF?
              </Button>
            </Grid>
            <Grid item xs={3} sx={{ mt: 0 }}>
              <Button
                sx={{ color: "white", width: "100%" }}
                variant="outlined"
                className="profileBtn"
              >
                {" "}
                Ignore Bundle Minimums for FC and STD Auto Flats?
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="siteinfoDetails">
        <Grid container spacing={2}>
          <Grid item xs={3} sx={{ mt: 2 }}>
            <Box
              className="rateDetails"
              sx={{
                border: "1px solid lightgray",
                borderRadius: "10px",
                padding: "10px",
                position: "relative",
              }}
            >
              <h4 className="infoRateHeader">Rates</h4>
              <Grid item xs={12} sx={{ my: 2 }}>
                <label>Active USPS Rate Set</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sx={{ my: 1 }}>
                <label>Default Priority Rate</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ mt: 2 }}>
            <Box
              className="rateDetails"
              sx={{
                border: "1px solid lightgray",
                borderRadius: "10px",
                padding: "10px",
                position: "relative",
                display: "flex",
                gap: "10px",
              }}
            >
              <h4 className="infoRateHeader">
                Full Service Mail Provider IDS (By)
              </h4>
              <Grid item xs={6} sx={{ my: 2 }}>
                <label>MID</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sx={{ my: 2 }}>
                <label>CRID</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
            </Box>
            <Box
              className="rateDetails"
              sx={{
                border: "1px solid lightgray",
                borderRadius: "10px",
                padding: "10px",
                position: "relative",
                display: "flex",
                gap: "10px",
                mt: 3,
              }}
            >
              <h4 className="infoRateHeader">
                Mailed Metered Authorization Information
              </h4>
              <Grid item xs={6} sx={{ my: 2 }}>
                <label>Metered Permit Number</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sx={{ my: 2 }}>
                <label>Metered Permit ZIP</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={5} sx={{ mt: 2 }}>
            <Box
              className="rateDetails"
              sx={{
                border: "1px solid lightgray",
                borderRadius: "10px",
                padding: "10px",
                position: "relative",
                display: "flex",
                gap: "10px",
              }}
            >
              <h4 className="infoRateHeader">Tray Serial Number</h4>
              <Grid item xs={4} sx={{ my: 2 }}>
                <label>Start</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4} sx={{ my: 2 }}>
                <label>Stop</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4} sx={{ my: 2 }}>
                <label>Current</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
            </Box>
            <Box
              className="rateDetails"
              sx={{
                border: "1px solid lightgray",
                borderRadius: "10px",
                padding: "10px",
                position: "relative",
                display: "flex",
                gap: "10px",
                mt: 3,
              }}
            >
              <h4 className="infoRateHeader">Container Serial Numbers</h4>
              <Grid item xs={4} sx={{ my: 2 }}>
                <label>Start</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4} sx={{ my: 2 }}>
                <label>Stop</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4} sx={{ my: 2 }}>
                <label>Current</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
            </Box>
          </Grid>
          <Button
            sx={{ color: "white", mt: 2, ml: 2 }}
            variant="outlined"
            className="profileBtn"
          >
            Enable Full Service
          </Button>
          <Button
            sx={{ color: "white", mt: 2, ml: 1 }}
            variant="outlined"
            className="profileBtn"
          >
            Enable Eps
          </Button>
        </Grid>
      </div>
      <div className="siteinfoDetails">
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Box
              display="flex"
              gap={2}
              className="rateDetails"
              sx={{
                border: "1px solid lightgray",
                borderRadius: "10px",
                padding: "10px",
                position: "relative",
              }}
            >
              <h4 className="infoRateHeader">EZ-Confirm</h4>

              <Grid item xs={5} sx={{ my: 2 }}>
                <Button
                  sx={{ color: "white", my: 2 }}
                  variant="outlined"
                  className="profileBtn"
                >
                  Enable Ez-Confirm
                </Button>{" "}
                <br />
                <label>EZ - Confirm User ID</label> <br />
                <TextField
                  sx={{ width: "100%", my: 1 }}
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={7} sx={{ my: 1 }}>
                <Box
                  className="rateDetails"
                  sx={{
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    padding: "10px",
                    position: "relative",

                    gap: "10px",
                    mt: 1,
                  }}
                >
                  <h4 className="infoRateHeader">Login Information</h4>
                  <label>Username</label> <br />
                  <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                  />
                  <label>Password</label> <br />
                  <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                  />
                  <Button
                    sx={{ color: "white", my: 2 }}
                    variant="outlined"
                    className="profileBtn"
                  >
                    Authenticate
                  </Button>{" "}
                </Box>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Box
              className="rateDetails"
              sx={{
                border: "1px solid lightgray",
                borderRadius: "10px",
                padding: "10px",
                position: "relative",
                height: "100%",
              }}
            >
              <h4 className="infoRateHeader">PC Postage Account Information</h4>
              <textarea
                id="textarea"
                name="textarea"
                rows="13"
                style={{
                  width: "100%",
                  marginTop: "20px",
                  borderRadius: "10px",
                  border: "1px solid lightgray",
                }}
              ></textarea>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
