import React, { useState, useEffect } from "react";
import { Box, Button, Typography, TextField, Modal, Grid } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close Square.svg";
import { apiBaseUrl } from "../../../config";
import { toast } from "react-toastify";
import axios from "axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
  borderRadius: "10px",
};

export default function UpdateTicketModal({ open, onClose, ticketDetails }) {
  const [updateTicketDetails, setUpdateTicketDetails] = useState();

  useEffect(() => {
    setUpdateTicketDetails(ticketDetails);
  }, []);

  const token = localStorage.getItem("auth");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateTicketDetails({
      ...updateTicketDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("work_order_id", updateTicketDetails?.mo_work_order_id);
      formData.append(
        "receipt_ticket_id",
        updateTicketDetails?.receipt_ticket_id
      );
      formData.append("pieces", updateTicketDetails?.pieces);
      formData.append("trays", updateTicketDetails?.trays);
      formData.append("pallets", updateTicketDetails?.pallets);

      const response = await axios.put(
        `${apiBaseUrl}receipt_ticket_update`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        toast.success("Ticket Updated Successfully");
        onClose(true);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            sx={{ mb: 2 }}
            className="createRoleHeader"
          >
            Update Ticket
            <CloseIcon className="closeicon" onClick={onClose} />
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            className="updateformDetails"
            style={{ height: "auto" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="p" className="lineitemsname">
                  <b>PCS</b>
                </Typography>
                <TextField
                  fullWidth
                  type="number"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  className="lineItemsFields"
                  name="pieces"
                  sx={{ pt: 1 }}
                  defaultValue={ticketDetails?.pieces}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="p" className="lineitemsname">
                  <b> Trays</b>
                </Typography>
                <TextField
                  fullWidth
                  type="number"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  className="lineItemsFields"
                  name="trays"
                  sx={{ pt: 1 }}
                  defaultValue={ticketDetails?.trays}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="p" className="lineitemsname">
                  <b> Pallets</b>
                </Typography>
                <TextField
                  className="lineItemsFields"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="number"
                  name="pallets"
                  sx={{ pt: 1 }}
                  defaultValue={ticketDetails?.pallets}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
              <Button variant="outlined" className="Btn" onClick={handleSubmit}>
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
