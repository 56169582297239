import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Link } from "react-router-dom";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { MspService } from "../../../services/MspService";
import Loader from "../../../components/Loader";
import "./workOrder.scss";
import UpdateTicketModal from "./UpdateTicket";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import axios from "axios";
import { apiBaseUrl } from "../../../config";
import { ReactComponent as ViewIcon } from "../../../assets/icons/File.svg";

export default function Receiving() {
  const [workOrderList, setWorkOrderList] = useState();
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [ticketDetails, setTicketDetails] = useState();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [mspPermission, setMspPermission] = useState([]);

  const getPermissions = () => {
    try {
      const token = localStorage.getItem("auth");
      const role_id = localStorage.getItem("role_id");
      axios
        .get(
          `${apiBaseUrl}msp_role_group_function_mapping_list?role_id=${role_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          res.data.msp_role_group_function_mapping_list?.map((obj) => {
            obj.role_function_name === "Receiving" && setMspPermission(obj);
          });
        });
    } catch (error) {
      console.log("Err==>", error);
    }
  };

  const getReceiveWorkOrderList = () => {
    try {
      setLoader(true);
      MspService.receiveWorkOrderList({
        page: page + 1,
        page_size: pageSize,
      })
        .then((res) => {
          const workOrderList = res.data.work_order_list;
          setWorkOrderList(workOrderList);
          setCount(res.data.total_count);
          setLoader(false);
        })
        .catch((e) => {
          toast.error("Something went wrong");
          setLoader(false);
        });
    } catch (error) {
      console.error("Err===>", error);
      setLoader(false);
    }
  };

  const handleOpen = (details) => {
    setUpdateModalOpen(true);
    setTicketDetails(details);
  };

  const handleClose = (data) => {
    setUpdateModalOpen(false);
    data && getReceiveWorkOrderList();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageSize(event.target.value);
  };

  const handleConfirm = (work_order_no, receipt_ticket_no) => {
    const formData = new FormData();
    formData.append("work_order_no", work_order_no);
    formData.append("receipt_ticket_no", receipt_ticket_no);

    MspService.updateWorkOrderStatus(formData)
      .then((res) => {
        getReceiveWorkOrderList();
      })
      .catch((error) => {
        toast.error(error.data?.detail || "Something went wrong");
      });
  };
  useEffect(() => {
    getReceiveWorkOrderList();
  }, [page, pageSize]);
  useEffect(() => {
    getPermissions();
  }, []);
  return (
    <div className="machineTable">
      {loader && <Loader />}
      {mspPermission?.msp_role_group_function_mapping_list?.role_create_flag ===
        1 && (
        <div className="work_order_home_page">
          <Box className="workOrder_add_button">
            {" "}
            <Link to="/msp/wocreate">
              <Button className="Btn">+ Add New WO</Button>
            </Link>
          </Box>
        </div>
      )}

      {updateModalOpen && (
        <UpdateTicketModal
          open={handleOpen}
          onClose={(data) => handleClose(data)}
          ticketDetails={ticketDetails}
        />
      )}

      <TableContainer component={Paper} className="machineDataTable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{
                  position: "sticky",
                  left: 0,
                  top: 0 /* Don't forget this, required for the stickiness */,
                  boxShadow: " 0 2px 2px -1px rgba(0, 0, 0, 0.4)",
                  zIndex: 800,
                  // boxShadow: "2px 0 5px -2px rgba(0,0,0,0.5)",
                }}
              >
                Mail Owner Name
              </TableCell>
              <TableCell>Mailing Date </TableCell>
              <TableCell>Pallets</TableCell>
              <TableCell>Pieces</TableCell>
              <TableCell>Trays</TableCell>
              <TableCell>Receipt No</TableCell>

              <TableCell>Status</TableCell>

              <TableCell
                align="left"
                style={{
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                }}
              >
                Job Card / Worksheet{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workOrderList?.length > 0 ? (
              workOrderList?.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{
                      position: "sticky",
                      left: 0,
                      background: "white",
                      zIndex: 800,
                      top: 0 /* Don't forget this, required for the stickiness */,
                      boxShadow: " 0 2px 2px -1px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    {row.mail_owner_name}
                  </TableCell>
                  <TableCell>{row.mailing_date}</TableCell>
                  <TableCell>{row.pallets}</TableCell>

                  <TableCell>{row.pieces}</TableCell>
                  <TableCell>{row.trays}</TableCell>
                  <TableCell align="center">
                    {row?.receipt_ticket_id ? (
                      row.receipt_ticket_id
                    ) : (
                      <Link
                        to={`/msp/receiptticketcreation/${row.mo_work_order_id}`}
                      >
                        <Button className="closeButton">
                          <AddCircleOutlineIcon sx={{ mr: 1 }} /> Add
                        </Button>
                      </Link>
                    )}
                  </TableCell>

                  <TableCell>
                    <p className="recordStatus">
                      {" "}
                      {row.work_order_status_name}
                    </p>
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      background: "white",
                      display: "flex",
                    }}
                  >
                    <div className="actionIcons">
                      {row.work_order_status_name == "Yet to Confirm" && (
                          <>
                            <span>
                              <Button
                                className="closeButton yes"
                                onClick={() => {
                                  handleConfirm(
                                    row.mo_work_order_id,
                                    row.receipt_ticket_id
                                  );
                                }}
                              >
                                <b>Confirm</b>
                              </Button>
                            </span>
                            <span>
                              <Button
                                className="closeButton no"
                                onClick={() => handleOpen(row)}
                              >
                                <b>Update</b>
                              </Button>
                            </span>
                          </>
                        )}
                      {row.work_order_status_name == "Confirmed" &&
                        !row.separate_job_card &&
                        !row.worksheet_status && (
                          <span>
                            <Link
                              to={`/msp/workordersheet/${row.mo_work_order_id}`}
                            >
                              <Button className="closeButton">
                                <Tooltip title="Job Card">
                                  <WorkHistoryOutlinedIcon />
                                </Tooltip>
                              </Button>
                            </Link>
                          </span>
                        )}
                      {(row.separate_job_card || row.worksheet_status) && (
                        <Box>
                          <span>
                            <Link
                              to={`/msp/previewworkordersheet/${row.mo_work_order_id}`}
                            >
                              <Button className="closeButton">
                                <Tooltip title="Print Job Card">
                                  <PrintOutlinedIcon />
                                </Tooltip>
                              </Button>
                            </Link>
                          </span>
                        </Box>
                      )}
                    </div>
                    <Link to={`/msp/previewworkorder/${row.mo_work_order_id}`}>
                      <Button className="closeButton">
                        <Tooltip title="Preview">
                          <ViewIcon />
                        </Tooltip>
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>
                  <Typography variant="h6" align="center">
                    No records found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
