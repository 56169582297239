import * as React from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import UndoIcon from "@mui/icons-material/Undo";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Typography } from "@mui/material";
import axios from "axios";
import { apiBaseUrl } from "../../../config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function BasicTable({ handleChange, printValue }) {
  const [tagForm, setTagForm] = React.useState([]);
  const isAuth = localStorage.getItem("auth");
  const params = {
    headers: {
      Authorization: "Bearer " + isAuth,
    },
  };
  async function fetchDropdowns() {
    try {
      const [tagForm, printerSerialNumber] = await axios.all([
        axios.get(`${apiBaseUrl}get_tag_form`, params),
        axios.get(`${apiBaseUrl}`, params),
      ]);
      setTagForm(tagForm.data.tag_form);
    } catch (error) {
      console.log("Err==>", error);
    }
  }
  React.useEffect(() => {
    fetchDropdowns();
  }, []);
  return (
    <div className="classtab accountEditorTabs printTab">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Box className="additional-fields">
            <Typography variant="p" className="additional-fields-header">
              Print Account Barcodes
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={8} xl={8}>
                  <FormControl
                    className="print-select"
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      className="profilelable"
                      sx={{ pr: 1, top: "-7px" }}
                      shrink
                    >
                      Tag Form
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      size="small"
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                      sx={{ flexGrow: "1" }}
                      name="tag_form_id"
                      defaultValue={printValue.tag_form_id}
                      onChange={(e) => handleChange(e, "print")}
                    >
                      {tagForm.map((item) => (
                        <MenuItem
                          key={item.tag_form_id}
                          value={item.tag_form_id}
                        >
                          {item.name_of_tag_form}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4} md={12}>
                  <Button className="Btn" variant="outlined">
                    Print Tags
                  </Button>
                </Grid>
              </Grid>

              <Box
                sx={{ my: 1 }}
                display="flex"
                gap={2}
                className="print-checkboxes"
              >
                <Box>
                  {" "}
                  <Checkbox
                    name="is_human_readable"
                    defaultChecked={printValue.is_human_readable}
                    onChange={(e) => handleChange(e, "print")}
                  />
                  <Typography variant="outlined">Human readable </Typography>
                </Box>
                <Box>
                  {" "}
                  <Checkbox
                    name="is_draw_lines"
                    defaultChecked={printValue.is_draw_lines}
                    onChange={(e) => handleChange(e, "print")}
                  />
                  <Typography variant="outlined">Draw lines</Typography>
                </Box>
                <Box>
                  {" "}
                  <Checkbox
                    name="is_1_page_collated"
                    defaultChecked={printValue.is_1_page_collated}
                    onChange={(e) => handleChange(e, "print")}
                  />
                  <Typography variant="outlined">1-Page collated</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="additional-fields">
            <Typography variant="p" className="additional-fields-header">
              Print priority labels
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} md={12} xl={6}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Printer Serial Number"
                    variant="outlined"
                    className="sequence"
                    name="printer_serial_number"
                    defaultValue={printValue.printer_serial_number}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleChange(e, "print")}
                  />
                </Grid>
                <Grid item xs={12} lg={6} md={12} xl={6}>
                  <FormControl
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      size="small"
                      label="Number of Labels to Print"
                      variant="outlined"
                      className="sequence"
                      name="number_of_labels_to_print"
                      defaultValue={printValue.number_of_labels_to_print}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => handleChange(e, "print")}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Button className="Btn" variant="outlined" sx={{ mt: 2 }}>
                Print
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
