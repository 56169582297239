import React, { useState, useEffect, useReducer } from "react";
import {
  Box,
  Checkbox,
  Modal,
  Fade,
  Typography,
  Grid,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Backdrop,
} from "@mui/material";
import { customerIcons } from "./icons";
import { toast } from "react-toastify";
import axios from "axios";
import { apiBaseUrl } from "../../config";
import { MoPermissionProps } from "./types";
import { MspService } from "../../services/MspService";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "780px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};
const initialState = [
  {
    role_group_id: "",
    role_function_id: "",
    role_create_flag: 0,
    role_read_flag: 0,
    role_update_flag: 0,
    role_delete_flag: 0,
  },
];

const reducer = (state, action) => {
  switch (action.type) {
    case "COMPLETE":
      let findIndex = state.findIndex(
        (val) =>
          val.role_function_id === action.values?.role_function_id &&
          val.role_group_id === action.values?.role_group_id
      );

      if (findIndex != -1) {
        let updateValue = { ...state[findIndex], ...action.values };
        state[findIndex] = updateValue;
        return [...state];
      } else if (state[0].role_function_id == "") {
        return [{ ...state[0], ...action.values }];
      } else {
        return [
          ...state,
          ...[
            {
              ...{
                role_group_id: "",
                role_function_id: "",
                role_create_flag: 0,
                role_read_flag: 0,
                role_update_flag: 0,
                role_delete_flag: 0,
              },
              ...action.values,
            },
          ],
        ];
      }
    case "UPDATE":
      return [...action.values];
    default:
      return state;
  }
};
const MOPermission: React.FC<MoPermissionProps> = ({ open, close }) => {
  const [roleList, setRoleList] = React.useState([]);
  const [modulesList, setModulesList] = useState([]);
  const [roleId, setRoleId] = useState([]);
  const [form, dispatch] = useReducer(reducer, initialState);
  const token = localStorage.getItem("auth");

  const handlePermissionChange = (values: any) => {
    dispatch({ type: "COMPLETE", values: values });
  };

  const RoleList = async () => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}get_mail_owner_roles_list_for_permission`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setRoleList(response.data.mail_owner_roles_list);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const moduleList = async () => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}get_mail_owner_role_function`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setModulesList(response.data.role_function_list);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const roleID = async (id) => {
    const response = await axios.get(
      `${apiBaseUrl}mail_owner_role_group_function_mapping_list?role_id=${id}&auto_error=true`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.data.mo_role_group_function_mapping_list) {
      let details = response.data.mo_role_group_function_mapping_list.map(
        (val) => val.mo_role_group_function_mapping_list
      );
      const cleanedArr = details?.map(
        ({
          role_id,
          created_by,
          created_on,
          updated_by,
          updated_on,
          ...rest
        }) => rest
      );
      dispatch({ type: "UPDATE", values: cleanedArr });
    } else {
      dispatch({ type: "UPDATE", values: initialState });
    }

    setRoleId(id);
  };
  const handleSubmit = (e) => {
    try {
      MspService.getMoPermissionMapping(form, roleId).then((res) => {
        if (res.status === 200) {
          toast.success("Permissions Granted Successfully");
        }
      });
    } catch (error) {
      console.log("Err===>", error);
    }
    close();
  };
  useEffect(() => {
    RoleList();
    moduleList();
  }, []);
  return (
    <Box>
      <div className="MsppermissionModal">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          className="modal-wrap site-creation-modal roleCreateModal"
          onClose={close}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style} className="accordionModal">
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                className="mspRoleHeader"
              >
                <b> Role Permissions</b>
                <Button onClick={close} className="closeButton">
                  <img
                    src={customerIcons.CloseIcon}
                    className="closeicon"
                    alt="closebutton"
                  />
                </Button>
              </Typography>

              <Box>
                <Grid container>
                  <Grid item lg={12} xl={12} sm={12} md={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        shrink
                        id="demo-simple-select-helper-label"
                        sx={{ color: "black", background: "white", px: 1 }}
                      >
                        List of Roles
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Role"
                      >
                        {roleList?.map((rolelist) => (
                          <MenuItem
                            onClick={() => roleID(rolelist.role_id)}
                            value={rolelist.role_id}
                            key={rolelist.role_id}
                          >
                            {rolelist.role_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ py: 2 }}>Groups</TableCell>
                        <TableCell sx={{ py: 2 }}>Functions</TableCell>

                        <TableCell sx={{ py: 2 }} align="center">
                          All
                        </TableCell>
                        <TableCell sx={{ py: 2 }} align="center">
                          Read
                        </TableCell>
                        <TableCell sx={{ py: 2 }} align="center">
                          Create
                        </TableCell>
                        <TableCell sx={{ py: 2 }} align="center">
                          Update
                        </TableCell>
                        <TableCell sx={{ py: 2 }} align="center">
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modulesList.map((row) => {
                        let selectedRow = form.find((val) => {
                          if (
                            val.role_group_id ==
                              row.role_function.role_group_id &&
                            val.role_function_id ==
                              row.role_function.role_function_id
                          ) {
                            return val;
                          }
                        });
                        return (
                          <TableRow
                            key={row.created_by}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ py: 1 }}
                            >
                              {row.role_group_name}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ py: 1 }}
                            >
                              {row.role_function.role_function_name}
                            </TableCell>
                            <TableCell sx={{ py: 1 }} align="center">
                              <Checkbox
                                name="All"
                                checked={
                                  selectedRow &&
                                  selectedRow?.role_read_flag == 1 &&
                                  selectedRow?.role_create_flag == 1 &&
                                  selectedRow?.role_update_flag == 1 &&
                                  selectedRow?.role_delete_flag == 1
                                    ? true
                                    : false
                                }
                                onClick={(e: any) =>
                                  handlePermissionChange({
                                    role_group_id:
                                      row.role_function.role_group_id,
                                    role_function_id:
                                      row.role_function.role_function_id,
                                    role_read_flag: e.target?.checked ? 1 : 0,
                                    role_create_flag: e.target.checked ? 1 : 0,
                                    role_update_flag: e.target.checked ? 1 : 0,
                                    role_delete_flag: e.target.checked ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>

                            <TableCell sx={{ py: 1 }} align="center">
                              <Checkbox
                                name="read"
                                checked={
                                  selectedRow &&
                                  selectedRow?.role_read_flag == 1
                                    ? true
                                    : false
                                }
                                onClick={(e: any) =>
                                  handlePermissionChange({
                                    role_group_id:
                                      row.role_function.role_group_id,
                                    role_function_id:
                                      row.role_function.role_function_id,
                                    role_read_flag: e.target?.checked ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell sx={{ py: 1 }} align="center">
                              <Checkbox
                                name="create"
                                checked={
                                  selectedRow &&
                                  selectedRow?.role_create_flag == 1
                                    ? true
                                    : false
                                }
                                onClick={(e: any) =>
                                  handlePermissionChange({
                                    role_group_id:
                                      row.role_function.role_group_id,
                                    role_function_id:
                                      row.role_function.role_function_id,
                                    role_create_flag: e.target.checked ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell sx={{ py: 1 }} align="center">
                              <Checkbox
                                name="update"
                                checked={
                                  selectedRow &&
                                  selectedRow?.role_update_flag == 1
                                    ? true
                                    : false
                                }
                                onClick={(e: any) =>
                                  handlePermissionChange({
                                    role_group_id:
                                      row.role_function.role_group_id,
                                    role_function_id:
                                      row.role_function.role_function_id,
                                    role_update_flag: e.target.checked ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell sx={{ py: 1 }} align="center">
                              <Checkbox
                                name="delete"
                                checked={
                                  selectedRow &&
                                  selectedRow?.role_delete_flag == 1
                                    ? true
                                    : false
                                }
                                onClick={(e: any) =>
                                  handlePermissionChange({
                                    role_group_id:
                                      row.role_function.role_group_id,
                                    role_function_id:
                                      row.role_function.role_function_id,
                                    role_delete_flag: e.target.checked ? 1 : 0,
                                  })
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box display="flex" justifyContent="end" gap={2} sx={{ mb: 2 }}>
                <Button variant="outlined" type="submit" onClick={close}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="outlined"
                  className="Btn"
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    </Box>
  );
};
export default MOPermission;
