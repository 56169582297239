import React, { useState, useEffect } from "react";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import { styled } from "@mui/material/styles";
import JobActivity from "./jobActivity";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: "700",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const rows = [];
export default function StatusMonitor() {
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRefreshCount(refreshCount + 1);
    }, 90 * 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [refreshCount]);

  return (
    <div>
      <JobActivity />
    </div>
  );
}
