import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import { apiBaseUrl } from "../../../config";
import axios from "axios";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "react-toastify";
import Barcode from "react-barcode";
import { useParams, useNavigate } from "react-router-dom";
import { MspService } from "../../../services/MspService";

const initialState = {
  date_of_print: "",
  operation_name: "",
  department_number: "",
  operation_description: "",
  place_to_sign_off: "",
  date_of_sign_off: "",
  barcode: "",
};

export default function WorkOrderSheet() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [workOrderSpecificDetails, setWorkOrderSpecificDetails] = useState();
  const [mspAdditionalDetails, setMspAdditionalDetails] =
    useState(initialState);

  const handleDateChange = (name, date) => {
    setMspAdditionalDetails({
      ...mspAdditionalDetails,
      [name]: date,
    });
  };
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setMspAdditionalDetails({ ...mspAdditionalDetails, [name]: value });
  };

  const handleSubmit = async () => {
    if (
      (workOrderSpecificDetails?.is_additional_services &&
        !mspAdditionalDetails.date_of_print) ||
      (workOrderSpecificDetails?.is_additional_services &&
        !mspAdditionalDetails.operation_name) ||
      (workOrderSpecificDetails?.is_additional_services &&
        !mspAdditionalDetails.operation_description) ||
      (workOrderSpecificDetails?.is_additional_services &&
        !mspAdditionalDetails.date_of_sign_off) ||
      (workOrderSpecificDetails?.is_additional_services &&
        !mspAdditionalDetails.place_to_sign_off)
    ) {
      toast.error("Please enter the details");
      return false;
    }

    if (
      !workOrderSpecificDetails?.is_additional_services &&
      !mspAdditionalDetails.barcode
    ) {
      toast.error("Please enter the details");
      return false;
    }

    const formData = new FormData();

    formData.append(
      "work_order_number",
      workOrderSpecificDetails?.work_order_id
    );
    if (workOrderSpecificDetails?.is_additional_services) {
      formData.append("operation_name", mspAdditionalDetails.operation_name);
      formData.append(
        "department_number",
        mspAdditionalDetails.department_number
      );
      formData.append(
        "operation_description",
        mspAdditionalDetails.operation_description
      );
      formData.append(
        "place_to_sign_off_the_operation",
        mspAdditionalDetails.place_to_sign_off
      );
      formData.append(
        "date_of_print",
        dayjs(mspAdditionalDetails.date_of_print).format("YYYY-MM-DD")
      );
      formData.append(
        "date_of_sign_off",
        dayjs(mspAdditionalDetails.date_of_sign_off).format("YYYY-MM-DD")
      );

      try {
        MspService.mspAdditionalTIcketDetails(formData)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Additioanl  Successfully");
              setMspAdditionalDetails(initialState);
              navigate("/msp/receiving");
            }
          })
          .catch((error) => {
            toast.error(error.data?.detail || "Something went wrong");
          });
      } catch (error) {
        toast.error(error.data?.detail || "Something went wrong");
      }
    } else {
      formData.append("separator_card_barcode", mspAdditionalDetails.barcode);

      try {
        MspService.addSeparatorCardBarcode(formData)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Separator Card Barcode Added  Successfully");
              setMspAdditionalDetails(initialState);
              navigate("/msp/receiving");
            }
          })
          .catch((error) => {
            toast.error(error.data?.detail || "Something went wrong");
          });
      } catch (error) {
        toast.error(error.data?.detail || "Something went wrong");
      }
    }
  };

  useEffect(() => {
    try {
      if (id && id > 0) {
        axios
          .get(`${apiBaseUrl}receive_specific_work_order`, {
            params: { work_order_id: id },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setWorkOrderSpecificDetails(res.data.work_order_details);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              toast.error("Unauthorized");
            }
          });
      }
    } catch (error) {
      console.log("Err===>", error);
    }
  }, []);

  return (
    <div className="work_order_home_page">
      <div className="ticket_page">
        <div className="workOrder">
          <Box className="workOrder_header">
            <Typography variant="p">
              {" "}
              {workOrderSpecificDetails?.is_additional_services
                ? "Worksheet"
                : "Job Card"}
            </Typography>
          </Box>

          <Typography variant="h6" sx={{ px: 2, mt: 2, mb: 0 }}>
            <b> Work Order</b> : {workOrderSpecificDetails?.work_order_id}
          </Typography>

          <Box>
            <Box className="customerDetails" sx={{ mt: 2 }}>
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Customer Details</b>
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="h6" className="customer-details">
                        <b>Customer ID</b> :{" "}
                        {workOrderSpecificDetails?.customer_id}
                      </Typography>
                      <Typography variant="h6" className="customer-details">
                        <b>Customer Name</b> :{" "}
                        {workOrderSpecificDetails?.owner_name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Barcode
                      className="barcode"
                      value={workOrderSpecificDetails?.barCode}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box className="customerDetails">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Product Info</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" className="customer-details">
                  <b>Job Name</b>: {workOrderSpecificDetails?.job_name}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>PO Number </b>: {workOrderSpecificDetails?.po_number}
                </Typography>
                {/* <Typography variant="h6" className="customer-details">
                  <b>Product Profile</b> : AAaa1
                </Typography> */}
                <Typography variant="h6" className="customer-details">
                  <b>Number of Pieces</b> :{workOrderSpecificDetails?.pieces}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Trays</b> : {workOrderSpecificDetails?.trays}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Pallets</b> : {workOrderSpecificDetails?.pallets}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Mailing Date</b> : {workOrderSpecificDetails?.mailing_date}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Delivery Date </b> :{" "}
                  {workOrderSpecificDetails?.pick_up_date}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Special Processing Notes</b> :{" "}
                  {workOrderSpecificDetails?.special_processing_notes}
                </Typography>
              </Box>
            </Box>
          </Box>
          {workOrderSpecificDetails?.is_additional_services ? (
            <Box className="customerDetails lineitems">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Additional Information</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }} component="form">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography
                      variant="p"
                      className="lineitemsname"
                      sx={{ mb: 1 }}
                    >
                      Date of Print
                    </Typography>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      size="small"
                    >
                      <DatePicker
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                          },
                        }}
                        className="lineItemsFields"
                        onChange={(date_of_print) =>
                          handleDateChange("date_of_print", date_of_print)
                        }
                        value={
                          mspAdditionalDetails.date_of_print
                            ? dayjs(mspAdditionalDetails.date_of_print)
                            : null
                        }
                        name="date_of_print"
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="p" className="lineitemsname">
                      Operation Name
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      className="lineItemsFields"
                      onChange={handleChange}
                      name="operation_name"
                      value={mspAdditionalDetails.operation_name}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="p" className="lineitemsname">
                      Department Number{" "}
                    </Typography>
                    <TextField
                      className="lineItemsFields"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      type="number"
                      onChange={handleChange}
                      name="department_number"
                      value={mspAdditionalDetails.department_number}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={4}>
                    <Typography variant="p" className="lineitemsname">
                      Operation Description{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      className="lineItemsFields"
                      onChange={handleChange}
                      name="operation_description"
                      value={mspAdditionalDetails.operation_description}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="p" className="lineitemsname">
                      Place to sign off the operation{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      className="lineItemsFields"
                      onChange={handleChange}
                      name="place_to_sign_off"
                      value={mspAdditionalDetails.place_to_sign_off}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="p"
                      className="lineitemsname"
                      sx={{ mb: 1 }}
                    >
                      Date of sign off{" "}
                    </Typography>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      size="small"
                    >
                      <DatePicker
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                          },
                        }}
                        className="lineItemsFields"
                        onChange={(date_of_sign_off) =>
                          handleDateChange("date_of_sign_off", date_of_sign_off)
                        }
                        value={
                          mspAdditionalDetails.date_of_sign_off
                            ? dayjs(mspAdditionalDetails.date_of_sign_off)
                            : null
                        }
                        name="date_of_sign_off"
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ) : (
            <Box className="customerDetails lineitems">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Add Separate Card</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }} component="form">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="p" className="lineitemsname">
                      Barcode
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      className="lineItemsFields"
                      onChange={handleChange}
                      name="barcode"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}

          <Box
            className="create-cancel-container container"
            sx={{ mt: 3, pr: 0 }}
          >
            <Button
              variant="outlined"
              type="submit"
              onClick={() => navigate("/msp/receiving")}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className="Btn save"
              onClick={() => handleSubmit()}
            >
              Update
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
}
