import React, { useEffect, useReducer } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "../../../assets/icons/Close Square.svg";
import axios from "axios";
import { apiBaseUrl } from "../../../config";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import CustomPhoneInput from "../../../components/customPhoneInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow:
    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
  borderRadius: "10px",
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const initialState = {
  snackbar: false,
  image_base64: "",
  first_name: "",
  last_name: "",
  contact_email: "",
  contact_number: "",
  password: "",
  role_name: "",
  image: "",
  is_logged_user: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "HANDLE CHANGE":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "UPDATE":
      return { ...action.payload };
    default:
      return state;
  }
};

export default function UserModal({
  open,
  onClose,
  rolesDetails,
  userDetails,
}) {
  const [user, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: "UPDATE",
      payload: userDetails,
    });
    generateRandomPassword();
  }, []);

  const uploadImage = (e) => {
    dispatch({
      type: "HANDLE CHANGE",
      field: "image",
      payload: e.target.files[0],
    });
  };

  const generateRandomPassword = () => {
    let Genpassword = Math.random().toString(20).substring(2, 8);
    dispatch({
      type: "HANDLE CHANGE",
      field: "password",
      payload: Genpassword,
    });
  };

  const handleChange = (e) => {
    dispatch({
      type: "HANDLE CHANGE",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handlePhoneNumber = (value) => {
    dispatch({
      type: "HANDLE CHANGE",
      field: "contact_number",
      payload: value,
    });
  };

  const handleSubmit = async (e) => {
    try {
      if (
        !user.first_name ||
        !user.last_name ||
        (!userDetails && !user.contact_email) ||
        !user.role_name ||
        !user.contact_number
      ) {
        toast.error("Please enter the details");
        return false;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidNumber = /^\d{10}$/.test(user.contact_number);

      if (!userDetails && !emailRegex.test(user.contact_email)) {
        toast.error("Please enter the valid email");
        return false;
      }

      if (!isValidNumber) {
        toast.error("Please enter the valid phone number");
        return false;
      }

      const formData = new FormData();
      formData.append("first_name", user.first_name);
      formData.append("last_name", user.last_name);
      formData.append("contact_number", user.contact_number);
      formData.append("role", user.role_name);
      formData.append("email", user.contact_email);
      user.image && formData.append("company_logo", user.image);

      let url = `${apiBaseUrl}eii_user_creation`;
      let method = "POST";

      if (userDetails) {
        url = `${apiBaseUrl}eii_user_updation`;
        method = "PUT";
        formData.append("account_guid", user.account_guid);
      } else {
        formData.append("password", user.password);
      }

      const tokens = localStorage.getItem("auth");

      const response = await axios({
        method: method,
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${tokens}`,
        },
      });

      if (response.status === 200) {
        toast.success(
          userDetails
            ? "User Updated Successfully"
            : "User Created Successfully"
        );
        onClose("success");
      }
    } catch (error) {
      toast.error(error.response?.data?.detail || "Something went wrong");
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => onClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal "
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            sx={{ mb: 3 }}
            className="mspRoleHeader"
          >
            <b>{userDetails ? "Update User" : "User Creation"}</b>
            <Button onClick={onClose}>
              <img src={CloseIcon} className="closeicon" alt="closeIcon" />
            </Button>
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            className="mspDetailsForm"
          >
            <Grid container spacing={2} className="eiiuser-modal">
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="First Name"
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 2, width: "100%" }}
                  name="first_name"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={user.first_name}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="Last Name"
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 2, width: "100%" }}
                  name="last_name"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={user.last_name}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} className="eiiuser-modal">
              <Grid item lg={6} md={6} sm={12}>
                <FormControl fullWidth size="small" sx={{ my: 2 }}>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Role"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    size="small"
                    name="role_name"
                    value={user.role_name}
                    disabled={user.is_logged_user}
                  >
                    {rolesDetails?.map((item) => (
                      <MenuItem key={item.role_id} value={item.role_name}>
                        {item.role_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                sx={{ marginTop: "10px" }}
                className="eii-contact-field"
              >
                <CustomPhoneInput
                  defaultCountry="us"
                  onlyCountries={["us"]}
                  disableCountryCode
                  country="us"
                  disableDropdown={true}
                  editable={user.contact_number}
                  name="contact_number"
                  onChange={(value) => {
                    handlePhoneNumber(value);
                  }}
                />
              </Grid>
            </Grid>

            <Button
              sx={{ my: 2 }}
              component="label"
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              className="uploadBtn"
              onChange={uploadImage}
            >
              Profile Image *
              <VisuallyHiddenInput required type="file" />
            </Button>

            {user.image && (
              <Box my={2}>
                <Box className="imgPreviewContainer">
                  <img
                    src={`${URL.createObjectURL(user.image)}`}
                    alt="uploadedImage"
                  />
                </Box>
              </Box>
            )}

            {!user.image && user.image_base64 && (
              <Box my={2}>
                <Box className="imgPreviewContainer">
                  <img
                    src={`data:image/png;base64,${user.image_base64}`}
                    className="mspListImage"
                    alt="user_image"
                  />
                </Box>
              </Box>
            )}

            {!userDetails && (
              <>
                <TextField
                  size="small"
                  label="Email Address"
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 2, width: "100%" }}
                  name="contact_email"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <TextField
                  disabled
                  helperText="Auto Generated Password"
                  size="small"
                  label="Password"
                  value={user.password}
                  sx={{ mt: 2, mb: 1, width: "100%" }}
                  name="password"
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <IconButton
                  //         edge="end"
                  //         onClick={generateRandomPassword}
                  //         sx={{ mr: 1 }}
                  //       >
                  //         <ReplayIcon />
                  //       </IconButton>
                  //       <IconButton onClick={handleCopyClipboard}>
                  //         <ContentCopyIcon />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="end"
            gap={2}
            sx={{ paddingRight: "40px", mb: 2 }}
          >
            <Button onClick={() => onClose()} variant="outlined" type="submit">
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={() => handleSubmit()}
              className="closeicon"
            >
              {userDetails ? "Update" : "Create"}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        key={{}}
        open={user.snackbar}
        onClose={() =>
          dispatch({
            type: "user_modal",
            field: "snackbar",
            payload: false,
          })
        }
        message="Password copied to clipboard"
        autoHideDuration={2000}
      />
    </>
  );
}
