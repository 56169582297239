import React, {
  useEffect,
  useReducer,
  useState,
  useRef,
  useCallback,
} from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AccountManager from "./Accountmanager";
import BasicTable from "./Classtab";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UndoIcon from "@mui/icons-material/Undo";
import Usps from "./Usps";
import Customs from "./Custom";
import Print from "./Print";
import Barcode from "./Barcode";
import SearchIcon from "@mui/icons-material/Search";
import { apiBaseUrl } from "../../../config";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialState = {
  details: {
    billing_address_1: "",
    billing_address_2: "",
    billing_city: "",
    billing_state: "",
    billing_postal_code: "",
    is_disabled: false,
    return_address_1: "",
    return_address_2: "",
    return_city: "",
    return_state: "",
    return_postal_code: "",
    contact_phone: "",
    contact_fax: "",
    account_name: "",
    contact_name: "",
    contact_email: "",
    billing_email: "",
    move_update_method_id: 0,
    account_keywords: "OVC",
  },
  class_settings: {
    endorsement_id: 0,
    move_update_method_id: 0,
    mailclass_handling_type_id: 0,
    service_type_identifier: "",
    mailer_id: "",
    metered_at_full_service: false,
    mail_owner_mid: 0,
    mail_owner_crid: 0,
    customer_metered_mail: false,
  },
  usps: {
    permit_origin: "",
    permit_number: "",
    permit_name: "",
    customer_ref_id: "",
  },
  custom: {
    custom_account: "",
    driver_route: "",
    custom_1: "",
    custom_2: "",
    key_line_pattern: "",
    key_line_required: 0,
    custom_account_barcode_type_id: 0,
  },
  print: {
    tag_form_id: 0,
    is_human_readable: 0,
    is_draw_lines: 0,
    is_1_page_collated: 0,
    last_printed_date: moment(new Date()).format("YYYY-MM-DD"),
    printer_serial_number: "",
    number_of_labels_to_print: 0,
    last_printed_date_priority_label: moment(new Date()).format("YYYY-MM-DD"),

    mo_accounts_print_barcode_id: 0,
  },
  barcode: {
    barcode_name: "smart bazar",
    barcode_type: 0,
    barcode_pattern: 0,
    is_imb_replacement: 0,
    is_required: 0,
    fill_no_bc_with_routing: 0,
    mo_account_barcode_guid: "",
    msp_account_barcode_guid: "",
    mo_account_guid: "",
    is_msp_account: false,
  },
  mo_account_guid: "",
  is_msp_account: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "APPEND":
      state[action.key][action.field] = action.payload;
      return state;
    case "APPEND_BARCODE":
      state["barcode"] = action.payload;
      return;
    case "FETCHED_VALUES":
      const key =
        action.key === "moAdmin"
          ? [
              "mail_owner_account_details",
              "mo_account_class_setting",
              "mo_account_usps",
              "mo_custom_account",
            ]
          : [
              "msp_account_details",
              "msp_account_class_setting",
              "msp_account_usps",
              "msp_custom_account",
            ];
      return {
        ...state,
        details: action.payload?.profile_details[key[0]],
        class_settings: action?.payload?.profile_class[key[1]],
        usps: action?.payload?.profile_usps[key[2]],
        custom: action?.payload?.profile_custom[key[3]],
        print: action?.payload?.profile_print,
      };
    case "BILLING_CODE":
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          billing_postal_code: action.payload.postalCode,
          billing_city: action.payload.city,
          billing_state: action.payload.state,
        },
      };
    case "RETURN_CODE":
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          return_postal_code: action.payload.postalCode,
          return_city: action.payload.city,
          return_state: action.payload.state,
        },
      };

    default:
      return state;
  }
};

export default function ProfileEditor() {
  const [expandedTop, setExpandedTop] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [selectedMailOwner, setSelectedMailOwner] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [state, dispatch] = useReducer(reducer, initialState);
  const [treeSelected, setTreeSelected] = useState();
  const [mspAccountDetails, setMspAccountDetails] = React.useState({});
  const [filteredData, setFilteredData] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const listInnerRef = useRef();
  console.log("State==>", state);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const itemsPerPage = 10;
  const baseUrl = apiBaseUrl;

  const handleFormChange = (e, keyName, addressType, postalCodes) => {
    if (e == null && postalCodes && !keyName) {
      if (addressType === "billing") {
        dispatch({
          type: "BILLING_CODE",
          key: "details",
          payload: postalCodes,
        });
      } else {
        dispatch({
          type: "RETURN_CODE",
          key: "details",
          payload: postalCodes,
        });
      }
      return;
    }

    const { name, value, type, checked } = e.target;
    let data;
    if (name === "mail_owner_crid" || name === "mail_owner_mid") {
      data = parseInt(value);
    } else {
      data = type === "checkbox" ? checked : value;
    }

    dispatch({
      type: "APPEND",
      key: keyName,
      field: name,
      payload: data,
    });
  };

  const tabResponse = [
    {
      id: "1",
      name: "details",
      component: (
        <AccountManager
          handleChange={handleFormChange}
          detailValue={state.details}
        />
      ),
    },
    {
      id: "2",
      name: "class",
      component: (
        <BasicTable
          handleChange={handleFormChange}
          classValue={state.class_settings}
        />
      ),
    },
    {
      id: "3",
      name: "usps",
      component: (
        <Usps handleChange={handleFormChange} uspsValue={state.usps} />
      ),
    },
    {
      id: "4",
      name: "custom",
      component: (
        <Customs handleChange={handleFormChange} customValue={state.custom} />
      ),
    },
    {
      id: "5",
      name: "barcodes",
      component: (
        <Barcode handleChange={handleFormChange} barcodeValue={state.barcode} />
      ),
    },
    {
      id: "5",
      name: "print",
      component: (
        <Print handleChange={handleFormChange} printValue={state.print} />
      ),
    },
  ];

  useEffect(() => {
    getAccountDetails();
  }, [currentPage]);

  const handleChangeTop = (panel) => (event, newExpanded) => {
    setExpandedTop(newExpanded ? panel : false);
  };

  const handleChange = (panel, item) => (event, newExpanded) => {
    setSelectedMailOwner(item.mail_owner_admin_account_guid);
    setExpanded(newExpanded ? panel : false);
    setTreeSelected("moAdmin");
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleMailOwnerClick = (mailOwner) => () => {
    setSelectedMailOwner(mailOwner);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filtered = filteredData.mo_accounts.filter((account) => {
      const accountNameMatch = account.mail_owner_account_name
        .toLowerCase()
        .includes(value);
      const userAccountMatch = account.mail_owner_user_accounts.some((user) =>
        user.mail_owner_account_name.toLowerCase().includes(value)
      );
      return accountNameMatch || userAccountMatch;
    });

    setMspAccountDetails(filtered);
  };

  const getAccountDetails = async () => {
    try {
      axios
        .get(`${baseUrl}get_profile_editor_accounts`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
          params: { page: currentPage, page_size: itemsPerPage },
        })
        .then((response) => {
          setMspAccountDetails(response.data);
          setFilteredData(response.data);
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  const createAccountProfileEditor = async () => {
    try {
      const keysToInclude = [
        "details",
        "class_settings",
        "usps",
        "custom",
        "print",
      ];

      const body = keysToInclude.reduce((acc, key) => {
        if (state[key]) {
          acc[key] = { ...state[key] };
        }
        const forMo = {
          ...acc,
          print: {
            ...acc.print,
            msp_accounts_print_barcode_id: 0,
            mo_accounts_print_barcode_id:
              state?.print.accounts_print_barcode_id || 0,
          },
          mo_account_guid: selectedMailOwner,

          is_msp_account: false,
        };
        const forMsp = {
          ...acc,
          print: {
            ...acc.print,
            msp_accounts_print_barcode_id:
              state.print.accounts_print_barcode_id || 0,
            mo_accounts_print_barcode_id: 0,
          },
          mo_account_guid: "",

          is_msp_account: true,
        };
        return selectedMailOwner ? forMo : forMsp;
      }, {});
      console.log("Body==>", body);

      const response = await axios.post(
        `${apiBaseUrl}save_account_profile_editor`,
        body,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        }
      );
      const formData = new FormData();
      formData.append("barcode_name", state.barcode_name);
      formData.append("barcode_type", isValidNumber(state.barcode_type));
      formData.append("barcode_pattern", isValidNumber(state.barcode_pattern));
      formData.append(
        "is_imb_replacement",
        isValidNumber(state.is_imb_replacement)
      );
      formData.append("is_required", isValidNumber(state.is_required));
      formData.append(
        "fill_no_bc_with_routing",
        isValidNumber(state.fill_no_bc_with_routing)
      );

      function isValidNumber(value) {
        const number = Number(value);
        return !isNaN(number) ? number : 0;
      }

      formData.append("is_msp_account", state.is_msp_account);

      const barCodeResponse = await axios.post(
        `${apiBaseUrl}save_profile_editor_bar_codes`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        }
      );
      toast.success(barCodeResponse.data.message);

      toast.success(response.data.message);
    } catch (error) {
      console.log("Err==>", error);
    }
  };
  const fetchAccountProfileEditor = () => {
    setLoader(true);
    axios
      .get(`${apiBaseUrl}get_account_profile_editor`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
        params: {
          mo_account_guid: selectedMailOwner,
          is_msp_account: selectedMailOwner ? false : true,
        },
      })
      .then((response) => {
        dispatch({
          type: "FETCHED_VALUES",
          payload: response.data.profile_editor_details,
          key: treeSelected,
        });
        setLoader(false);
      });
  };
  // const fetchBarcode = () => {
  //   axios
  //     .get(`${apiBaseUrl}get_profile_editor_bar_codes`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("auth")}`,
  //       },
  //       params: {
  //         mo_account_guid: selectedMailOwner,
  //       },
  //     })
  //     .then((res) => {
  //       dispatch({
  //         type: "APPEND_BARCODE",
  //         payload: selectedMailOwner
  //           ? res.data.bar_code_details.mo_bar_code
  //           : res.data.bar_code_details.msp_bar_code,
  //       });
  //     });
  // };
  useEffect(() => {
    if (selectedMailOwner) {
      fetchAccountProfileEditor();
    }
  }, [selectedMailOwner]);
  return (
    <div className="profile-editor-wrap">
      {loader && <Loader />}
      <div className="account-wrap">
        <h2 className="account-wrap-header">accounts</h2>
        <div className="legends-wrap">
          <ul>
            <li>mail owner</li>
            <li>child account</li>
          </ul>
        </div>
        <Accordion
          expanded={expandedTop == `1`}
          onChange={handleChangeTop(`1`)}
          className="check"
        >
          <AccordionSummary
            onClick={() => {
              setTreeSelected();
              fetchAccountProfileEditor();
            }}
          >
            <Typography className="account-wrap-details">
              {mspAccountDetails?.msp_account_name} ( MSP )
            </Typography>
          </AccordionSummary>

          <FormControl
            sx={{ my: 2, px: 1 }}
            variant="outlined"
            fullWidth
            size="small"
            className="search-bar"
          >
            <InputLabel htmlFor="outlined-adornment-password" shrink>
              Search
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              value={searchText}
              onChange={handleSearchChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <SearchIcon className="search_icon" />
                  </IconButton>
                </InputAdornment>
              }
              label="Search"
            />
          </FormControl>
          <AccordionDetails
            className="accordionDetails"
            ref={listInnerRef}
            onScroll={onScroll}
          >
            {mspAccountDetails?.mo_accounts?.map((item, index) => (
              <Accordion
                key={item.mail_owner_admin_account_guid}
                expanded={expanded == `${index}`}
                onChange={handleChange(`${index}`, item)}
              >
                <AccordionSummary
                  aria-controls={`${index}-content`}
                  id={`${index}-header`}
                >
                  <Typography>{item.mail_owner_account_name}</Typography>
                  <IconButton className="add-icon" aria-label="add">
                    <AddCircleIcon />{" "}
                  </IconButton>
                  <IconButton className="delete-icon" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="mo-list">
                    {item?.mail_owner_user_accounts?.map(
                      (owner, ownerIndex) => (
                        <li key={owner?.mail_owner_user_account_guid}>
                          <button
                            key={owner?.name}
                            onClick={handleMailOwnerClick(
                              owner.mail_owner_user_account_guid
                            )}
                          >
                            {owner?.mail_owner_account_name}
                          </button>
                          <IconButton
                            className="delete-icon"
                            aria-label="delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </li>
                      )
                    )}
                  </ul>
                  {/* )} */}
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
      {/* Tabs */}

      <Box className="acc-editor-tab">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            className="tab-headers"
          >
            {tabResponse.map((e, index) => (
              <Tab key={e.id} label={e.name} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>

        {tabResponse.map((e, index) => (
          <CustomTabPanel
            key={e.id}
            value={value}
            index={index}
            className="profile-editor-tab"
          >
            {e.component}
          </CustomTabPanel>
        ))}

        <div className="revert-bottom btn-wrap">
          <Button variant="contained" className="reverttab">
            revert tab
            <UndoIcon />
          </Button>
        </div>

        <div className="btn-wrap">
          <Button variant="outlined" className="editor-close-button">
            close
          </Button>
          <Button
            variant="contained"
            className="savechanges"
            onClick={createAccountProfileEditor}
          >
            save changes
          </Button>
        </div>
      </Box>
    </div>
  );
}
