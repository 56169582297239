import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Button,
  Typography,
  FormControl,
  OutlinedInput,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import FileUpload from "../../../assets/images/fileUpload.png";
import { MspService } from "../../../services/MspService";
import { useNavigate } from "react-router-dom";
import PubSub from "pubsub-js";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";
import "../../../style/style.css";
import ZipIcon from "../../../assets/images/zip.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { convertTimeZone } from "../../../utils/formateDate";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Loader from "../../../components/Loader";
import CustomToolBar from "../../../components/customToolbar/customToolbar";
import CustomPagination from "../../../components/customPagination/custompagination";
import moment from "moment";
const UploadDiv = makeStyles({
  root: {
    position: "relative",
    border: "3px dotted grey",
    padding: "5px 50px",
    justifyContent: "center",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    marginRight: "15px",
    width: "100%",
  },
  image: {
    width: "50px",
    height: "50px",
  },
  input: {
    display: "block",
    position: "absolute",
    top: 0,
    bottom: 0,
    opacity: 0,
    cursor: "pointer",
    left: 0,
    right: 0,
    height: "100%",
  },
  p: {
    margin: 0,
    color: "grey",
    whiteSpace: "nowrap",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  failed: {
    padding: "7px 15px",
    backgroundColor: "#F9B3B9",
    borderRadius: "5px",
    color: "#F04452",
    textAlign: "center",
    display: "block",
  },
  uploaded: {
    padding: "7px 15px",
    backgroundColor: "#9BE6CC",
    borderRadius: "5px",
    color: "#369260",
    textAlign: "center",
    display: "block",
  },
  creating: {
    padding: "7px 15px",
    backgroundColor: "#c8cd7c",
    borderRadius: "5px",
    color: "#424141",
    textAlign: "center",
    display: "block",
  },
  filevalidate: {
    padding: "7px 15px",
    backgroundColor: "#9BE6CC",
    borderRadius: "5px",
    color: "#369260",
    textAlign: "center",
    display: "block",
  },
  manifested: {
    padding: "7px 15px",
    backgroundColor: "#ABC7F9",
    borderRadius: "5px",
    color: "#072f74",
    textAlign: "center",
    display: "block",
  },
  notstarted: {
    padding: "7px 15px",
    backgroundColor: "#FEEAAF",
    borderRadius: "5px",
    color: "#BF982B",
    textAlign: "center",
    display: "block",
  },
  tableHead: {
    "& .MuiTableCell-head": {
      color: "black",
      backgroundColor: "#e0e0e0",
    },
  },
}));

const columns = [
  { field: "job_id", headerName: "JOB ID", width: 130 },
  { field: "filename", headerName: "FILE NAME", width: 200 },
  { field: "created_on", headerName: "CREATED ON", width: 200 },
  { field: "status", headerName: "STATUS", width: 150 },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "blue",
    color: "white",
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function UploadFolder() {
  const classes = UploadDiv();
  const classes1 = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [formDetails, setFormDetails] = useState({});
  const navigate = useNavigate();
  const [fileSize, setFileSize] = useState(null);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const [sites, setSites] = useState([]);
  const [sitesId, setSitesId] = useState(0);
  const [machineId, setMachineId] = useState(0);
  const [machines, setMachines] = useState([]);
  const [jobList, setJobList] = useState([]);

  const columns = [
    { field: "job_id", headerName: "JOB ID", width: 130 },
    { field: "filename", headerName: "FILE NAME", width: 300 },
    {
      field: "created_on",
      headerName: "CREATED ON",
      width: 300,
      renderCell: (params) => (
        <div>{moment(params.value).format("YYYY-MM-DD")}</div>
      ),
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 150,
      renderCell: (params) => renderStatus(params.value),
    },
  ];
  const renderStatus = (status) => {
    let cellStyle;
    switch (status) {
      case "Failed":
        cellStyle = classes1.failed;
        break;
      case "Uploaded":
        cellStyle = classes1.uploaded;
        break;
      case "Creating Mail.dat":
        cellStyle = classes1.creating;
        break;
      case "Manifested":
        cellStyle = classes1.manifested;
        break;
      case "Not Yet Started":
        cellStyle = classes1.notstarted;
        break;
      default:
        cellStyle = classes1.filevalidate;
    }
    return <div className={cellStyle}>{status}</div>;
  };

  const clearResponse = () => {
    setSelectedFile(null);
    setFileSize("");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    event.target.value = null;
    if (file) {
      const fileSizeInKB = file.size / 1024;
      setFileSize(fileSizeInKB.toFixed(2)); // Rounded to 2 decimal places
    }
    setSelectedFile(file);
    const fileReader = new FileReader();
    fileReader.onload = function () {
      setPreview(fileReader.result);
    };
    fileReader?.readAsDataURL(file);
  };
  function getFileExtension(fileName) {
    return fileName.name.split(".").pop();
  }

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    const fileExtension = getFileExtension(selectedFile);
    if (fileExtension === "zip") {
      const formData = new FormData();
      formData.append("zip_file", selectedFile);
      formData.append("site_id", sitesId);
      formData.append("machine_id", machineId);
      try {
        await MspService.fileValidation(formData)
          .then(
            (res) => {
              if (res.data.error) {
                toast.error("File already exists");
              } else if (res && res?.status) {
                fieldValidationEvent(selectedFile, res.data.created_job_id);
                setSelectedFile(null);
                setFileSize("");
                toast.success(res.data.message);
              }
            },
            (error) => {
              console.log("Err===>", error);
              if (error?.status == "401") {
                toast.error(
                  "Your session has expired. Please log in again to continue."
                );
                localStorage.clear();
                navigate("/login", { replace: true });
              } else {
                toast.error(error.data.detail);
              }
              PubSub.publish("msg", false);
            }
          )
          .catch((error) => {
            PubSub.publish("msg", false);
            console.log("Err===>", error);
            toast.error(error.data.detail);

            if (error?.status == "401") {
              toast.error(
                "Your session has expired. Please log in again to continue."
              );
              localStorage.clear();
              navigate("/login", { replace: true });
            } else {
              toast.error(error.data.detail);
            }
          });
      } catch (error) {
        console.log("Err===>", error);
      }
    } else if (fileExtension === "pdr") {
      const formData = new FormData();

      formData.append("file", selectedFile);
      formData.append("site_id", sitesId);
      formData.append("machine_id", machineId);
      setLoader(true);
      try {
        await MspService.uploadPDR(formData)
          .then(
            (res) => {
              setLoader(false);
              getUploadFolderEvent();
              if (res && res?.status) {
                clearResponse();
                toast.success(res.data.message);
                setSitesId(0);
                setMachineId(0);
              } else if (res?.data?.error) {
                toast.error(res.data.message);
              }
            },
            (error) => {
              console.log("Err===>", error);
              setLoader(false);
              if (error?.status == "401") {
                toast.error(
                  "Your session has expired. Please log in again to continue."
                );
                localStorage.clear();
                navigate("/login", { replace: true });
              } else {
                toast.error(error.data.detail);
              }
              PubSub.publish("msg", false);
            }
          )
          .catch((error) => {
            PubSub.publish("msg", false);
            setLoader(false);
            console.log("Err===>", error);
            toast.error(error.data.detail);
            if (error?.status == "401") {
              toast.error(
                "Your session has expired. Please log in again to continue."
              );
              localStorage.clear();
              navigate("/login", { replace: true });
            } else {
              toast.error(error.data.detail);
            }
          });
      } catch (error) {
        console.log("Err===>", error);
        setLoader(false);
      }
    } else {
      const formData = new FormData();

      formData.append("file", selectedFile);
      formData.append("site_id", sitesId);
      formData.append("machine_id", machineId);
      setLoader(true);
      try {
        await MspService.uploadMPU(formData)
          .then(
            (res) => {
              setLoader(false);
              getUploadFolderEvent();
              if (res && res?.status) {
                clearResponse();
                toast.success(res.data.message);
                setSitesId(0);
                setMachineId(0);
              } else if (res?.data?.error) {
                toast.error(res.data.message);
              }
            },
            (error) => {
              console.log("Err===>", error);
              toast.error(error.data.detail);

              setLoader(false);
              if (error?.status == "401") {
                toast.error(
                  "Your session has expired. Please log in again to continue."
                );
                localStorage.clear();
                navigate("/login", { replace: true });
              } else {
                toast.error(error.data.detail);
              }
              PubSub.publish("msg", false);
            }
          )
          .catch((error) => {
            PubSub.publish("msg", false);
            setLoader(false);
            console.log("Err===>", error);
            if (error?.status == "401") {
              toast.error(
                "Your session has expired. Please log in again to continue."
              );
              localStorage.clear();
              navigate("/login", { replace: true });
            } else {
              toast.error(error.data.detail);
            }
          });
      } catch (error) {
        console.log("Err===>", error);
        setLoader(false);
      }
    }
  };

  const fieldValidationEvent = async (data, ID) => {
    const formData = new FormData();
    formData.append("folder", data);
    setLoader(true);
    try {
      await MspService.uploadFolder(formData, ID).then(
        (res) => {
          setLoader(false);
          getUploadFolderEvent();
          toast.success(res.data.message);
          setSitesId(0);
          setMachineId(0);
          if (res && res?.status) {
            setSelectedFile(null);
            setFileSize("");
          }
        },
        (error) => {
          console.log("Err===>", error);
          setLoader(false);
          if (error?.status == "401") {
            toast.error(
              "Your session has expired. Please log in again to continue."
            );
            localStorage.clear();
            navigate("/login", { replace: true });
          } else {
            toast.error(error.data.detail);
            setLoader(false);
          }
          PubSub.publish("msg", false);
        }
      );
    } catch (error) {
      console.log("Err===>", error);
      setLoader(false);
    }
  };

  const getUploadFolderEvent = async () => {
    setLoader(true);
    try {
      await MspService.getUploadFolder({
        page: page,
        page_size: pageSize,
      }).then(
        (res) => {
          setLoader(false);
          if (res && res?.status) {
            setRowCount(res.data.total_record);
            setJobList(res.data.upload_folder_list);
          }
        },
        (error) => {
          setLoader(false);
          console.log("Err===>", error);
          if (error?.status == "401") {
            toast.error(
              "Your session has expired. Please log in again to continue."
            );
            localStorage.clear();
            navigate("/login", { replace: true });
          } else {
            toast.error(error.data.detail);
          }
          PubSub.publish("msg", false);
        }
      );
    } catch (error) {
      setLoader(false);
      console.log("Err===>", error);
    }
  };
  const getSitesList = async () => {
    try {
      await MspService.getSitesListForJob().then((res) => {
        setSites(res.data);
      });
    } catch (error) {
      setLoader(false);
      console.log("Err===>", error);
    }
  };
  const getMachineList = async () => {
    try {
      await MspService.getMachineListForJob({ site_id: sitesId }).then(
        (res) => {
          setMachines(res.data);
        }
      );
    } catch (error) {
      setLoader(false);
      console.log("Err===>", error);
    }
  };
  useEffect(() => {
    getUploadFolderEvent();
    getSitesList();
  }, [page, pageSize]);

  useEffect(() => {
    sitesId && getMachineList();
  }, [sitesId]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1); // Reset to first page
  };
  return (
    <>
      {loader && <Loader />}
      <Box className="uploader-wrap">
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item lg={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Site</InputLabel>
                  <Select
                    label="Site"
                    onChange={(e) => {
                      setSitesId(e.target.value);
                    }}
                    value={sitesId}
                  >
                    {sites.map((item) => (
                      <MenuItem value={item.site_id} key={item.site_id}>
                        {item.site_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Machine</InputLabel>
                  <Select
                    label="Machine"
                    onChange={(e) => {
                      setMachineId(e.target.value);
                    }}
                    value={machineId}
                  >
                    {machines.map((item) => (
                      <MenuItem value={item.machine_id} key={item.machine_id}>
                        {item.machine_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Grid item>
              <form
                onSubmit={onSubmitHandler}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className={classes.root}>
                  <img src={FileUpload} alt="file" className={classes.image} />
                  <input
                    type="file"
                    name="upload"
                    accept=".zip,.mpu,.pdr"
                    className={classes.input}
                    onChange={handleFileChange}
                  />
                  <p className={classes.p}>Drop Files Here</p>
                </div>
                {selectedFile && (
                  <div className="zip-info-wrap">
                    <img src={ZipIcon} alt="zip" />
                    <div className="zip-info">
                      <Typography>{selectedFile.name}</Typography>
                      <Typography variant="span">{fileSize} kb</Typography>
                    </div>
                    <IconButton onClick={clearResponse}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!selectedFile}
                >
                  Submit
                </Button>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box
        style={{ height: 400, width: "100%", margin: "20px 0" }}
        className="roles rolesTable"
      >
        <DataGrid
          className="datagrid gridToolbar"
          columns={columns}
          rows={jobList.map((job, index) => ({ ...job, id: index }))}
          components={{
            NoRowsOverlay: () => {
              return null;
            },
            Toolbar: CustomToolBar,
            Pagination: (props) => (
              <CustomPagination
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
              />
            ),
          }}
          componentsProps={{
            toolbar: {
              toolbarWished: {
                columns: true,
                filter: true,
                density: true,
                export: true,
              },
            },
          }}
        />
      </Box>
    </>
  );
}
