import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "../../../assets/icons/Close Square.svg";
import { useQuery } from "@tanstack/react-query";
import { apiBaseUrl } from "../../../config";
import axios from "axios";
import { toast } from "react-toastify";

const AssignModal = ({ open, onClose, editId }) => {
  const [assignRoutes, setAssignRoutes] = useState({
    route_id: "",
    driver_id: "",
    mo_route_driver_id: "",
  });
  const isAuth = localStorage.getItem("auth");

  const fetchDropDowns = async () => {
    const [routesResponse, driverResponse] = await axios.all([
      axios.get(`${apiBaseUrl}get_routes`, {
        headers: {
          Authorization: `Bearer ${isAuth}`,
        },
      }),
      axios.get(`${apiBaseUrl}get_drivers_dropdown`, {
        headers: {
          Authorization: `Bearer ${isAuth}`,
        },
      }),
    ]);
    return {
      routes: routesResponse.data,
      drivers: driverResponse.data,
    };
  };
  const handleSubmit = () => {
    try {
      const formData = new FormData();
      formData.append("mo_pickup_route_id", assignRoutes.route_id);
      formData.append("driver_id", assignRoutes.driver_id);
      if (editId) {
        axios
          .put(
            `${apiBaseUrl}update_route_driver?mo_route_driver_id=${assignRoutes.mo_route_driver_id}&mo_pickup_route_id=${assignRoutes.route_id}&driver_id=${assignRoutes.driver_id}&auto_error=true`,
            "",
            {
              headers: {
                Authorization: `Bearer ${isAuth}`,
              },
            }
          )
          .then((res) => {
            toast.success(res.data.message);
            onClose("Success");
          })
          .catch((e) => {
            toast.error(e);
          });
      } else {
        axios
          .post(`${apiBaseUrl}assign_route_to_driver`, formData, {
            headers: {
              Authorization: `Bearer ${isAuth}`,
            },
          })
          .then((res) => {
            toast.success(res.data.message);
            onClose("Success");
          })
          .catch((e) => {
            toast.error(e);
          });
      }
    } catch (error) {
      console.log("Err==>", error);
    }
  };
  const { data } = useQuery({
    queryKey: ["routes"],
    queryFn: fetchDropDowns,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    if (editId) {
      setAssignRoutes((prev) => ({
        ...prev,
        mo_route_driver_id: editId.mo_route_driver_id,
        route_id: editId.route_id,
        driver_id: editId.driver_id,
      }));
    }
  }, [editId]);
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="create-assign-modal">
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{ mb: 3 }}
          className="modal-header"
        >
          <b>Assign Route</b>
          <Button onClick={() => onClose()} className="closeButton">
            {" "}
            <img alt="closeIcon" src={CloseIcon} className="closeicon" />
          </Button>
        </Typography>
        <div className="modal-container">
          <Box>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Route</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={assignRoutes.route_id}
                label="Route"
                onChange={(e) =>
                  setAssignRoutes((prev) => ({
                    ...prev,
                    route_id: e.target.value,
                  }))
                }
              >
                {data?.routes?.route_details?.map((item) => (
                  <MenuItem key={item.mo_route_id} value={item.mo_route_id}>
                    {item.mo_route_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Driver</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={assignRoutes.driver_id}
                label="Driver"
                onChange={(e) =>
                  setAssignRoutes((prev) => ({
                    ...prev,
                    driver_id: e.target.value,
                  }))
                }
              >
                {data?.drivers?.drivers_list.map((item) => (
                  <MenuItem key={item.account_id} value={item.account_id}>
                    {item.account_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="end" gap={2} sx={{ mt: 2 }}>
            <Button variant="outlined" type="button" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={!assignRoutes.route_id && !assignRoutes.driver_id}
              className="Btn"
              type="button"
            >
              {editId ? "Update" : "Create"}
            </Button>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default AssignModal;
