import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Toaster() {
  return (
    <>
      <ToastContainer autoClose={1000} />
    </>
  );
}
