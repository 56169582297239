import React, { useState } from "react";
import MachineUpdate from "./MachineUpdate";
import Box from "@mui/material/Box";
import MachinesTable from "./MachinesTable";
import "./Machines.scss";

export default function Machines() {
  const [machines, setMachines] = useState();
  const [refresh, setRefresh] = useState();
  const [openEdit, setOpenEdit] = useState(false);

  const handleOpenEdit = (data) => {
    setOpenEdit(true);
    setMachines(data);
  };

  const handleClose = (data) => {
    setOpenEdit(false);
    data && setRefresh(Date.now());
  };

  return (
    <>
      <Box
        style={{ height: 400, width: "100%", margin: "20px 0" }}
        className="roles rolesTable"
      >
        <MachinesTable handleOpenEdit={handleOpenEdit} refresh={refresh} />
      </Box>

      {openEdit && (
        <MachineUpdate
          openEdit={openEdit}
          closeEdit={handleClose}
          editList={machines}
        />
      )}
    </>
  );
}
