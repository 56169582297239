import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axios";
import { apiBaseUrl } from "../config";
import { toast } from "react-toastify";

const LoginPage = () => {
  const [formData, setFormData] = useState({
    machine_secret_key: "",
    pin: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formObj = new FormData();
    formObj.append("machine_secret_key", formData.machine_secret_key);
    formObj.append("pin", formData.pin);
    axios
      .post(`${apiBaseUrl}signup_with_pin`, formObj)
      .then((res) => {
        const { data } = res;
        toast.success(data.message);
        navigate("/authorization");
      })
      .catch((err) => {
        console.log("err===>", err.data.detail);
        toast.error(err.data.detail);
      });
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(135deg, #00bfa5, #1e88e5)", // Linear gradient background
      }}
    >
      <Container maxWidth="xs">
        <Paper
          elevation={6}
          sx={{
            padding: "2rem",
            borderRadius: "15px",
            backgroundColor: "#ffffff",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ color: "#333", fontWeight: "bold", mb: 3 }}
          >
            Sign Up
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Machine Secret Key"
                  name="machine_secret_key"
                  size="small"
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  value={formData.machine_secret_key}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  required
                  sx={{
                    "& label.Mui-focused": {
                      color: "#1976d2",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ddd",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1976d2",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Pin Code"
                  name="pin"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={formData.pin}
                  onChange={handleChange}
                  required
                  sx={{
                    "& label.Mui-focused": {
                      color: "#1976d2",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ddd",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1976d2",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "#1976d2",
                    "&:hover": {
                      backgroundColor: "#1565c0",
                    },
                    padding: "5px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Sign Up
                </Button>
              </Grid>
              <Grid item xs={12} className="or-content-box">
                <Typography className="or-text">OR</Typography>
                <Divider
                  sx={{
                    borderColor: "lightgrey",
                    borderWidth: "1px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => navigate("/authorization")}
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "#1976d2",
                    "&:hover": {
                      backgroundColor: "#1565c0",
                    },
                    padding: "5px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Log in
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default LoginPage;
