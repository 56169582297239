import * as React from "react";
import Box from "@mui/material/Box";
import Roles from "./Roles";

export default function RolesTab() {
  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="RolesmanagementTab"
    >
      <Roles />
    </Box>
  );
}
