import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  Switch,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { handleWorkOrderChange } from "../../../../store/WorkOrderCreation";
import dayjs from "dayjs";
import axios from "axios";
import { apiBaseUrl } from "../../../../config";
import { useParams } from "react-router-dom";
import { updateWO } from "../../../../store/WorkOrderCreation";
import moment from "moment";

export default function CreateWorkOrder({ openWorkOrderModal }) {
  const [siteList, setSiteList] = useState([]);
  const [mailOwnerList, setMailOwnerList] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const { id } = useParams();

  const dispatch = useDispatch();

  const {
    site_id,
    mo_account_id,
    eps_account_number,
    eps_reference_field,
    work_order_type_id,
    job_name,
    owner_id,
    po_number,
    comments,
    work_order_upload_option_id,
    is_additional_services_present,
    pieces,
    trays,
    pallets,
    mailing_date,
    work_order_type,
    pick_up_date,
    special_processing_notes,
  } = useSelector((state) => state.workOrder);

  const handleChange = (fieldName) => (event) => {
    const value =
      event?.target?.type === "checkbox"
        ? event?.target?.checked
        : event?.target?.value;
    dispatch(handleWorkOrderChange({ field: fieldName, value }));
    if (fieldName === "pieces" && Number(value) < 200) {
      setIsDisabled(true);
      return;
    }
    if (fieldName === "trays" && Number(value) < 7) {
      setIsDisabled(true);
      return;
    }
  };
  const handleDateChange = (fieldName, newDate) => {
    dispatch(handleWorkOrderChange({ field: fieldName, value: newDate }));
  };

  useEffect(() => {
    dispatch(
      handleWorkOrderChange({
        field: "msp_id",
        value: localStorage.getItem("msp_account_guid"),
      })
    );

    axios
      .get(
        `${apiBaseUrl}sites_list_for_work_order?msp_id=${localStorage.getItem(
          "msp_id"
        )}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        }
      )
      .then((response) => {
        setSiteList(response.data.site_list);
      })
      .catch((e) => {
        console.log("Err==>", e);
      });
  }, []);
  useEffect(() => {
    const mspSelected = JSON.parse(localStorage.getItem("switchMsp"));
    if (mspSelected) {
      dispatch(
        handleWorkOrderChange({
          field: "msp_id",
          value: mspSelected.msp_account_guid,
        })
      );
      dispatch(
        handleWorkOrderChange({
          field: "msp_name",
          value: mspSelected.msp_name,
        })
      );
    }

    if (id) {
      axios
        .get(`${apiBaseUrl}receive_specific_work_order?work_order_id=${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          response.data.work_order_details.mo_account_id =
            response.data.work_order_details.owner_id;
          dispatch(updateWO(response.data.work_order_details));
        })
        .catch((e) => {
          console.log("Err==>", e);
        });
    }
  }, []);
  useEffect(() => {
    if (site_id) {
      axios
        .get(`${apiBaseUrl}get_mail_owners_by_site?site_id=${site_id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          setMailOwnerList(response.data.mail_owners);
        })
        .catch((e) => {
          console.log("Err==>", e);
        });
    }
  }, [site_id]);
  return (
    <Box className="work_order_home_page">
      <div className="create_page">
        <div className="workOrder">
          <Box className="workOrder_header">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography variant="p">
                  {id ? "View Work Order" : "Create Work Order"}{" "}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box className="customerDetails" sx={{ mt: 4 }}>
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Customer Details</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" className="customer-details">
                  MSP Name : <b>{localStorage.getItem("user_name")}</b>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item md={4} lg={4}>
                    <FormControl
                      size="small"
                      sx={{ mt: "10px", ml: "20px" }}
                      fullWidth
                      required
                    >
                      <InputLabel id="demo-simple-select-label">
                        Site
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Site"
                        onChange={handleChange("site_id")}
                        value={site_id || ""}
                        disabled={!!id}
                      >
                        {siteList?.map((item) => (
                          <MenuItem key={item.site_guid} value={item.site_guid}>
                            {item.site_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} lg={4}>
                    <FormControl
                      size="small"
                      sx={{ mt: "10px", ml: "20px" }}
                      fullWidth
                      required
                    >
                      <InputLabel id="demo-simple-select-label">
                        Mail Owner
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Mail Owner"
                        disabled={!!id}
                        onChange={handleChange("mo_account_id")}
                        value={mo_account_id || ""}
                      >
                        {mailOwnerList?.map((item) => (
                          <MenuItem key={item.mo_id} value={item.mo_id}>
                            {item.mail_owner_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className="customerDetails workinfo">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Work Order Info</b>
                </Typography>
              </Box>
              <Box className="container">
                <Grid container spacing={2} sx={{ mt: 0.5 }}>
                  <Grid item md={6} lg={6}>
                    <Grid item mb={2}>
                      <TextField
                        variant="outlined"
                        name="job_name"
                        label="Job Name"
                        size="small"
                        fullWidth
                        required
                        value={job_name || ""}
                        onChange={handleChange("job_name")}
                        disabled={!!id}
                      />
                    </Grid>
                    <Grid item mb={2}>
                      <TextField
                        variant="outlined"
                        name="po_number"
                        label="PO Number"
                        required
                        size="small"
                        fullWidth
                        value={po_number || ""}
                        onChange={handleChange("po_number")}
                        disabled={!!id}
                      />
                    </Grid>

                    <Grid item>
                      <TextField
                        variant="outlined"
                        label="Comments"
                        name="comments"
                        size="small"
                        multiline
                        rows={4}
                        value={comments || ""}
                        fullWidth
                        onChange={handleChange("comments")}
                        disabled={!!id}
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={6} lg={6}>
                    <Grid item mb={2}>
                      <TextField
                        variant="outlined"
                        name="eps_account_number"
                        label="EPS Account Number"
                        required
                        size="small"
                        fullWidth
                        value={eps_account_number || ""}
                        onChange={handleChange("eps_account_number")}
                        disabled={!!id}
                      />
                    </Grid>

                    <Grid item mb={2}>
                      <TextField
                        variant="outlined"
                        name="eps_reference_field"
                        label="EPS Reference Field"
                        size="small"
                        value={eps_reference_field || ""}
                        onChange={handleChange("eps_reference_field")}
                        fullWidth
                        disabled={!!id}
                      />
                    </Grid>

                    <Grid item>
                      <FormControl size="small" fullWidth required>
                        <InputLabel id="demo-simple-select-label">
                          Work Order Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Work Order Type"
                          name="work_order_type_id"
                          required
                          value={work_order_type_id || ""}
                          onChange={handleChange("work_order_type_id")}
                          disabled={!!id}
                        >
                          <MenuItem value={1}>Delivery</MenuItem>
                          <MenuItem value={2}>Pickup Of Mail</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box className="customerDetails uploads">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Do you have data to upload ?</b>
                </Typography>
              </Box>
              <Box className="container" sx={{ mt: 2 }}>
                {" "}
                <Grid container>
                  <Grid item md={4} lg={4}>
                    <FormControl size="small" fullWidth required>
                      <InputLabel id="demo-simple-select-label">
                        Work Order Upload Option
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Work Order Upload Option"
                        name="work_order_upload_option_id"
                        onChange={handleChange("work_order_upload_option_id")}
                        required
                        value={work_order_upload_option_id || ""}
                        disabled={!!id}
                      >
                        <MenuItem value={1}>None</MenuItem>
                        <MenuItem
                          value={2}
                          onClick={() => openWorkOrderModal()}
                        >
                          Data Exchange
                        </MenuItem>
                        <MenuItem
                          value={3}
                          onClick={() => openWorkOrderModal()}
                        >
                          Mail.dat File
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className="customerDetails lineitems">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Line Items</b>
                </Typography>
              </Box>
              <Box className="container" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item md={6} lg={6}>
                    <Grid item mb={2}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        size="small"
                      >
                        <DatePicker
                          value={
                            mailing_date
                              ? dayjs(mailing_date)
                              : dayjs(new Date())
                          }
                          name="mailing_date"
                          disabled={!!id}
                          onChange={(newDate) =>
                            handleDateChange("mailing_date", newDate)
                          }
                          renderInput={(params) => (
                            <TextField {...params} required />
                          )}
                          minDate={dayjs(new Date())}
                          slotProps={{
                            textField: {
                              size: "small",
                              fullWidth: true,
                            },
                          }}
                          label="Mailing Date"
                          className="lineItemsFields"
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item mb={2}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        size="small"
                      >
                        <DatePicker
                          value={
                            pick_up_date
                              ? dayjs(pick_up_date)
                              : dayjs(new Date())
                          }
                          name="pick_up_date"
                          disabled={!!id}
                          onChange={(newDate) =>
                            handleDateChange("pick_up_date", newDate)
                          }
                          renderInput={(params) => (
                            <TextField {...params} required />
                          )}
                          minDate={dayjs(new Date())}
                          slotProps={{
                            textField: {
                              size: "small",
                              fullWidth: true,
                            },
                          }}
                          label={
                            work_order_type_id === 1
                              ? "Delivery Date"
                              : "Pickup Date"
                          }
                          className="lineItemsFields"
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item>
                      <TextField
                        name="special_processing_notes"
                        label="Special Processing Notes"
                        size="small"
                        multiline
                        rows={4}
                        disabled={!!id}
                        fullWidth
                        value={special_processing_notes || ""}
                        onChange={handleChange("special_processing_notes")}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={6} lg={6}>
                    <Grid item mb={2}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="pieces"
                        label="Pieces"
                        type="number"
                        value={pieces || ""}
                        disabled={id}
                        inputProps={{ min: 0 }}
                        onChange={handleChange("pieces")}
                      />
                    </Grid>
                    <Grid item mb={2}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="trays"
                        label="Trays"
                        disabled={id || pieces < 200}
                        type="number"
                        value={trays || ""}
                        inputProps={{ min: 0 }}
                        onChange={handleChange("trays")}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="pallets"
                        label="Pallets"
                        type="number"
                        value={pallets || ""}
                        inputProps={{ min: 0 }}
                        disabled={id || trays < 8}
                        onChange={handleChange("pallets")}
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={6} lg={6} alignItems="center">
                    <FormControl component="fieldset">
                      <FormControlLabel
                        required
                        disabled={!!id}
                        control={
                          <Switch
                            checked={is_additional_services_present}
                            inputProps={{ "aria-label": "controlled" }}
                            name="is_additional_services_present"
                            onChange={handleChange(
                              "is_additional_services_present"
                            )}
                          />
                        }
                        label="Additional Services?"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </Box>
  );
}
