import * as React from "react";
import {
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
} from "@mui/material";
import Chart from "react-apexcharts";

function createData(
  machine,
  sort_profile,
  pieces_sorted,
  pieces_rejected,
  avg_pcs_being,
  acc_being,
  backlog,
  logged_into_machine
) {
  return {
    machine,
    sort_profile,
    pieces_sorted,
    pieces_rejected,
    avg_pcs_being,
    acc_being,
    backlog,
    logged_into_machine,
  };
}

const rows = [
  createData("Machine 1", 159, 6.0, 24, 4.0, 1, 3, 2),
  createData("Machine 2", 237, 9.0, 37, 4, 1, 3, 2),
  createData("Machine 3 ", 262, 16.0, 24, 6.0, 1, 3, 2),
];

export default function MachineTable() {
  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: rows.map((row) => row.machine),
    },
    yaxis: {
      title: {
        text: "Pieces",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} pieces`,
      },
    },
  };

  const chartSeries = [
    // {
    //   name: "Pieces Fed",
    //   data: rows.map((row) => row.pieces_fed),
    // },
    {
      name: "Pieces Sorted",
      data: rows.map((row) => row.pieces_sorted),
    },
    {
      name: "Pieces Rejected",
      data: rows.map((row) => row.pieces_rejected),
    },
    {
      name: "Logged",
      data: rows.map((row) => row.logged_into_machine),
    },
    // {
    //   name: "Pieces Fed",
    //   data: rows.map((row) => row.pieces_fed),
    // },
    {
      name: "Backlog",
      data: rows.map((row) => row.backlog),
    },
  ];

  return (
    <Box>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={350}
      />

      <TableContainer
        component={Paper}
        className="mailing-editor-table"
        sx={{ mt: 4, mb: 2 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mailing Run</TableCell>
              <TableCell>Sort Profile Used</TableCell>
              <TableCell>Pcs Fed,Pieces Sorted / % of pcs Sorted</TableCell>
              <TableCell>Pcs Rejected / % of pcs Rejected</TableCell>
              <TableCell>Avg pcs being fed/hr. on the mchn @ time </TableCell>
              <TableCell>Acct being run on mchn @ time</TableCell>
              <TableCell>Backlog on mchn for that mailing</TableCell>
              <TableCell>Operator logged into the machine</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.machine}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.machine}
                </TableCell>
                <TableCell>{row.sort_profile}</TableCell>
                <TableCell>{row.pieces_sorted}</TableCell>
                <TableCell>{row.pieces_rejected}</TableCell>
                <TableCell>{row.avg_pcs_being}</TableCell>
                <TableCell>{row.acc_being}</TableCell>
                <TableCell>{row.backlog}</TableCell>
                <TableCell>{row.logged_into_machine}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
