import { apiBaseUrl } from "../config";
import axios from "axios";

export async function Login(data) {
  try {
    const url = `initial_login`;
    const response = await axios.post(apiBaseUrl + url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.log("Err===>", error);
    throw error;
  }
}
