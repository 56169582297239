import React from "react";
import { Box, Grid, Typography, TextField, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function ContactDetails({
  detailValue,
  handleChange,
  siteValue,
  errorMessage,
}) {
  return (
    <Box className="sitedetails_tab contact-details">
      <Box className="sitedetails-header" sx={{ mb: 3 }}>
        <Typography>
          <b>Contact Details</b>
        </Typography>
      </Box>
      <Box
        className="sitedetails-body"
        component="form"
        noValidate
        sx={{ mb: 3, px: 2 }}
      >
        <Box className="site-details-info" sx={{ mb: 3 }}>
          <Typography variant="p" className="site-info-header">
            Main Contact Info
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="main_contact_first_name"
                onChange={(e) => handleChange(e, "contact_details")}
                value={detailValue?.main_contact_first_name}
                error={!!errorMessage.main_contact_first_name}
                helperText={errorMessage.main_contact_first_name}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 30 }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="main_contact_last_name"
                onChange={(e) => handleChange(e, "contact_details")}
                value={detailValue?.main_contact_last_name}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="Email Id"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
                fullWidth
                name="main_contact_email"
                onChange={(e) => handleChange(e, "contact_details")}
                value={detailValue?.main_contact_email}
                error={!!errorMessage.main_contact_email}
                helperText={errorMessage.main_contact_email}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="Contact Phone"
                name="main_contact_phone"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 50 }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                onChange={(e) => handleChange(e, "contact_details")}
                value={detailValue?.main_contact_phone}
                error={!!errorMessage.main_contact_phone}
                helperText={errorMessage.main_contact_phone}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className="site-details-info" sx={{ mb: 3 }}>
          <Typography variant="p" className="site-info-header">
            Mail.Dat Contact Info
          </Typography>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="mail_dat_contact_first_name"
                onChange={(e) => handleChange(e, "contact_details")}
                value={detailValue?.mail_dat_contact_first_name}
                error={!!errorMessage.mail_dat_contact_first_name}
                helperText={errorMessage.mail_dat_contact_first_name}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 30 }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="mail_dat_contact_last_name"
                onChange={(e) => handleChange(e, "contact_details")}
                value={detailValue?.mail_dat_contact_last_name}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="Email Id"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
                fullWidth
                name="mail_dat_contact_email"
                onChange={(e) => handleChange(e, "contact_details")}
                value={detailValue?.mail_dat_contact_email}
                error={!!errorMessage.mail_dat_contact_email}
                helperText={errorMessage.mail_dat_contact_email}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="Contact Phone"
                name="mail_dat_contact_phone"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 50 }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                onChange={(e) => handleChange(e, "contact_details")}
                value={detailValue?.mail_dat_contact_phone}
                error={!!errorMessage.mail_dat_contact_phone}
                helperText={errorMessage.mail_dat_contact_phone}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className="site-details-info" sx={{ mb: 3 }}>
          <Typography variant="p" className="site-info-header">
            Maintenance contact info (Shift 1)
          </Typography>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="shift1_maintenance_contact_first_name"
                onChange={(e) => handleChange(e, "contact_details")}
                value={detailValue?.shift1_maintenance_contact_first_name}
                error={!!errorMessage.shift1_maintenance_contact_first_name}
                helperText={errorMessage.shift1_maintenance_contact_first_name}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 30 }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="shift1_maintenance_contact_last_name"
                onChange={(e) => handleChange(e, "contact_details")}
                value={detailValue?.shift1_maintenance_contact_last_name}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="Email Id"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
                fullWidth
                name="shift1_maintenance_contact_email"
                onChange={(e) => handleChange(e, "contact_details")}
                value={detailValue?.shift1_maintenance_contact_email}
                error={!!errorMessage.shift1_maintenance_contact_email}
                helperText={errorMessage.shift1_maintenance_contact_email}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                id="outlined-basic"
                label="Contact Phone"
                name="shift1_maintenance_contact_phone"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 50 }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                onChange={(e) => handleChange(e, "contact_details")}
                value={detailValue?.shift1_maintenance_contact_phone}
                error={!!errorMessage.shift1_maintenance_contact_phone}
                helperText={errorMessage.shift1_maintenance_contact_phone}
              />
            </Grid>
          </Grid>
        </Box>
        {siteValue.is_24_hours_active ? (
          <Box>
            <Box className="site-details-info" sx={{ mb: 3 }}>
              <Typography variant="p" className="site-info-header">
                Maintenance contact info (Shift 2)
              </Typography>
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    name="shift2_maintenance_contact_first_name"
                    onChange={(e) => handleChange(e, "contact_details")}
                    value={detailValue?.shift2_maintenance_contact_first_name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 30 }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    name="shift2_maintenance_contact_last_name"
                    onChange={(e) => handleChange(e, "contact_details")}
                    value={detailValue?.shift2_maintenance_contact_last_name}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="Email Id"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    name="shift2_maintenance_contact_email"
                    onChange={(e) => handleChange(e, "contact_details")}
                    value={detailValue?.shift2_maintenance_contact_email}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="Contact Phone"
                    name="shift2_maintenance_contact_phone"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 50 }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    onChange={(e) => handleChange(e, "contact_details")}
                    value={detailValue?.shift2_maintenance_contact_phone}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className="site-details-info" sx={{ mb: 3 }}>
              <Typography variant="p" className="site-info-header">
                Maintenance contact info (Shift 3)
              </Typography>
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    name="shift3_maintenance_contact_first_name"
                    onChange={(e) => handleChange(e, "contact_details")}
                    value={detailValue?.shift3_maintenance_contact_first_name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 30 }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    name="shift3_maintenance_contact_last_name"
                    onChange={(e) => handleChange(e, "contact_details")}
                    value={detailValue?.shift3_maintenance_contact_last_name}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="Email Id"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    name="shift3_maintenance_contact_email"
                    onChange={(e) => handleChange(e, "contact_details")}
                    value={detailValue?.shift3_maintenance_contact_email}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="Contact Phone"
                    name="shift3_maintenance_contact_phone"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 50 }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    onChange={(e) => handleChange(e, "contact_details")}
                    value={detailValue?.shift3_maintenance_contact_phone}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}
