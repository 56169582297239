import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { apiBaseUrl } from "../../../config";
import axios from "axios";
import { toast } from "react-toastify";
import Barcode from "react-barcode";
import { useParams, useNavigate } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import "./workOrder.scss";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

const initialState = {
  date_of_print: "",
  operation_name: "",
  department_number: "",
  operation_description: "",
  place_to_sign_off: "",
  date_of_sign_off: "",
  barcode: "",
};

export default function PrintWorkOrderSheet() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [workOrderSpecificDetails, setWorkOrderSpecificDetails] = useState();
  const [mspAdditionalDetails, setMspAdditionalDetails] =
    useState(initialState);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    try {
      if (id && id > 0) {
        axios
          .get(`${apiBaseUrl}receive_specific_work_order`, {
            params: { work_order_id: id },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setWorkOrderSpecificDetails(res.data.work_order_details);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              toast.error("Unauthorized");
            }
          });
      }
    } catch (error) {
      console.log("Err===>", error);
    }
  }, []);

  return (
    <div className="work_order_home_page" ref={componentRef}>
      <div className="ticket_page">
        <div className="workOrder">
          <Box className="workOrder_header">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="p">
                {workOrderSpecificDetails?.is_additional_services
                  ? "Worksheet"
                  : "Job Card"}
              </Typography>
              <FileDownloadIcon
                sx={{ cursor: "pointer" }}
                onClick={handlePrint}
              />
            </Box>
          </Box>

          <Typography variant="h6" sx={{ px: 2, mt: 2, mb: 0 }}>
            <b> Work Order</b> : {workOrderSpecificDetails?.work_order_id}
          </Typography>

          <Box>
            <Box className="customerDetails" sx={{ mt: 2 }}>
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Customer Details</b>
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="h6" className="customer-details">
                        <b>Customer ID</b> :{" "}
                        {workOrderSpecificDetails?.customer_id}
                      </Typography>
                      <Typography variant="h6" className="customer-details">
                        <b>Customer Name</b> :{" "}
                        {workOrderSpecificDetails?.owner_name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Barcode
                      className="barcode"
                      value={workOrderSpecificDetails?.barCode}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box className="customerDetails">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Product Info</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" className="customer-details">
                  <b>Job Name</b>: {workOrderSpecificDetails?.job_name}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>PO Number </b>: {workOrderSpecificDetails?.po_number}
                </Typography>

                <Typography variant="h6" className="customer-details">
                  <b>Number of Pieces</b> :{workOrderSpecificDetails?.pieces}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Trays</b> : {workOrderSpecificDetails?.trays}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Pallets</b> : {workOrderSpecificDetails?.pallets}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Mailing Date</b> : {workOrderSpecificDetails?.mailing_date}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Delivery Date </b> :{" "}
                  {workOrderSpecificDetails?.pick_up_date}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Special Processing Notes</b> :{" "}
                  {workOrderSpecificDetails?.special_processing_notes}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box className="customerDetails">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Additional Information</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" className="customer-details">
                  <b>Date Of Print</b>:{" "}
                  {moment(workOrderSpecificDetails?.date_of_print).format(
                    "YYYY-MM-DD"
                  )}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Operation Name </b>:{" "}
                  {workOrderSpecificDetails?.operation_name}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Department Number</b> :
                  {workOrderSpecificDetails?.department_number}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Operation Description</b> :{" "}
                  {workOrderSpecificDetails?.operation_description}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Place to sign off the operation</b> :{" "}
                  {workOrderSpecificDetails?.place_to_sign_off_the_operation}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  <b>Date of sign off</b> :{" "}
                  {workOrderSpecificDetails?.date_of_sign_off}
                </Typography>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}
