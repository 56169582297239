import React from "react";
import {
  Stack,
  FormControl,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const CustomPagination = ({
  onPageChange,
  onPageSizeChange,
  page,
  pageSize,
  rowCount,
}) => {
  const pageSizes = [5, 10, 15];

  const handlePageChange = (newPage) => {
    onPageChange(newPage);
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    onPageSizeChange(newPageSize);
  };

  return (
    <Stack
      mt={1}
      className="pagination-wrap"
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <span>Rows per page:</span>
        <FormControl>
          <Select value={pageSize} onChange={handlePageSizeChange}>
            {pageSizes.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <span>
          {page &&
            pageSize &&
            rowCount &&
            `${(page - 1) * pageSize + 1}-${Math.min(
              page * pageSize,
              rowCount
            )} of ${rowCount}`}
        </span>
      </Stack>
      <Stack
        className="pagination-btn-wrap"
        direction="row"
        spacing={1}
        alignItems="center"
      >
        <IconButton
          disabled={page === 1}
          onClick={() => handlePageChange(page - 1)}
        >
          <ArrowBackIos />
        </IconButton>
        <IconButton
          disabled={page * pageSize >= rowCount}
          onClick={() => handlePageChange(page + 1)}
        >
          <ArrowForwardIos />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default CustomPagination;
