import React from "react";
import { RoleModalProps } from "../types";
import {
  Box,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { customerIcons } from "../icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "-webkit-fill-available",
  bgcolor: "background.paper",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  borderRadius: "10px",
  p: 0,
};
const RolesModal: React.FC<RoleModalProps> = ({
  open,
  onClose,
  onSubmit,
  roles,
  handleChange,
  title,
  buttonText,
}) => {
  return (
    <Box>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            sx={{ mb: 3 }}
            className="createRoleHeader"
          >
            <b>{title}</b>
            <Button onClick={onClose} className="closeButton">
              {" "}
              <img
                src={customerIcons.CloseIcon}
                className="closeicon"
                alt="closebtn"
              />
            </Button>
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={onSubmit}
            sx={{ mt: 1 }}
            className="createFormDetails"
          >
            <TextField
              size="small"
              label="Role Name"
              sx={{ my: 2, mb: 0, width: "100%" }}
              name="role_name"
              value={roles?.role_name || ""}
              onChange={handleChange}
            />

            <FormControl fullWidth sx={{ mt: 3 }} size="small">
              <InputLabel id="demo-simple-select-label">
                Account Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="for_name"
                name="account_status_id"
                defaultValue={roles?.account_status_id || ""}
                onChange={handleChange}
              >
                <MenuItem value="y">Active</MenuItem>
                <MenuItem value="n">Inactive</MenuItem>
              </Select>
            </FormControl>
            <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
              <Button
                disabled={!roles?.role_name || !roles?.account_status_id}
                variant="outlined"
                className="Btn"
                type="submit"
              >
                {buttonText}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default RolesModal;
