import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { RolesService } from "../../../services/RolesService";
import { toast } from "react-toastify";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Trash Bin Trash.svg";
import { ReactComponent as AddRoleIcon } from "../../../assets/icons/Masked_Icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import Loader from "../../../components/Loader";
import { MSPUserCreationService } from "../../../services/MSPUserCreation";
import CustomToolBar from "../../../components/customToolbar/customToolbar";
import CustomPagination from "../../../components/customPagination/custompagination";
import DummyProfileImage from "../../../assets/images/hello_robot.png";
import UserModal from "./UserModal";
import { PermissionList, RolesDetails } from "./type";

export default function UserCreation() {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [rowCount, setRowCount] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    contact_email: "",
    role_name: "",
    contact_number: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postal_code: "",
  });
  const [rolesDetails, setRolesDetails] = useState<RolesDetails[]>([]);
  const [usersList, setUsersList] = useState([]);
  const [permissionList, setPermissionList] = useState<PermissionList | null>(
    null
  );
  const [deleteId, setDeleteId] = useState();

  const columns = [
    {
      field: "full_name",
      headerName: "Full Name",
      width: 300,
      renderCell: (params) => {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <img
              alt="mspListImage"
              src={
                params.row.image_base64
                  ? `data:image/png;base64,${params.row.image_base64}`
                  : DummyProfileImage
              }
              className="mspListImage"
            />
            <span>{`${params.row?.first_name ?? ""} ${
              params.row?.last_name ?? ""
            }`}</span>
          </Box>
        );
      },
    },

    { field: "contact_email", headerName: "Email", width: 300 },
    {
      field: "contact_number",
      headerName: "Contact Number",
      width: 250,
      renderCell: (params) => {
        const formattedNumber = formatNumber(params.row.contact_number);
        return <span>{formattedNumber}</span>;
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => {
        let index = usersList.findIndex(
          (x) => x.account_guid === params.row.account_guid
        );
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            {permissionList != null &&
              permissionList.mo_role_group_function_mapping_list
                ?.role_update_flag === 1 && !params.row.is_logged_user && (
                <EditIcon
                  className="EditIcon"
                  onClick={() => handleEditOpen(index, params.row.account_guid)}
                />
              )}

            {permissionList != null &&
              permissionList.mo_role_group_function_mapping_list
                ?.role_delete_flag === 1 && !params.row.is_logged_user && (
                <DeleteIcon
                  className="deleteIcon"
                  onClick={() => handleDeleteOpen(params.row.account_guid)}
                />
              )}
          </Box>
        );
      },
      width: 300,
    },
  ];

  useEffect(() => {
    getUserList();
  }, [page, pageSize]);

  useEffect(() => {
    getRolePermissions();
    roleList();
  }, []);

  const getRolePermissions = async () => {
    try {
      const role_id = localStorage.getItem("role_id");
      const response = await RolesService.getMoRolePermissions(role_id);
      if (response.status === 200) {
        response.data.mo_role_group_function_mapping_list?.map((obj) => {
          obj.role_function_name === "Mail Owner Users" &&
            setPermissionList(obj);
        });
      }
    } catch (error) {
      console.log("Err==>", error);
    }
  };

  const getUserList = () => {
    try {
      setLoader(true);
      MSPUserCreationService.getMoUserCreationList({
        page: page,
        page_size: pageSize,
      })
        .then((res) => {
          const userList = res.data.user_account_list;
          setUsersList(userList);
          setRowCount(res.data.total_record);
          setLoader(false);
        })
        .catch((e) => {
          toast.error("Something went wrong");
          setLoader(false);
        });
    } catch (error) {
      console.error("Err===>", error);
      setLoader(false);
    }
  };

  const roleList = () => {
    RolesService.getMoRoles().then((res) => {
      const roleList = res.data.mail_owner_roles_list;
      debugger;
      setRolesDetails(roleList);
    });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  const handleEditOpen = (id, image) => {
    setEditModal(true);
    setUserDetails(usersList[id]);
  };

  const handleDeleteOpen = (userId) => {
    setDeleteModal(true);
    setDeleteId(userId);
  };
  const handleDeleteClose = () => setDeleteModal(false);

  const handleClose = (data) => {
    setOpen(false);
    setEditModal(false);
    setUserDetails({
      first_name: "",
      last_name: "",
      contact_email: "",
      role_name: "",
      contact_number: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      postal_code: "",
    });
    setDeleteModal(false);
    data && getUserList();
  };

  const deleteData = async () => {
    try {
      const formData = new FormData();
      formData.append("account_guid", deleteId);
      MSPUserCreationService.deleteMoUserList(formData)
        .then((res: any) => {
          if (res.status === 200) {
            toast.success("User Deleted Successfully");
            getUserList();
          }
        })
        .catch((e) => toast.error("Something went wrong"));
    } catch (error) {
      console.log("Err===>", error);
    }

    handleDeleteClose();
  };

  const formatNumber = (phoneNo) => {
    return phoneNo.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3");
  };
  function NoRowsOverlay() {
    return null;
  }

  return (
    <div className="roles mspcreation">
      {loader && <Loader />}

      <Box display="flex" justifyContent="end">
        {permissionList != null &&
          permissionList.mo_role_group_function_mapping_list
            ?.role_create_flag === 1 && (
            <Button
              variant="contained"
              sx={{ fontWeight: "bold" }}
              onClick={() => setOpen(true)}
              className="Btn"
            >
              <AddRoleIcon />  User Creation
            </Button>
          )}
      </Box>

      <Grid container>
        <Grid item md={12}>
          <Box
            style={{ height: 400, width: "100%", margin: "20px 0" }}
            className="userTable"
          >
            <DataGrid
              className="datagrid gridToolbar"
              rows={usersList}
              getRowId={(row) => row.account_guid}
              columns={columns}
              slots={{
                noRowsOverlay: NoRowsOverlay,
                toolbar: CustomToolBar,
                pagination: (props) => (
                  <CustomPagination
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    page={page}
                    pageSize={pageSize}
                    rowCount={rowCount}
                  />
                ),
              }}
              slotProps={{
                toolbar: {
                  toolbarWished: {
                    columns: true,
                    filter: true,
                    density: true,
                    export: true,
                  },
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {open && (
        <UserModal
          open={open}
          rolesDetails={rolesDetails}
          onClose={handleClose}
        />
      )}

      {editModal && (
        <UserModal
          open={editModal}
          rolesDetails={rolesDetails}
          userDetails={userDetails}
          onClose={handleClose}
        />
      )}

      {deleteModal && (
        <Modal
          open={deleteModal}
          onClose={handleDeleteClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="roleCreateModal"
        >
          <Box className="user-delete-modal">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 1, p: 2 }}
            >
              <b>Confirm Delete ? </b>
            </Typography>
            <Box sx={{ mt: 1, px: 2 }}>
              <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
                <Button
                  onClick={handleDeleteClose}
                  variant="outlined"
                  // className="createBtn"
                  type="submit"
                >
                  No
                </Button>
                <Button
                  onClick={deleteData}
                  variant="outlined"
                  className="Btn"
                  type="submit"
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
}
