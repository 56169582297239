import React, { useState } from "react";
import {
  Modal,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Box,
  InputLabel,
  FormControl,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow:
    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
  borderRadius: "10px",
  p: 2,
};

const AddMailingModal = ({ open, close }) => {
  const [checkboxes, setCheckboxes] = useState({
    create_mailing: false,
    create_new_template: false,
    create_from_existing_template: false,
    copy_mailing: false,
  });

  const [machinelist, setMachineList] = React.useState([]);
  const [product, setProduct] = useState("");

  const handleCheckboxChange = (event) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked,
    });
  };
  const handleChange = (event) => {
    // setAge(event.target.value);
  };

  const handleMachineChange = (event) => {
    const {
      target: { value },
    } = event;
    setMachineList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => close()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal mailingModal"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="create_mailing"
                    checked={checkboxes.create_mailing}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Create Mailing"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    name="create_new_template"
                    checked={checkboxes.create_new_template}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Create New Template"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    name="create_from_existing_template"
                    checked={checkboxes.create_from_existing_template}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Create from Existing Template"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    name="create_from_existing_template"
                    checked={checkboxes.copy_mailing}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Copy Mailing"
              />
            </Grid>
          </Grid>

          {checkboxes.create_mailing && (
            <Box>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="outlined-basic"
                    label="Mailing Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      label="Mailing Date"
                      name="mailing_date"
                      minDate={dayjs(new Date())}
                      renderInput={(params) => <TextField {...params} />}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Class</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>First Class</MenuItem>
                      <MenuItem value={20}>Marketing</MenuItem>
                      <MenuItem value={30}>BPM</MenuItem>
                      <MenuItem value={30}>Library</MenuItem>
                      <MenuItem value={30}>Media</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Product
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      onChange={(e) => {
                        setProduct(e.target.value);
                      }}
                    >
                      <MenuItem value={10}>Letter </MenuItem>
                      <MenuItem value={20}>Card</MenuItem>
                      <MenuItem value={30}>Flat </MenuItem>
                      <MenuItem value={40}>Parcel</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>{" "}
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Site</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>Site 1</MenuItem>
                      <MenuItem value={20}>Site 2</MenuItem>
                      <MenuItem value={30}>Site 3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>{" "}
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Machine
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Machine"
                      multiple
                      value={machinelist}
                      onChange={handleMachineChange}
                    >
                      <MenuItem value={10}>Select All</MenuItem>
                      <MenuItem value={20}>Machine 1</MenuItem>
                      <MenuItem value={30}>Machine 2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>{" "}
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Sort Profile
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>Profile 1</MenuItem>
                      <MenuItem value={20}>Profile 2</MenuItem>
                      <MenuItem value={30}>Profile 3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Types of Mailing{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>
                        Informed Visibility / Promotions
                      </MenuItem>
                      <MenuItem value={20}>Matchback / Validation</MenuItem>
                      <MenuItem value={30}>Dynamic Account matching</MenuItem>
                      <MenuItem value={40}>
                        Additional Services: Metering / Permit
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="postcard_as_separate_mailing"
                        checked={checkboxes.postcard_as_separate_mailing}
                        onChange={handleCheckboxChange}
                        disabled={product == "10" ? false : true}
                      />
                    }
                    label="Postcard as separate mailing"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="machinable_as_separate_mailing"
                        // checked={checkboxes.postcard_as_separate_mailing}
                        // onChange={handleCheckboxChange}
                        // disabled={product == "10" ? false : true}
                      />
                    }
                    label="Machinable as separate mailing"
                  />
                </Grid>
              </Grid>
              <Box className="mailing-modal-control" sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={close}>
                  Cancel
                </Button>

                <Button variant="contained" className="Btn" onClick={close}>
                  Save
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default AddMailingModal;
