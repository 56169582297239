import React from "react";
import { Navigate } from "react-router-dom";
import Homepage from "../pages/home";
import CustomerSignIN from "../pages/Customer-portal/sign-in";
import Authorization from "../demo/Authorization";
import WelcomePage from "../demo/Welcomepage";
import AuthSignUp from "../demo/SignUp1";
import Twofactor from "../demo/SignUp2";
import GoogleLoginPage from "../demo/2FALogin";

const PubicRoutes = [
  { path: "/", element: <Homepage /> },
  { path: "/sign-in", element: <CustomerSignIN /> },
  { path: "*", element: <Navigate to="/" /> },
  { path: "/authorization", element: <Authorization /> },
  { path: "/welcomepage", element: <WelcomePage /> },
  { path: "/auth-sign-up", element: <AuthSignUp /> },
  { path: "/auth-two-factor", element: <Twofactor /> },
  { path: "/auth-two-factor-login", element: <GoogleLoginPage /> },
];
export default PubicRoutes;
