import React, { useEffect, useState } from "react";
import UndoIcon from "@mui/icons-material/Undo";
import {
  FormControl,
  Grid,
  Paper,
  TextField,
  IconButton,
  Box,
  Button,
  InputLabel,
  Select,
  OutlinedInput,
  Typography,
  MenuItem,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import { apiBaseUrl } from "../../../config";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Custom({ handleChange, customValue }) {
  const [customAccount, setCustomAccount] = useState([]);
  const isAuth = localStorage.getItem("auth");

  function fetchCustomAccountBarcode() {
    try {
      axios
        .get(`${apiBaseUrl}get_custom_account_barcode`, {
          headers: {
            Authorization: "Bearer " + isAuth,
          },
        })
        .then((res) => {
          setCustomAccount(res.data.custom_account_barcode);
        });
    } catch (error) {
      console.log("Err==>", error);
    }
  }
  useEffect(() => {
    fetchCustomAccountBarcode();
  }, []);
  return (
    <div className="classtab accountEditorTabs">
      <Grid
        container
        spacing={2}
        justifyContent="center"
        className="accountDetails"
      >
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6} xl={6}>
                <FormControl className="revert-option">
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Custom Account"
                    variant="outlined"
                    className="sequence"
                    name="custom_account"
                    defaultValue={customValue.custom_account}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleChange(e, "custom")}
                  />
                  <IconButton size="small" className="revert-btn">
                    <UndoIcon />
                  </IconButton>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={6} xl={6}>
                <FormControl className="revert-option">
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Driver Route"
                    variant="outlined"
                    className="sequence"
                    name="driver_route"
                    defaultValue={customValue.driver_route}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleChange(e, "custom")}
                  />
                  <IconButton size="small" className="revert-btn">
                    <UndoIcon />
                  </IconButton>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={6} xl={6}>
                <FormControl className="revert-option">
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Custom One"
                    variant="outlined"
                    className="sequence"
                    name="custom_1"
                    defaultValue={customValue.custom_1}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleChange(e, "custom")}
                  />
                  <IconButton size="small" className="revert-btn">
                    <UndoIcon />
                  </IconButton>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={6} xl={6}>
                <FormControl className="revert-option">
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Custom Two"
                    variant="outlined"
                    className="sequence"
                    name="custom_2"
                    defaultValue={customValue.custom_2}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleChange(e, "custom")}
                  />
                  <IconButton size="small" className="revert-btn">
                    <UndoIcon />
                  </IconButton>
                </FormControl>
              </Grid>
            </Grid>

            <Box className="additional-fields">
              <Box
                sx={{ my: 1, alignItems: "center" }}
                className="revert-option"
              >
                <Checkbox
                  name="key_line_required"
                  defaultChecked={customValue.key_line_required}
                  onChange={(e) => handleChange(e, "custom")}
                />
                <Box className="metered-check-boxes">
                  <Box>
                    {/* <Checkbox /> */}

                    <Typography variant="outlined">
                      Keyline Required?
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton size="small" className="revert-btn">
                      <UndoIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Typography variant="p" className="additional-fields-header">
                Keyline
              </Typography>

              <Grid spacing={2}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <FormControl className="revert-option" sx={{ mt: 2 }}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      label="Keyline Pattern"
                      variant="outlined"
                      className="sequence"
                      name="key_line_pattern"
                      defaultValue={customValue.key_line_pattern}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => handleChange(e, "custom")}
                    />
                    <IconButton size="small" className="revert-btn">
                      <UndoIcon />
                    </IconButton>
                  </FormControl>
                </Grid>
                <Grid xs={6} md={4} lg={4} xl={4} sx={{ mt: 2 }}>
                  <Button
                    sx={{
                      color: "white",
                      textWrap: "nowrap",
                      fontSize: "12px",
                    }}
                    className="Btn"
                    variant="outlined"
                    fullWidth
                  >
                    Test Pattern
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2} sx={{ my: 2 }}>
              <Grid item xs={12} md={12} lg={6} xl={6}>
                <FormControl className="revert-option">
                  <InputLabel
                    shrink
                    id="demo-multiple-name-label"
                    className="profilelable"
                    sx={{ pr: 1, top: "-7px" }}
                  >
                    Custom Account Barcode Type
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    size="small"
                    onChange={(e) => handleChange(e, "custom")}
                    input={
                      <OutlinedInput label="Custom Account Barcode Type" />
                    }
                    MenuProps={MenuProps}
                    sx={{ flexGrow: "1" }}
                    name="custom_account_barcode_type_id"
                    defaultValue={customValue.custom_account_barcode_type_id}
                  >
                    {customAccount.map((item) => (
                      <MenuItem
                        key={item.custom_account_barcode_id}
                        value={item.custom_account_barcode_id}
                      >
                        {item.custom_account_barcode_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={6} xl={6}>
                <FormControl className="revert-option">
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Confirm Account Number"
                    variant="outlined"
                    className="sequence"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
