import UserActive from "../assets/icons/eiimenuicons/User - Filled White.svg";
import User from "../assets/icons/eiimenuicons/User - Outline White.svg";
import SitesActive from "../assets/icons/eiimenuicons/Sites White-1.svg";
import Sites from "../assets/icons/eiimenuicons/Sites White.svg";
import MachinesActive from "../assets/icons/eiimenuicons/Machine  - Filled - White.svg";
import Machines from "../assets/icons/eiimenuicons/Machine  - Outline - White.svg";
import MailOwnerActive from "../assets/icons/eiimenuicons/Mail Owner - Filled White.svg";
import MailOwner from "../assets/icons/eiimenuicons/Mail Owner - Outline White.svg";
import SortProfileActive from "../assets/icons/eiimenuicons/Sort Profiles - Filled White.svg";
import SortProfile from "../assets/icons/eiimenuicons/Sort Profiles - Outline White.svg";
import MailArchivingActive from "../assets/icons/eiimenuicons/Mailing Archiving - Filled White.svg";
import MailArchiving from "../assets/icons/eiimenuicons/Mailing Archiving - Outline White.svg";
import MailingsActive from "../assets/icons/eiimenuicons/Mailing - Filled White.svg";
import Mailings from "../assets/icons/eiimenuicons/Mailing - Outline White.svg";
import TrayTagsActive from "../assets/icons/eiimenuicons/Tray Tags - Filled White.svg";
import TrayTags from "../assets/icons/eiimenuicons/Tray Tags - Outline White.svg";
import PalletPlacardActive from "../assets/icons/eiimenuicons/Pallet Placard - Filled White.svg";
import PalletPlacard from "../assets/icons/eiimenuicons/Pallet Placard - Outline White.svg";
import HelmActive from "../assets/icons/eiimenuicons/Helm - Filled White.svg";
import Helm from "../assets/icons/eiimenuicons/Helm - Outline White.svg";
import PostalReportsActive from "../assets/icons/eiimenuicons/Postal Reports - Filled - White.svg";
import PostalReports from "../assets/icons/eiimenuicons/Postal Reports - Outline  White.svg";
import BillingReportsActive from "../assets/icons/eiimenuicons/Billing Reports - Filled White.svg";
import BillingReports from "../assets/icons/eiimenuicons/Billing Reports - Outline White.svg";
import ProductivityReportsActive from "../assets/icons/eiimenuicons/Productivity Reports - Filled White.svg";
import ProductivityReports from "../assets/icons/eiimenuicons/Productivity Reports - Outline White.svg";
import MachineReportActive from "../assets/icons/eiimenuicons/Machine Reports - Filled White.svg";
import MachineReports from "../assets/icons/eiimenuicons/Machine Reports - Outline  White.svg";
import PiecesActive from "../assets/icons/eiimenuicons/Pieces - Filled  White.svg";
import Pieces from "../assets/icons/eiimenuicons/Pieces - Outline  White.svg";
import ReceivingActive from "../assets/icons/eiimenuicons/Receiving - Filled White.svg";
import Receiving from "../assets/icons/eiimenuicons/Receiving - Outline White.svg";
import ConfigurationActive from "../assets/icons/eiimenuicons/Configuration -  Filled-2.svg";
import Configuration from "../assets/icons/eiimenuicons/Configuration -  Outline-2.svg";
import ToolsActive from "../assets/icons/eiimenuicons/Tools - Filled White.svg";
import Tools from "../assets/icons/eiimenuicons/Tools - Outline White.svg";
import ReportingActive from "../assets/icons/eiimenuicons/Reports - Filled White.svg";
import Reporting from "../assets/icons/eiimenuicons/Reports - Outline White.svg";
import ProductionActive from "../assets/icons/eiimenuicons/Production - Filled White.svg";
import Production from "../assets/icons/eiimenuicons/Production - Outline White.svg";
import JobsActive from "../assets/icons/eiimenuicons/Jobs - Filled White.svg";
import Jobs from "../assets/icons/eiimenuicons/Jobs - Outline White.svg";
import GenerateDatActive from "../assets/icons/eiimenuicons/Genrate mail.dat - Filled White.svg";
import GenerateDat from "../assets/icons/eiimenuicons/Genrate mail.dat - Outline White.svg";
import RoleMaintenance from "../assets/icons/eiimenuicons/Ell Role Maintenance - Outline - White.svg";
import RoleMaintenanceActive from "../assets/icons/eiimenuicons/Ell Role Maintenance - Filled - White.svg";
import MSPCreation from "../assets/icons/eiimenuicons/MSP Creation - Outline - White.svg";
import MSPCreationActive from "../assets/icons/eiimenuicons/MSP Creation - Filled - White.svg";
import EiiUser from "../assets/icons/eiimenuicons/EII Users - Outline - White.svg";
import EiiUserActive from "../assets/icons/eiimenuicons/EII Users  - Filled - White.svg";
const mspIcons = {
  UserActive,
  User,
  SitesActive,
  Sites,
  MachinesActive,
  Machines,
  MailOwnerActive,
  MailOwner,
  SortProfileActive,
  SortProfile,
  MailArchivingActive,
  MailArchiving,
  MailingsActive,
  Mailings,
  TrayTagsActive,
  TrayTags,
  PalletPlacardActive,
  PalletPlacard,
  HelmActive,
  Helm,
  PostalReportsActive,
  PostalReports,
  BillingReportsActive,
  BillingReports,
  ProductivityReportsActive,
  ProductivityReports,
  MachineReportActive,
  MachineReports,
  PiecesActive,
  Pieces,
  ReceivingActive,
  Receiving,
  ConfigurationActive,
  Configuration,
  ToolsActive,
  Tools,
  ReportingActive,
  Reporting,
  ProductionActive,
  Production,
  JobsActive,
  Jobs,
  GenerateDatActive,
  GenerateDat,
  RoleMaintenance,
  RoleMaintenanceActive,
  MSPCreation,
  MSPCreationActive,
  EiiUser,
  EiiUserActive,
};
export default mspIcons;
