import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import Logo from "../../assets/images/Apian_Combo-Logo_White 1.png";
import Popover from "@mui/material/Popover";
import "../../style/style.css";
const drawerWidth = 240;

const HomeHeader = () => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    navigate("/sign-in");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        className={`home-header ${isScrolled ? "scrolled" : ""}`}
        sx={{ py: { lg: 2, xl: 2 } }}
      >
        <div className="container">
          <Toolbar
            className="headerToolbar"
            sx={{
              justifyContent: {
                xs: "space-between",
                md: "space-between",
                lg: "space-between",
                xl: "space-between",
              },
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                fontSize: { lg: "25px", xl: "28px" },
              }}
              className="logo"
            >
              <Link to="/" component={RouterLink}>
                <img src={Logo} alt="login" />
              </Link>
            </Typography>
            <Box
              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
              className="nav"
            ></Box>
            <Box className="btn-wrap">
              <Button
                color="secondary"
                variant="outlined"
                // onClick={() =>
                //   navigate(`/customer-portal/sign-in`, { replace: true })
                // }
                onClick={handleClick}
              >
                Login
              </Button>
            </Box>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className="more"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { xs: "block", md: "none", sm: "block" },
              }}
            >
              <MenuIcon />
            </IconButton> */}
          </Toolbar>
        </div>
      </AppBar>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ mt: 1 }}
      >
        <Typography
          onClick={() => navigate(`/eii/sign-in`, { replace: true })}
          className="loginMenu"
        >
          EII
        </Typography>
        <Typography
          onClick={() => navigate(`/msp/sign-in`, { replace: true })}
          className="loginMenu"
        >
          MSP
        </Typography>
        <Typography
          onClick={() =>
            navigate(`/customer-portal/sign-in`, { replace: true })
          }
          className="loginMenu"
        >
          Customer
        </Typography>
      </Popover>

      {/* <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer> */}
    </Box>
  );
};
export default HomeHeader;
