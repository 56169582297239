import React, { useState } from "react";
import { Menu, Button, Box } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

const FilterWidgets = ({ layoutProps, setchecked }) => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const [checked, setChecked] = useState([]);
  const [layout, setLayout] = useState(layoutProps);
  const openFilter = Boolean(filterAnchorEl);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);

    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  //   React.useEffect(() => {
  //     setchecked(checked);
  //   }, [checked]);
  const handleFilterSubmit = () => {
    setchecked(checked);
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={openFilter ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openFilter ? "true" : undefined}
        onClick={handleFilterClick}
        title="Filter Widgets"
      >
        <FilterAltIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={filterAnchorEl}
        open={openFilter}
        onClose={handleFilterClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {layout.map((value) => {
              const labelId = `checkbox-list-label-${value.i}`;

              return (
                <ListItem key={value} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={handleToggle(value.i)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(value.i) !== -1}
                        //   tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value.gridname}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={handleFilterSubmit}>
              Filter
            </Button>
          </Box>
        </div>
      </Menu>
    </div>
  );
};

export default FilterWidgets;
