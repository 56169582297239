import React, { forwardRef, Ref, useEffect, useReducer } from "react";
import "./mspModal.scss";
import {
  Modal,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  TextField,
  RadioGroup,
  Box,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  SelectChangeEvent,
} from "@mui/material";
import { toast } from "react-toastify";
import { styled } from "@mui/system";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CustomInputProps, InitialStateProps, MspModalProps } from "./type";
import CloseIcon from "../../../assets/icons/Close Square.svg";
import { MspService } from "../../../services/MspService";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
  "& .form-control": {
    width: "100%",
    height: "40px",
    fontSize: "14px",
    marginTop: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  "& .form-control.error": {
    border: "1px solid red",
  },
}));

const CustomPhoneInput = forwardRef(
  ({ viewable, editable, ...props }: CustomInputProps, ref: Ref<any>) => {
    return (
      <StyledPhoneInput
        {...props}
        specialLabel="Contact Person Number"
        value={viewable || editable || ""}
        //@ts-ignore
        inputComponent={({ ...inputProps }) => (
          <TextField
            {...inputProps}
            inputRef={ref}
            size="small"
            sx={{ my: 2 }}
            required
            label="Contact Person Number"
            variant="outlined"
            fullWidth
            autoComplete="off"
          />
        )}
      />
    );
  }
);
const initialState: InitialStateProps = {
  company_name: "",
  first_name: "",
  last_name: "",
  site_name: "",
  contact_no: "",
  email: "",
  site_url: "",
  enroll_plan: "",
  password: "",
  role_id: 0,
  guid: "",
  image: "",
  imageBlob: "",
};
const reducer = (state: InitialStateProps, action: any) => {
  switch (action.type) {
    case "HANDLE CHANGE":
      return { ...state, [action.field]: action.payload };
    case "UPDATE":
      return { ...action.payload };
    default:
      return state;
  }
};
const MspModal: React.FC<MspModalProps> = ({
  open,
  handleClose,
  editMspDetails,
  viewMspDetails,
}) => {
  const [user, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let mspDetails = editMspDetails || viewMspDetails;

    if (!mspDetails) {
      generateRandomPassword();
    } else {
      mspDetails.contact_no = mspDetails.contact_phone;
      mspDetails.uploadImage = mspDetails.image;
      mspDetails.image = "";
      dispatch({
        type: "UPDATE",
        payload: mspDetails,
      });
    }
  }, [editMspDetails, viewMspDetails]);
  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "HANDLE CHANGE",
      field: "image",
      payload: e.target.files[0],
    });

    dispatch({
      type: "HANDLE CHANGE",
      field: "imageBlob",
      payload: URL.createObjectURL(e.target.files[0]),
    });
  };
  const generateRandomPassword = () => {
    let Genpassword = Math.random().toString(20).substring(2, 8);
    dispatch({
      type: "HANDLE CHANGE",
      field: "password",
      payload: Genpassword,
    });
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<any>
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
    if (name === "msp_name") {
      dispatch({
        type: "HANDLE CHANGE",
        field: "site_url",
        payload: value,
      });
      dispatch({
        type: "HANDLE CHANGE",
        field: "company_name",
        payload: value,
      });
    }
    dispatch({
      type: "HANDLE CHANGE",
      field: name,
      payload: value,
    });
  };

  const handlePhoneNumber = (value: string | number) => {
    dispatch({
      type: "HANDLE CHANGE",
      field: "contact_no",
      payload: value,
    });
  };
  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      if (
        !user.first_name ||
        !user.last_name ||
        !user.site_name ||
        (!editMspDetails && !user.email) ||
        !user.company_name ||
        !user.contact_no ||
        (!editMspDetails && !user.enroll_plan) ||
        (!editMspDetails && !user.image) ||
        (!editMspDetails && !user.site_url) ||
        (!editMspDetails && !user.password) ||
        (!editMspDetails && !user.site_name)
      ) {
        toast.error("Please enter the details");
        return false;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidNumber = /^\d{10}$/.test(user.contact_no);

      if (!editMspDetails && !emailRegex.test(user.email)) {
        toast.error("Please enter the valid email");
        return false;
      }

      if (!isValidNumber) {
        toast.error("Please enter the valid phone number");
        return false;
      }

      const formData = new FormData();
      const roleId = 3;
      formData.append(
        "msp_name",
        !editMspDetails ? user.msp_name : user.company_name
      );
      formData.append("first_name", user.first_name);
      formData.append("last_name", user.last_name);
      formData.append("site_name", user.site_name);

      user.image && formData.append("company_logo", user.image);
      formData.append("sub_domain", user.site_url.replace(/\s/g, ""));
      formData.append(
        "site_url",
        `​
https://Eii-online/apiansort/${user.site_url.replace(/\s/g, "")}`
      );
      formData.append("enroll_plan", user.enroll_plan);
      formData.append("role_id", roleId.toString());

      if (editMspDetails) {
        formData.append("msp_account_guid", user.guid);
        formData.append("contact_phone", user.contact_no);

        MspService.mspUpdation(formData)
          .then((res: any) => {
            if (res.status === 200) {
              toast.success("MSP Updated Successfully");
              handleClose("success");
            }
          })
          .catch((e) => {
            toast.error(e.response?.detail?.msg || "Something went wrong");
          });
      } else {
        formData.append("email", user.email);
        formData.append("password", user.password);
        formData.append("contact_no", user.contact_no);

        MspService.mspcreation(formData)
          .then((res: any) => {
            if (res.status === 200) {
              toast.success("MSP Created Successfully");
              handleClose("success");
            }
          })
          .catch((e) => {
            toast.error(e.data?.detail || "Something went wrong");
          });
      }
    } catch (error) {
      console.log("Error==>", error);
    }
  };

  console.log("user==>", user);


  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal"
      >
        <Box className="msp-creation-updation-modal">
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h6"
            sx={{ mb: 2 }}
            className="mspRoleHeader"
          >
           <b> {(editMspDetails &&
              Object.keys(editMspDetails).length > 0 &&
              "Update MSP") ||
              (viewMspDetails &&
                Object.keys(viewMspDetails).length > 0 &&
                "View MSP") ||
              "Create MSP"}</b>
            <Button className="closeButton" onClick={() => handleClose()}>
              {" "}
              <img src={CloseIcon} className="closeicon" alt="closeicon" />
            </Button>
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            className="mspDetailsForm"
          >
            <TextField
              size="small"
              label="MSP Name"
              sx={{ my: 2, width: "100%" }}
              name="msp_name"
              required
              inputProps={{
                maxLength: 50,
                readOnly: !!viewMspDetails,
              }}
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={user.company_name}
            />

            <Button
              sx={{ my: 1}}
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              className="uploadBtn"
              disabled={!!viewMspDetails}
            >
              Upload Logo *
              <VisuallyHiddenInput
                required
                type="file"
                onChange={(e) => uploadImage(e)}
              />
            </Button>
            {!editMspDetails && !viewMspDetails && user.imageBlob && (
              <Box my={2}>
                <Box className="imgPreviewContainer">
                  <img src={user?.imageBlob} alt="Logo" />
                </Box>
              </Box>
            )}
            {editMspDetails || viewMspDetails ? (
              <Box my={2}>
                <Box className="imgPreviewContainer">
                  <img
                    src={
                      user.imageBlob
                        ? user.imageBlob
                        : `data:image/png;base64,${user.uploadImage}`
                    }
                    alt="Logo"
                  />
                </Box>
              </Box>
            ) : (
              ""
            )}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="First Name"
                  required
                  sx={{ my: 2, width: "100%" }}
                  name="first_name"
                  inputProps={{
                    maxLength: 50,
                    readOnly: !!viewMspDetails,
                  }}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  value={user.first_name}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="Last Name"
                  required
                  sx={{ my: 2, width: "100%" }}
                  name="last_name"
                  inputProps={{
                    maxLength: 50,
                    readOnly: !!viewMspDetails,
                  }}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  value={user.last_name}
                />
              </Grid>
            </Grid>

            <CustomPhoneInput
              defaultCountry="us"
              onlyCountries={["us"]}
              disableCountryCode
              country="us"
              disableDropdown={true}
              onChange={handlePhoneNumber}
              inputProps={{
                name: "contact_no",
                required: true,
                readOnly: !!viewMspDetails,
              }}
              editable={user.contact_no}
            />
            <Grid container spacing={2} sx={{mt:1}}>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  size="small"
                  label="MSP Role "
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  name="role_id"
                  disabled
                  defaultValue="MSP Admin"
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                fullWidth
                  size="small"
                  label="Site Name"
                  InputLabelProps={{ shrink: true }}
                  name="site_name"
                  inputProps={{
                    maxLength: 50,
                    readOnly: !!viewMspDetails,
                  }}
                  onChange={handleChange}
                  value={user.site_name}
                />
              </Grid>
            </Grid>
            {!editMspDetails && !viewMspDetails && (
              <TextField
                size="small"
                type="email"
                label="Admin Email ID"
                sx={{ my: 2, width: "100%" }}
                name="email"
                onChange={handleChange}
                required
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  maxLength: 120,
                }}
                //change this for viewable
                // defaultValue={editMspDetails && user.admin_email}
              />
            )}
            {!editMspDetails && !viewMspDetails && (
              <TextField
                disabled
                helperText="Auto Generated Password"
                size="small"
                label="Password"
                value={user.password}
                sx={{ mt: 2, mb: 1, width: "100%" }}
                name="password"
                onChange={handleChange}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <IconButton
                //         edge="end"
                //         onClick={generateRandomPassword}
                //         sx={{ mr: 1 }}
                //       >
                //         <ReplayIcon />
                //       </IconButton>
                //       <IconButton onClick={handleCopyClipboard}>
                //         <ContentCopyIcon />
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
              />
            )}

            {!editMspDetails && (
              <FormControl>
                <Box className="subDomain">
                  <FormLabel id="demo-radio-buttons-group-label" required>
                    SubDomain
                  </FormLabel>
                  <TextField
                    fullWidth
                    name="site_url"
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    placeholder="Enter your subdomain name"
                    sx={{ mb: 2 }}
                    InputProps={{
                      readOnly: !!viewMspDetails,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className="inputAdornment"
                        >
                          https://Eii-online/apiansort/
                        </InputAdornment>
                      ),
                    }}
                    value={user.site_url?.replace(/\s/g, "")}
                  />
                </Box>
              </FormControl>
            )}
            {!editMspDetails && (
              <Box>
                <FormControl fullWidth>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      pointerEvents: viewMspDetails && "none",
                    }}
                  >
                    <FormLabel id="demo-radio-buttons-group-label" required>
                      Enrollment Plan
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="enroll_plan"
                      row
                      onChange={handleChange}
                      value={
                        editMspDetails
                          ? user.enroll_plan === 1
                            ? "Shared plan"
                            : "Dedicated plan"
                          : viewMspDetails && user.enroll_plan
                      }
                    >
                      <FormControlLabel
                        value="Shared plan"
                        control={<Radio size="small" />}
                        label="Shared "
                      />
                      <FormControlLabel
                        value="Dedicated plan"
                        sx={{ marginRight: 0 }}
                        control={<Radio size="small" />}
                        label="Dedicated "
                      />
                    </RadioGroup>
                  </Box>
                </FormControl>
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="end"
            gap={2}
            sx={{ paddingRight: "40px",mt:1 }}
          >
            {viewMspDetails ? (
              <Button
                variant="contained"
                className="createButton"
                onClick={() => {
                  handleClose();
                }}
                sx={{ backgroundColor: "#00344e" }}
                disableRipple
              >
                OK
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => handleClose()}
                  variant="outlined"
                  type="submit"
                  disableRipple
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="createButton"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  sx={{ backgroundColor: "#00344e" }}
                  disableRipple
                >
                  {editMspDetails ? "Update" : "Create"}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default MspModal;
