import { configureStore } from "@reduxjs/toolkit";
import MachineList from "./MachineList";
import WorkOrderCreation from "./WorkOrderCreation";
const store = configureStore({
  reducer: {
    machines: MachineList,
    workOrder: WorkOrderCreation,
  },
  // devTools: false,
});

export default store;
