import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import LandingIllustration from "../../assets/images/LandingIllustration.png";
const BGLANDING: string = require("../../assets/images/landingBanner.ddecb831be61b5beac37.webp");

// @ts-ignore

export default function Banner() {
  return (
    <div className="banner-wrap" id="home-section">
      <img src={BGLANDING} alt="" className="landing-banner" />
      <div>
        <img src={LandingIllustration} alt="" width="100%" />
      </div>
      <Grid container justifyContent="center">
        <Grid item md={10} xl={8}>
          <Box className="banner-content">
            <Typography variant="h3">
              Automated Mail and Parcel Sorting Equipment
            </Typography>
            <Typography variant="body1" sx={{ m: 0 }}>
              Respond precisely to your customers and the market with custom
              solutions for the postal and material handling industries.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <div className="landing-copyrights">
        <p>
          © 2024 Engineering Innovation, Inc - All Rights Reserved | Privacy
          Policy
        </p>
      </div>
    </div>
  );
}
