import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "../../../assets/icons/Close Square.svg";
import AddMspIcon from "../../../assets/icons/Masked_Icon.svg";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TabContext from "@mui/lab/TabContext";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { MspService } from "../../../services/MspService";
import { sitesService } from "../../../services/sitesService";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import CustomToolBar from "../../../components/customToolbar/customToolbar";
import CustomPagination from "../../../components/customPagination/custompagination";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Trash Bin Trash.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import axios from "axios";
import { apiBaseUrl } from "../../../config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px ",
};

export default function BasicTable() {
  const [addOpen, setAddOpen] = useState(false);
  const [value, setValue] = useState("1");

  const [siteOperationList, setSiteOperationList] = useState("");
  const [userList, setUserList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [Permissions, setPermissions] = React.useState({});
  const initialState = {
    msp_name: "",
    site_name: "",
    operations: "",
    users: [],
    admin_site: false,
    address: "",
    line_two: "",
    state: "",
    city: "",
    postal_code: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const token = localStorage.getItem("auth");
  const role_id = localStorage.getItem("role_id");

  const Permission = async () => {
    const response = await axios.get(
      `${apiBaseUrl}msp_role_group_function_mapping_list?role_id=${role_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      response.data.msp_role_group_function_mapping_list?.map((obj) => {
        obj.role_function_name === "Sites" && setPermissions(obj);
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("site_name", formData.site_name);
    // data.append("admin_site", formData.admin_site ? 1 : 0);
    data.append("user_list", formData.users?.join(","));
    data.append("site_op_id", formData.operations);

    data.append("state", formData.state);
    data.append("city", formData.city);
    data.append("postal_code", formData.postal_code);
    data.append("site_address_line_1", formData.address);
    data.append("site_address_line_2", formData.line_two);

    editID && data.append("site_guid", editID);

    if (editID) {
      try {
        await sitesService.siteUpdation(data).then((res) => {
          setFormData(initialState);
          setAddOpen(false);
          siteListEvent();
          toast.success("Site updated successfully:)");
        });
      } catch (e) {
        toast.error(e?.data?.detail || "Something went wrong");
      }
    } else {
      try {
        await sitesService.siteCreation(data).then((res) => {
          setFormData(initialState);
          setAddOpen(false);
          siteListEvent();
          toast.success("Site created successfully");
        });
      } catch (e) {
        toast.error(e?.data?.detail || "Something went wrong");
      }
    }
  };

  useEffect(() => {
    const isFormValid = () => {
      const { site_name, operations, users, admin_site } = formData;

      if (
        !site_name?.trim() ||
        !String(operations).trim() ||
        users.length === 0 ||
        typeof admin_site !== "boolean"
      ) {
        return false;
      }

      return true;
    };

    setIsSubmitEnabled(isFormValid());
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;

    setFormData((prevFormData) => ({
      ...prevFormData,
      users:
        typeof value === "string" ? (value ? value.split(",") : []) : value,
    }));
  };

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1); // Reset to first page
  };

  // If whole response rendered
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [editID, setEditID] = useState("");
  const [loader, setLoader] = useState(false);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "site_name", headerName: "Site Name", width: 500 },
    // {
    //   field: "admin",
    //   headerName: "Admin Site",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return params.row.admin_site === 1 ? (
    //       <CheckCircleOutlineIcon sx={{ color: "green" }} />
    //     ) : (
    //       <CancelIcon sx={{ color: "#cf0808" }} />
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
          {Permissions.msp_role_group_function_mapping_list
            ?.role_update_flag === 1 ? (
            <EditIcon
              className="EditIcon"
              onClick={() => handleEditOpen(params.row)}
            />
          ) : (
            ""
          )}
          {Permissions.msp_role_group_function_mapping_list
            ?.role_delete_flag === 1 ? (
            <DeleteIcon
              className="deleteIcon"
              onClick={() => handleDeleteOpen(params.row.site_guid)}
            />
          ) : (
            ""
          )}
        </Box>
      ),
    },
  ];

  const handleDeleteOpen = (ID) => {
    setDeleteOpen(true);
    setDeleteID(ID);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteID("");
  };

  const handleDeleteEvent = async () => {
    const data = new FormData();
    data.append("site_guid", deleteID);
    try {
      sitesService
        .siteDeletion(data)
        .then((res) => {
          siteListEvent();
          setDeleteOpen(false);
          setDeleteID("");
          toast.success("Site deleted successfully");
        })
        .catch((e) => {
          toast.error(e?.data?.detail || "Something went wrong");
        });
    } catch (e) {
      console.log("Err===>", e);
    }
  };

  const handleAddOpen = () => {
    setAddOpen(true);
    setFormData((prevData) => ({
      ...prevData,
      msp_name: localStorage.getItem("msp_name"),
    }));
  };

  const handleEditOpen = async (row) => {
    setAddOpen(true);
    let editData;
    try {
      editData = await sitesService.finalSiteList({
        site_id: row.id,
        auto_error: true,
      });
      editData = editData.data.site_list[0];

      setFormData({
        address: editData.site_address,
        postal_code: editData.postal_code,
        city: editData.city,
        state: editData.state,
        line_two: editData.address_line_2,
      });
    } catch (e) {
      toast.error("error", e);
    }

    setFormData((prevData) => ({
      ...prevData,
      site_name: row.site_name,
      admin_site: row.admin_site === 1 ? true : false,
      msp_name: localStorage.getItem("msp_name"),
      operations: editData?.operation_details[0]?.site_operation_id,
      users: editData.operation_details.map((item) => {
        return item.msp_account_id ? item.msp_account_id : "null";
      }),
    }));
    if (row) {
      setEditID(row.site_guid);
    }
  };

  const handleAddClose = (event) => {
    event.preventDefault();
    setFormData(initialState);
    setAddOpen(false);
    setEditID("");
  };

  useEffect(() => {
    siteListEvent();
  }, [page, pageSize]);

  useEffect(() => {
    SiteOperationEvent();
    MspUsersList();
    Permission();
    MspNameEvent();
  }, []);

  const siteListEvent = async () => {
    try {
      await sitesService
        .siteDetails({ page: page, page_size: pageSize })
        .then((res) => {
          const modifiedRes = res.data.site_list.map(
            ({ site_id, ...rest }) => ({
              id: site_id,
              ...rest,
            })
          );
          setRowCount(res.data.total_record);
          setSiteList(modifiedRes);
        })
        .catch((e) => {
          toast.error("Something went wrong");
        });
    } catch (e) {
      console.log("Err===>", e);
    }
  };

  const MspNameEvent = async () => {
    try {
      setFormData((prevData) => ({
        ...prevData,
        msp_name: localStorage.getItem("msp_name"),
      }));
    } catch (e) {
      console.log("Err===>", e);
    }
  };

  const SiteOperationEvent = async () => {
    try {
      setLoader(true);

      MspService.siteOperationList()
        .then((res) => {
          setSiteOperationList(res.data.site_operation);
          setLoader(false);
        })
        .catch((e) => {
          toast.error("Something went wrong");
          setLoader(false);
        });
    } catch (e) {
      console.log("Err===>", e);
      setLoader(false);
    }
  };

  const MspUsersList = async () => {
    try {
      MspService.sitesUsersDropdownList()
        .then((res) => {
          setUserList(res.data.user_account_list);
        })
        .catch((e) => toast.error("Something went wrong"));
    } catch (e) {
      console.log("Err===>", e);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <Stack direction="row" justifyContent="flex-end">
        {Permissions?.msp_role_group_function_mapping_list?.role_create_flag ===
          1 && (
          <Button variant="contained" onClick={handleAddOpen} className="Btn">
            <img src={AddMspIcon} height={25} width={25} alt="addmspicon" />
            New Site
          </Button>
        )}
      </Stack>

      <Grid container>
        <Grid item md={12}>
          <Box
            style={{
              height: 375,
              width: "100%",
              margin: "20px 0",
            }}
            className="roles userTable"
          >
            <DataGrid
              className="datagrid gridToolbar"
              localeText={{
                noRowsLabel: "No records Found",
              }}
              slotProps={{
                toolbar: {
                  toolbarWished: {
                    columns: true,
                    filter: true,
                    density: true,
                    export: true,
                  },
                },
              }}
              rows={siteList}
              columns={columns}
              disableSelectionOnClick
              slots={{
                pagination: (props) => (
                  <CustomPagination
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    page={page}
                    pageSize={pageSize}
                    rowCount={rowCount}
                  />
                ),

                Toolbar: CustomToolBar,
              }}
              sortModel={[
                {
                  field: "id",
                  sort: "desc",
                },
              ]}
              columnVisibilityModel={{
                id: false,
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={addOpen}
        className="modal-wrap site-creation-modal roleCreateModal blur-issue-sol  "
        onClose={handleAddClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={addOpen} className="msp-user-creation-modal-fade">
          <Box sx={style} className="msp-user-creation-modal blur-effect">
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              className="mspRoleHeader"
            >
              <b> {editID ? "Site Update" : "Site creation"}</b>
              <Button onClick={handleAddClose} className="closeButton">
                <img src={CloseIcon} className="closeicon" alt="closeicon" />
              </Button>
            </Typography>

            <Box className="msp-sites">
              {/* <TabContext value={value}> */}
              <Box
                component="form"
                noValidate
                // onSubmit={handleSubmit}
                className="mspDetailsForm"
              >
                <Grid
                  container
                  spacing={1}
                  className="sitecreationModal msp-site"
                >
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="MSP Name"
                      InputLabelProps={{ shrink: true }}
                      name="msp_name"
                      value={formData.msp_name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <Box>
                      <TextField
                        fullWidth
                        size="small"
                        label="Site Name"
                        InputLabelProps={{ shrink: true }}
                        name="site_name"
                        value={formData.site_name}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <FormControl fullWidth>
                      <InputLabel shrink id="demo-simple-select-label">
                        Operations
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="operations-select"
                        value={formData.operations}
                        label="Operations"
                        name="operations"
                        onChange={handleChange}
                        size="small"
                      >
                        {siteOperationList?.length &&
                          siteOperationList.map((e) => (
                            <MenuItem
                              key={e.site_operation_id}
                              value={e.site_operation_id}
                            >
                              {e.operation}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <FormControl fullWidth>
                      <InputLabel shrink id="demo-simple-select-label">
                        Users
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        className="checkboxList"
                        id="users-select"
                        multiple
                        size="small"
                        label="Users"
                        value={formData.users}
                        onChange={handleSelectChange}
                        input={<OutlinedInput label="Users" />}
                        renderValue={(selected) =>
                          selected
                            .map(
                              (id) =>
                                userList.find(
                                  (user) => user.account_guid === id
                                )?.contact_name
                            )
                            .join(",")
                        }
                      >
                        {userList.length &&
                          userList.map((user) => (
                            <MenuItem
                              className="userModal"
                              key={user.account_guid}
                              value={user.account_guid}
                            >
                              <Checkbox
                                checked={
                                  formData.users.indexOf(user.account_guid) > -1
                                }
                              />
                              <ListItemText primary={user.contact_name} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Address Line One"
                      InputLabelProps={{ shrink: true }}
                      name="address"
                      value={formData.address}
                      multiline
                      maxRows={4}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Address Line 2"
                      InputLabelProps={{ shrink: true }}
                      name="line_two"
                      value={formData.line_two}
                      multiline
                      maxRows={4}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="City"
                      value={formData.city}
                      InputLabelProps={{ shrink: true }}
                      name="city"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="State"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 2 }}
                      name="state"
                      onChange={handleChange}
                      value={formData.state}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Postal Code"
                      InputLabelProps={{ shrink: true }}
                      name="postal_code"
                      onChange={handleChange}
                      value={formData.postal_code}
                    />
                  </Grid>
                </Grid>

                {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.admin_site}
                        onChange={handleChange}
                        name="admin_site"
                      />
                    }
                    label="Admin site"
                    sx={{ mb: 2 }}
                  /> */}
              </Box>
              {/* </TabContext> */}
              <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
                <Button variant="outlined" onClick={handleAddClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="Btn"
                  onClick={handleSubmit}
                  disabled={!isSubmitEnabled}
                >
                  {editID ? "Update" : "Submit"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>

      {/* delete modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={deleteOpen}
        className="modal-wrap site-creation-modal roleCreateModal"
        onClose={handleDeleteClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={deleteOpen}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              className="mspRoleHeader"
            >
              Delete confirmation
              <Button onClick={handleDeleteClose}>
                <img src={CloseIcon} className="closeicon" alt="closeicon" />
              </Button>
            </Typography>

            <Box sx={{ width: "100%", typography: "body1" }}>
              <Typography>Are you sure want to delete this site ? </Typography>
            </Box>
            <Box display="flex" justifyContent="end" gap={2} sx={{ mb: 2 }}>
              <Button variant="outlined" onClick={handleDeleteClose}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleDeleteEvent}>
                Delete
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
