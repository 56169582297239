import React, { useState } from "react";
import {
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
} from "@mui/material";
import { ReactComponent as AddIcon } from "../../../assets/icons/Masked_Icon.svg";
import SortEditorModal from "./SortEditorNewModal";
import "./SortEditor.scss";

function createData(
  machine,
  sort_profile,
  pieces_sorted,
  pieces_rejected,
  avg_pcs_being,
  acc_being,
  backlog,
  logged_into_machine
) {
  return {
    machine,
    sort_profile,
    pieces_sorted,
    pieces_rejected,
    avg_pcs_being,
    acc_being,
    backlog,
    logged_into_machine,
  };
}

const rows = [
  createData("Profile  1", 159, 6.0, 24, 4.0, 1),
  createData("Profile 2", 237, 9.0, 37, 4, 1),
  createData("Profile 3 ", 262, 16.0, 24, 6.0, 1),
];

export default function MailingEditor() {
  const [sortEditorModalOpen, setSortEditorModalOpen] = useState(false);

  const handleModalOpen = () => setSortEditorModalOpen(true);
  const handleModalClose = () => setSortEditorModalOpen(false);

  return (
    <Box className="sort-editor">
      <Box display="flex" justifyContent="end" margin="15px 0">
        <Button variant="outlined" className="Btn" onClick={handleModalOpen}>
          <AddIcon /> Add
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        className="mailing-editor-table"
        sx={{ mt: 4, mb: 2 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sort Profile Name</TableCell>
              <TableCell>Number of Bins</TableCell>
              <TableCell>Double Detect</TableCell>
              <TableCell>Bar Codes Reject Bins</TableCell>
              <TableCell>Out of Profile Bin </TableCell>
              <TableCell>Preallocate ADC Bin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.machine}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.machine}
                </TableCell>
                <TableCell>{row.sort_profile}</TableCell>
                <TableCell>{row.pieces_sorted}</TableCell>
                <TableCell>{row.pieces_rejected}</TableCell>
                <TableCell>{row.avg_pcs_being}</TableCell>
                <TableCell>{row.acc_being}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {sortEditorModalOpen && (
        <SortEditorModal open={handleModalOpen} close={handleModalClose} />
      )}
    </Box>
  );
}
