import React, { useEffect, useState, useReducer } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteIcon from "../../../assets/icons/Trash Bin Trash.svg";
import { ReactComponent as AddRoleIcon } from "../../../assets/icons/Masked_Icon.svg";
import EditIcon from "../../../assets/icons/edit.svg";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import TuneIcon from "@mui/icons-material/Tune";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ViewIcon from "../../../assets/icons/File.svg";
import axios from "axios";
import { apiBaseUrl } from "../../../config";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import ViewRouteModal from "./viewrouteModal";
import UpdateModal from "./updateRouteModal";
import Loader from "../../../components/Loader";
import CustomPagination from "../../../components/customPagination/custompagination";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow:
    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
  borderRadius: "10px",
};

const initialState = {
  routerList: [],
  anchorEl: null,
  anchorFilter: null,
};

const showColumns = [
  {
    id: 1,
    name: "Router Number",
    value: "router_number",
  },
  { id: 2, name: "Stop Number", value: "stop_number" },
  { id: 3, name: "Customer Name", value: "customer_name" },
];

const reducer = (state, action) => {
  switch (action.type) {
    case "show_Details":
      return {
        ...state,
        [action.field]: action.payload,
      };
    default:
      return state;
  }
};

export default function RouterCreation() {
  const [router, dispatch] = useReducer(reducer, initialState);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [open, setOpen] = React.useState(false);
  const [viewableDetail, setViewableDetail] = useState({});
  const [editModal, setEditModal] = React.useState(false);
  const [editableDetails, setEditableDetails] = React.useState({});
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [permissionList, setPermissionList] = useState({});
  const [count, setCount] = useState(0);

  const isAuth = localStorage.getItem("auth");
  const navigate = useNavigate();

  useEffect(() => {
    Permission();
  }, []);

  const handleViewOpen = (data) => {
    setOpen(true);
    setViewableDetail(data);
  };

  const handleOpenEdit = (data) => {
    setEditModal(true);
    setEditableDetails(data);
  };

  const handleClick = (event) => {
    dispatch({
      type: "show_Details",
      field: "anchorEl",
      payload: event.currentTarget,
    });
  };

  const handleClickFilter = (event) => {
    dispatch({
      type: "show_Details",
      field: "anchorFilter",
      payload: event.currentTarget,
    });
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (newPageSize) => {
  //   setPageSize(newPageSize);
  //   setPage(1);
  // };
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  const handleDeleteOpen = (userId) => {
    setDeleteModal(true);
    setDeleteId(userId);
  };

  const Permission = async () => {
    const token = localStorage.getItem("auth");
    const role_id = localStorage.getItem("role_id");
    const response = await axios.get(
      `${apiBaseUrl}msp_role_group_function_mapping_list?role_id=${role_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      response.data.msp_role_group_function_mapping_list?.map((obj) => {
        obj.role_group_name === "Production" &&
          obj.role_function_name === "Pieces" &&
          setPermissionList(obj);
      });
    }
  };
  const fetchRoutes = async () => {
    const isAuth = localStorage.getItem("auth");
    const { data } = await axios.get(`${apiBaseUrl}get_routes`, {
      headers: { Authorization: `Bearer ${isAuth}` },
      params: {
        page: page,
        page_size: pageSize,
      },
    });
    return data;
  };
  const handleDelete = () => {
    try {
      axios
        .delete(`${apiBaseUrl}route_deletion?route_id=${deleteId}`, {
          headers: {
            Authorization: `Bearer ${isAuth}`,
          },
        })
        .then((res) => {
          toast.success("Route delete successfully:)");
          handleClose("Success");
        });
    } catch (error) {}
  };
  const { data, isLoading, isError, refetch, error } = useQuery({
    queryKey: ["routes", page || 1, pageSize || 5], // Default page = 1, pageSize = 10
    queryFn: fetchRoutes,
    staleTime: 1000 * 60 * 1, // 1 minute stale time
    refetchOnWindowFocus: false,
    enabled: page !== undefined && pageSize !== undefined, // Ensure both are defined
  });

  useEffect(() => {
    if (isError && error) {
      toast.error(`Error loading data: ${error.message}`);
    }
  }, [isError, error]);
  const handleClose = (data) => {
    setOpen(false);
    setEditModal(false);
    setDeleteModal(false);
    data && refetch();
  };
  return (
    <div className="roles mspcreation machineTable">
      {isLoading && <Loader />}

      <Box display="flex" justifyContent="end">
        {permissionList.msp_role_group_function_mapping_list
          ?.role_create_flag === 1 && (
          <Button
            variant="contained"
            sx={{ fontWeight: "bold" }}
            onClick={() => navigate("/msp/createrouter")}
            className="Btn"
          >
            <AddRoleIcon /> Create Route
          </Button>
        )}
        <Button
          variant="contained"
          sx={{ fontWeight: "bold" }}
          onClick={() => navigate("/msp/assignroute")}
          className="Btn"
        >
          <AddRoleIcon /> Assign Route
        </Button>
      </Box>

      <div className="filters">
        <div className="showListOptions">
          <span className="columns">
            <Button className="actionIcons" onClick={handleClick}>
              <ViewColumnIcon className="filterIcon" />
              Columns
            </Button>
          </span>
          <span className="columns">
            <Button className="actionIcons" onClick={handleClickFilter}>
              <TuneIcon className="filterIcon" />
              Filter
            </Button>
          </span>
        </div>
        <div className="export">
          <Button className="actionIcons">
            <FileDownloadIcon className="filterIcon" />
            Export
          </Button>
        </div>
      </div>
      <TableContainer component={Paper} className="machineDataTable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Routes</TableCell>

              <TableCell align="center">Created on</TableCell>
              <TableCell align="center">Ordering / Stop Number</TableCell>

              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.route_details?.length > 0 ? (
              data?.route_details.map((row) => (
                <TableRow
                  key={row.mo_route_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{
                      position: "sticky",
                      left: 0,
                      background: "white",
                      zIndex: 800,
                    }}
                  >
                    {row?.mo_route_name}
                  </TableCell>

                  <TableCell align="center">
                    {row.created_on &&
                      moment(row?.created_on).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell align="center">{row?.stops?.length}</TableCell>

                  <TableCell
                    align="left"
                    style={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      background: "white",
                    }}
                  >
                    <div className="actionIcons">
                      <span>
                        <Button
                          className="closeButton"
                          onClick={() => handleViewOpen(row)}
                        >
                          {" "}
                          <img
                            src={ViewIcon}
                            className="actionIcons"
                            alt="viewIcon"
                          />
                        </Button>
                      </span>
                      <span>
                        <Button
                          className="closeButton"
                          onClick={() => handleOpenEdit(row.mo_route_id)}
                        >
                          {" "}
                          <img
                            src={EditIcon}
                            className="actionIcons"
                            alt="editicon"
                          />
                        </Button>
                      </span>
                      <span>
                        <Button
                          className="closeButton"
                          onClick={() => handleDeleteOpen(row.mo_route_id)}
                        >
                          <img
                            src={DeleteIcon}
                            className="actionIcons"
                            alt="deleteIcon"
                          />
                        </Button>
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>
                  <Typography variant="h6" align="center">
                    No records found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPagination
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        rowCount={data?.total_count}
      />

      {open && (
        <ViewRouteModal
          open={open}
          onClose={(data) => handleClose(data)}
          viewableDetail={viewableDetail}
        />
      )}
      {editModal && (
        <UpdateModal
          open={editModal}
          onClose={(data) => handleClose(data)}
          editableDetail={editableDetails}
        />
      )}
      {deleteModal && (
        <Dialog
          open={deleteModal}
          onClose={(data) => handleClose(data)}
          className="route-delete-modal"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm delete?"}</DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleDelete()}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
