import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popover,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  TablePagination,
  InputBase,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  Typography,
  Switch,
  Tooltip,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MachineService } from "../../../services/MachineService";
import Loader from "../../../components/Loader";
import EditIcon from "../../../assets/icons/edit.svg";
import DeleteIcon from "../../../assets/icons/Trash Bin Trash.svg";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import TuneIcon from "@mui/icons-material/Tune";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchMachineTable,
  fetchReorderedList,
} from "../../../store/MachineList";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  machineList: [],
  anchorEl: null,
  anchorFilter: null,
};

const showColumnsResponse = [
  {
    id: 1,
    name: "MSP Name",
    value: "owner_name",
  },
  { id: 2, name: "Machine Name", value: "machine_name" },
  { id: 3, name: "Serial Number", value: "serial" },
  { id: 4, name: "Manufacturer", value: "manufacturer" },
  { id: 5, name: "Model", value: "model" },
  { id: 6, name: "Verifier", value: "verifier" },
  { id: 7, name: "Primary Printer", value: "primary" },
  { id: 8, name: "Secondary Printer", value: "secondry" },
  { id: 9, name: "IMB Printer", value: "imb_printer" },
  { id: 10, name: "COA Printer", value: "coa_printer" },
  { id: 11, name: "Capable service", value: "capable_service" },
  { id: 12, name: "Production Date Range", value: "production_date_range" },
  { id: 13, name: "Last Preventive", value: "last_preventive" },
  { id: 14, name: "Next Preventive", value: "next_preventive" },
];

const reducer = (state, action) => {
  switch (action.type) {
    case "show_Details":
      return {
        ...state,
        [action.field]: action.payload,
      };
    default:
      return state;
  }
};

export default function MachinesTable({
  handleOpenEdit,
  handleDeleteOpen,
  refresh,
  permissionList,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [checkedItems, setCheckedItems] = useState([]);
  const dispatchList = useDispatch();
  const machineTableList = useSelector(
    (state) => state.machines.machineTableList
  );
  const reorderedTableList = useSelector(
    (state) => state.machines.reorderedList
  );
  const [showColumns, setShowColumns] = useState(showColumnsResponse);
  const filteredItems = showColumns.filter((column) =>
    checkedItems.includes(column.value)
  );
  const showColumnsRef = useRef(showColumns);
  const [fixedLabel, setFixedLabel] = useState(["machine_name"]);

  useEffect(() => {
    if (reorderedTableList.length) {
      setShowColumns(reorderedTableList);
    }
  }, [reorderedTableList]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(showColumns);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setShowColumns(reorderedItems);

    const updatedFixedLabels = fixedLabel.filter((value) => {
      const newIndex = reorderedItems.findIndex((item) => item.value === value);
      return newIndex <= 2;
    });

    setFixedLabel(updatedFixedLabels);
  };

  useEffect(() => {
    showColumnsRef.current = showColumns;
  }, [showColumns]);

  useEffect(() => {
    if (machineTableList && machineTableList.length) {
      const filteredColumns = showColumns
        .filter((column) => machineTableList?.includes(column.value))
        .map((column) => column.value);
      setCheckedItems(filteredColumns);
    } else {
      setCheckedItems(showColumns.map((column) => column.value));
    }

    return () => {
      dispatchList(fetchReorderedList(showColumnsRef.current));
    };
  }, []);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setCheckedItems((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  useEffect(() => {
    dispatchList(fetchMachineTable(checkedItems));
  }, [checkedItems]);

  useEffect(() => {
    machinesDetails();
  }, [page, pageSize, refresh]);

  const machinesDetails = () => {
    setLoader(true);
    MachineService.getMachinesList({
      page: page + 1,
      page_size: pageSize,
    }).then((res) => {
      const rows = res.data.machines_data.map((item) => ({
        id: item.machine_config?.machine_id,
        machine_name: item.machine_config?.machine_name,
        serial: item.machine_config?.serial,
        location: item.machine_config?.location_id,
        machine_id: item.machine_config?.machine_id,
        owner_id: item.machine_config?.owner_id,
        owner_name: item.machine_config?.owner_name,

        manufacturer: item.machine_config?.manufacturer_name,
        manufacturer_id: item.machine_config?.manufacturer_id,

        model: item.machine_config?.model_name,
        model_id: item.machine_config?.model_id,

        service_number: item.machine_config?.service_number,
        mass_id: item.machine_config?.mass_id,
        move_update_id: item.machine_config?.move_update_id,
        scale_id: item.machine_config?.scale_id,
        verifier: item.machine_config?.verifier_name,
        verifier_id: item.machine_config?.verifier_id,

        primary: item.machine_config?.primary_ocr_name,
        primary_ocr_id: item.machine_config?.primary_ocr_id,

        secondry: item.machine_config?.secondary_ocr_name,
        secondary_ocr_id: item.machine_config?.secondary_ocr_id,

        number_of_bins: item.machine_config?.number_of_bins,
        bin_type_id: item.machine_config?.bin_type_id,
        doubles_detector_id: item.machine_config?.doubles_detector_id,
        thickness_detector_id: item.machine_config?.thickness_detector_id,

        imb_printer: item.machine_config?.imb_printer_name,
        imb_printer_id: item.machine_config?.imb_printer_id,

        coa_printer: item.machine_config?.coa_printer_name,
        coa_printer_id: item.machine_config?.coa_printer_id,

        endorsement_printer_id: item.machine_config?.endorsement_printer_id,
        graphics_printer_id: item.machine_config?.graphics_printer_id,
        labeler_id: item.machine_config?.labeler_id,
        feeder_id: item.machine_config?.feeder_id,
        machine_maintenance_contact_id:
          item.machine_config?.machine_maintenance_contact_id,

        max_throughput:
          item?.machine_config?.machine_productivity?.max_throughput,
        production_throughput:
          item?.machine_config?.machine_productivity?.production_throughput,
        actual_production_throughput:
          item?.machine_config?.machine_productivity
            ?.actual_production_throughput,
        percentage_realization_production:
          item?.machine_config?.machine_productivity
            ?.percentage_realization_production,
        machine_enabled_disable_for_production:
          item?.machine_config?.machine_productivity
            ?.machine_enabled_disable_for_production,
        preventive_maintenance_time_hrs:
          item?.machine_config?.machine_productivity
            ?.preventive_maintenance_time_hrs,
        capable_service:
          item?.machine_config?.machine_productivity
            ?.expected_capable_for_service,
        production_date_range:
          item?.machine_config.machine_productivity?.production_date_range,
        last_preventive:
          item?.machine_config?.machine_productivity
            ?.last_preventive_maintenance_completed,
        next_preventive:
          item?.machine_config?.machine_productivity
            ?.next_scheduled_preventive_maintenance,
        date: item?.machine_config?.machine_log?.date,
        logs_attached: item?.machine_config?.machine_log?.logs_attached,
        log: item?.machine_config?.machine_log?.log,
        first_name:
          item?.machine_config.machine_maintenance_contact?.first_name,
        last_name: item?.machine_config.machine_maintenance_contact?.last_name,
        shift_id:
          item?.machine_config.machine_maintenance_contact
            ?.machine_maintenance_shift_id,
        email: item?.machine_config.machine_maintenance_contact?.email,
        phone: item?.machine_config.machine_maintenance_contact?.phone,
        priority: item?.machine_config.machine_maintenance_contact?.priority,
      }));
      dispatch({
        type: "show_Details",
        field: "machineList",
        payload: rows,
      });
      setCount(res.data?.total_record);
      setLoader(false);
    });
  };

  let stickyIndex = 0;

  const stickyHandle = (e) => {
    if (fixedLabel.find((val) => val === e.value)) {
      stickyIndex += 1;
      return `active-sticky cell-${stickyIndex}`;
    }
    return "";
  };

  const toggleFixed = (value, e) => {
    if (e) {
      if (fixedLabel.length < 3) {
        setFixedLabel([...fixedLabel, value]);
      }
    } else {
      const temp = fixedLabel.filter((e) => e !== value);
      setFixedLabel(temp);
    }
  };

  const handleClick = (event) => {
    dispatch({
      type: "show_Details",
      field: "anchorEl",
      payload: event.currentTarget,
    });
  };

  const handleClickFilter = (event) => {
    dispatch({
      type: "show_Details",
      field: "anchorFilter",
      payload: event.currentTarget,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageSize(event.target.value);
  };

  const handleClose = () => {
    dispatch({ type: "show_Details", field: "anchorEl", payload: 0 });
    dispatch({ type: "show_Details", field: "anchorFilter", payload: 0 });
  };

  const open = Boolean(state.anchorEl);
  const id = open ? "columns-popover" : undefined;
  const openFilter = Boolean(state.anchorFilter);
  const filterId = open ? "filter-popover" : undefined;

  return (
    <div className="machineTable">
      {loader && <Loader />}

      <Popover
        id={id}
        open={open}
        anchorEl={state.anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ mt: 2 }}
      >
        <List>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {showColumns.map(({ value, name }, index) => (
                    <Draggable
                      key={value}
                      draggableId={String(value)}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ListItem key={value}>
                            <Checkbox
                              className="showHideCheckbox"
                              onChange={handleCheckboxChange}
                              value={value}
                              checked={checkedItems.includes(value)}
                            />
                            <ListItemText primary={name} />
                            {index < 3 && (
                              <Tooltip title="Fixed to left">
                                <Switch
                                  defaultChecked={
                                    fixedLabel.find((e) => e === value) &&
                                    index < 3
                                  }
                                  size="small"
                                  onChange={(e) =>
                                    toggleFixed(value, e.target.checked)
                                  }
                                />
                              </Tooltip>
                            )}
                          </ListItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
      </Popover>

      <Popover
        id={filterId}
        open={openFilter}
        anchorEl={state.anchorFilter}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ mt: 2 }}
      >
        <Paper
          component="form"
          sx={{
            p: 1,
            display: "flex",
            alignItems: "center",
            width: 600,
          }}
        >
          <IconButton sx={{ p: "10px" }} aria-label="menu">
            <CloseIcon onClick={handleClose} />
          </IconButton>
          <FormControl size="small" className="tableFilters">
            <InputLabel id="demo-simple-select-label">Columns</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Columns"
            >
              {showColumns.map((column) => (
                <MenuItem key={column.id} value={column.name}>
                  {column.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" className="tableFilters" sx={{ mx: 1 }}>
            <InputLabel id="demo-simple-select-label">Operator</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Operator"
            >
              <MenuItem value="contains">Contains</MenuItem>
              <MenuItem value="equals">Equals</MenuItem>
              <MenuItem value="startswith">Start With</MenuItem>
              <MenuItem value="endswith">Ends With</MenuItem>
              <MenuItem value="isempty">Is Empty</MenuItem>
              <MenuItem value="isnotempty">Is Not Empty</MenuItem>
              <MenuItem value="isanyof">Is Any Of</MenuItem>
            </Select>
          </FormControl>
          <InputBase
            sx={{ flex: 1 }}
            placeholder="Filter Value"
            inputProps={{ "aria-label": "value" }}
            className="tableFilters filterValue"
          ></InputBase>
        </Paper>
      </Popover>

      <div className="filters">
        <div className="showListOptions">
          <span className="columns">
            <Button className="actionIcons" onClick={handleClick}>
              <ViewColumnIcon className="filterIcon" />
              Columns
            </Button>
          </span>
          <span className="columns">
            <Button className="actionIcons" onClick={handleClickFilter}>
              <TuneIcon className="filterIcon" />
              Filter
            </Button>
          </span>
        </div>
        <div className="export">
          <Button className="actionIcons">
            <FileDownloadIcon className="filterIcon" />
            Export
          </Button>
        </div>
      </div>
      <TableContainer component={Paper} className="machineDataTable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {filteredItems.map((column) => (
                <TableCell key={column.id} className={stickyHandle(column)}>
                  {column.name}
                </TableCell>
              ))}
              <TableCell
                align="left"
                style={{
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.machineList.length > 0 ? (
              state.machineList.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {filteredItems.map((column) => (
                    <TableCell key={column.id} className={stickyHandle(column)}>
                      {row[column.value]}
                    </TableCell>
                  ))}

                  <TableCell
                    align="left"
                    style={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      background: "white",
                    }}
                  >
                    <div className="actionIcons">
                      <span>
                        {permissionList?.msp_role_group_function_mapping_list
                          ?.role_update_flag === 1 && (
                          <Button
                            className="closeButton"
                            onClick={() => handleOpenEdit(row)}
                          >
                            {" "}
                            <img
                              src={EditIcon}
                              className="actionIcons"
                              alt="editicon"
                            />
                          </Button>
                        )}
                      </span>
                      <span>
                        {permissionList?.msp_role_group_function_mapping_list
                          ?.role_delete_flag === 1 && (
                          <Button
                            className="closeButton"
                            onClick={() => handleDeleteOpen(row.id)}
                          >
                            <img
                              src={DeleteIcon}
                              className="actionIcons"
                              alt="deleteIcon"
                            />
                          </Button>
                        )}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>
                  <Typography variant="h6" align="center">
                    No records found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
