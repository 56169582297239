import { useEffect, useReducer, useState } from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import UndoIcon from "@mui/icons-material/Undo";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import MailingEditorModal from "./mailingEditorModal";
import axios from "axios";
import { apiBaseUrl } from "../../../config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function BasicTable({ handleChange, barcodeValue }) {
  const [barCode, setBarCodes] = useState([]);

  const fetchBarcode = () => {
    try {
      axios
        .get(`${apiBaseUrl}get_barcode_type`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((res) => {
          setBarCodes(res.data.barcode_type);
        });
    } catch (error) {
      console.log("Err==>", error);
    }
  };
  const sampleData = [
    {
      slot: 1,
      barcodeName: "Empty Slot",
      barcodeType: "Unknown",
      barcodePattern: "Nill",
    },
    {
      slot: 2,
      barcodeName: "Empty Slot",
      barcodeType: "Unknown",
      barcodePattern: "Nill",
    },
  ];
  useEffect(() => {
    fetchBarcode();
  }, []);
  return (
    <div className="classtab accountEditorTabs">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Box>
              <Box display="flex" alignItems="center" gap={1} sx={{ my: 2 }}>
                <FormControl className="revert-option">
                  <TextField
                    id="outlined-basic"
                    size="small"
                    disabled
                    label="Barcode Name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className="sequence"
                    name="barcode_name"
                    defaultValue={barcodeValue.barcode_name}
                    onChange={(e) => handleChange(e, "barcode")}
                  />
                </FormControl>
                <FormControl fullWidth className="revert-option">
                  <InputLabel
                    id="demo-multiple-name-label"
                    className="profilelable"
                    sx={{ pr: 1, top: "-7px" }}
                    shrink
                  >
                    Barcode Type{" "}
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    size="small"
                    label="Barcode Type"
                    input={<OutlinedInput label="Name" />}
                    MenuProps={MenuProps}
                    sx={{ flexGrow: "1" }}
                    name="barcode_type"
                    defaultValue={barcodeValue.barcode_type}
                    onChange={(e) => handleChange(e, "barcode")}
                  >
                    {barCode.map((item) => (
                      <MenuItem
                        key={item.barcode_type_id}
                        value={item.barcode_type_id}
                      >
                        {item.name_of_barcode_type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box className="checkbox">
                <Box>
                  {" "}
                  <Checkbox
                    onChange={(e) => handleChange(e, "barcode")}
                    name="barcode_pattern"
                    defaultChecked={barcodeValue.barcode_pattern}
                  />
                  <Typography variant="outlined">Barcode Pattern</Typography>
                </Box>
                <Box>
                  {" "}
                  <Checkbox
                    onChange={(e) => handleChange(e, "barcode")}
                    name="is_imb_replacement"
                    defaultChecked={barcodeValue.is_imb_replacement}
                  />
                  <Typography variant="outlined">Use as IMD SID</Typography>
                </Box>
                <Box>
                  {" "}
                  <Checkbox
                    onChange={(e) => handleChange(e, "barcode")}
                    name="fill_no_bc_with_routing"
                    defaultChecked={barcodeValue.fill_no_bc_with_routing}
                  />
                  <Typography variant="outlined">
                    Require this barcode Fill no BC with routing
                  </Typography>
                </Box>
              </Box>

              <Box component="span">
                {" "}
                <Button
                  sx={{ m: 1.5, color: "white" }}
                  className="Btn"
                  variant="outlined"
                >
                  Clear Barcode Slot
                </Button>
                <Button
                  sx={{ my: 1.5, color: "white" }}
                  className="Btn"
                  variant="outlined"
                >
                  Test Pattern
                </Button>
              </Box>
            </Box>
            <Box mt={2}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Slot#</TableCell>
                      <TableCell>Barcode Name</TableCell>
                      <TableCell>Barcode Type</TableCell>
                      <TableCell>Barcode Pattern</TableCell>
                      <TableCell>Use as IMB SID</TableCell>
                      <TableCell>Required</TableCell>
                      <TableCell>FillNoBCWithRouting</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sampleData?.map((item) => (
                      <TableRow>
                        <TableCell>{item.slot}</TableCell>
                        <TableCell>{item.barcodeName}</TableCell>
                        <TableCell>{item.barcodeType}</TableCell>
                        <TableCell>{item.barcodePattern}</TableCell>
                        <TableCell align="center">
                          <CheckBox />
                        </TableCell>
                        <TableCell align="center">
                          <CheckBox />
                        </TableCell>
                        <TableCell align="center">
                          <CheckBox />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
