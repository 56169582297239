import { get, deleteRequest, postFormData, putFormData } from "./BaseService";
import { apiBaseUrl } from "../config";

export const MSPUserCreationService = {
  getMSPUserCreationList,
  AddMSPUserList,
  UpdateMSPUserList,
  deleteMSPUserList,
  getEIIUsersList,
  deleteEIIUserList,
  AddEIIUserList,
  getMoUserCreationList,
  deleteMoUserList,
};

function getMSPUserCreationList(params) {
  const url = `users_list_v2`;
  return get(apiBaseUrl + url, params);
}

function AddMSPUserList(data) {
  const url = `user_creation`;
  return postFormData(apiBaseUrl + url, data);
}

function UpdateMSPUserList(data) {
  const url = `update_user`;
  return putFormData(apiBaseUrl + url, data);
}

function deleteMSPUserList(data) {
  const url = `delete_user`;
  return deleteRequest(apiBaseUrl + url, data);
}

function getEIIUsersList(params) {
  const url = `eii_users_list`;
  return get(apiBaseUrl + url, params);
}

function AddEIIUserList(data) {
  const url = `eii_user_creation`;
  return postFormData(apiBaseUrl + url, data);
}

function deleteEIIUserList(data) {
  const url = `eii_user_deletion`;
  return deleteRequest(apiBaseUrl + url, data);
}
//mail owner functionalities
function getMoUserCreationList(params) {
  const url = `mail_owner_users_list_v2`;
  return get(apiBaseUrl + url, params);
}
function deleteMoUserList(data) {
  const url = `mail_owner_user_delete`;
  return deleteRequest(apiBaseUrl + url, data);
}
