import * as React from "react";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import signInBanner1 from "../../assets/images/cusomerloginbg.png";
import signInBanner2 from "../../assets/images/Brians-Project-Login-Screen-backgrounds-16.png";
import signInBanner3 from "../../assets/images/msploginbackground.png";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import OtpInput from "react-otp-input";
import ApianLogo from "../../assets/images/apianSortLogo Capy copy 1.png";
import ApianLogoTitle from "../../assets/images/apianSortLogo Capy copy 2.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../style/style.css";
import "../../style/home.scss";
import { Login } from "../../services/loginService";
import QuoteSymbol from "../../assets/css/quotes.png";
import CustomerQuotes from "../../assets/css/blueQuotes.png";
import ApianBeeLogo from "../../assets/images/Apain Dark Blue Logo.png";
import EIILogo from "../../assets/images/EII Logo.png";
import { fetchMachineTable, fetchReorderedList } from "../../store/MachineList";
import { useDispatch } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Apian{" "}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const defaultTheme = createTheme();

export default function SignInSide() {
  const navigate = useNavigate();
  const [lock, setLock] = React.useState(false);
  const [attempts, setAttempts] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [otpFilled, setOtpFilled] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState(300); // 5 minutes in seconds
  const dispatch = useDispatch();

  const handleClose = () => setOpen(false);

  const handleVerification = () => {
    // Handle OTP verification
  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("password", values.password);

      const matchedCredentials = await Login(formData);
      const data = matchedCredentials?.data;
      if (matchedCredentials) {
        setAttempts(0);
        localStorage.setItem("auth", data.access_token);
        localStorage.setItem("role_id", data.role_id);
        localStorage.setItem("user_name", data.user_name);
        localStorage.setItem("email", data.email);
        localStorage.setItem("role_type", data.role_type);
        localStorage.setItem("msp_name", data.logo_name);
        localStorage.setItem("msp_account_guid", data.account_id);
        localStorage.setItem("msp_id", data.msp_id);
        if (data.role_type === "EII") {
          navigate("/eii/dashboard");
        } else if (data.role_type === "MSP") {
          navigate("/msp/dashboard");
        } else if (data.role_type === "MAIL_OWNER") {
          navigate("/customer-portal/dashboard");
        } else {
          toast.error("Invalid Role");
          navigate("/");
        }
      } else {
        if (attempts >= 3) {
          setLock(true);
        } else {
          setAttempts((prevState) => prevState + 1);
        }
      }
    } catch (error) {
      if (attempts >= 3) {
        setLock(true);
      } else {
        setAttempts((prevState) => prevState + 1);
      }
      console.log("Err===>", error);
      toast.error("Invalid Credentials");
    }
  };
  //otp functions
  const handleOtpChange = (otp) => {
    const numInputs = 6;
    setOtp(otp);
    setOtpFilled(otp.length === numInputs);
  };
  const navigateOnTimeLeft = () => {
    const domain = window.location.pathname.split("/")[1];

    if (domain === "eii") {
      navigate("/eii/sign-in");
    } else if (domain === "customer-portal") {
      navigate("/customer-portal/sign-in");
    } else if (domain === "msp") {
      navigate("/msp/sign-in");
    } else {
      navigate("/");
    }
  };
  React.useEffect(() => {
    localStorage.removeItem("auth");
    dispatch(fetchMachineTable([]));
    dispatch(fetchReorderedList([]));
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft === 0) {
          clearInterval(interval);
          navigateOnTimeLeft();
          setOpen(false);
          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [open]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const { touched, errors, getFieldProps } = formik;
  const subDomain = window.location.pathname.split("/")[1];
  let bannerSrc;

  if (subDomain === "eii") {
    bannerSrc = signInBanner1;
  } else if (subDomain === "msp") {
    bannerSrc = signInBanner3;
  } else {
    bannerSrc = signInBanner2;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          md={7}
          lg={7}
          xl={7}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        >
          <Box position="relative">
            <img
              src={bannerSrc}
              style={{ width: "100%", height: "100vh" }}
              alt="SignIn Banner"
            />
            <Box className="signInQuotes">
              <img
                src={
                  subDomain === "customer-portal" ? CustomerQuotes : QuoteSymbol
                }
                alt="quotes"
              />
              <h6
                className={
                  subDomain === "customer-portal"
                    ? "quotes customerQuotes"
                    : "quotes"
                }
              >
                Lorem ipsum dolor sit amet consectetur. Porta sagittis cursus
                tellus eleifend mollis ultricies aliquet lobortis.
              </h6>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box className="signInFormContainer">
            <Button onClick={() => navigate("/")} className="closeButton">
              <img
                src={ApianBeeLogo}
                width={344}
                height={102}
                alt="apian_logo"
              />
            </Button>
            <Box>
              <Grid container display="flex" justifyContent="center">
                <Grid item xl={7} lg={10} md={12} sm={12}>
                  <Box className="loginForm">
                    <h5>
                      Sign in&nbsp;
                      {subDomain === "msp" && <span>to MSP platform</span>}
                    </h5>
                    <Formik {...formik}>
                      <Form
                        noValidate
                        autoComplete="off"
                        onSubmit={formik.handleSubmit}
                      >
                        {lock ? (
                          <Typography variant="body1" color="error" mt={12}>
                            Sorry your account is locked due to three
                            consecutive failed attempts to login. Kindly get in
                            touch with the administrator to unlock your account
                          </Typography>
                        ) : (
                          <>
                            <Field
                              as={TextField}
                              margin="normal"
                              required
                              fullWidth
                              id="email"
                              label="Email Address"
                              name="email"
                              autoComplete="email"
                              autoFocus
                              {...getFieldProps("email")}
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                              InputLabelProps={{ shrink: true }}
                            />
                            <Field
                              as={TextField}
                              margin="normal"
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              id="password"
                              autoComplete="current-password"
                              {...getFieldProps("password")}
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              helperText={touched.password && errors.password}
                              InputLabelProps={{ shrink: true }}
                            />
                            <Button
                              className={
                                subDomain === "customer-portal"
                                  ? "forgotPassword customerForgotPass"
                                  : "forgotPassword"
                              }
                              onClick={() => navigate("/authorization")}
                            >
                              Forgot password?
                            </Button>
                          </>
                        )}
                        {lock ? (
                          ""
                        ) : (
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className="signInBtn"
                          >
                            Sign In
                          </Button>
                        )}

                        {attempts <= 3 && attempts > 0 && !lock && (
                          <Typography
                            variant="body1"
                            color="error"
                            textAlign="center"
                          >
                            Invalid Credentials - Account will be locked after
                            three consecutive failed attempts. {3 - attempts}{" "}
                            more attempts left.
                          </Typography>
                        )}
                      </Form>
                    </Formik>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <img src={EIILogo} alt="eiilogo" />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* OTP Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={ApianLogo} width={140} height={120} alt="Apian Logo" />
            <img
              src={ApianLogoTitle}
              width={200}
              height={35}
              alt="Apian Logo Title"
            />
            <Typography component="p" className="otpSlogan">
              Please enter the OTP received in your registered email{" "}
            </Typography>
          </Box>
          <OtpInput
            containerStyle={{
              margin: "20px 0",
              justifyContent: "space-around",
            }}
            value={otp}
            onChange={handleOtpChange}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
            separator={<span style={{ width: "8px" }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            inputStyle={{
              border: "1px solid rgba(0,0,0,.3)",
              background: "#F3F3F3",
              borderRadius: "8px",
              width: "54px",
              height: "54px",
              fontSize: "22px",
              color: "#000",
              fontWeight: "400",
              caretColor: "blue",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <small className="otpModalText">Didn't receive otp?</small>

            <p className="otpModalText resend">Resend Code</p>
            <Button
              variant="contained"
              onClick={handleVerification}
              disabled={!otpFilled}
            >
              submit otp{" "}
            </Button>
            <Typography
              component="small"
              color="error"
              sx={{ position: "fixed", bottom: 16, right: 16 }}
            >
              Time left: {Math.floor(timeLeft / 60)}:
              {(timeLeft % 60).toString().padStart(2, "0")}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
