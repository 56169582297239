import React from "react";
import SimpleDrag from "./draggableContainer";
import { Box } from "@mui/material";

export default function DashboardKpi() {
  return (
    <Box>
      <SimpleDrag />
    </Box>
  );
}
