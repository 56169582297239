import {
  InfoOutlined,
  PrintOutlined,
  CancelOutlined,
  DeleteOutline,
  GridView,
} from "@mui/icons-material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import TableRowsIcon from "@mui/icons-material/TableRows";
import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  Tooltip,
  Grid,
  List,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import BinRangeModal from "./binRange";
import SortEditorTable from "./SortEditorTable";

export default function SortEditorDetails() {
  const scrollContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [spitedArray, setSpitedArray] = useState([]);
  const [pageDetails, setPageDetails] = useState();
  const [selected, setSelected] = useState([]);
  const [bins, setBins] = useState([]);
  const [zip, setZip] = useState();
  const [show, setShow] = useState(false);
  const [showBinRange, setShowBinRange] = useState(false);
  const [binType, setBinType] = useState();
  const [view, setView] = useState("grid");

  let details = JSON.parse(localStorage.getItem("sort"));

  useEffect(() => {
    if (details) {
      setPageDetails(details);
      let totalBins =
        details.side == 1 ? details.no_bin : Number(details.no_bin) * 2;
      const numbers = Array.from({ length: totalBins }, (_, i) => i + 1);
      if (details.bin_layout) {
        switch (details.bin_layout) {
          case "verticalTop":
            verticalTop(numbers, details.no_of_tier, details.side);
            break;
          case "verticalBottom":
            verticalBottom(numbers, details.no_of_tier, details.side);
            break;
          case "horizontalTop":
            horizontalFromTop(
              numbers,
              Number(details.no_of_columns),
              details.side
            );
            break;
          case "horizontalBottom":
            horizontalFromBottom(
              numbers,
              Number(details.no_of_columns),
              details.side
            );
            break;
          default:
            break;
        }
      }
    }
  }, []);

  useEffect(() => {
    let obj = [
      {
        id: 1,
        type: "REJ",
        value: "",
      },
    ];

    setBins(obj);
  }, [spitedArray.length]);

  const horizontalFromBottom = (numbers, no_of_columns, type) => {
    const rows = [];
    const rowsTwo = [];
    if (type == 1) {
      for (let i = 0; i < numbers.length; i += no_of_columns) {
        let row = numbers.slice(i, i + no_of_columns);
        rows.push(row);
      }
      setSpitedArray(rows.reverse());
    } else {
      for (let i = 0; i < numbers.length; i += no_of_columns) {
        let row = numbers.slice(i, i + no_of_columns);
        if (i + (1 % 2) === 0 || i >= numbers.length / 2) {
          row.reverse();
          rows.push(row);
        } else {
          rowsTwo.push(row);
        }
      }
      let newValue = [...rows.reverse(), ...rowsTwo.reverse()];
      setSpitedArray(newValue);
    }
  };

  const horizontalFromTop = (numbers, no_of_columns, type) => {
    const rows = [];
    const rowsTwo = [];
    if (type == 1) {
      for (let i = 0; i < numbers.length; i += no_of_columns) {
        let row = numbers.slice(i, i + no_of_columns);
        rows.push(row);
      }
      setSpitedArray(rows);
    } else {
      for (let i = 0; i < numbers.length; i += no_of_columns) {
        let row = numbers.slice(i, i + no_of_columns);
        if (i + (1 % 2) === 0 || i >= numbers.length / 2) {
          row.reverse();
          rows.push(row);
        } else {
          rowsTwo.push(row);
        }
      }
      let newValue = [...rows, ...rowsTwo];
      setSpitedArray(newValue);
    }
  };

  const verticalTop = (numbers, no_of_tier, type) => {
    const rows = Array.from({ length: no_of_tier }, () => []);
    const rowsTwo = Array.from({ length: no_of_tier }, () => []);
    if (type == 1) {
      for (let i = 0; i < numbers.length; i++) {
        const colIndex = i % no_of_tier;
        rows[colIndex].push(numbers[i]);
      }
      setSpitedArray(rows);
    } else {
      for (let i = 0; i < numbers.length; i++) {
        const colIndex = i % no_of_tier;
        if (i + (1 % 2) === 0 || i >= numbers.length / 2) {
          rows[colIndex].push(numbers[i]);
        } else {
          rowsTwo[colIndex].push(numbers[i]);
        }
      }
      rows.map((row) => row.reverse());
      setSpitedArray([...rows.reverse(), ...rowsTwo]);
    }
  };

  const verticalBottom = (numbers, no_of_tier, type) => {
    const rows = Array.from({ length: no_of_tier }, () => []);
    const rowsTwo = Array.from({ length: no_of_tier }, () => []);
    if (type == 1) {
      for (let i = 0; i < numbers.length; i++) {
        const colIndex = i % no_of_tier;
        rows[colIndex].push(numbers[i]);
      }
      setSpitedArray(rows.reverse());
    } else {
      for (let i = 0; i < numbers.length; i++) {
        const colIndex = i % no_of_tier;
        if (i + (1 % 2) === 0 || i >= numbers.length / 2) {
          rows[colIndex].push(numbers[i]);
        } else {
          rowsTwo[colIndex].push(numbers[i]);
        }
      }
      rows.map((row) => row.reverse());
      setSpitedArray([...rows, ...rowsTwo.reverse()]);
    }
  };

  const handleSelect = (index) => {
    let checkBinDetails = bins.findIndex((bin) => bin.id == index);
    if (selected.includes(index) && checkBinDetails == -1) {
      setSelected((val) => val.filter((i) => i !== index));
    } else {
      setSelected([...selected, ...[index]]);
    }
  };

  const handleApplyType = (type) => {
    if (selected.length > 1) {
      setBinType(type);
      setShowBinRange(true);
    } else {
      let binSets = [];
      selected.map((val) => {
        let obj = {
          id: val,
          type: type,
          value: "",
          rangeType: "",
          range: "",
        };
        binSets.push(obj);
      });
      setBins([...bins, ...binSets]);
      inputRef.current.focus();
    }
  };

  const submitBin = () => {
    if (selected.length > 0 && zip) {
      let index = bins.findIndex((bin) => bin.id == selected[0]);

      if (index) {
        let groups = bins[index]["range"];
        if (groups) {
          bins.forEach((item) => {
            if (groups.includes(item.id)) {
              item.value = zip;
            }
          });
        } else {
          bins[index]["value"] = zip;
        }
        setBins(bins);
        setZip("");
        setSelected([]);
      }
    }
  };

  const handleClose = (rangeType) => {
    if (rangeType) {
      handleUpdateBins(rangeType);
      inputRef.current.focus();
    }
    setShowBinRange(false);
    debugger;
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  const handleUpdateBins = (rangeType) => {
    let binSets = [];
    selected.map((val) => {
      let obj = {
        id: val,
        type: binType,
        value: "",
        rangeType: rangeType,
        range: selected,
      };
      binSets.push(obj);
    });
    setBins([...bins, ...binSets]);
    inputRef.current.focus();
  };

  const checkScrollPosition = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
  };

  const scrollLeft = () => {
    scrollContainerRef.current.scrollTo({
      left: scrollContainerRef.current.scrollLeft - 500,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollTo({
      left: scrollContainerRef.current.scrollLeft + 500,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setTimeout(checkScrollPosition, 200);

    const handleScroll = () => {
      checkScrollPosition();
    };

    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleRemove = (id) => {
    setBins((val) => val.filter((i) => i.id != id));
    setSelected([]);
  };

  return (
    <Box className="sort-details">
      <Box
        className="control-panel-print"
        sx={{
          p: 2,
        }}
      >
        <Box className="control-buttons">
          <Button
            variant="contained"
            className="sort-control-buttons print"
            onClick={() => setView("grid")}
          >
            <GridView />
          </Button>
          <Button
            variant="contained"
            className="sort-control-buttons print"
            onClick={() => setView("list")}
          >
            <TableRowsIcon />
          </Button>
        </Box>
      </Box>

      {view === "grid" ? (
        <Box>
          <Box className="sort-details-wrap">
            <Box className="sort-details-content" ref={scrollContainerRef}>
              {spitedArray.map((chunk, chunkIndex) => (
                <Box display="flex" key={chunkIndex}>
                  {chunk.map((item, itemIndex) => {
                    let findIndex = selected.findIndex((val) => val == item);
                    let className =
                      findIndex != -1
                        ? "details-box div-selected"
                        : "details-box";
                    let parentClass = "details-data";
                    let binDetails = bins.find((val) => val.id == item);
                    if (binDetails && binDetails.type) {
                      className = className + " " + binDetails.type;
                      parentClass = parentClass + " " + "filled";
                    }

                    return (
                      <Box
                        key={itemIndex}
                        className={parentClass}
                        onClick={() => handleSelect(item)}
                      >
                        <Box
                          className={className}
                          sx={{
                            border: "1px solid #ccc",
                            padding: 2,
                            textAlign: "center",
                          }}
                        >
                          {binDetails && binDetails.type && (
                            <Box className="delete-icon">
                              <DeleteOutline
                                onClick={() => handleRemove(item)}
                              />
                            </Box>
                          )}
                          <Box></Box>
                          <Box>
                            <Typography variant="p" className={"details-info"}>
                              <Tooltip title="Info" placement="right-start">
                                {binDetails && binDetails.value && (
                                  <>
                                    {show && (
                                      <CancelOutlined
                                        className="exit-icon"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          setShow(false);
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </Tooltip>
                            </Typography>
                            {binDetails && (
                              <Typography>{binDetails.value}</Typography>
                            )}
                            <Typography className="item-label">
                              {item}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              ))}
            </Box>
            {show && (
              <Box className="sort-info">
                <ul>
                  <li>
                    Bin <span>15</span>
                  </li>
                  <li>
                    Ranging <span>2T</span>
                  </li>
                  <li>
                    Type <span>linear</span>
                  </li>
                  <li>
                    Profile Name <span>UMS</span>
                  </li>
                  <li>
                    Depth of Sort <span>10</span>
                  </li>
                  <li>
                    Finalized Bin <span>6</span>
                  </li>
                  <li>
                    Zip <span>ASDFE445</span>
                  </li>
                  <li>
                    Used Column <span>4</span>
                  </li>
                </ul>
              </Box>
            )}
          </Box>
          <Box className="control-panel" sx={{ p: 2 }}>
            <Box className="sort-control">
              <Button
                variant="contained"
                className="sort-control-buttons"
                onClick={() => {
                  handleApplyType("ADC");
                }}
              >
                ADC
              </Button>
              <Button
                variant="contained"
                className="sort-control-buttons"
                onClick={() => {
                  handleApplyType("d3");
                }}
              >
                3D
              </Button>
              <Button
                variant="contained"
                className="sort-control-buttons"
                onClick={() => {
                  handleApplyType("d5");
                }}
              >
                5D
              </Button>
              <Button
                variant="contained"
                className="sort-control-buttons"
                onClick={() => {
                  handleApplyType("MADC");
                }}
              >
                MADC
              </Button>
              <Button
                variant="contained"
                className="sort-control-buttons"
                onClick={() => {
                  handleApplyType("REJ");
                }}
              >
                Rej
              </Button>
            </Box>
            <Box className="sub-input-wrap" sx={{ ml: 1 }}>
              <TextField
                name="zip"
                fullWidth
                size="small"
                value={zip}
                inputRef={(input) => (inputRef.current = input)}
                defaultValue={zip}
                onChange={(e) => {
                  setZip(e.target.value);
                }}
              />
              <Button
                variant="contained"
                className="Btn sub-input-wrap-btn"
                onClick={() => {
                  submitBin();
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
          <Box className="sort-details-btn">
            <Link to="/msp/sort-editor" style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                className="sort-details-back "
                style={{ background: "lightslategray", color: "white" }}
              >
                Cancel
              </Button>
            </Link>
            <Button
              variant="contained"
              className="Btn sort-details-next "
              style={{ background: "green" }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="Btn sort-details-next "
              style={{ background: "dodgerblue" }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <SortEditorTable />
        </Box>
      )}
      {showBinRange && (
        <BinRangeModal open={showBinRange} close={(val) => handleClose(val)} />
      )}
    </Box>
  );
}
