import React, { useState, useReducer, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { apiBaseUrl } from "../../../config";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../../components/Loader";

const woListState = {
  id: 1,
  moAccountId: "",
  moWoList: [],
  workOrders: [],
  workOrderDetails: {},
};

export default function RouterModal({ EditrouterDetails }) {
  const [moRouteName, setMoRouteName] = useState("");
  const [woList, setWoList] = useState([woListState]);
  const [draggableDivs, setDraggableDivs] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleAddDiv = () => {
    setWoList((prevWoList) => [
      ...prevWoList,
      {
        id: prevWoList.length + 1,
        moAccountId: "",
        moWoList: [],
        workOrders: [],
        workOrderDetails: {},
      },
    ]);
    fetchWorkOrders();
  };

  const handleRemoveDiv = (id) => {
    setWoList((prevWoList) => prevWoList.filter((item) => item.id !== id));
  };

  const handleSelectChange = (event, id) => {
    const { name, value } = event.target;
    setWoList((prevWoList) => {
      const updatedWoList = [...prevWoList];
      const index = updatedWoList.findIndex((item) => item.id === id);

      if (index !== -1) {
        if (name === "mail_owner") {
          const selectedMailOwner = updatedWoList[index].moWoList.find(
            (item) => item.account_guid === value
          );
          if (selectedMailOwner) {
            updatedWoList[index].moAccountId = selectedMailOwner.account_guid;
            updatedWoList[index].workOrders = selectedMailOwner.work_orders;
          }
        } else if (name === "work_order") {
          const selectedWorkOrder = updatedWoList[index].workOrders.find(
            (item) => item.work_order_id === value
          );
          if (selectedWorkOrder) {
            updatedWoList[index].workOrderDetails = selectedWorkOrder;
          }
        }

        return updatedWoList;
      }
      return prevWoList;
    });
  };

  const handleDragStart = (index) => {
    setDraggableDivs(index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (index) => {
    setWoList((prevWoList) => {
      const newItems = [...prevWoList];
      const [draggedItem] = newItems.splice(draggableDivs, 1);
      newItems.splice(index, 0, draggedItem);
      return newItems;
    });
    setDraggableDivs(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const checkIfEmpty = woList?.some(
        (item) =>
          item.moWoList.length === 0 ||
          item.workOrders.length === 0 ||
          Object.keys(item.workOrderDetails).length === 0
      );
      if (!checkIfEmpty && moRouteName.length !== 0) {
        setLoader(true);
        const isAuth = localStorage.getItem("auth");
        const body = {
          mo_account_id: woList.map((item) => item.moAccountId),
          mo_route_name: moRouteName,
          mo_work_order_id: woList.map((item) => item.id),
          address: woList.map((item) => item.workOrderDetails.mo_address),
          city: woList.map((item) => item.workOrderDetails.mo_city),
          state: woList.map((item) => item.workOrderDetails.mo_state),
          postal_code: woList.map(
            (item) => item.workOrderDetails.mo_postal_code
          ),
          mo_stop_number: woList.map((item, index) => index + 1),
        };

        axios
          .post(`${apiBaseUrl}route_creation`, JSON.stringify(body), {
            headers: {
              Authorization: `Bearer ${isAuth}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            toast.success(res.data.message);
            setLoader(false);
            navigate("/msp/routers");
          })
          .catch((e) => {
            setLoader(false);
            toast.error(e.response?.data?.detail.msg || "Something went wrong");
          });
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.response?.data?.detail.msg || "Something went wrong");
    }
  };

  const fetchWorkOrders = async () => {
    try {
      const isAuth = localStorage.getItem("auth");

      const response = await axios.get(
        `${apiBaseUrl}mail_owner_created_work_order`,
        {
          headers: {
            Authorization: `Bearer ${isAuth}`,
          },
        }
      );

      if (response.status === 200) {
        setWoList((prevWoList) =>
          prevWoList.map((item) => ({
            ...item,
            moWoList: response.data.mail_owner_created_work_order_list,
          }))
        );
      }
    } catch (error) {
      console.log("Error fetching work orders:", error);
    }
  };

  useEffect(() => {
    fetchWorkOrders();
  }, []);

  return (
    <>
      {loader && <Loader />}
      <Box className="routercreation">
        <Box component="form">
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            sx={{ mb: 3 }}
            className="mspRoleHeader"
          >
            <b>{EditrouterDetails ? "Update Router" : "Route Creation"}</b>
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item lg={5} md={5} sm={12}>
              <TextField
                size="small"
                label="Route Name"
                InputLabelProps={{ shrink: true }}
                sx={{ width: "100%" }}
                name="mo_route_name"
                onChange={(e) => setMoRouteName(e.target.value)}
              />
            </Grid>
          </Grid>

          {woList.map((div, index) => (
            <Box
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={(e) => handleDragOver(e)}
              onDrop={() => handleDrop(index)}
              key={div.id}
              my={3}
              className="routerfields"
            >
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item lg={11} md={11}>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={12}>
                      <Box>
                        <FormControl fullWidth size="small" sx={{ my: 2 }}>
                          <InputLabel shrink id={`mail-owner-label-${div.id}`}>
                            Mail Owner
                          </InputLabel>
                          <Select
                            labelId={`mail-owner-label-${div.id}`}
                            id={`mail-owner-select-${div.id}`}
                            value={div?.moWoList?.name}
                            label="Mail Owner"
                            name="mail_owner"
                            onChange={(e) => handleSelectChange(e, div.id)}
                          >
                            {div.moWoList.map((item) => (
                              <MenuItem
                                key={item.account_guid}
                                value={item.account_guid}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12}>
                      <Box>
                        <FormControl fullWidth size="small" sx={{ my: 2 }}>
                          <InputLabel shrink id={`work-order-label-${div.id}`}>
                            Work Order
                          </InputLabel>
                          <Select
                            labelId={`work-order-label-${div.id}`}
                            id={`work-order-select-${div.id}`}
                            value={div.workOrders?.job_name}
                            label="Work Order"
                            name="work_order"
                            onChange={(e) => handleSelectChange(e, div.id)}
                          >
                            {div.workOrders.map((item) => (
                              <MenuItem
                                key={item.work_order_id}
                                value={item.work_order_id}
                              >
                                {item.job_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12}>
                      <TextField
                        sx={{ my: 2 }}
                        fullWidth
                        id={`msp-site-${div.id}`}
                        label="MSP Site"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={div.workOrderDetails?.site_name || ""}
                        name={`msp_site_${div.id}`}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        sx={{ mb: 2 }}
                        label="Address"
                        variant="outlined"
                        multiline
                        value={div.workOrderDetails?.mo_address || ""}
                        name={`address_${div.id}`}
                        rows={3}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12}>
                      <TextField
                        sx={{ my: 2 }}
                        fullWidth
                        id={`city-${div.id}`}
                        label="City"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={div.workOrderDetails?.mo_city || ""}
                        name={`city_${div.id}`}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12}>
                      <TextField
                        sx={{ my: 2 }}
                        fullWidth
                        id={`state-${div.id}`}
                        label="State"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={div.workOrderDetails?.mo_state || ""}
                        name={`state_${div.id}`}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12}>
                      <TextField
                        sx={{ my: 2 }}
                        fullWidth
                        id={`postal-code-${div.id}`}
                        label="Postal Code"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={div.workOrderDetails?.mo_postal_code || ""}
                        name={`postal_code_${div.id}`}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={1} md={1}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      className="remove"
                      onClick={() => handleRemoveDiv(div.id)}
                      sx={{ p: 0 }}
                    >
                      <Tooltip title="Delete Route">
                        <DeleteIcon className="deleteButton" />
                      </Tooltip>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}

          <Box display="flex" justifyContent="end" sx={{ my: 4 }}>
            <Tooltip title="Add More Routes">
              <Button className="Btn" onClick={handleAddDiv}>
                <AddCircleOutlineIcon /> Add
              </Button>
            </Tooltip>
          </Box>

          <Box display="flex" justifyContent="end" gap={2} sx={{ mb: 2 }}>
            <Button
              onClick={() => navigate("/msp/routers")}
              variant="outlined"
              type="button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              className="Btn"
            >
              {EditrouterDetails ? "Update" : "Create"}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
