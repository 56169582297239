import React, { useState, useEffect, useReducer } from "react";
import "react-phone-input-2/lib/style.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CloseIcon from "../../../assets/icons/Close Square.svg";
import { MachineService } from "../../../services/MachineService";
import Loader from "../../../components/Loader";
import { apiBaseUrl } from "../../../config";
import axios from "axios";
import CustomPhoneInput from "../../../components/customPhoneInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "1050px",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const initialValues = {
  owner_name: "",
  location: "",
  serial: "",
  machine_name: "",
  machine_id: "",
  manufacturer_id: "",
  model_id: "",
  service_number: "",
  move_update_id: "",
  mass_id: "",
  verifier_id: "",
  scale_id: "",
  primary_ocr_id: "",
  secondary_ocr_id: "",
  number_of_bins: "",
  bin_type_id: "",
  doubles_detector_id: "",
  thickness_detector_id: "",
  imb_printer_id: "",
  coa_printer_id: "",
  endorsement_printer_id: "",
  graphics_printer_id: "",
  labeler_id: "",
  feeder_id: "",
  max_throughput: "",
  production_throughput: "",
  percentage_realization_production: "",
  machine_enabled_disable_for_production: "",
  preventive_maintenance_time_hrs: "",
  logs_attached: "",
  log: "",
  first_name: "",
  last_name: "",
  shift_id: "",
  email: "",
  phone: "",
  priority: "",
  actual_production_throughput: "",
};

const initialState = {
  contact_number: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "HANDLE CHANGE":
      return {
        ...state,
        [action.field]: action.payload,
      };

    default:
      return state;
  }
};

export default function MachineCreate({ show, close }) {
  const [user, dispatch] = useReducer(reducer, initialState);
  const [expanded, setExpanded] = React.useState("");
  const [modalHeight, setModalHeight] = React.useState("70vh");
  const [machines, setMachines] = React.useState(initialValues);
  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [machineDetails, setMachineDetails] = React.useState({
    open: false,
    ownersList: [],
    machineList: [],
    siteList: [],
    manufacturerList: [],
    modelList: [],
    primaryList: [],
    secondaryList: [],
    verifierList: [],
    binTypeList: [],
    doubleDetectorList: [],
    imbPrinterList: [],
    coaPrinterList: [],
    scalerList: [],
    labelerList: [],
    graphicsList: [],
    endorsementList: [],
    feederList: [],
    maintenanceList: [],
    maintenanceId: [],
  });

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setModalHeight(isExpanded ? "85vh" : "70vh");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true);
        const [
          ownerListResponse,
          machineListResponse,
          manufacturerListResponse,
          modelListResponse,
          primaryListResponse,
          secondaryListResponse,
          verifierListResponse,
          binTypeListResponse,
          doubledetectorListResponse,
          imbPrinterListResponse,
          coaPrinterListResponse,
          scaleListResponse,
          labelerListResponse,
          graphicsListResponse,
          endorsementListResponse,
          feederListResponse,
          maintenanceListResponse,
          maintenanceIdListResponse,
        ] = await Promise.all([
          MachineService.getOwnerListDetails(),
          MachineService.getMachinesList(),
          MachineService.getMachineManufacturerList(),
          MachineService.getMachineModelList(),
          MachineService.getMachinePrimaryList(),
          MachineService.getMachineSecondaryList(),
          MachineService.getMachineVerifierList(),
          MachineService.getMachineBintypeList(),
          MachineService.getMachineDoubleDetectorList(),
          MachineService.getMachineImbPrinterList(),
          MachineService.getMachineCoaPrinterList(),
          MachineService.getMachineScaleList(),
          MachineService.getMachinelabelerList(),
          MachineService.getMachinegraphicsList(),
          MachineService.getMachineEndorsementList(),
          MachineService.getMachineFeederList(),
          MachineService.getMachineMaintenanceList(),
          MachineService.getOwnerDetails(),
        ]);
        setMachineDetails((prevState) => ({
          ...prevState,
          ownersList: ownerListResponse?.data?.msp_account_list,
          machineList: machineListResponse.data,
          manufacturerList:
            manufacturerListResponse?.data?.machine_manufacturer_list,
          modelList: modelListResponse?.data?.machine_models_list,
          primaryList: primaryListResponse.data.machine_primary_ocr_list,
          secondaryList:
            secondaryListResponse?.data?.machine_secondary_ocr_list,
          verifierList:
            verifierListResponse?.data?.machine_verifier_models_list,
          binTypeList: binTypeListResponse.data.machine_bin_type_list,
          doubleDetectorList:
            doubledetectorListResponse.data.machine_doubles_detector_list,
          imbPrinterList: imbPrinterListResponse.data.machine_imb_printer_list,
          coaPrinterList: coaPrinterListResponse.data.machine_coa_printer_list,
          scalerList: scaleListResponse.data.machine_scale_list,
          labelerList: labelerListResponse.data.machine_labeler_list,
          graphicsList: graphicsListResponse.data.machine_graphics_printer_list,
          endorsementList:
            endorsementListResponse.data.machine_endorsement_printer_list,
          feederList: feederListResponse.data.machine_feeder_list,
          maintenanceList:
            maintenanceListResponse.data.machine_maintenance_shift,
          maintenanceId:
            maintenanceIdListResponse.data.machine_maintenance_contact,
        }));
        setLoader(false);
      } catch (error) {
        console.error("Err==>", error);
      }
    };

    fetchData();
  }, []);

  const handleBlur = () => {
    // Resetting the blur effect when the field is unfocused
    document.body.style.filter = "none";
  };

  useEffect(() => {
    try {
      if (machines?.owner_name) {
        let locationId = machineDetails.ownersList.find(
          (val) => val.account_guid == machines?.owner_name
        );
        axios
          .get(`${apiBaseUrl}sites_list_for_machines`, {
            params: { msp_id: locationId.account_id },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setMachineDetails((prevState) => ({
                ...prevState,
                siteList: res.data.site_list,
              }));
            } else {
              toast.error(res.error || "An unexpected error occurred");
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              toast.error("Unauthorized");
            } else {
              toast.error("An error occurred while fetching the data.");
            }
          });
      }
    } catch (error) {
      console.log("Err===>", error);
    }
  }, [machines?.owner_name]);

  const [dates, setDates] = useState({
    expected_service: "",
    production_date_range: "",
    last_preventive_maintenance: "",
    next_scheduled_preventive: "",
    date: "",
  });

  const handleDateChange = (key, newDate) => {
    setDates((prevDates) => ({
      ...prevDates,
      [key]: newDate,
    }));
  };

  const handlePhoneNumber = (value) => {
    dispatch({
      type: "HANDLE CHANGE",
      field: "contact_number",
      payload: value,
    });
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setMachines((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleSubmit = async () => {
    setSubmit(true);

    if (
      !machines ||
      !machines.owner_name ||
      !machines.location ||
      !machines.machine_name ||
      !machines.serial ||
      !machines.manufacturer_id ||
      !machines.model_id ||
      !machines.service_number ||
      !machines.move_update_id ||
      !machines.mass_id ||
      !machines.verifier_id ||
      !machines.scale_id ||
      !machines.primary_ocr_id ||
      !machines.secondary_ocr_id ||
      !machines.number_of_bins ||
      !machines.bin_type_id ||
      !machines.doubles_detector_id ||
      !machines.thickness_detector_id ||
      !machines.imb_printer_id ||
      !machines.coa_printer_id ||
      !machines.endorsement_printer_id ||
      !machines.graphics_printer_id ||
      !machines.labeler_id ||
      !machines.feeder_id ||
      !machines.max_throughput ||
      !dates.expected_service ||
      !machines.production_throughput ||
      !dates.production_date_range ||
      !machines.percentage_realization_production ||
      !machines.machine_enabled_disable_for_production ||
      !dates.last_preventive_maintenance ||
      !dates.next_scheduled_preventive ||
      !machines.preventive_maintenance_time_hrs ||
      !dates.date ||
      !machines.logs_attached ||
      !machines.log ||
      !machines.first_name ||
      !machines.last_name ||
      !machines.shift_id ||
      !machines.email ||
      !user.contact_number ||
      !machines.priority ||
      !machines.actual_production_throughput
    ) {
      toast.error("Please fill all the fields");

      return false;
    }

    const data = {
      owner_id: machines.owner_name,
      location_id: machines.location,
      serial: machines.serial,
      machine_name: machines.machine_name,
      manufacturer_id: machines.manufacturer_id,
      model_id: machines.model_id,
      service_number: machines.service_number,
      mass_id: machines.mass_id,
      move_update_id: machines.move_update_id,

      verifier_id: machines.verifier_id,
      scale_id: machines.scale_id,
      primary_ocr_id: machines.primary_ocr_id,
      secondary_ocr_id: machines.secondary_ocr_id,
      number_of_bins: parseInt(machines.number_of_bins),
      bin_type_id: machines.bin_type_id,
      doubles_detector_id: machines.doubles_detector_id,
      thickness_detector_id: machines.thickness_detector_id,
      imb_printer_id: machines.imb_printer_id,
      coa_printer_id: machines.coa_printer_id,
      endorsement_printer_id: machines.endorsement_printer_id,
      graphics_printer_id: machines.graphics_printer_id,
      labeler_id: machines.labeler_id,
      feeder_id: machines.feeder_id,

      max_throughput: parseInt(machines.max_throughput),
      expected_capable_for_service: dayjs(dates.expected_service).format(
        "YYYY-MM-DD hh:mm:ss"
      ),
      production_throughput: parseInt(machines.production_throughput),
      actual_production_throughput: parseInt(
        machines.actual_production_throughput
      ),
      production_date_range: dayjs(dates.production_date_range).format(
        "YYYY-MM-DD"
      ),
      percentage_realization_production: parseInt(
        machines.percentage_realization_production
      ),
      machine_enabled_disable_for_production:
        machines.machine_enabled_disable_for_production,
      last_preventive_maintenance_completed: dayjs(
        dates.last_preventive_maintenance
      ).format("YYYY-MM-DD"),
      next_scheduled_preventive_maintenance: dayjs(
        dates.next_scheduled_preventive
      ).format("YYYY-MM-DD"),
      preventive_maintenance_time_hrs: parseInt(
        machines.preventive_maintenance_time_hrs
      ),
      date: dayjs(dates.date).format("YYYY-MM-DD"),
      logs_attached: parseInt(machines.logs_attached),
      log: machines.log,
      first_name: machines.first_name,
      last_name: machines.last_name,
      shift_id: machines.shift_id,
      email: machines.email,
      phone: user.contact_number,
      priority: machines.priority,
    };

    function convertToFormData(data) {
      const formData = new FormData();
      for (const key in data) {
        if (data?.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }

      return formData;
    }

    const formData = convertToFormData(data);

    try {
      MachineService.postMachineDetails(formData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Machine Created Successfully");
            close("success");
          }
        })
        .catch((error) => {
          toast.error(error.data?.detail || "Something went wrong");
        });
    } catch (error) {
      console.log("Err===>", error);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={show}
        className="modal-wrap site-creation-modal roleCreateModal"
        onClose={close}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={show}>
          <Box
            sx={style}
            className="accordionModal eii-machine-create"
            // style={{ height: modalHeight }}
          >
            {loader && <Loader />}
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              className="mspRoleHeader"
            >
              <b> Machine creation</b>
              <Button onClick={close} className="closeButton">
                <img src={CloseIcon} alt="createIcon" className="closeicon" />
              </Button>
            </Typography>

            <Box
              sx={{ width: "100%", typography: "body1" }}
              className="accordionDetails eii-machine-create"
            >
              {/* common fields */}
              <Grid container sx={{ mt: 0 }}>
                <Grid
                  item
                  xs={12}
                  lg={3}
                  xl={3}
                  md={3}
                  className="machineDetails"
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Owner</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Owner"
                      name="owner_name"
                      onChange={handleChange}
                    >
                      {machineDetails?.ownersList.map((owners) => (
                        <MenuItem
                          value={owners.account_guid}
                          key={owners.account_guid}
                        >
                          {owners.account_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {submit && !machines.owner_name && (
                    <span className="errorMessage">Owner Name is required</span>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={3}
                  xl={3}
                  md={3}
                  className="machineDetails"
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Location
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Location"
                      name="location"
                      onChange={handleChange}
                    >
                      {machineDetails?.siteList.map((sites) => (
                        <MenuItem value={sites.site_guid} key={sites.site_guid}>
                          {sites.site_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {submit && !machines.location && (
                    <span className="errorMessage">Location is required</span>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={3}
                  xl={3}
                  md={3}
                  className="machineDetails"
                >
                  <TextField
                    fullWidth
                    id="outlined-multiline-flexible"
                    label="Machine Name"
                    size="small"
                    inputProps={{ maxLength: 32 }}
                    name="machine_name"
                    onChange={handleChange}
                  />
                  {submit && !machines.machine_name && (
                    <span className="errorMessage">
                      Machine Name is required
                    </span>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={3}
                  xl={3}
                  md={3}
                  className="machineDetails"
                >
                  <TextField
                    fullWidth
                    id="outlined-multiline-flexible"
                    label="Serial"
                    size="small"
                    inputProps={{ maxLength: 50 }}
                    name="serial"
                    onChange={handleChange}
                  />
                  {submit && !machines.serial && (
                    <span className="errorMessage">Serial is required</span>
                  )}
                </Grid>
              </Grid>
              {/* config  */}
              <Accordion
                sx={{ my: 1 }}
                className="Accordion"
                expanded={expanded === "panel1"}
                onChange={handleAccordionChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon className="accordion_icon" />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="accordian_header"
                  sx={{
                    mb: 1,
                    background: "#693e75",
                    borderLeft: "7px solid #a88aae",
                  }}
                >
                  <Typography>
                    <b>Machine Config</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ mt: 2 }}>
                  <Grid container spacing={2}>
                    {/* Manufacturer */}
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <Box className="machineFields">
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Manufacturer
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Manufacturer"
                              name="manufacturer_id"
                              onChange={handleChange}
                            >
                              {machineDetails.manufacturerList.map(
                                (manufacturer) => (
                                  <MenuItem
                                    value={manufacturer.machine_manufacturer_id}
                                    key={manufacturer.machine_manufacturer_id}
                                  >
                                    {manufacturer.name_of_machine_oem}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          {submit && !machines.manufacturer_id && (
                            <span className="errorMessage">
                              Manufacturer Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Model
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Model"
                              name="model_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.modelList.map((model) => (
                                <MenuItem
                                  value={model.machine_model_id}
                                  key={model.machine_model_id}
                                >
                                  {model.machine_model_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {submit && !machines.model_id && (
                            <span className="errorMessage">
                              Model Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Service Number"
                            size="small"
                            inputProps={{ maxLength: 32 }}
                            name="service_number"
                            onChange={handleChange}
                          />
                          {submit && !machines.service_number && (
                            <span className="errorMessage">
                              Service Number is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Mass ID"
                            size="small"
                            inputProps={{ maxLength: 4 }}
                            name="mass_id"
                            onChange={handleChange}
                          />
                          {submit && !machines.mass_id && (
                            <span className="errorMessage">
                              Mass Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Move Update ID"
                            size="small"
                            inputProps={{ maxLength: 4 }}
                            name="move_update_id"
                            onChange={handleChange}
                          />
                          {submit && !machines.mass_id && (
                            <span className="errorMessage">
                              MOve Update Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Verifier
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Verifier"
                              name="verifier_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.verifierList.map((verify) => (
                                <MenuItem
                                  value={verify.machine_verifier_model_id}
                                  key={verify.machine_verifier_model_id}
                                >
                                  {verify.machine_verifier_model}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {submit && !machines.verifier_id && (
                            <span className="errorMessage">
                              verifier Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Scale
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Scale"
                              name="scale_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.scalerList.map((scale) => (
                                <MenuItem
                                  value={scale.machine_scale_id}
                                  key={scale.machine_scale_id}
                                >
                                  {scale.name_of_machine_scale}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {submit && !machines.scale_id && (
                            <span className="errorMessage">
                              Scale Id is required
                            </span>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                    {/*   OCR */}
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <Box className="machineFields">
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Primary OCR
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Primary OCR"
                              name="primary_ocr_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.primaryList.map((primary) => (
                                <MenuItem
                                  value={primary.machine_primary_ocr_id}
                                  key={primary.machine_primary_ocr_id}
                                >
                                  {primary.name_of_machine_primary_ocr}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {submit && !machines.primary_ocr_id && (
                            <span className="errorMessage">
                              Primary Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Secondary OCR
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Secondary OCR"
                              name="secondary_ocr_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.secondaryList.map((secondary) => (
                                <MenuItem
                                  value={secondary.machine_secondary_ocr_id}
                                  key={secondary.machine_secondary_ocr_id}
                                >
                                  {secondary.name_of_machine_secondary_ocr}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {submit && !machines.secondary_ocr_id && (
                            <span className="errorMessage">
                              Secondary Id is required
                            </span>
                          )}
                        </Grid>

                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Number of Bins"
                            size="small"
                            inputProps={{ maxLength: 4 }}
                            name="number_of_bins"
                            type="number"
                            onChange={handleChange}
                          />
                          {submit && !machines.number_of_bins && (
                            <span className="errorMessage">
                              Number of Bin is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Bin Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label=" Bin Type"
                              name="bin_type_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.binTypeList.map((bin) => (
                                <MenuItem
                                  value={bin.machine_bin_type_id}
                                  key={bin.machine_bin_type_id}
                                >
                                  {bin.machine_bin_type}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {submit && !machines.bin_type_id && (
                            <span className="errorMessage">
                              Bintype Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Doubles Detector
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Doubles Detectors"
                              name="doubles_detector_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.doubleDetectorList.map(
                                (detect) => (
                                  <MenuItem
                                    value={detect.doubles_detector_id}
                                    key={detect.doubles_detector_id}
                                  >
                                    {detect.name_of_doubles_detector}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          {submit && !machines.doubles_detector_id && (
                            <span className="errorMessage">
                              Doubles detector Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Thickness Detector
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Thickness Detectors"
                              name="thickness_detector_id"
                              size="small"
                              onChange={handleChange}
                            >
                              <MenuItem value="1">Yes</MenuItem>
                              <MenuItem value="2">N/A</MenuItem>
                            </Select>
                          </FormControl>
                          {submit && !machines.thickness_detector_id && (
                            <span className="errorMessage">
                              Thickness Detector Id is required
                            </span>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                    {/* Printer */}
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <Box className="machineFields">
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              IMB Printer
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Doubles Detectors"
                              name="imb_printer_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.imbPrinterList.map((imb) => (
                                <MenuItem
                                  value={imb.imb_printer_id}
                                  key={imb.imb_printer_id}
                                >
                                  {imb.name_of_imb_printer}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {submit && !machines.imb_printer_id && (
                            <span className="errorMessage">
                              IMB Printer Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              COA Printer
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="COA Printer"
                              name="coa_printer_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.coaPrinterList.map((coa) => (
                                <MenuItem
                                  value={coa.machine_coa_printer_id}
                                  key={coa.machine_coa_printer_id}
                                >
                                  {coa.name_of_machine_coa_printer}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {submit && !machines.coa_printer_id && (
                            <span className="errorMessage">
                              COA Printer Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Endorsement Printer
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Endorsement Printer"
                              name="endorsement_printer_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.endorsementList.map(
                                (document) => (
                                  <MenuItem
                                    value={
                                      document.machine_endorsement_printer_id
                                    }
                                    key={
                                      document.machine_endorsement_printer_id
                                    }
                                  >
                                    {document.machine_endorsement_printer}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          {submit && !machines.endorsement_printer_id && (
                            <span className="errorMessage">
                              Endorsement Printer Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Graphics Printer
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Graphics Printer"
                              name="graphics_printer_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.graphicsList.map((graphics) => (
                                <MenuItem
                                  value={graphics.graphics_printer_id}
                                  key={graphics.graphics_printer_id}
                                >
                                  {graphics.name_of_graphics_printer}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {submit && !machines.graphics_printer_id && (
                            <span className="errorMessage">
                              Graphics Printer Id is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Labeler
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Endorsement Printer"
                              name="labeler_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.labelerList.map((labler) => (
                                <MenuItem
                                  value={labler.machine_labeler_id}
                                  key={labler.machine_labeler_id}
                                >
                                  {labler.name_of_machine_labeler}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {submit && !machines.labeler_id && (
                            <span className="errorMessage">
                              Labeler Id is required
                            </span>
                          )}
                        </Grid>

                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Feeder
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Endorsement Printer"
                              name="feeder_id"
                              size="small"
                              onChange={handleChange}
                            >
                              {machineDetails.feederList.map((feeder) => (
                                <MenuItem
                                  key={feeder.machine_feeder_id}
                                  value={feeder.machine_feeder_id}
                                >
                                  {feeder.name_of_machine_feeder}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {submit && !machines.feeder_id && (
                            <span className="errorMessage">
                              Feeder Id is required
                            </span>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {/* Productivity */}
              <Accordion
                sx={{ my: 1 }}
                className="Accordion"
                expanded={expanded === "panel2"}
                onChange={handleAccordionChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon className="accordion_icon" />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className="accordian_header"
                  sx={{
                    mb: 2,
                    background: "#693e75",
                    borderLeft: "7px solid #a88aae",
                  }}
                >
                  <Typography>
                    <b>Machine Productivity</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} sx={{ mt: 1 }}>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <Box className="machineFields">
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Max Throughput"
                            size="small"
                            type="number"
                            inputProps={{ maxLength: 32 }}
                            name="max_throughput"
                            onChange={handleChange}
                          />

                          {submit && !machines.max_throughput && (
                            <span className="errorMessage">
                              Max Throughput is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label="Expected Capable for Service"
                              name="expected_service"
                              onChange={(expected_service) =>
                                handleDateChange(
                                  "expected_service",
                                  expected_service
                                )
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              minDate={dayjs(new Date())}
                            />
                          </LocalizationProvider>
                          {submit && !dates.expected_service && (
                            <span className="errorMessage">
                              Expected Service is required
                            </span>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <Box className="machineFields">
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Production Throughput"
                            size="small"
                            type="number"
                            inputProps={{ maxLength: 32 }}
                            name="production_throughput"
                            onChange={handleChange}
                          />
                          {submit && !machines.production_throughput && (
                            <span className="errorMessage">
                              Production Throughput is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Actual Production Throughput"
                            size="small"
                            type="number"
                            inputProps={{ maxLength: 32 }}
                            name="actual_production_throughput"
                            onChange={handleChange}
                          />
                          {submit && !machines.actual_production_throughput && (
                            <span className="errorMessage">
                              Actual Production Throughput is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          {/* <h5 style={{ margin: "0 0 5px 0" }}>
                            Production Date Range
                          </h5> */}
                          <Grid container>
                            <Grid lg={12} sx={{ width: "100%" }}>
                              {" "}
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label=" Production Date Range"
                                  name="production_date_range"
                                  onChange={(newDate) =>
                                    handleDateChange(
                                      "production_date_range",
                                      newDate
                                    )
                                  }
                                  minDate={dayjs(new Date())}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      fullWidth: true,
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              {submit && !dates.production_date_range && (
                                <span className="errorMessage">
                                  Production Date Range is required
                                </span>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Percentage realization production"
                            size="small"
                            type="number"
                            inputProps={{ maxLength: 32 }}
                            name="percentage_realization_production"
                            onChange={handleChange}
                          />
                          {submit &&
                            !machines.percentage_realization_production && (
                              <span className="errorMessage">
                                Percentage Realization is required
                              </span>
                            )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Machine Enabled / Disable for Production{" "}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Disable for Production"
                              name="machine_enabled_disable_for_production"
                              size="small"
                              onChange={handleChange}
                            >
                              <MenuItem value="Enabled">Enable</MenuItem>
                              <MenuItem value="Disabled">Disabled</MenuItem>
                            </Select>
                          </FormControl>
                          {submit &&
                            !machines.machine_enabled_disable_for_production && (
                              <span className="errorMessage">
                                Machine Enable is required
                              </span>
                            )}
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <Box className="machineFields">
                        <Grid item className="machineDetails">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              fullWidth
                              label="Last Preventive Maintenance completed"
                              onChange={(newDate) =>
                                handleDateChange(
                                  "last_preventive_maintenance",
                                  newDate
                                )
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              name="last_preventive_maintenance"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              minDate={dayjs(new Date())}
                            />
                          </LocalizationProvider>
                          {submit && !dates.last_preventive_maintenance && (
                            <span className="errorMessage">
                              Last Preventive Maintenance is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              fullWidth
                              label="Next Scheduled Preventive Maintenance"
                              onChange={(newDate) =>
                                handleDateChange(
                                  "next_scheduled_preventive",
                                  newDate
                                )
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              name="next_scheduled_preventive"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              minDate={dayjs(new Date())}
                            />
                          </LocalizationProvider>
                          {submit && !dates.next_scheduled_preventive && (
                            <span className="errorMessage">
                              Next Scheduled is required
                            </span>
                          )}
                        </Grid>
                        <Grid item className="machineDetails">
                          <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            label="Preventive Maintenance Time (hrs.)"
                            size="small"
                            type="number"
                            inputProps={{ maxLength: 3 }}
                            name="preventive_maintenance_time_hrs"
                            onChange={handleChange}
                          />
                          {submit &&
                            !machines.preventive_maintenance_time_hrs && (
                              <span className="errorMessage">
                                Preventive Hours is required
                              </span>
                            )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {/* Log */}
              <Accordion
                sx={{ my: 1 }}
                className="Accordion"
                expanded={expanded === "panel3"}
                onChange={handleAccordionChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon className="accordion_icon" />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="accordian_header"
                  sx={{
                    mb: 2,
                    background: "#693e75",
                    borderLeft: "7px solid #a88aae",
                  }}
                >
                  <Typography>
                    <b>Machine Log</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          label="Date"
                          onChange={(newDate) =>
                            handleDateChange("date", newDate)
                          }
                          renderInput={(params) => <TextField {...params} />}
                          name="date"
                          slotProps={{
                            textField: {
                              size: "small",
                              fullWidth: true,
                            },
                          }}
                          minDate={dayjs(new Date())}
                        />
                      </LocalizationProvider>
                      {submit && !dates.date && (
                        <span className="errorMessage">Date is required</span>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <TextField
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="Attach Log"
                        size="small"
                        inputProps={{ maxLength: 64 }}
                        name="logs_attached"
                        type="number"
                        onChange={handleChange}
                      />
                      {submit && !machines.logs_attached && (
                        <span className="errorMessage">
                          Logs Attached is required
                        </span>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <TextField
                        className="comments-field"
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="Comments"
                        multiline
                        // maxRows={4}
                        size="small"
                        name="log"
                        onChange={handleChange}
                      />
                      {submit && !machines.log && (
                        <span className="errorMessage">Log is required</span>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {/* contacts */}
              <Accordion
                sx={{ my: 1 }}
                className="Accordion"
                expanded={expanded === "panel4"}
                onChange={handleAccordionChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon className="accordion_icon" />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className="accordian_header"
                  sx={{
                    background: "#693e75",
                    borderLeft: "7px solid #a88aae",
                  }}
                >
                  <Typography>
                    <b>Maintenance Contacts</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container sx={{ mt: 1 }} spacing={1}>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <FormControl
                        fullWidth
                        size="small"
                        className="eii-machine-maintenance-contact"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Shift
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Shift"
                          name="shift_id"
                          onChange={handleChange}
                          // className="shiftclass"
                          onBlur={handleBlur}
                        >
                          {machineDetails.maintenanceList.map((shift) => (
                            <MenuItem
                              value={shift.machine_maintenance_shift_id}
                              key={shift.machine_maintenance_shift_id}
                            >
                              {shift.machine_maintenance_shift}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {submit && !machines.shift_id && (
                        <span className="errorMessage">
                          Shift Id is required
                        </span>
                      )}
                    </Grid>
                    <Grid
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      item
                      className="machineDetails"
                    >
                      <TextField
                        className="eii-machine-maintenance-contact"
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="First Name"
                        size="small"
                        inputProps={{ maxLength: 32 }}
                        name="first_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {submit && !machines.first_name && (
                        <span className="errorMessage">
                          First Name is required
                        </span>
                      )}
                    </Grid>
                    <Grid
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      item
                      className="machineDetails"
                    >
                      <TextField
                        className="eii-machine-maintenance-contact"
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="Last Name"
                        size="small"
                        inputProps={{ maxLength: 32 }}
                        name="last_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {submit && !machines.last_name && (
                        <span className="errorMessage">
                          Last Name is required
                        </span>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <TextField
                        className="eii-machine-maintenance-contact"
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="Email"
                        size="small"
                        inputProps={{ maxLength: 64 }}
                        name="email"
                        onChange={handleChange}
                        type="email"
                        onBlur={handleBlur}
                      />
                      {submit && !machines.email && (
                        <span className="errorMessage">Email is required</span>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <CustomPhoneInput
                        className="eii-machine-maintenance-contact contact-number"
                        defaultCountry="us"
                        onlyCountries={["us"]}
                        disableCountryCode
                        country="us"
                        disableDropdown={true}
                        editable={user.contact_number}
                        name="contact_number"
                        onChange={(value) => {
                          handlePhoneNumber(value);
                        }}
                      />
                      {submit && !user.contact_number && (
                        <span className="errorMessage">
                          Phone Number is required
                        </span>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      xl={4}
                      md={4}
                      className="machineDetails"
                    >
                      <TextField
                        className="eii-machine-maintenance-contact"
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="Priority"
                        size="small"
                        inputProps={{ maxLength: 2 }}
                        name="priority"
                        onChange={handleChange}
                      />
                      {submit && !machines.priority && (
                        <span className="errorMessage">
                          Priority is required
                        </span>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box display="flex" justifyContent="end" gap={2}>
              <Button variant="outlined" type="submit" onClick={close}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                variant="outlined"
                className="Btn"
                type="submit"
              >
                Create
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
