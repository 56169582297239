import React, { useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "../../style/home.scss";
import {
  Box,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { styled } from "@mui/material/styles";
import MachineStatusTable from "./machineStatusTable";
import MachineTable from "./machineTable";
import ThroughputGraph from "./throughputGraph";
import PieceCountGraph from "./pieceCountGraph";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Menu from "@mui/material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import FilterWidgets from "./filterWidgets";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Modal from "@mui/material/Modal";
import PieChart from "./pieChart";
import Alert from "@mui/material/Alert";
const ResponsiveGridLayout = WidthProvider(Responsive);
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%",
  borderRadius: "5px",
}));
const cols = 12;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "50%",
  borderRadius: "10px",
  border: "none",
};
export default function SimpleDrag() {
  const [layout, setLayout] = React.useState([
    { i: "a", index: "0", x: 0, y: 0, w: 4, h: 2, gridname: "Dropdown" },
    { i: "b", index: "1", x: 4, y: 0, w: 4, h: 2, gridname: "Status Table" },
    { i: "c", index: "2", x: 8, y: 0, w: 4, h: 2, gridname: "Machine Table" },
    {
      i: "d",
      index: "3",
      x: 0,
      y: 1,
      w: 6,
      h: 2,
      gridname: "Throughput Graph",
    },
    {
      i: "e",
      index: "4",
      x: 6,
      y: 1,
      w: 6,
      h: 2,
      gridname: "Piececount Graph",
    },
    {
      i: "f",
      index: "5",
      x: 0,
      y: 2,
      w: 6,
      h: 3,
      gridname: "Piechart Graph",
    },
  ]);
  // msp user ,msp operator, mail ownerDocument,mail user
  const [widgetMOdal, setWidgetModal] = useState({
    a: false,
    b: false,
    c: false,
    d: false,
    e: false,
    f: false,
  });
  const [age, setAge] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const responsiveProps = {
    className: "responsive-grid",
    margin: [10, 10],
    breakpoints: { xl: 1600, lg: 1200, md: 960, sm: 720, xs: 480, xxs: 0 },
    cols: { xl: cols, lg: cols, md: cols, sm: cols, xs: cols, xxs: cols },
    layouts: {
      xl: layout,
      lg: layout,
      md: layout,
      sm: layout,
      xs: layout,
      xxs: layout,
    },
  };

  const handlePopOverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };
  const handleModalOpen = (key) => {
    setModalOpen(true);
    setWidgetModal({ ...widgetMOdal, [key]: true });
  };
  const handleModalClose = () => setModalOpen(false);
  const open = Boolean(anchorEl);
  const dataGridRef = React.useRef(null);
  const dataApiRef = React.useRef(null);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleInputMouseDown = (event) => {
    event.stopPropagation();
  };
  const handleDeleteWidget = (key) => {
    let restItems = [...table];
    restItems.splice(key, 1);
    settable(restItems);
  };
  const exportAsPdf = async () => {
    document.getElementsByClassName("appBar")[0].style.display = "none";
    document.getElementsByClassName("drawers")[0].style.display = "none";
    document.getElementsByClassName("drawerHeader")[0].style.display = "none";
    setTimeout(async () => {
      const pdf = new jsPDF("portrait", "pt", "a4");
      const data = await html2canvas(document.querySelector(".dashboardkpi"), {
        width: dataApiRef.current.offsetWidth + 100,
        height: dataApiRef.current.offsetHeight + 100,
        scale: 1,
      });
      const img = data.toDataURL("image/png");
      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, "PNG", 20, 10, pdfWidth, pdfHeight);
      pdf.save("EII-Reports.pdf");

      document.getElementsByClassName("appBar")[0].style.display = "block";
      document.getElementsByClassName("drawers")[0].style.display = "block";
      document.getElementsByClassName("drawerHeader")[0].style.display =
        "block";
    }, 1000);
  };

  const exportAsPng = () => {
    document.getElementsByClassName("appBar")[0].style.display = "none";
    document.getElementsByClassName("drawers")[0].style.display = "none";
    document.getElementsByClassName("drawerHeader")[0].style.display = "none";
    html2canvas(dataApiRef.current, {
      width: dataApiRef.current.offsetWidth + 100,
      height: dataApiRef.current.offsetHeight + 100,
      scale: 1,
    }).then((canvas) => {
      const modifiedImage = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = modifiedImage;
      link.download = "EII-Reports.png";
      link.click();
    });
    document.getElementsByClassName("appBar")[0].style.display = "block";
    document.getElementsByClassName("drawers")[0].style.display = "block";
    document.getElementsByClassName("drawerHeader")[0].style.display = "block";
  };

  const [table, settable] = useState([
    <div key="a" className="kpiData">
      <Item sx={{ height: "100%" }} elevation={6} className="dropdownContainer">
        <Box display="flex" justifyContent="space-between">
          <div
            onMouseDown={(e) => {
              e.stopPropagation();
              handleModalOpen("a");
            }}
            className="fullScreen"
          >
            <FullscreenIcon />
          </div>

          <div
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => {
              handleDeleteWidget(0);
            }}
            className="closeWidget"
          >
            <CloseIcon fontSize="12px" />
          </div>
        </Box>

        <Box sx={{ p: "18px 10px" }}>
          <Box>
            <h4>Shift Dropdown</h4>
            <FormControl fullWidth size="small" className="formItem">
              <InputLabel id="demo-simple-select-label">Shift</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                onChange={handleChange}
                defaultValue="Twenty"
                label="Shift"
                onMouseDown={handleInputMouseDown}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mt={5}>
            <h4 style={{ textAlign: "left", margin: 0 }}>Machine Dropdown</h4>
            <FormControl fullWidth size="small" className="formItem">
              <InputLabel id="demo-simple-select-label">Machine</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                onChange={handleChange}
                defaultValue="Twenty"
                label="Machine"
                onMouseDown={handleInputMouseDown}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Item>
    </div>,
    <div key="b" className="kpiData">
      <Item elevation={6}>
        <Box display="flex" justifyContent="space-between">
          <div
            onMouseDown={(e) => {
              e.stopPropagation();
              handleModalOpen("b");
            }}
            className="fullScreen"
          >
            <FullscreenIcon />
          </div>
          <div
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => {
              handleDeleteWidget(1);
            }}
            className="closeWidget"
          >
            <CloseIcon fontSize="12px" />
          </div>
        </Box>
        <MachineStatusTable dataGridref={dataGridRef} />
      </Item>{" "}
    </div>,
    <div key="c" className="kpiData">
      <Item elevation={6}>
        <Box display="flex" justifyContent="space-between">
          <div
            onMouseDown={(e) => {
              e.stopPropagation();
              handleModalOpen("c");
            }}
            className="fullScreen"
          >
            <FullscreenIcon />
          </div>
          <div
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => {
              handleDeleteWidget(2);
            }}
            className="closeWidget"
          >
            <CloseIcon fontSize="12px" />
          </div>
        </Box>
        <MachineTable />
      </Item>{" "}
    </div>,
    <div key="d" className="kpiData">
      <Item elevation={6}>
        <Box display="flex" justifyContent="space-between">
          <div
            onMouseDown={(e) => {
              e.stopPropagation();
              handleModalOpen("d");
            }}
            className="fullScreen"
          >
            <FullscreenIcon />
          </div>
          <div
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => {
              handleDeleteWidget(3);
            }}
            className="closeWidget"
          >
            <CloseIcon fontSize="12px" />
          </div>
        </Box>
        {/* <Box display="flex" justifyContent="flex-end" mb={2}>
          <DatePickerViews />
        </Box> */}
        <div style={{ height: "auto", width: "100%" }}>
          <ThroughputGraph />
        </div>
      </Item>{" "}
    </div>,
    <div key="e" className="kpiData">
      <Item elevation={6}>
        <Box display="flex" justifyContent="space-between">
          <div
            onMouseDown={(e) => {
              e.stopPropagation();
              handleModalOpen();
              setWidgetModal({ ...widgetMOdal, e: true });
            }}
            className="fullScreen"
          >
            <FullscreenIcon />
          </div>
          <div
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => {
              handleDeleteWidget(4);
            }}
            className="closeWidget"
          >
            <CloseIcon fontSize="12px" />
          </div>
        </Box>
        {/* <Box display="flex" justifyContent="flex-end" mb={2}>
          <DatePickerViews />
        </Box> */}
        <div style={{ height: "auto", width: "100%" }}>
          <PieceCountGraph />
        </div>
      </Item>{" "}
    </div>,
    <div key="f" className="kpiData">
      <Item elevation={6}>
        <Box display="flex" justifyContent="space-between">
          <div
            onMouseDown={(e) => {
              e.stopPropagation();
              handleModalOpen("f");
              setWidgetModal({ ...widgetMOdal, f: true });
            }}
            className="fullScreen"
          >
            <FullscreenIcon />
          </div>
          <div
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => {
              handleDeleteWidget(4);
            }}
            className="closeWidget"
          >
            <CloseIcon fontSize="12px" />
          </div>
        </Box>

        <div style={{ height: "auto", width: "100%" }}>
          <PieChart />
        </div>
      </Item>{" "}
    </div>,
  ]);
  // useEffect(() => {
  //   checked.map((test) => {
  //     const updated = layout.filter((i) => i !== test);
  //   });
  // }, [checked]);
  return (
    <>
      <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
        <Alert variant="filled" severity="info">
          This reports section is under construction :)
        </Alert>
        <Button
          variant="contained"
          title="Export as PDF"
          color="success"
          startIcon={<FileDownloadIcon />}
          onClick={handlePopOverClick}
        >
          Export Reports
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handlePopOverClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              exportAsPdf();
              handlePopOverClose();
            }}
          >
            Export as PDF
          </MenuItem>
          <MenuItem
            onClick={() => {
              exportAsPng();
              handlePopOverClose();
            }}
          >
            Export as PNG
          </MenuItem>
        </Menu>
        <div>
          <FilterWidgets layoutProps={layout} setchecked={setChecked} />
        </div>
      </Box>
      <div className="dashboardkpi" ref={dataApiRef}>
        <Box
          className="kpiData"
          sx={{ marginTop: "20px !important", marginBottom: "20px !important" }}
        >
          <Box className="kpiDetails" display="flex" alignItems="center">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={3}>
                <div className="wave1">
                  <div className="cardContainer">
                    <h4 className="cardHeader">Piece Count-Minute</h4>
                  </div>
                  <h3 className="cardHeader count ">100</h3>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className="wave2">
                  <div className="cardContainer">
                    <h4 className="cardHeader">Throughput-PPH</h4>
                  </div>
                  <h3 className="cardHeader count running">72</h3>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className="wave3">
                  <div className="cardContainer">
                    <h4 className="cardHeader">Piece Count-Shift</h4>
                  </div>
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection={{ sm: "column", md: "column", lg: "row" }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <h3 className="cardHeader count running">451</h3>

                    <div className="timeDuration eight">
                      <QueryBuilderIcon sx={{ fontSize: "16px" }} />
                      <p>Last 8 hours</p>
                    </div>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className="wave4">
                  <div className="cardContainer">
                    <h4 className="cardHeader">Piece Count-Hour</h4>
                  </div>
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection={{ sm: "column", md: "column", lg: "row" }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <h3 className="cardHeader count running">439</h3>

                    <div className="timeDuration ">
                      <QueryBuilderIcon sx={{ fontSize: "16px" }} />
                      <p>Last 1 hours</p>
                    </div>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <ResponsiveGridLayout {...responsiveProps} className="rglLayout">
          {checked.length === 0
            ? table
            : table.filter((item, index) => checked.includes(index.toString()))}
        </ResponsiveGridLayout>
        {modalOpen && (
          <Modal open={modalOpen} onClose={handleModalClose}>
            <Box sx={style}>
              {widgetMOdal.a && (
                <Box sx={{ p: "18px 10px" }}>
                  <Box>
                    <h4>Shift Dropdown</h4>
                    <FormControl fullWidth size="small" className="formItem">
                      <InputLabel id="demo-simple-select-label">
                        Shift
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        onChange={handleChange}
                        defaultValue="Twenty"
                        label="Shift"
                        onMouseDown={handleInputMouseDown}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mt={5}>
                    <h4>Machine Dropdown</h4>
                    <FormControl fullWidth size="small" className="formItem">
                      <InputLabel id="demo-simple-select-label">
                        Machine
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        onChange={handleChange}
                        defaultValue="Twenty"
                        label="Machine"
                        onMouseDown={handleInputMouseDown}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              )}
              {widgetMOdal.b && (
                <MachineStatusTable dataGridref={dataGridRef} isModalopen />
              )}
              {widgetMOdal.c && <MachineTable isModalopen />}
              {widgetMOdal.d && <ThroughputGraph isModalopen />}
              {widgetMOdal.e && <PieceCountGraph isModalopen />}
              {widgetMOdal.f && <PieChart isModalopen />}
            </Box>
          </Modal>
        )}
      </div>
      {/* <SimplTesteDrag /> */}
    </>
  );
}
