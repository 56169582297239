import React, { forwardRef } from "react";
import { styled } from "@mui/material/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
  "& .form-control": {
    width: "100%",
    height: "40px",
    fontSize: "14px",
    marginTop: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  "& .form-control.error": {
    border: "1px solid red",
  },
}));

const CustomPhoneInput = forwardRef(({ viewable, editable, ...props }, ref) => (
  <StyledPhoneInput
    {...props}
    specialLabel="Contact Number"
    value={viewable ?? (editable ? editable.toString() : "")}
    inputComponent={({ ...inputProps }) => (
      <TextField
        {...inputProps}
        inputRef={ref}
        size="small"
        sx={{ my: 2 }}
        required
        label="Contact Number"
        variant="outlined"
        fullWidth
      />
    )}
  />
));

CustomPhoneInput.propTypes = {
  viewable: PropTypes.string,
  editable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CustomPhoneInput;
