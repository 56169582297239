import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";

import "../../style/style.css";

const columns = [
  { field: "status", headerName: "Status" },
  { field: "id", headerName: "SSF ID" },
  { field: "transactionId", headerName: "Transaction ID", width: "150" },
  { field: "company", headerName: "Company" },
  { field: "site", headerName: "Site" },
  { field: "reference", headerName: "Reference" },
  {
    field: "dateCreated",
    headerName: "Date Created(MM-DD-YYYY)",
    width: "150",
  },
  {
    field: "dateUploaded",
    headerName: "Date Uploaded(MM-DD-YYYY)",
    width: "150",
  },
  { field: "env", headerName: "Env" },
  { field: "records", headerName: "Records" },
  { field: "manifest", headerName: "Manifest" },
];

const rows = [
  {
    status: "Uploaded",
    id: 1,
    transactionId: "TXN123",
    company: "ABC Corp",
    site: "Site A",
    reference: "REF001",
    dateCreated: "04-01-2024",
    dateUploaded: "04-02-2024",
    env: "Production",
    records: 100,
    manifest: "Manifest A",
  },
  {
    status: "Failed",

    id: 2,
    transactionId: "TXN124",
    company: "XYZ Inc",
    site: "Site B",
    reference: "REF002",
    dateCreated: "04-03-2024",
    dateUploaded: "04-04-2024",
    env: "Testing",
    records: 150,
    manifest: "Manifest B",
  },
  {
    status: "Not Started",
    id: 3,
    transactionId: "TXN123",
    company: "ABC Corp",
    site: "Site A",
    reference: "REF001",
    dateCreated: "04-05-2024",
    dateUploaded: "04-06-2024",
    env: "Production",
    records: 100,
    manifest: "Manifest A",
  },
  {
    status: "Manifested",
    id: 4,
    transactionId: "TXN123",
    company: "ABC Corp",
    site: "Site A",
    reference: "REF001",
    dateCreated: "04-07-2024",
    dateUploaded: "04-08-2024",
    env: "Production",
    records: 100,
    manifest: "Manifest A",
  },
];

function CustomDataGrid() {
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    failed: {
      padding: "5px",
      backgroundColor: "#F9B3B9",
      borderRadius: "5px",
      color: "#F04452",
      textAlign: "center",
    },
    uploaded: {
      padding: "5px",
      backgroundColor: "#9BE6CC",
      borderRadius: "5px",
      color: "#369260",
      textAlign: "center",
    },
    manifested: {
      padding: "5px",
      backgroundColor: "#ABC7F9",
      borderRadius: "5px",
      color: "#2359B6",
      textAlign: "center",
    },
    notstarted: {
      padding: "5px",
      backgroundColor: "#FEEAAF",
      borderRadius: "5px",
      color: "#BF982B",
      textAlign: "center",
    },
    tableHead: {
      "& .MuiTableCell-head": {
        color: "white",
        backgroundColor: "blue",
      },
    },
  }));
  const classes = useStyles();

  const renderStatus = (status) => {
    let cellStyle;
    switch (status) {
      case "Failed":
        cellStyle = classes.failed;
        break;
      case "Uploaded":
        cellStyle = classes.uploaded;
        break;
      case "Manifested":
        cellStyle = classes.manifested;
        break;
      case "Not Started":
        cellStyle = classes.notstarted;
        break;
      default:
        cellStyle = classes.uploaded;
    }
    return <div className={cellStyle}>{status}</div>;
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#e0e0e0",
      color: "black",
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <>
      <Box>
        <h1>Job Activity</h1>
        <Grid container spacing={2} sx={{ width: "100%" }}>
          <Grid item lg={3}>
            <FormControl fullWidth>
              <TextField size="small" variant="outlined" label="Company Name" />
            </FormControl>
          </Grid>
          <Grid item lg={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label">Site</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={age}
                label="Site"
                onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label">
                Activity Period
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={age}
                label="Activity Period"
                onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3}>
            <Button
              variant="contained"
              sx={{ fontWeight: 700 }}
              startIcon={<Search />}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <TableContainer component={Paper} sx={{ whiteSpace: "nowrap" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHead}>
                {columns.map((column) => {
                  return (
                    <StyledTableCell key={column.id}>
                      {column.headerName}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {renderStatus(row.status)}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.id}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.transactionId}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.company}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.site}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.reference}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.dateCreated}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.dateUploaded}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.env}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.records}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.manifest}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default CustomDataGrid;
