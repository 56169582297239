import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { DeleteModalProps } from "../types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  borderRadius: "10px",
  p: 0,
};
const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  onClose,
  onDelete,
}) => {
  return (
    <Box>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 1, p: 2 }}
          >
            <b>Are you sure you want to delete?</b>
          </Typography>
          <Box sx={{ mt: 1, px: 2 }}>
            <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
              <Button onClick={onClose} variant="outlined">
                No
              </Button>
              <Button onClick={onDelete} variant="outlined" className="Btn">
                Yes
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DeleteModal;
