import React from "react";
import HomeHeader from "./header";
import Banner from "./Banner";
import "../../style/home.scss";

interface HomepageProps {
  toaster: (message: string, variant: string) => void;
}

const Homepage: React.FC<HomepageProps> = ({ toaster }) => {
  return (
    <div className="home-page-wrap">
      <HomeHeader />
      <Banner />
    </div>
  );
};

export default Homepage;
