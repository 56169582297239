import React, { useState, useEffect, forwardRef } from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../../../config";
import { MspService } from "../../../../services/MspService";
import { styled } from "@mui/material/styles";
import { workOrderService } from "../../../../services/WorkorderService";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const initialState = {
  pieces: "",
  trays: "",
  pallets: "",
  contact_name: "",
  email: "",
  mailing_date: "",
  contact_person_phone: "",
};
const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
  "& .form-control": {
    width: "100%",
    height: "40px",
    fontSize: "14px",
    marginTop: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  "& .form-control.error": {
    border: "1px solid red",
  },
}));
const CustomPhoneInput = forwardRef(({ viewable, editable, ...props }, ref) => (
  <StyledPhoneInput
    {...props}
    specialLabel="Contact Number"
    value={viewable ?? (editable ? editable.toString() : "")}
    inputComponent={({ ...inputProps }) => (
      <TextField
        {...inputProps}
        inputRef={ref}
        size="small"
        sx={{ my: 2 }}
        required
        label="Contact Number"
        variant="outlined"
        fullWidth
      />
    )}
  />
));
export default function ReceiptTicketCreation() {
  const { id, updateid } = useParams();
  const [workOrderSpecificDetails, setWorkOrderSpecificDetails] = useState();

  const [mspTicketDetails, setMspTicketDetails] = useState(initialState);
  const navigate = useNavigate();
  const handleDateChange = (name, date) => {
    setMspTicketDetails({
      ...mspTicketDetails,
      [name]: date,
    });
  };

  useEffect(() => {
    try {
      if (id && id > 0) {
        axios
          .get(`${apiBaseUrl}mo_specific_work_order`, {
            params: { work_order_id: id },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth")}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setWorkOrderSpecificDetails(res.data.work_order_details);
              let obj = {
                pieces: res.data.work_order_details.pieces,
                trays: res.data.work_order_details.trays,
                pallets: res.data.work_order_details.pallets,
                mailing_date: dayjs(new Date()),
              };
              setMspTicketDetails({
                ...mspTicketDetails,
                ...obj,
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              toast.error("Unauthorized");
            }
          });
      } else if (updateid && updateid > 0) {
        toast.success("getting updates details");
      }
    } catch (error) {
      console.log("Err===>", error);
    }
  }, []);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setMspTicketDetails({ ...mspTicketDetails, [name]: value });
  };

  const handleSubmit = async () => {
    if (
      !mspTicketDetails.pieces ||
      !mspTicketDetails.pallets ||
      !mspTicketDetails.trays ||
      !mspTicketDetails.contact_name ||
      !mspTicketDetails.email
    ) {
      toast.error("Please enter the details");
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(mspTicketDetails.email)) {
      toast.error("Please enter the valid email");
      return false;
    }
    const formData = new FormData();

    formData.append("work_order_id", workOrderSpecificDetails?.work_order_id);
    formData.append("contact_person", mspTicketDetails.contact_name);
    formData.append("pieces", mspTicketDetails.pieces);
    formData.append("trays", mspTicketDetails.trays);
    formData.append("pallets", mspTicketDetails.pallets);
    formData.append(
      "mailing_date",
      dayjs(mspTicketDetails.mailing_date).format("YYYY-MM-DD")
    );

    formData.append("contact_person_email", mspTicketDetails.email);
    formData.append(
      "contact_person_phone",
      mspTicketDetails.contact_person_phone
    );

    try {
      workOrderService
        .moticketReceiptCreate(formData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Ticket Created Successfully");
            setMspTicketDetails(initialState);
            navigate("/customer-portal/workorder");
          }
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      toast.error(error.data?.detail || "Something went wrong");
    }
  };

  return (
    <div className="work_order_home_page">
      <div className="ticket_page">
        {" "}
        <div className="workOrder">
          <Box className="workOrder_header">
            <Typography variant="p">Receipt Ticket </Typography>
          </Box>
          <Box>
            <Box className="customerDetails" sx={{ mt: 4 }}>
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Customer Details</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" className="customer-details">
                  <b> Customer Name </b>: {workOrderSpecificDetails?.owner_name}
                </Typography>
              </Box>
            </Box>

            <Box className="customerDetails lineitems">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Line Items</b>
                </Typography>
              </Box>
              <Box className="container" sx={{ mt: 2 }} component="form">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="p" className="lineitemsname">
                      PCS
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      value={mspTicketDetails.pieces}
                      className="lineItemsFields"
                      onChange={handleChange}
                      name="pieces"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="p" className="lineitemsname">
                      Trays
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      className="lineItemsFields"
                      onChange={handleChange}
                      name="trays"
                      value={mspTicketDetails.trays}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="p" className="lineitemsname">
                      Pallets
                    </Typography>
                    <TextField
                      className="lineItemsFields"
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      type="number"
                      onChange={handleChange}
                      name="pallets"
                      value={mspTicketDetails.pallets}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={4}>
                    <Typography variant="p" className="lineitemsname">
                      Contact Name
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      className="lineItemsFields"
                      onChange={handleChange}
                      name="contact_name"
                      value={mspTicketDetails.contact_name}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="p" className="lineitemsname">
                      Contact Email
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      className="lineItemsFields"
                      onChange={handleChange}
                      name="email"
                      value={mspTicketDetails.email}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="p"
                      className="lineitemsname"
                      sx={{ mb: 1 }}
                    >
                      Mailing Date
                    </Typography>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      size="small"
                    >
                      <DatePicker
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                          },
                        }}
                        className="lineItemsFields"
                        onChange={(mailing_date) =>
                          handleDateChange("mailing_date", mailing_date)
                        }
                        value={
                          mspTicketDetails.mailing_date
                            ? dayjs(mspTicketDetails.mailing_date)
                            : null
                        }
                        name="mailing_date"
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="p" className="lineitemsname">
                      Phone Number
                    </Typography>
                    <CustomPhoneInput
                      defaultCountry="us"
                      onlyCountries={["us"]}
                      disableCountryCode
                      country="us"
                      disableDropdown={true}
                      editable={mspTicketDetails.contact_person_phone}
                      name="contact_person_phone"
                      onChange={(value) => {
                        handleDateChange("contact_person_phone", value);
                      }}
                    />
                  </Grid>
                </Grid>

                <Box className="create-cancel-container" sx={{ mt: 3, pr: 0 }}>
                  <Link to="/customer-portal/workorder">
                    <Button variant="outlined" type="submit">
                      Cancel
                    </Button>
                  </Link>

                  <Button
                    variant="contained"
                    className="Btn save"
                    onClick={handleSubmit}
                  >
                    Create
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>{" "}
      </div>
    </div>
  );
}
