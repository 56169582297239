import React from "react";
import { Chart as ChartJS, Legend, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import DatePickerViews from "./yearPicker";
import { Box, Menu, MenuItem } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { exportChartAsPdf, exportChartAsPng } from "../../utils/exportFile";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const getRandomNumber = () => Math.floor(Math.random() * 1000);
const labels = ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"];
export const data = {
  labels: labels,
  datasets: [
    {
      label: "# of MailDat",
      data: labels.map(() => getRandomNumber()),
      backgroundColor: [
        "rgba(255, 99, 132, 0.8)",
        "rgba(54, 162, 235, 0.8)",
        "rgba(255, 206, 86, 0.8)",
        "rgba(75, 192, 192, 0.8)",
        "rgba(153, 102, 255, 0.8)",
        "rgba(255, 159, 64, 0.8)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};
const options = {
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    datalabels: {
      color: "#fff",
      display: true,
      formatter: (value, context) => {
        return context.chart.data.labels[context.dataIndex];
      },
      font: {
        weight: "bold",
        size: 14,
      },
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          return `${tooltipItem.label}: ${tooltipItem.raw} votes`;
        },
      },
    },
  },
};
export default function PieChart({ isModalopen }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handlePopOverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {isModalopen && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box
            display="flex"
            gap="5px"
            alignItems="center"
            justifyContent="center"
            color="dodgerblue"
            sx={{ cursor: "pointer" }}
            onClick={handlePopOverClick}
          >
            <DownloadIcon />
            Export
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handlePopOverClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                exportChartAsPdf("PieceCountGraph");
                handlePopOverClose();
              }}
            >
              Export as PDF
            </MenuItem>
            <MenuItem
              onClick={() => {
                exportChartAsPng("PieceCountGraph");
                handlePopOverClose();
              }}
            >
              Export as PNG
            </MenuItem>
          </Menu>
          <DatePickerViews />
        </Box>
      )}
      <div
        style={
          isModalopen
            ? { height: "400px", overflow: "hidden" }
            : {
                // height: "250px",

                display: "flex",
                justifyContent: "center",
              }
        }
        id="piechartgraph"
      >
        <Doughnut data={data} height={400} options={options} />
      </div>
    </>
  );
}
