import { useEffect, useCallback, useState, useRef } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  Backdrop,
  Fade,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Chip,
  OutlinedInput,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ReactComponent as AddRoleIcon } from "../../../assets/icons/Masked_Icon.svg";
import CloseIcon from "../../../assets/icons/Close Square.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "../../../style/common.css";
import { DataGrid } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
import CustomToolBar from "../../../components/customToolbar/customToolbar";
import { mailingServices } from "../../../services/mailing";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px ",
};
const Mailings = () => {
  const theme = useTheme();
  const [state, setState] = useState({
    open: false,
    personName: [],
    classList: [],
    processCategoryList: [],
    containerPrepList: [],
    accountList: [],
    jobList: [],
    sortProfileList: [],
  });
  const handleOpen = useCallback(() => {
    setState((prevState) => ({ ...prevState, open: true }));
  }, []);

  const handleClose = useCallback(() => {
    setState((prevState) => ({ ...prevState, open: false }));
  }, []);

  const handleMultipleSelectChange = useCallback((event) => {
    const {
      target: { value },
    } = event;
    setState((prevState) => ({
      ...prevState,
      personName: typeof value === "string" ? value.split(",") : value,
    }));
  }, []);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const machineUsedNames = ["All", "Machine 1", "Machine 2"];
  const sortProfilesNames = [
    "All",
    "Profile 1",
    "Submailing 1",
    "Dropship A",
    "Master 1",
  ];

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const columns = [
    { field: "id", headerName: "Mailing ID", width: 90 },
    {
      field: "mailing_name",
      headerName: "Mailing Name",
      width: 150,
    },
    { field: "accounts_used", headerName: "Accounts Used", width: 150 },
    {
      field: "original_software_vendor_name",
      headerName: "Product Name",
      width: 150,
    },
    {
      field: "vendor_email",
      headerName: "Vendor's Email",
      width: 150,
    },
    {
      field: "verification_facility",
      headerName: "Verification Faility",
      width: 150,
    },
    { field: "msp_name", headerName: "MSP's Name", width: 150 },
  ];
  const rows = [
    {
      id: "1",
      mailing_name: "HDR-1103",
      accounts_used: "MSP",
      original_software_vendor_name: "HDR-1174",
      vendor_email: "cms@gmail.com",
      verification_facility: "Indiana",
      msp_name: "CMS",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          classListResponse,
          processCategoryListResponse,
          containerPrepListResponse,
          jobListResponse,
          sortProfileResponse,
        ] = await Promise.all([
          mailingServices.getClassLists(),
          mailingServices.getProcessingCategoryList(),
          mailingServices.getContainerPrepList(),
          mailingServices.getJobList(),
          mailingServices.getSortProfilesList(),
        ]);

        const classList = classListResponse.data.class_list;
        const processCategoryList =
          processCategoryListResponse.data.processing_category_list;

        setState((prevState) => ({
          ...prevState,
          classList,
          processCategoryList,
          containerPrepList: containerPrepListResponse.data.container_prep_list,
          jobList: jobListResponse.data.job_list,
          sortProfileList: sortProfileResponse.data.sort_profile_list,
        }));
      } catch (error) {
        console.log("Err===>", error);
      }
    };

    fetchData();
  }, []);

  const ClassRef = useRef(0);
  const ProcessingCategoryRef = useRef("");

  const getAccountList = useCallback(async () => {
    try {
      const response = await mailingServices.getAccountList({
        ClassRef: ClassRef.current,
        ProcessingCategoryRef: ProcessingCategoryRef.current,
      });
      response &&
        setState((prevState) => ({
          ...prevState,
          accountList: response.data.account_list,
        }));
    } catch (error) {}
  }, []);

  return (
    <Box>
      <div className="roles mspcreation">
        <Box display="flex" justifyContent="flex-end">
          <Alert variant="filled" severity="info">
            This mailing section is under construction :)
          </Alert>
        </Box>
        <Box display="flex" justifyContent="end" mt={3}>
          <Button
            variant="contained"
            sx={{ fontWeight: "bold" }}
            onClick={handleOpen}
            className="Btn"
          >
            <AddRoleIcon /> Add Mailing
          </Button>
        </Box>
        {/* create modal */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={state.open}
          className="modal-wrap site-creation-modal roleCreateModal"
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={state.open}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                className="mspRoleHeader"
              >
                Mail creation
                <Button className="closeButton" onClick={handleClose}>
                  {" "}
                  <img src={CloseIcon} alt="closeicon" className="closeicon" />
                </Button>
              </Typography>

              <Box
                sx={{ width: "100%", typography: "body1" }}
                className="accordionDetails"
              >
                {/* Mailing  */}
                <Accordion sx={{ my: 2 }} defaultExpanded className="Accordion">
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownIcon className="accordion_icon" />
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="accordian_header"
                    sx={{
                      mb: 2,
                      background: "#693e75",
                      borderLeft: "7px solid #a88aae",
                    }}
                  >
                    <Typography>
                      <b>Mailing</b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container mt={2} spacing={2}>
                      <Grid item xs={12} lg={6} md={6} xl={6}>
                        <Box
                          sx={{
                            border: "1px solid #c4c4c4",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <Grid item className="machineDetails" sx={{ my: 2 }}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Licensed User's Job Number"
                              size="small"
                              inputProps={{ maxLength: 25 }}
                              name="service_number"
                            />
                          </Grid>
                          <Grid item className="machineDetails" sx={{ mb: 2 }}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Mailing Name"
                              size="small"
                              inputProps={{ maxLength: 30 }}
                              name="service_number"
                            />
                          </Grid>
                          <Grid item className="machineDetails" sx={{ mb: 2 }}>
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">
                                Class{" "}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Manufacturer"
                                name="manufacturer"
                                onChange={(e) => {
                                  ClassRef.current = e.target.value;
                                  if (
                                    ProcessingCategoryRef.current &&
                                    e.target.value
                                  ) {
                                    getAccountList();
                                  }
                                }}
                              >
                                {state.classList.map((item) => (
                                  <MenuItem
                                    value={item.class_of_mail_id}
                                    key={item.class_of_mail_id}
                                  >
                                    {item.class_of_mail_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item className="machineDetails" sx={{ mb: 2 }}>
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">
                                Processing Category
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Model"
                                name="model"
                                onChange={(e) => {
                                  ProcessingCategoryRef.current =
                                    e.target.value;
                                  if (ClassRef && e.target.value) {
                                    getAccountList();
                                  }
                                }}
                              >
                                {state.processCategoryList.map((item) => (
                                  <MenuItem
                                    value={item.mail_dat_processing_category_id}
                                    key={item.mail_dat_processing_category_id}
                                  >
                                    {item.processing_category}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item className="machineDetails" sx={{ mb: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              {/* <DemoContainer components={["DatePicker"]}> */}
                              <DatePicker
                                label="Expected USPS Drop Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    fullWidth: true,
                                  },
                                }}
                              />
                              {/* </DemoContainer> */}
                            </LocalizationProvider>
                          </Grid>
                          <Grid item className="machineDetails" sx={{ mb: 2 }}>
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">
                                ALT. CONTAINER PREP
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Manufacturer"
                                name="manufacturer"
                                // onChange={handleChange}
                              >
                                {state.containerPrepList.map((res) => (
                                  <MenuItem
                                    value={res.substituted_container_prep_code}
                                    key={res.substituted_container_prep_code}
                                  >
                                    {res.substituted_container_prep_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={6} md={6} xl={6}>
                        <Box
                          sx={{
                            border: "1px solid #c4c4c4",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <Grid item className="machineDetails" sx={{ my: 2 }}>
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">
                                Machines Used{" "}
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={state.personName}
                                onChange={handleMultipleSelectChange}
                                input={
                                  <OutlinedInput id="select-multiple-chip" />
                                }
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                                disableRipple
                                sx={{
                                  "& .Mui-focused": {
                                    outline: "none",
                                  },
                                }}
                              >
                                {machineUsedNames.map((name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(
                                      name,
                                      state.personName,
                                      theme
                                    )}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item className="machineDetails" sx={{ mb: 2 }}>
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">
                                Sort Profiles{" "}
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={state.personName}
                                onChange={handleMultipleSelectChange}
                                input={
                                  <OutlinedInput id="select-multiple-chip" />
                                }
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                                disableRipple
                                sx={{
                                  "& .Mui-focused": {
                                    outline: "none",
                                  },
                                }}
                              >
                                {sortProfilesNames.map((name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(
                                      name,
                                      state.personName,
                                      theme
                                    )}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item className="machineDetails" sx={{ mb: 2 }}>
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">
                                Accounts Used{" "}
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={state.personName}
                                onChange={handleMultipleSelectChange}
                                input={
                                  <OutlinedInput id="select-multiple-chip" />
                                }
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                                disableRipple
                                sx={{
                                  "& .Mui-focused": {
                                    outline: "none",
                                  },
                                }}
                              >
                                {state.accountList?.map((name) => (
                                  <MenuItem
                                    key={name.account_id}
                                    value={name.account_id}
                                    style={getStyles(
                                      name,
                                      state.personName,
                                      theme
                                    )}
                                  >
                                    {name.account_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item className="machineDetails" sx={{ mb: 2 }}>
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">
                                Jobs{" "}
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={state.personName}
                                onChange={handleMultipleSelectChange}
                                input={
                                  <OutlinedInput id="select-multiple-chip" />
                                }
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                                disableRipple
                                sx={{
                                  "& .Mui-focused": {
                                    outline: "none",
                                  },
                                }}
                              >
                                {state.jobList.map((name) => (
                                  <MenuItem
                                    key={name.job_id}
                                    value={name.job_id}
                                    style={getStyles(
                                      name,
                                      state.personName,
                                      theme
                                    )}
                                  >
                                    {name.file_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* Fixed setup values */}
                <Accordion sx={{ my: 2 }} className="Accordion">
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownIcon className="accordion_icon" />
                    }
                    aria-controls="panel2-content"
                    id="panel2-header"
                    className="accordian_header"
                    sx={{
                      mb: 2,
                      background: "#693e75",
                      borderLeft: "7px solid #a88aae",
                    }}
                  >
                    <Typography>
                      <b>Fixed Setup Values</b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container mt={2}>
                      <Grid
                        item
                        className="machineDetails"
                        sx={{ mb: 2 }}
                        lg={6}
                        xl={6}
                        md={6}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            padding: "10px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "5px",
                          }}
                        >
                          <label
                            htmlFor="original_software"
                            style={{
                              position: "absolute",
                              top: "-14px",
                              background: "white",
                            }}
                          >
                            Original Software
                          </label>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Vendor Name"
                              size="small"
                              inputProps={{ maxLength: 30 }}
                              name="service_number"
                            />
                          </Grid>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Product Name"
                              size="small"
                              inputProps={{ maxLength: 30 }}
                              name="service_number"
                            />
                          </Grid>

                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Version"
                              size="small"
                              inputProps={{ maxLength: 10 }}
                              name="service_number"
                            />
                          </Grid>

                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Vendor’s Email"
                              size="small"
                              inputProps={{ maxLength: 60 }}
                              name="service_number"
                            />
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        className="machineDetails"
                        sx={{ mb: 2 }}
                        lg={6}
                        xl={6}
                        md={6}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            padding: "10px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "5px",
                          }}
                        >
                          <label
                            htmlFor="mail.dat_software"
                            style={{
                              position: "absolute",
                              top: "-14px",
                              background: "white",
                            }}
                          >
                            Mail.Dat Software
                          </label>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Mail.Dat Version"
                              size="small"
                              inputProps={{ maxLength: 4 }}
                              name="service_number"
                            />
                          </Grid>

                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Vendor Name"
                              size="small"
                              inputProps={{ maxLength: 30 }}
                              name="service_number"
                            />
                          </Grid>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Product's Name"
                              size="small"
                              inputProps={{ maxLength: 30 }}
                              name="service_number"
                            />
                          </Grid>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Version"
                              size="small"
                              inputProps={{ maxLength: 10 }}
                              name="service_number"
                            />
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* Site */}
                <Accordion sx={{ my: 2 }} className="Accordion">
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownIcon className="accordion_icon" />
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="accordian_header"
                    sx={{
                      mb: 2,
                      background: "#693e75",
                      borderLeft: "7px solid #a88aae",
                    }}
                  >
                    <Typography>
                      <b>Site</b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container mt={2} spacing={2}>
                      <Grid item mb={2} lg={4} md={4} xl={4}>
                        <Box
                          sx={{
                            position: "relative",
                            padding: "10px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "5px",
                          }}
                        >
                          <label
                            htmlFor="mail_contact"
                            style={{
                              position: "absolute",
                              top: "-14px",
                              background: "white",
                            }}
                          >
                            Mail.Dat Contact
                          </label>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Name"
                              size="small"
                              inputProps={{ maxLength: 30 }}
                              name="service_number"
                            />
                          </Grid>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Phone"
                              size="small"
                              inputProps={{ maxLength: 10 }}
                              name="service_number"
                            />
                          </Grid>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Email"
                              size="small"
                              inputProps={{ maxLength: 60 }}
                              name="service_number"
                            />
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item mb={2} lg={4} md={4} xl={4}>
                        <Box
                          sx={{
                            position: "relative",
                            padding: "10px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "5px",
                          }}
                        >
                          <label
                            htmlFor="verification"
                            style={{
                              position: "absolute",
                              top: "-14px",
                              background: "white",
                            }}
                          >
                            Verification
                          </label>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Facility"
                              size="small"
                              inputProps={{ maxLength: 30 }}
                              name="service_number"
                            />
                          </Grid>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="Facility Zip Code"
                              size="small"
                              inputProps={{ maxLength: 9 }}
                              name="service_number"
                            />
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item mb={2} lg={4} md={4} xl={4}>
                        <Box
                          sx={{
                            position: "relative",
                            padding: "10px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "5px",
                          }}
                        >
                          <label
                            htmlFor="others"
                            style={{
                              position: "absolute",
                              top: "-14px",
                              background: "white",
                            }}
                          >
                            Others
                          </label>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="DTAC User License"
                              size="small"
                              inputProps={{ maxLength: 4 }}
                              name="service_number"
                            />
                          </Grid>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="CSA Agreement ID"
                              size="small"
                              inputProps={{ maxLength: 10 }}
                              name="service_number"
                            />
                          </Grid>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="MSP's Name"
                              size="small"
                              inputProps={{ maxLength: 30 }}
                              name="service_number"
                            />
                          </Grid>
                          <Grid item mt={2}>
                            <TextField
                              fullWidth
                              id="outlined-multiline-flexible"
                              label="MSP CRID"
                              size="small"
                              inputProps={{ maxLength: 12 }}
                              name="service_number"
                            />
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box display="flex" justifyContent="end" gap={2} sx={{ mb: 2 }}>
                <Button variant="outlined" type="submit" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  className="Btn"
                  type="submit"
                >
                  Create
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <Box
          style={{ height: 400, width: "100%", margin: "20px 0" }}
          className="rolesTable"
        >
          <DataGrid
            className="datagrid gridToolbar"
            columns={columns}
            rows={rows}
            localeText={{
              noRowsLabel: "No records Found",
            }}
            slots={{
              Toolbar: CustomToolBar,
            }}
            slotProps={{
              toolbar: {
                toolbarWished: {
                  columns: true,
                  filter: true,
                  density: true,
                  export: true,
                },
              },
            }}
          />
        </Box>
      </div>
    </Box>
  );
};
export default Mailings;
