import React, { useState, useEffect } from "react";
import "../../../style/home.scss";
import { Box, Button, Typography, Modal, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { MspService } from "../../../services/MspService";
import { toast } from "react-toastify";
import AddMspIcon from "../../../assets/icons/Masked_Icon.svg";
import CopyIcon from "../../../assets/icons/File.svg";
import EditIcon from "../../../assets/icons/edit.svg";
import DeleteIcon from "../../../assets/icons/Trash Bin Trash.svg";
import DummyProfileImage from "../../../assets/images/hello_robot.png";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import CustomPagination from "../../../components/customPagination/custompagination";
import { RolesService } from "../../../services/RolesService";
import MspModal from "./mspModal";

export default function MSPCreation() {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [guid, setGuid] = useState<string>("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [editable, setEditable] = useState<any>();
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [details, setDetails] = useState([]);
  const [reloadAfterDelete, setReloadAfterDelete] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [permissionList, setPermissionList] = useState<any>({});

  const columns = [
    { field: "id", headerName: "Id" },
    {
      field: "company_name",
      headerName: "MSP Name",
      headerClassName: "pinned-header",
      cellClassName: "pinned-cell",
      pinned: true,
      width: 300,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
          <img
            src={
              params.row.image
                ? `data:image/png;base64,${params.row.image}`
                : DummyProfileImage
            }
            className="mspListImage"
            alt="msplistimage"
          />
          <div>{params.value}</div>
        </Box>
      ),
    },
    { field: "admin_email", headerName: "Admin Email", width: 500 },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" gap={2}>
          {permissionList?.msp_role_group_function_mapping_list
            ?.role_read_flag === 1 && (
            <Button
              className="closeButton"
              onClick={() => {
                handleViewOpen(params.row);
              }}
            >
              <img src={CopyIcon} className="actionIcons" alt="actionIcons" />
            </Button>
          )}

          {permissionList?.msp_role_group_function_mapping_list
            ?.role_update_flag === 1 && (
            <Button
              className="closeButton"
              onClick={() => {
                handleEditOpen(params.row.guid, params.row);
              }}
            >
              <img src={EditIcon} className="actionIcons" alt="actionIcons" />
            </Button>
          )}

          {permissionList?.msp_role_group_function_mapping_list
            ?.role_delete_flag === 1 && (
            <Button
              className="closeButton"
              onClick={() => {
                setDeleteModal(true);
                setGuid(params.row.guid);
              }}
            >
              <img src={DeleteIcon} className="actionIcons" alt="actionIcons" />
            </Button>
          )}
        </Box>
      ),
    },
  ];
  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await MspService.mspAccountList({
        page: page,
        page_size: pageSize,
      });
      if (response) {
        const data = response.data.msp_account_list;
        const updateDetails = data.map((item: any) => ({
          id: item.account_id,
          company_name: item.account_name,
          first_name: item.first_name,
          last_name: item.last_name,
          contact_phone: item.contact_phone,
          admin_email: item.contact_email,
          guid: item.account_guid,
          image: item.image_base64,
          site_url: item.site_url,
          enroll_plan: item.enroll_plan_id,
          role_id: item?.role_name?.role_id,
          site_name: item?.site_name,
        }));

        setRowCount(response.data.total_record);
        setDetails(updateDetails);
        setLoader(false);
      }
    } catch (error) {
      console.error("Err===>", error);
      setLoader(false);
      if (error?.status == 401) {
        toast.error("Token expired");
        navigate("/sign-in");
      }
    }
  };

  const getRolePermissions = async () => {
    try {
      const response = await RolesService.getEiiRolePermissions(localStorage.getItem('role_id'));
      if (response.status === 200) {
        response.data.eii_role_group_function_mapping_list?.map((obj) => {
          obj.role_function_name === "MSP Creation" && setPermissionList(obj);
        });
      }
    } catch (error) {
      console.log("Err==>", error);
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  //Edit Modal
  const handleEditOpen = (guid: string, editableData: any) => {
    setEditModal(true);
    setEditable((prevState) => ({
      ...prevState,
      guid: guid,
      ...editableData,
    }));
  };

  //View Modal
  const handleViewOpen = (viewableData: any) => {
    setViewModal(true);
    setSelectedRow({
      company_name: viewableData.company_name,
      first_name: viewableData.first_name,
      last_name: viewableData.last_name,
      site_url: viewableData.site_url,
      site_name: viewableData.site_name,
      enroll_plan:
        viewableData.enroll_plan == 1 ? "Shared plan" : "Dedicated plan",
      contact_phone: viewableData.contact_phone,
      role_id: viewableData.role_id,
      image: viewableData.image,
    });
  };

  //Delete Modal
  const popData = async (guid: string) => {
    try {
      const formData = new FormData();
      formData.append("account_guid", guid);
      await MspService.mspAccountDelete(formData).then(() => {
        setReloadAfterDelete(!reloadAfterDelete);
        toast.success("MSP deleted successfully :)");
        handleClose("success");
      });
    } catch (error) {
      toast.error("Error deleting MSP");
    }
  };
  const handleClose = (data?: string) => {
    setOpen(false);
    setEditModal(false);
    setViewModal(false);
    setDeleteModal(false);
    data && fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [reloadAfterDelete, page, pageSize]);

  useEffect(() => {
    getRolePermissions();
  }, []);
  return (
    <div className="roles mspcreation">
      {loader && <Loader />}
      {/* Creation Modal */}
      {open && <MspModal open={open} handleClose={handleClose} />}
      {/* Edit Modal */}
      {editModal && (
        <MspModal
          open={editModal}
          handleClose={handleClose}
          editMspDetails={editable}
        />
      )}
      {/* View Modal */}
      {viewModal && (
        <MspModal
          open={viewModal}
          handleClose={handleClose}
          viewMspDetails={selectedRow}
        />
      )}
      {/* Delete Modal */}
      <Modal
        open={deleteModal}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="roleCreateModal"
      >
        <Box className="msp-deletion-updation-modal" height="auto" width="30%">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 1, p: 2 }}
          >
            <b>Confirm Delete ? </b>
          </Typography>
          <Box sx={{ mt: 1, px: 2 }}>
            <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
              <Button
                onClick={() => handleClose()}
                variant="outlined"
                type="submit"
              >
                No
              </Button>
              <Button
                onClick={() => {
                  popData(guid);
                  handleClose();
                }}
                className="createButton"
                variant="contained"
                type="submit"
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Box display="flex" justifyContent="flex-end">
        {permissionList.msp_role_group_function_mapping_list
          ?.role_create_flag === 1 && (
          <Button
            variant="contained"
            onClick={() => setOpen(true)}
            className="Btn"
          >
            {" "}
            <img src={AddMspIcon} height={25} width={25} alt="addmspicons" />
            Create MSP
          </Button>
        )}
      </Box>

      <Grid container>
        <Grid item md={12}>
          <Box
            style={{ height: 400, width: "100%", margin: "20px 0" }}
            className="rolesTable"
          >
            <DataGrid
              rows={details}
              columns={columns}
              //@ts-ignore
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              disableSelectionOnClick
              localeText={{
                noRowsLabel: "No records Found",
              }}
              slots={{
                pagination: (props) => (
                  <CustomPagination
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    page={page}
                    pageSize={pageSize}
                    rowCount={rowCount}
                  />
                ),
              }}
              sortModel={[
                {
                  field: "id",
                  sort: "desc",
                },
              ]}
              columnVisibilityModel={{
                id: false,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
