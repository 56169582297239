import React from "react";
import { Box, Grid, Typography, TextField } from "@mui/material";

export default function Licence({ handleChange, detailValue, errorMessage }) {
  return (
    <Box className="sitedetails_tab license-details">
      <Box className="sitedetails-header" sx={{ mb: 3 }}>
        <Typography>
          <b>Licence Details</b>
        </Typography>
      </Box>
      <Box
        className="sitedetails-body"
        component="form"
        noValidate
        sx={{ mb: 3, px: 2 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="outlined-basic"
              label="MSP CRID"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 30 }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              name="msp_crid"
              onChange={(e) => handleChange(e, "license_and_other_details")}
              value={detailValue?.msp_crid}
              error={!!errorMessage.msp_crid}
              helperText={errorMessage.msp_crid}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="outlined-basic"
              label="MSP Mailer ID"
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              name="msp_mid"
              onChange={(e) => handleChange(e, "license_and_other_details")}
              value={detailValue?.msp_mid}
              error={!!errorMessage.msp_mid}
              helperText={errorMessage.msp_mid}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="outlined-basic"
              label="CSA Agreement ID"
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 50 }}
              fullWidth
              name="csa_agreement_id"
              onChange={(e) => handleChange(e, "license_and_other_details")}
              value={detailValue?.csa_agreement_id}
              error={!!errorMessage.csa_agreement_id}
              helperText={errorMessage.csa_agreement_id}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="outlined-basic"
              label="DTAC User License"
              name="dtac_user_license"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 30 }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              onChange={(e) => handleChange(e, "license_and_other_details")}
              value={detailValue?.dtac_user_license}
              error={!!errorMessage.dtac_user_license}
              helperText={errorMessage.dtac_user_license}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="outlined-basic"
              label="Verfication Facility"
              variant="outlined"
              name="verification_facility"
              size="small"
              inputProps={{ maxLength: 30 }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              onChange={(e) => handleChange(e, "license_and_other_details")}
              value={detailValue?.verification_facility}
              error={!!errorMessage.verification_facility}
              helperText={errorMessage.verification_facility}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="outlined-basic"
              label="Verification Facility Zip Code"
              variant="outlined"
              name="verification_facility_zip_code"
              size="small"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 50 }}
              fullWidth
              onChange={(e) => handleChange(e, "license_and_other_details")}
              value={detailValue?.verification_facility_zip_code}
              error={!!errorMessage.verification_facility_zip_code}
              helperText={errorMessage.verification_facility_zip_code}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
