import {
  get,
  deleteRequest,
  postFormData,
  post,
  postBlob,
  putRequest,
  putFormData,
} from "./BaseService";
import { apiBaseUrl } from "../config";

function downloadEvent(data) {
  const url = `download`;
  return postBlob(apiBaseUrl + url, data, true);
}

function uploadFolder(data, ID) {
  const url = `upload_folder?created_job_id=${ID}`;
  return postFormData(apiBaseUrl + url, data, true);
}

function fileValidation(data) {
  const url = `file_validation`;
  return postFormData(apiBaseUrl + url, data, true);
}

function uploadMPU(data) {
  const url = `upload_mpu`;
  return postFormData(apiBaseUrl + url, data, true);
}
function uploadPDR(data) {
  const url = `upload_pdr`;
  return postFormData(apiBaseUrl + url, data, true);
}

function getFileStatus(job_id) {
  const url = `file_status?job_id=${job_id}`;
  return get(apiBaseUrl + url);
}

function getUploadFolder(params) {
  const url = `get_msp_upload_folder_list`;
  return get(apiBaseUrl + url, params);
}
function createMspPage(data) {
  const url = `msp_page_creation`;
  return post(apiBaseUrl + url, data);
}

function retrieveMspPageList() {
  const url = `get_msp_pages_list`;
  return get(apiBaseUrl + url);
}
function deleteMspPage(data) {
  const url = `msp_page_deletion`;
  return deleteRequest(apiBaseUrl + url, data);
}
function updateMspPage(data) {
  const url = `msp_page_update`;
  return putRequest(apiBaseUrl + url, data);
}
//MSP creation
function mspcreation(data) {
  const url = `msp_creation`;
  return postFormData(apiBaseUrl + url, data, true);
}
function mspUpdation(data) {
  const url = `update_msp`;
  return putFormData(apiBaseUrl + url, data, true);
}
function mspAccountList(pagination) {
  const url = `msp_account_list`;
  return get(apiBaseUrl + url, pagination);
}
function mspGetRolesList() {
  const url = `get_msp_roles_list_for_permission`;
  return get(apiBaseUrl + url);
}
function currentMspName() {
  const url = `current_msp_account_name`;
  return get(apiBaseUrl + url);
}

function siteOperationList() {
  const url = `get_site_operation_list`;
  return get(apiBaseUrl + url);
}

function mspUsersList() {
  const url = `users_list_v2`;
  return get(apiBaseUrl + url);
}

function sitesUsersDropdownList() {
  const url = `sites_users_dropdown`;
  return get(apiBaseUrl + url);
}

function mspAccountDelete(data) {
  const url = `msp_deletion`;
  return deleteRequest(apiBaseUrl + url, data);
}
function getOnlyMspRolesForUser() {
  const url = `get_only_msp_roles_list_for_user`;
  return get(apiBaseUrl + url);
}
function getSitesListForJob() {
  const url = `sites_dropdown`;
  return get(apiBaseUrl + url);
}
function getMachineListForJob(params) {
  const url = `machine_dropdown`;
  return get(apiBaseUrl + url, params);
}

function getMspPermissionMapping(data, roleId) {
  const queryParams = data
    .map((obj) =>
      Object.keys(obj)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        )
        .join("&")
    )
    .join("&");
  const url =
    `create_msp_role_group_function_mapping?role_id=${roleId}&` + queryParams;
  return post(apiBaseUrl + url);
}
//Mail owner
function mocreation(data) {
  const url = `mail_owner_creation`;
  return postFormData(apiBaseUrl + url, data, true);
}
function moUpdation(data) {
  const url = `update_mail_owner`;
  return putFormData(apiBaseUrl + url, data, true);
}
function moAccountDelete(data) {
  const url = `mail_owner_deletion`;
  return deleteRequest(apiBaseUrl + url, data);
}
function moAccountList(pagination) {
  const url = `mail_owner_account_list`;
  return get(apiBaseUrl + url, pagination);
}
function getOnlyMoRolesForUser() {
  const url = `get_only_mail_owner_roles_list_for_user`;
  return get(apiBaseUrl + url);
}
function getMoPermissionMapping(data, roleId) {
  const queryParams = data
    .map((obj) =>
      Object.keys(obj)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        )
        .join("&")
    )
    .join("&");
  const url =
    `create_mail_owner_role_group_function_mapping?role_id=${roleId}&` +
    queryParams;
  return post(apiBaseUrl + url);
}

function moDetails(email) {
  const url = `check_mail_owner_details?email=${email}`;
  return get(apiBaseUrl + url);
}

function mapMoMsp(data) {
  const url = `map_mo_msp`;
  return postFormData(apiBaseUrl + url, data, true);
}

function receiveWorkOrderList(params) {
  const url = `get_msp_work_order`;
  return get(apiBaseUrl + url, params);
}

function mspCreateTicket(data) {
  const url = `msp_receipt_ticket_creation`;
  return postFormData(apiBaseUrl + url, data);
}

function mspAdditionalTIcketDetails(data) {
  const url = `worksheet_creation`;
  return postFormData(apiBaseUrl + url, data);
}

function updateWorkOrderStatus(data) {
  const url = `confirmation_status`;
  return putFormData(apiBaseUrl + url, data);
}

function addSeparatorCardBarcode(data) {
  const url = `separate_job_card_creation`;
  return postFormData(apiBaseUrl + url, data);
}

function MSPSitesList() {
  const url = `sites_dropdown`;
  return get(apiBaseUrl + url);
}

function MOSitesList() {
  const url = `mo_sites_dropdown`;
  return get(apiBaseUrl + url);
}
export const MspService = {
  MOSitesList,
  mocreation,
  moUpdation,
  moAccountDelete,
  moAccountList,
  getMoPermissionMapping,
  getOnlyMoRolesForUser,
  mspUsersList,
  MSPSitesList,
  siteOperationList,
  currentMspName,
  downloadEvent,
  createMspPage,
  retrieveMspPageList,
  deleteMspPage,
  updateMspPage,
  uploadFolder,
  getFileStatus,
  getUploadFolder,
  fileValidation,
  mspcreation,
  mspAccountList,
  mspUpdation,
  uploadMPU,
  uploadPDR,
  mspAccountDelete,
  mspGetRolesList,
  getOnlyMspRolesForUser,
  getSitesListForJob,
  getMachineListForJob,
  getMspPermissionMapping,
  moDetails,
  mapMoMsp,
  receiveWorkOrderList,
  mspCreateTicket,
  mspAdditionalTIcketDetails,
  updateWorkOrderStatus,
  addSeparatorCardBarcode,
  sitesUsersDropdownList,
};
