import React from "react";
import { useRoutes } from "react-router-dom";
import Layout from "../components/Layout";
import publicRoutes from "./public";
import LoginProdRoute from "../auth/LoginProdRoute";
import EiiRoutes from "./eiiRoutes";
import MSPRoutes from "./mspRoutes";
import MailOwnerRoutes from "./mailOwnersRoutes";

function AppRoutes() {
  let roleType = localStorage.getItem("role_type");
  let routerPath;

  switch (roleType) {
    case "EII":
      routerPath = EiiRoutes;
      break;
    case "MSP":
      routerPath = MSPRoutes;
      break;
    case "MAIL_OWNER":
      routerPath = MailOwnerRoutes;
      break;
  }

  const auth = localStorage.getItem("auth") ? "Yes" : "No";
  const routes = auth === "Yes" ? routerPath : publicRoutes;
  const element = useRoutes([...routes]);

  if (auth === "Yes") {
    return (
      <LoginProdRoute>
        <Layout element={element} />
      </LoginProdRoute>
    );
  }
  return <>{element}</>;
}

export default AppRoutes;
