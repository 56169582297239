import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import axios from "axios";
import { RolesService } from "../../../services/RolesService";
import Loader from "../../../components/Loader";
import EditIcon from "../../../assets/icons/edit.svg";
import DeleteIcon from "../../../assets/icons/Trash Bin Trash.svg";
import { ReactComponent as AddRoleIcon } from "../../../assets/icons/Masked_Icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close Square.svg";
import { apiBaseUrl } from "../../../config";
import CustomPagination from "../../../components/customPagination/custompagination";
import Permissions from "../../Eii/Permissions/MSPPermissions";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  borderRadius: "10px",
  p: 0,
};

const RoleModal = ({
  open,
  onClose,
  onSubmit,
  roles,
  handleChange,
  title,
  buttonText,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="roleCreateModal"
  >
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h5"
        component="h2"
        sx={{ mb: 3 }}
        className="createRoleHeader"
      >
        <b>{title}</b>
        <CloseIcon className="closeicon" onClick={onClose} />
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={onSubmit}
        sx={{ mt: 1 }}
        className="createFormDetails"
        style={{ height: "auto" }}
      >
        <TextField
          size="small"
          label="Role Name"
          sx={{ my: 2, mb: 0, width: "100%" }}
          name="role_name"
          value={roles.role_name || ""}
          onChange={handleChange}
        />

        <FormControl fullWidth sx={{ mt: 3 }} size="small">
          <InputLabel id="demo-simple-select-label">Account Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="for_name"
            name="account_status_id"
            defaultValue={roles.account_status_id || ""}
            onChange={handleChange}
          >
            <MenuItem value="y">Active</MenuItem>
            <MenuItem value="n">Inactive</MenuItem>
          </Select>
        </FormControl>
        <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={!roles.role_name || !roles.account_status_id}
            variant="outlined"
            className="Btn"
            type="submit"
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Box>
  </Modal>
);

const DeleteModal = ({ open, onClose, onDelete }) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="roleCreateModal"
  >
    <Box sx={style}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ mb: 1, p: 2 }}
      >
        <b>Are you sure you want to delete?</b>
      </Typography>
      <Box sx={{ mt: 1, px: 2 }}>
        <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
          <Button onClick={onClose} variant="outlined">
            No
          </Button>
          <Button onClick={onDelete} variant="outlined" className="Btn">
            Yes
          </Button>
        </Box>
      </Box>
    </Box>
  </Modal>
);

export default function Roles() {
  const [open, setOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [roles, setRoles] = useState({});
  const [loader, setLoader] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [permissions, setPermissions] = useState(false);
  const [permissionList, setPermissionList] = useState({});

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setRoles({});
  }, []);
  const handleEditOpen = (id) => {
    setRoles(roleList[id]);
    setEditModal(true);
  };
  const handleEditClose = () => {
    setEditModal(false);
    setRoles("");
  };
  const handleDeleteOpen = (id) => {
    setDeleteModal(true);
    setDeleteId(id);
  };
  const handleDeleteClose = () => setDeleteModal(false);

  const permissionOpen = () => setPermissions(true);
  const permissionClose = () => setPermissions(false);

  const success = () => toast.error("Name should be more than 3 characters!");
  const error = () => toast.error("Role Already exists");
  const posted = () => toast.success("Role Created");
  const deleted = () => toast.success("Role Deleted Successfully");
  const edited = () => toast.success("Role Updated Successfully");

  const token = localStorage.getItem("auth");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRoles((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("role_name", roles.role_name);
    formData.append("is_active_flag", roles.account_status_id);

    if (roles.role_name.trim().length < 3) {
      success();
    } else if (roleList.find((item) => item.role_name === roles.role_name)) {
      error();
    } else {
      try {
        const response = await RolesService.AddMSPRoles(formData);
        if (response.status === 200) {
          posted();
          getRoleDetails();
        }
      } catch (err) {
        toast.error(err.message);
      }
      handleClose();
    }
  };

  const deleteData = async () => {
    try {
      const formData = new FormData();
      formData.append("role_id", deleteId);
      const response = await RolesService.deleteMSPRoles(formData);
      if (response.status === 200) {
        deleted();
        getRoleDetails();
      }
    } catch (err) {
      toast.error(err.message);
    }
    handleDeleteClose();
  };

  const editData = async (e) => {
    e.preventDefault();
    if (roles.role_name.trim().length < 3) {
      success();
    } else {
      try {
        const formData = new FormData();
        formData.append("role_id", roles.role_id);
        formData.append("role_name", roles.role_name);
        formData.append("is_active_flag", roles.account_status_id);

        const response = await axios.put(
          `${apiBaseUrl}msp_role_update`,
          formData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          edited();
          getRoleDetails();
        }
      } catch (err) {
        toast.error(err.message);
      }
      handleEditClose();
    }
  };

  const getRoleDetails = async () => {
    try {
      setLoader(true);
      const response = await axios.get(
        `${apiBaseUrl}get_only_msp_roles_list_v2`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { page: page, page_size: pageSize },
        }
      );
      const updatedRoles = response.data.msp_roles_list.items.map((item) => ({
        id: item.role_id,
        role_id: item.role_id,
        role_name: item.role_name,
        account_status_id: item.is_active_flag,
        account_status: item.active_or_inactive,
      }));
      setRoleList(updatedRoles);
      setRowCount(response.data.msp_roles_list.total_record);
      setLoader(false);
    } catch (err) {
      toast.error(err.message);
      setLoader(false);
    }
  };
  const Permission = async () => {
    const role_id = localStorage.getItem("role_id");

    const response = await axios.get(
      `${apiBaseUrl}msp_role_group_function_mapping_list?role_id=${role_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      response.data.msp_role_group_function_mapping_list?.map((obj) => {
        obj.role_function_name === "MSP Role Maintenance" &&
          setPermissionList(obj);
      });
    }
  };

  useEffect(() => {
    getRoleDetails();
    Permission();
  }, [page, pageSize]);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "role_name", headerName: "Name of Role", width: 300 },
    {
      field: "account_status",
      headerName: "Account Status",
      width: 300,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => {
        const index = roleList.findIndex((x) => x.id === params.row.id);
        return (
          <Box display="flex" justifyContent="center" gap={2}>
            {permissionList.msp_role_group_function_mapping_list
              ?.role_update_flag === 1 && (
              <Button
                onClick={() => handleEditOpen(index)}
                className="closeButton"
              >
                <img src={EditIcon} className="actionIcons" alt="editicon" />
              </Button>
            )}
            {permissionList.msp_role_group_function_mapping_list
              ?.role_delete_flag === 1 && (
              <Button
                onClick={() => handleDeleteOpen(params.row.id)}
                className="closeButton"
              >
                <img
                  src={DeleteIcon}
                  alt="deleteicon"
                  className="actionIcons"
                />
              </Button>
            )}
          </Box>
        );
      },
      width: 300,
    },
  ];
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  return (
    <div className="roles">
      {loader && <Loader />}
      <RoleModal
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        roles={roles}
        handleChange={handleChange}
        title="MSP Role Creation"
        buttonText="Create"
      />
      <RoleModal
        open={editModal}
        onClose={handleEditClose}
        onSubmit={editData}
        roles={roles}
        handleChange={handleChange}
        title="MSP Role Update "
        buttonText="Update"
      />
      {permissions && (
        <Permissions open={permissionOpen} close={permissionClose} />
      )}
      <DeleteModal
        open={deleteModal}
        onClose={handleDeleteClose}
        onDelete={deleteData}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1 style={{ margin: 0 }}>Role</h1>
        <Box>
          {permissionList.msp_role_group_function_mapping_list
            ?.role_create_flag === 1 && (
            <Button className="Btn" variant="contained" onClick={handleOpen}>
              <AddRoleIcon /> <b>Create MSP Role</b>
            </Button>
          )}

          <Button className="Btn" variant="contained" onClick={permissionOpen}>
            <AddRoleIcon /> <b>MSP Role Permissions</b>
          </Button>
        </Box>
      </Box>
      <Grid container>
        <Grid item md={12}>
          <Box
            style={{
              height: "calc(100vh - 200px)",
              width: "100%",
              margin: "20px 0",
            }}
            className="rolesTable"
          >
            <DataGrid
              className="RolesTable"
              rows={roleList}
              columns={columns}
              columnVisibilityModel={{
                id: false,
              }}
              localeText={{
                noRowsLabel: "No records Found",
              }}
              slots={{
                Pagination: (props) => (
                  <CustomPagination
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    page={page}
                    pageSize={pageSize}
                    rowCount={rowCount}
                  />
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
