import React, { useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close Square.svg";
import { toast } from "react-toastify";

const MailIdModal = ({ open, close, tableData, setTableData }) => {
  const [mailIdDetails, setMailIdDetails] = useState({
    discription: "",
    mailer_id: "",
    start_sequence: "",
    stop_sequence: "",
  });
  const [editIndex, setEditIndex] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMailIdDetails({
      ...mailIdDetails,
      [name]: value,
    });
  };

  const handleSave = (e) => {
    if (
      mailIdDetails.discription == "" ||
      mailIdDetails.mailer_id == "" ||
      mailIdDetails.start_sequence == "" ||
      mailIdDetails.stop_sequence == " "
    ) {
      toast.error("Fill the all fields");
    } else if (editIndex !== null) {
      const updatedData = tableData.map((item, index) =>
        index === editIndex ? mailIdDetails : item
      );
      setTableData(updatedData);
      setEditIndex(null);
      close();
    } else {
      setTableData([...tableData, mailIdDetails]);
    }
    setMailIdDetails({
      discription: "",
      mailer_id: "",
      start_sequence: "",
      stop_sequence: "",
    });
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setMailIdDetails(tableData[index]);
  };

  const handleDelete = (index) => {
    const updatedData = tableData.filter((_, i) => i !== index);
    setTableData(updatedData);
  };

  return (
    <Modal open={open} onClose={close}>
      <Box sx={{ ...modalStyle, width: "50%" }}>
        <Box className="mailer-modal-header" sx={{ px: 2, py: 1 }}>
          {" "}
          <h2 style={{ margin: "5px 0" }}>
            {editIndex !== null ? "Edit Mailer Details" : "Add Mailer Details"}
          </h2>
          <CloseIcon onClick={close} />
        </Box>
        <Grid container spacing={2} sx={{ px: 2, pb: 0 }}>
          <Grid item lg={3}>
            <TextField
              name="discription"
              label="Discription"
              fullWidth
              margin="normal"
              value={mailIdDetails.discription}
              onChange={handleChange}
              size="small"
              multiline
            />
          </Grid>
          <Grid item lg={3}>
            <TextField
              name="mailer_id"
              label="Mailer ID"
              fullWidth
              margin="normal"
              value={mailIdDetails.mailer_id}
              onChange={handleChange}
              size="small"
            />
          </Grid>
          <Grid item lg={3}>
            <TextField
              name="start_sequence"
              label="Start Sequence"
              fullWidth
              margin="normal"
              value={mailIdDetails.start_sequence}
              size="small"
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={3}>
            <TextField
              name="stop_sequence"
              label="Stop Sequence"
              fullWidth
              margin="normal"
              value={mailIdDetails.stop_sequence}
              size="small"
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="end" sx={{ px: 2 }}>
          <Button
            onClick={() => handleSave()}
            variant="contained"
            sx={{ mt: 2 }}
          >
            {editIndex !== null ? "Update" : "Save"}
          </Button>
        </Box>
        <TableContainer component={Paper} sx={{ mt: 4, px: 2, pb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Discription</TableCell>
                <TableCell>Mailer ID</TableCell>
                <TableCell>Start Sequence</TableCell>
                <TableCell>Stop Sequence</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.discription}</TableCell>
                  <TableCell>{row.mailer_id}</TableCell>
                  <TableCell>{row.start_sequence}</TableCell>
                  <TableCell>{row.stop_sequence}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(index)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(index)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default MailIdModal;
