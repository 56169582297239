import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Box, Grid, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./SortEditor.scss";
import SortEditorTabs from "./SortEditorTabs/index";

const rows = [
  {
    id: 1,
    start_bin: "1",
    end_bin: "2",
    switch: "On",
    bin_type: "General",
    qual_level: "3",
    auto_mach: "Automatic",
    submailing_type: "Default",
    tray_label_type: "USPS",
    zip_range: "20",
  },
  {
    id: 2,
    start_bin: "2",
    end_bin: "2",
    switch: "On",
    bin_type: "Out of stock",
    qual_level: "3",
    auto_mach: "Automatic",
    submailing_type: "Default",
    tray_label_type: "USPS",
    zip_range: "017-067,120-123,128-129",
  },
  {
    id: 3,
    start_bin: "3",
    end_bin: "3",
    switch: "On",
    bin_type: "Sort",
    qual_level: "5",
    auto_mach: "Automatic",
    submailing_type: "Default",
    tray_label_type: "USPS",
    zip_range: "000:999",
  },
  {
    id: 4,
    start_bin: "4",
    end_bin: "4",
    switch: "On",
    bin_type: "Sort",
    qual_level: "3",
    auto_mach: "Machine",
    submailing_type: "Default",
    tray_label_type: "USPS",
    zip_range: "005,068-079,085-098,100-119,124-475",
  },
  {
    id: 5,
    start_bin: "5",
    end_bin: "5",
    switch: "On",
    bin_type: "Sort",
    qual_level: "3",
    auto_mach: "Automatic",
    submailing_type: "Default",
    tray_label_type: "USPS",
    zip_range: "010-067,120-123,128-129",
  },
];

export default function SortEditorTable() {
  return (
    <Box className="sort-editor-table">
      <TableContainer component={Paper} className="sort-editor-table-datas">
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          stickyHeader
          className="binrange-table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ py: 0, px: 1 }}>Start Bin</TableCell>
              <TableCell sx={{ py: 0, px: 1 }}>End Bin</TableCell>
              <TableCell sx={{ py: 0, px: 1 }}>Switch</TableCell>
              <TableCell sx={{ py: 0, px: 1 }}>Bin Type</TableCell>
              <TableCell sx={{ py: 0, px: 1 }}>Qual Level</TableCell>
              <TableCell sx={{ py: 0, px: 1 }}>Auto / Mach</TableCell>
              <TableCell sx={{ py: 0, px: 1 }}>Sub-Mailing Type</TableCell>
              <TableCell sx={{ py: 0, px: 1 }}>Tray Label Type</TableCell>

              <TableCell sx={{ py: 0, px: 1 }}>Zip Range</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="bin-range-table">
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={{ py: 0, px: 1 }}>{row.start_bin}</TableCell>
                <TableCell sx={{ py: 0, px: 1 }}>{row.end_bin}</TableCell>
                <TableCell sx={{ py: 0, px: 1 }}>{row.switch}</TableCell>

                <TableCell sx={{ py: 0, px: 1 }}>{row.bin_type}</TableCell>
                <TableCell sx={{ py: 0, px: 1 }}>{row.qual_level}</TableCell>
                <TableCell sx={{ py: 0, px: 1 }}>{row.auto_mach}</TableCell>
                <TableCell sx={{ py: 0, px: 1 }}>
                  {row.submailing_type}
                </TableCell>
                <TableCell sx={{ py: 0, px: 1 }}>
                  {row.tray_label_type}
                </TableCell>

                <TableCell sx={{ py: 0, px: 1 }}>{row.zip_range}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="sort-editor-table-grid-datas">
        <Grid container>
          <Grid item xs={7} md={7}>
            <Grid container spacing={2} className="plan-range-datas-field">
              <Grid item xs={6} md={4} className="range-inputs">
                <TextField
                  className="plan-range-datas-input"
                  id="outlined-basic"
                  label="Start Bin"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4} className="range-inputs">
                <TextField
                  className="plan-range-datas-input"
                  id="outlined-basic"
                  label="End Bin"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4} className="range-inputs">
                <TextField
                  className="plan-range-datas-input"
                  id="outlined-basic"
                  label="Tray Tags"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4} className="range-inputs">
                <TextField
                  className="plan-range-datas-input"
                  id="outlined-basic"
                  label="Sub Mailing Type"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} className="range-inputs">
                <TextField
                  className="plan-range-datas-input"
                  id="outlined-basic"
                  label="Tray Label Type"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} className="range-inputs">
                <TextField
                  className="plan-range-datas-input"
                  id="outlined-basic"
                  label="Bin Type"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={4}
                className="switch-buttons range-inputs"
              >
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Qual Level
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="3digit"
                      control={<Radio />}
                      label="3 Digit"
                    />
                    <FormControlLabel
                      value="5digit"
                      control={<Radio />}
                      label="5 Digit"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={4}
                className="switch-buttons"
              >
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Bin Switch
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="on"
                      control={<Radio />}
                      label="On"
                    />
                    <FormControlLabel
                      value="off"
                      control={<Radio />}
                      label="Off"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={4}
                className="switch-buttons range-inputs"
              >
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Auto / Mach
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="auto"
                      control={<Radio />}
                      label="Auto"
                    />
                    <FormControlLabel
                      value="mach"
                      control={<Radio />}
                      label="Machine"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                className="switch-buttons range-inputs"
              >
                <TextField
                  id="outlined-basic"
                  label="Zip Range"
                  variant="outlined"
                  fullWidth
                  type="number"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} md={5} sx={{ width: "100%", overflow: "auto" }}>
            <SortEditorTabs />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
