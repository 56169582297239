import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  FormControl,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../../config";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const AccountManager = ({ handleChange, detailValue }) => {
  const [moveUpdateMethod, setMoveUpdate] = useState([]);

  const [postalCodeData, setPostalCodeData] = useState({
    return: {
      zipCode: "",
      city: "",
      state: "",
    },
    billing: {
      zipCode: "",
      city: "",
      state: "",
    },
  });

  const formlabelStyle = {
    display: "flex",
  };

  const handleZipCodeChange = (stateKey) => async (e) => {
    const inputZipCode = e.target.value;

    setPostalCodeData((prevData) => ({
      ...prevData,
      [stateKey]: {
        ...prevData[stateKey],
        zipCode: inputZipCode,
      },
    }));

    if (inputZipCode.length === 5) {
      try {
        const response = await axios.get(
          `https://api.zippopotam.us/us/${inputZipCode}`
        );
        const { "place name": cityName, "state abbreviation": stateName } =
          response.data.places[0];
        const handlePostCodes = {
          postalCode: inputZipCode,
          city: cityName,
          state: stateName,
        };
        handleChange(null, "", stateKey, handlePostCodes);
        setPostalCodeData((prevData) => ({
          ...prevData,
          [stateKey]: {
            ...prevData[stateKey],
            city: cityName,
            state: stateName,
            error: "",
          },
        }));
      } catch (error) {
        toast.error("Something Went Wrong");
      }
    } else {
      setPostalCodeData((prevData) => ({
        ...prevData,
        [stateKey]: {
          ...prevData[stateKey],
          city: "",
          state: "",
        },
      }));
    }
  };
  const isAuth = localStorage.getItem("auth");
  const params = {
    headers: {
      Authorization: "Bearer " + isAuth,
    },
  };
  const fetchDropdown = () => {
    axios
      .get(`${apiBaseUrl}get_move_update_method`, params)
      .then((res) => {
        setMoveUpdate(res.data.move_update_method);
      })
      .catch((e) => {
        console.log("Err==>", e);
      });
  };
  useEffect(() => {
    fetchDropdown();
  }, []);

  return (
    <Box className="accountDetails">
      <Grid
        container
        spacing={2}
        justifyContent="center"
        className="accountEditorTabs"
      >
        {" "}
        <Grid item xs={12}>
          <FormGroup className="details-tab">
            <Grid container spacing={2} className="row-align">
              <Grid item lg={6} md={12} sm={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  className="undo-wrap"
                >
                  <TextField
                    size="small"
                    label="Account Name"
                    required
                    fullWidth
                    name="account_name"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={detailValue?.account_name}
                    onChange={(e) => handleChange(e, "details")}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={12} sm={12}>
                <Box display="flex">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_disabled"
                        defaultChecked={detailValue?.is_disabled}
                        onChange={(e) => handleChange(e, "details")}
                      />
                    }
                    label="Account Disabled ?"
                    sx={formlabelStyle}
                  />

                  <Box
                    display="flex"
                    alignItems="center"
                    className="undo-wrap revert-option-details"
                  >
                    <IconButton
                      size="small"
                      title="revert"
                      className="revert-btn"
                    >
                      <UndoIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={12} sm={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  className="undo-wrap"
                >
                  <TextField
                    size="small"
                    label="Account ID"
                    required
                    fullWidth
                    name="first_name"
                    inputProps={{
                      maxLength: 50,
                    }}
                    // InputLabelProps={{ shrink: true }}
                    value="1"
                    disabled
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={12} sm={12}>
                <Box display="flex">
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Use Return Address ?"
                    sx={formlabelStyle}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    className="undo-wrap revert-option-details"
                  >
                    <IconButton
                      size="small"
                      title="revert"
                      className="revert-btn"
                    >
                      <UndoIcon />
                    </IconButton>
                    {/* <p className="labelP">Account Name</p> */}
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <Box>
                  <FormControl
                    fullWidth
                    className="undo-wrap revert-option revert-option-details"
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      className="profilelable"
                      sx={{ pr: 1, top: "-7px" }}
                    >
                      Move Update Method
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      size="small"
                      defaultValue={detailValue?.move_update_method_id}
                      name="move_update_method_id"
                      onChange={(e) => handleChange(e, "details")}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                      sx={{ flexGrow: "1" }}
                    >
                      {moveUpdateMethod.map((item) => (
                        <MenuItem
                          key={item.move_update_method_id}
                          value={item.move_update_method_id}
                        >
                          {item.move_update_method_name}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton size="small" className="revert-btn">
                      <UndoIcon />
                    </IconButton>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Accordion className="address-details">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <b> Billing Address</b>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Billing Name"
                        variant="outlined"
                        className="sequence"
                        name="billing_name"
                        InputLabelProps={{ shrink: true }}
                        defaultValue={detailValue?.billing_name}
                        onChange={(e) => handleChange(e, "details")}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                        className="revert-btn"
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Billing Phone"
                        variant="outlined"
                        className="sequence"
                        name="billing_phone"
                        InputLabelProps={{ shrink: true }}
                        defaultValue={detailValue?.billing_phone}
                        onChange={(e) => handleChange(e, "details")}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                        className="revert-btn"
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Address 1"
                        variant="outlined"
                        className="sequence"
                        name="billing_address_1"
                        InputLabelProps={{ shrink: true }}
                        defaultValue={detailValue?.billing_address_1}
                        onChange={(e) => handleChange(e, "details")}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Address 2"
                        variant="outlined"
                        className="sequence"
                        name="billing_address_2"
                        InputLabelProps={{ shrink: true }}
                        defaultValue={detailValue?.billing_address_2}
                        onChange={(e) => handleChange(e, "details")}
                      />
                      <IconButton
                        size="small"
                        className="revert-btn"
                        title="revert"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Billing Email"
                        variant="outlined"
                        className="sequence"
                        name="billing_email"
                        InputLabelProps={{ shrink: true }}
                        defaultValue={detailValue?.billing_email}
                        onChange={(e) => handleChange(e, "details")}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Postal Code"
                        variant="outlined"
                        className="sequence"
                        InputLabelProps={{ shrink: true }}
                        defaultValue={
                          postalCodeData.billing.zipCode ||
                          detailValue?.billing_postal_code
                        }
                        name="billing_postal_code"
                        onChange={handleZipCodeChange("billing")}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="City"
                        variant="outlined"
                        className="sequence"
                        InputLabelProps={{ shrink: true }}
                        value={
                          postalCodeData.billing.city ||
                          detailValue?.billing_city
                        }
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="State"
                        variant="outlined"
                        className="sequence"
                        value={
                          postalCodeData.billing.state ||
                          detailValue?.billing_state
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion className="address-details">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <b> Return Address</b>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} sx={{ mt: 2 }} alignItems="center">
                  <Grid item xs={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Address 1"
                        variant="outlined"
                        className="sequence"
                        name="return_address_1"
                        defaultValue={detailValue?.return_address_1}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => handleChange(e, "details")}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Address 2"
                        variant="outlined"
                        className="sequence"
                        name="return_address_2"
                        defaultValue={detailValue?.return_address_2}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => handleChange(e, "details")}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={4}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Postal Code"
                        variant="outlined"
                        className="sequence"
                        defaultValue={
                          postalCodeData.return.zipCode ||
                          detailValue?.return_postal_code
                        }
                        InputLabelProps={{ shrink: true }}
                        onChange={handleZipCodeChange("return")}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={4}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="City"
                        variant="outlined"
                        className="sequence"
                        value={
                          postalCodeData.return.city || detailValue?.return_city
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={4}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="State"
                        variant="outlined"
                        className="sequence"
                        value={
                          postalCodeData.return.state ||
                          detailValue?.return_state
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Box className="additional-fields">
              <Typography variant="p" className="additional-fields-header">
                <b> Contact Details</b>{" "}
              </Typography>
              <Box mt={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Contact Name"
                        variant="outlined"
                        className="sequence"
                        name="contact_name"
                        defaultValue={detailValue?.contact_name}
                        onChange={(e) => handleChange(e, "details")}
                        InputLabelProps={{ shrink: true }}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>

                  <Grid xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Contact Phone"
                        variant="outlined"
                        className="sequence"
                        name="contact_email"
                        defaultValue={detailValue?.contact_email}
                        onChange={(e) => handleChange(e, "details")}
                        InputLabelProps={{ shrink: true }}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Contact Email"
                        variant="outlined"
                        className="sequence"
                        name="contact_phone"
                        defaultValue={detailValue?.contact_phone}
                        onChange={(e) => handleChange(e, "details")}
                        InputLabelProps={{ shrink: true }}
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>

                  <Grid xs={12} md={12} lg={6}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Contact Fax"
                        variant="outlined"
                        className="sequence"
                        name="contact_fax"
                        defaultValue={detailValue?.contact_fax}
                        onChange={(e) => handleChange(e, "details")}
                        InputLabelProps={{ shrink: true }}
                      />
                      <IconButton
                        size="small"
                        className="revert-btn"
                        title="revert"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  {/* <Grid xs={12} md={12} lg={12}>
                    <FormControl className="revert-option">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label="Account Keyword"
                        variant="outlined"
                        className="sequence"
                        value="laura, hitze"
                        disabled
                      />
                      <IconButton
                        size="small"
                        title="revert"
                        className="revert-btn"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 14,
                          },
                        }}
                      >
                        <UndoIcon />
                      </IconButton>
                    </FormControl>
                  </Grid> */}
                </Grid>
                <FormControl sx={{ my: 2 }} className="revert-option">
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Account Keyword"
                    variant="outlined"
                    className="sequence"
                    defaultValue={detailValue?.account_keywords}
                    // disabled
                    name="account_keywords"
                    onChange={(e) => handleChange(e, "details")}
                    InputLabelProps={{ shrink: true }}
                  />
                  <IconButton
                    size="small"
                    title="revert"
                    className="revert-btn"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 14,
                      },
                    }}
                  >
                    <UndoIcon />
                  </IconButton>
                </FormControl>

                <Button
                  variant="contained"
                  className="accountKeywords"
                  sx={{ mt: 2 }}
                >
                  edit account keywords
                </Button>
              </Box>
            </Box>
          </FormGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountManager;
