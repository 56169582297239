import axiosWithoutAuth from "axios";
import PubSub from "pubsub-js";
import axios from "../utils/axios";

export function get(url, paramsObject) {
  const isAuth = localStorage.getItem("auth");
  const classRef = paramsObject?.ClassRef;
  const processCategoryRef = paramsObject?.ProcessingCategoryRef;
  const page = paramsObject?.page;
  const pageSize = paramsObject?.page_size;
  const site_id = paramsObject?.site_id;
  const auto_error = paramsObject?.auto_error;
  const params = {
    ...(classRef && { class_id: classRef }),
    ...(processCategoryRef && { processing_category_id: processCategoryRef }),
    ...(page && { page: page }),
    ...(pageSize && { page_size: pageSize }),
    ...(site_id && { site_id: site_id }),
    ...(auto_error && { auto_error: auto_error }),
  };
  PubSub.publish("msg", true);
  return axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + isAuth,
      },
      params,
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getBlob(url) {
  const isAuth = localStorage.getItem("auth");

  PubSub.publish("msg", true);

  const options = {
    headers: {
      Authorization: "Bearer " + isAuth,
    },
    responseType: "blob",
  };

  return axios.get(url, options).then(handleResponse).catch(handleError);
}

export function post(url, postdata) {
  const isAuth = localStorage.getItem("auth");

  PubSub.publish("msg", true);
  const headers = {
    "Access-Control-Allow-Origin": "*", //NOSONAR
    Authorization: "Bearer " + isAuth,
  };
  return axios
    .post(url, postdata, { headers })
    .then(handleResponse)
    .catch(handleError);
}

export function postBlob(url, postdata) {
  const isAuth = localStorage.getItem("auth");

  PubSub.publish("msg", true);
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + isAuth,
    },
    responseType: "blob",
    body: JSON.stringify({}),
  };

  return axios
    .post(url, postdata, options)
    .then(handleResponse)
    .catch(handleError);
}

export function postFormData(url, postdata, loaderHide, paramsObject) {
  if (!loaderHide) {
    PubSub.publish("msg", true);
  }
  const isAuth = localStorage.getItem("auth");
  const page = paramsObject?.page;
  const pageSize = paramsObject?.page_size;
  const params = {
    ...(page && { page: page }),
    ...(pageSize && { page_size: pageSize }),
  };
  const headers = {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*", //NOSONAR
    Authorization: "Bearer " + isAuth,
  };
  return axios
    .post(url, postdata, { headers, params })
    .then(handleResponse)
    .catch(handleError);
}

export function putFormData(url, updatedata) {
  const isAuth = localStorage.getItem("auth");

  const headers = {
    "Content-Type": updatedata.type,
    Authorization: "Bearer " + isAuth,
  };
  return axios
    .put(url, updatedata, { headers })
    .then((resp) => resp)
    .catch(handleError);
}

export function putRequest(url, postdata) {
  PubSub.publish("msg", true);
  const isAuth = localStorage.getItem("auth");
  const headers = {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*", //NOSONAR
    Authorization: "Bearer " + isAuth,
  };
  return axios
    .put(url, postdata, headers)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteRequest(url, data) {
  const isAuth = localStorage.getItem("auth");
  PubSub.publish("msg", true);
  let token = isAuth;

  return axios
    .delete(url, { data, headers: { Authorization: "Bearer " + token } })
    .then((response) => response)
    .catch(handleError);
}

export function patch(url, postdata) {
  PubSub.publish("msg", true);
  return axios.patch(url, postdata).then(handleResponse).catch(handleError);
}

export function uploadImageToS3(url, data) {
  PubSub.publish("msg", true);
  const headers = { "Content-Type": data.type };
  return axiosWithoutAuth
    .put(url, data, { headers })
    .then((resp) => resp)
    .catch(handleError);
}

export function patchNoAuth(url, data) {
  PubSub.publish("msg", true);
  return axiosWithoutAuth
    .patch(url, data)
    .then((resp) => resp)
    .catch(handleError);
}

function handleError(error) {
  PubSub.publish("msg", false);
  throw error;
}

function handleResponse(response) {
  return response;
}
