import {
  get,
  deleteRequest,
  postFormData,
  post,
  postBlob,
  putRequest,
  putFormData,
} from "./BaseService";
import { apiBaseUrl } from "../config";

function getClassLists() {
  const url = `get_class_list`;
  return get(apiBaseUrl + url);
}
function getProcessingCategoryList() {
  const url = `get_processing_category_list`;
  return get(apiBaseUrl + url);
}
function getContainerPrepList() {
  const url = `get_container_prep_list`;
  return get(apiBaseUrl + url);
}
function getAccountList(params) {
  const url = `get_account_list`;
  return get(apiBaseUrl + url, params);
}
function getJobList() {
  const url = `get_job_list`;
  return get(apiBaseUrl + url);
}
function getSortProfilesList() {
  const url = `get_sort_profiles_list`;
  return get(apiBaseUrl + url);
}
export const mailingServices = {
  getClassLists,
  getProcessingCategoryList,
  getContainerPrepList,
  getAccountList,
  getJobList,
  getSortProfilesList,
};
