import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { RolesService } from "../../../services/RolesService";
import { toast } from "react-toastify";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Trash Bin Trash.svg";
import { ReactComponent as AddRoleIcon } from "../../../assets/icons/Masked_Icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import Loader from "../../../components/Loader";
import { MSPUserCreationService } from "../../../services/MSPUserCreation";
import CustomToolBar from "../../../components/customToolbar/customToolbar";
import CustomPagination from "../../../components/customPagination/custompagination";
import UserModal from "./UserModal";
import DummyProfileImage from "../../../assets/images/hello_robot.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow:
    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
  borderRadius: "10px",
};

export default function UserCreation() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState();
  const [rolesDetails, setRoleDetails] = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);
  const [permissionList, setPermissionList] = useState({});
  const [deleteId, setDeleteId] = React.useState({
    userId: null,
    pageId: null,
  });

  const columns = [
    {
      field: "account_name",
      headerName: "EII User Name",
      headerClassName: "pinned-header",
      cellClassName: "pinned-cell",
      pinned: true,
      width: 300,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
          <img
            src={
              params.row.image_base64
                ? `data:image/png;base64,${params.row.image_base64}`
                : DummyProfileImage
            }
            className="mspListImage"
            alt="mspListImage"
          />
          <div>{params.value}</div>
        </Box>
      ),
    },
    { field: "contact_email", headerName: "Email", width: 300 },
    {
      field: "contact_number",
      headerName: "Contact Number",
      width: 250,
      renderCell: (params) => {
        return formatNumber(params.value);
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            {permissionList.msp_role_group_function_mapping_list
              ?.role_update_flag === 1 && (
              <EditIcon
                className="EditIcon"
                onClick={() => handleEditOpen(params.row)}
              />
            )}

            {permissionList.msp_role_group_function_mapping_list
              ?.role_delete_flag === 1 &&
              !params.row.is_logged_user && (
                <DeleteIcon
                  className="deleteIcon"
                  onClick={() => handleDeleteOpen(params.row.id)}
                />
              )}
          </Box>
        );
      },
      width: 300,
    },
  ];

  useEffect(() => {
    getUserList();
  }, [page, pageSize]);

  useEffect(() => {
    getRolePermissions();
    roleList();
  }, []);

  const formatNumber = (phoneNo) => {
    return phoneNo.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3");
  };

  const getRolePermissions = async () => {
    try {
      const response = await RolesService.getEiiRolePermissions(localStorage.getItem('role_id'));
      if (response.status === 200) {
        response.data.eii_role_group_function_mapping_list?.map((obj) => {
          obj.role_function_name === "EII User" && setPermissionList(obj);
        });
      }
    } catch (error) {
      console.log("Err==>", error);
    }
  };

  const getUserList = () => {
    try {
      setLoader(true);
      MSPUserCreationService.getEIIUsersList({
        page: page,
        page_size: pageSize,
      })
        .then((res) => {
          const userList = res.data.user_account_list;
          setUsersList(userList);
          setRowCount(res.data.total_record);
          setLoader(false);
        })
        .catch((e) => {
          toast.error("Something went wrong");
          setLoader(false);
        });
    } catch (error) {
      console.error("Err===>", error);
      setLoader(false);
    }
  };

  const roleList = () => {
    RolesService.getEiiRoles().then((res) => {
      const roleList = res.data;
      setRoleDetails(roleList);
    });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1); // Reset to first page
  };

  const handleEditOpen = (data) => {
    setEditModal(true);
    setUserDetails(data);
  };

  const handleDeleteOpen = (userId) => {
    setDeleteModal(true);
    setDeleteId(userId);
  };
  const handleDeleteClose = () => setDeleteModal(false);

  const handleClose = (data) => {
    setOpen(false);
    setEditModal(false);
    setUserDetails("");
    data && getUserList();
  };

  const deleteData = async () => {
    try {
      const formData = new FormData();
      const deleteData = usersList.find((items) => items.id === deleteId);
      formData.append("account_guid", deleteData.account_guid);
      MSPUserCreationService.deleteEIIUserList(formData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("User Deleted Successfully");
            getUserList();
          }
        })
        .catch((e) => toast.error("Something went wrong"));
    } catch (error) {
      console.log("Err===>", error);
    }

    handleDeleteClose();
  };

  return (
    <div className="roles mspcreation">
      {loader && <Loader />}

      <Box display="flex" justifyContent="end">
        {permissionList.msp_role_group_function_mapping_list
          ?.role_create_flag === 1 && (
          <Button
            variant="contained"
            sx={{ fontWeight: "bold" }}
            onClick={() => setOpen(true)}
            className="Btn"
          >
            <AddRoleIcon /> User Creation
          </Button>
        )}
      </Box>

      <Grid container>
        <Grid item md={12}>
          <Box
            style={{
              height: "calc(100vh - 200px )",
              width: "100%",
              margin: "20px 0",
            }}
            className="userTable"
          >
            <DataGrid
              className="datagrid gridToolbar"
              rows={usersList}
              getRowId={(row) => row.account_guid}
              columns={columns}
              localeText={{
                noRowsLabel: "No records Found",
              }}
              slots={{
                Toolbar: CustomToolBar,
                Pagination: (props) => (
                  <CustomPagination
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    page={page}
                    pageSize={pageSize}
                    rowCount={rowCount}
                  />
                ),
              }}
              slotProps={{
                toolbar: {
                  toolbarWished: {
                    columns: true,
                    filter: true,
                    density: true,
                    export: true,
                  },
                },
              }}
              disableSelectionOnClick
            />
          </Box>
        </Grid>
      </Grid>

      {open && (
        <UserModal
          open={open}
          rolesDetails={rolesDetails}
          onClose={(data) => handleClose(data)}
        />
      )}

      {editModal && (
        <UserModal
          open={editModal}
          rolesDetails={rolesDetails}
          userDetails={userDetails}
          onClose={(data) => handleClose(data)}
        />
      )}

      {deleteModal && (
        <Modal
          open={deleteModal}
          onClose={handleDeleteClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="roleCreateModal"
        >
          <Box sx={style} style={{ height: "auto", width: "30%" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 1, p: 2 }}
            >
              <b>Confirm Delete ? </b>
            </Typography>
            <Box sx={{ mt: 1, px: 2 }}>
              <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
                <Button
                  onClick={handleDeleteClose}
                  variant="outlined"
                  // className="createBtn"
                  type="submit"
                >
                  No
                </Button>
                <Button
                  onClick={deleteData}
                  variant="outlined"
                  className="Btn"
                  type="submit"
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
}
