import React from "react";
import { Box, Grid, Typography, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import "../workOrder.scss";
import dayjs from "dayjs";

export default function WOPreview() {
  const CustomerName = localStorage.getItem("msp_name");

  const {
    msp_id,
    work_order_id,
    file_type,
    file_name,
    job_name,
    po_number,
    pieces,
    trays,
    pallets,
    mailing_date,
    pick_up_date,
    eps_account_number,
    eps_reference_field,
    work_order_type_id,
    special_processing_notes,
  } = useSelector((state) => state.workOrder);

  const rows = [
    {
      pcs: pieces,
      trays: trays,
      pallets: pallets,
      mailingDate: dayjs(mailing_date).format("MM/DD/YYYY"),
      pickupDate: dayjs(pick_up_date).format("MM/DD/YYYY"),
    },
  ];
  const columns = [
    {
      field: "pcs",
      headerName: "PCS",
      width: 150,
      flex: 1,
    },
    {
      field: "trays",
      headerName: "Trays",
      width: 150,
      flex: 1,
    },
    {
      field: "pallets",
      headerName: "Pallets",
      width: 150,
      flex: 1,
    },
    {
      field: "mailingDate",
      headerName: "Mailing Date",
      width: 150,
      flex: 1,
    },
    {
      field: "pickupDate",
      headerName: work_order_type_id === 1 ? "Delivery date" : "Pickup date",
      width: 150,
      flex: 1,
    },
  ];

  return (
    <Box className="work_order_home_page">
      <div className="create_page">
        <div className="workOrder">
          <Box>
            <Box className="workOrder_header">
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography variant="p">Work Order</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box className="customerDetails" sx={{ mt: 4 }}>
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Customer Details</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" className="customer-details">
                  Customer Name : <b>{CustomerName}</b>
                </Typography>
              </Box>
            </Box>

            <Box className="customerDetails" sx={{ mt: 1 }}>
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Work Order Info</b>
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item md={6} lg={6} sm={12}>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" className="customer-details">
                      Work Order: <b>{work_order_id}</b>
                    </Typography>
                    <Typography variant="h6" className="customer-details">
                      PO Number : <b>{po_number}</b>
                    </Typography>
                    <Typography variant="h6" className="customer-details">
                      Job Name : <b>{job_name}</b>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6} lg={6} sm={12}>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" className="customer-details">
                      EPS Account Number: <b>{eps_account_number}</b>
                    </Typography>
                    <Typography variant="h6" className="customer-details">
                      Reference Field : <b>{eps_reference_field}</b>
                    </Typography>
                    <Typography variant="h6" className="customer-details">
                      Work Order Type :{" "}
                      <b>
                        {work_order_type_id === 1
                          ? "Delivery"
                          : "Pickup Of Mail"}
                      </b>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box className="customerDetails results">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Line Items</b>
                </Typography>
              </Box>
              <TextField
                name="special_processing_notes"
                label="Special Processing Notes"
                size="small"
                multiline
                sx={{ mt: 3, width: "50%" }}
                value={special_processing_notes || ""}
                inputProps={{
                  readOnly: true,
                }}
              />
              <Box sx={{ height: "100%", width: "100%", mt: 2 }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  getRowId={(row) => row.pcs + "-" + row.trays}
                  hideFooterPagination={true}
                />
              </Box>
            </Box>

            {file_name && file_type && (
              <Box className="customerDetails">
                <Box className="customerDetailsHeader">
                  <Typography variant="p">
                    <b>File Attachment</b>
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6" className="customer-details">
                    File Type : {file_type || "Nil"}
                  </Typography>
                  <Typography variant="h6" className="customer-details">
                    File Name : {file_name || "Nil"}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </div>
    </Box>
  );
}
