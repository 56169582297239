import {
  Modal,
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
} from "@mui/material";
import "./routercreation.scss";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { apiBaseUrl } from "../../../config";
import CloseIcon from "../../../assets/icons/Close Square.svg";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";

const UpdateModal = ({ open, onClose, editableDetail }) => {
  // const [listOfWo, setListOfWo] = useState(null);
  const [route, setRoute] = useState(null);

  const isAuth = localStorage.getItem("auth");

  const fetchSpecificRoute = async (id) => {
    const response = await axios.get(
      `${apiBaseUrl}get_specific_route?pickup_route_id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${isAuth}`,
        },
      }
    );
    return response.data.route_details;
  };
  const fetchWorkOrder = async () => {
    const response = await axios.get(
      `${apiBaseUrl}mail_owner_created_work_order`,
      {
        headers: {
          Authorization: `Bearer ${isAuth}`,
        },
      }
    );

    return response.data.mail_owner_created_work_order_list;
  };
  const { data: woDropdown } = useQuery({
    queryKey: ["workOrders"],
    queryFn: fetchWorkOrder,
  });

  const { data: routeDetail } = useQuery({
    queryKey: ["route", editableDetail],
    queryFn: () => fetchSpecificRoute(editableDetail),
    enabled: !!editableDetail,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (routeDetail) {
      setRoute(routeDetail[0]);
    }
  }, [routeDetail]);

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    if (name === "mail_owner_name") {
      const selectedText = woDropdown.find(
        (item) => item.account_guid == value && item
      );
      // setListOfWo(selectedText.work_orders);
      setRoute((prevRoute) => {
        let updatedRoute = { ...prevRoute };
        let ifExist = updatedRoute.stops[index];
        if (ifExist) {
          updatedRoute.stops[index][name] = selectedText.name;
          updatedRoute.stops[index]["mo_account_id"] = value;

          return updatedRoute;
        }
        return prevRoute;
      });
    } else if (name === "job_name") {
      const selectedWO = woDropdown.find((item) => {
        return item.work_orders.find(
          (wos) => wos.work_order_id === value && wos
        );
      });
      setRoute((prevRoute) => {
        let updatedRoute = { ...prevRoute };
        let ifExist = updatedRoute.stops[index];
        if (ifExist) {
          const jobName = selectedWO.work_orders.find(
            (item) => item.work_order_id === value && item
          );
          updatedRoute.stops[index][name] = jobName.job_name;
          updatedRoute.stops[index]["mo_work_order_id"] = value;
          updatedRoute.stops[index]["street"] = jobName.mo_address;
          updatedRoute.stops[index]["site_name"] = jobName.site_name;
          updatedRoute.stops[index]["city"] = jobName.city;
          updatedRoute.stops[index]["postal_code"] = jobName.postal_code;
          updatedRoute.stops[index]["state"] = jobName.state;

          return updatedRoute;
        }
        return prevRoute;
      });
    }
  };
  const handleSubmit = () => {
    let mo_stop_details = route.stops;
    const body = {
      mo_route_name: route.mo_route_name,
    };
    for (let data of mo_stop_details) {
      data["action"] = "update";
    }
    body["mo_stop_details"] = mo_stop_details;
    console.log(mo_stop_details, "mo_stop_details");
    try {
      axios
        .put(
          `${apiBaseUrl}update_route?route_id=${editableDetail}`,
          JSON.stringify(body),
          {
            headers: {
              Authorization: `Bearer ${isAuth}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
          onClose("Success");
        });
    } catch (error) {}
  };
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="roleCreateModal"
    >
      <Box className="route-create-modal">
        <Box component="form">
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            sx={{ mb: 3 }}
            className="mspRoleHeader"
          >
            <b>Update Route</b>
            <Button onClick={() => onClose()} className="closeButton">
              {" "}
              <img alt="closeIcon" src={CloseIcon} className="closeicon" />
            </Button>
          </Typography>

          <Box className="routerContainer">
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item lg={5} md={5} sm={12}>
                <TextField
                  size="small"
                  label="Route Name"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                  sx={{ width: "100%" }}
                  name="mo_route_name"
                  value={routeDetail ? routeDetail[0].mo_route_name : ""}
                />
              </Grid>
            </Grid>

            {route?.stops.map((div, index) => (
              <Box my={3} className="routerfield">
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item lg={11} md={11}>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} sm={12}>
                        <Box>
                          <FormControl fullWidth size="small" sx={{ my: 2 }}>
                            <InputLabel
                              shrink
                              id={`mail-owner-label-${div.id}`}
                            >
                              Mail Owner
                            </InputLabel>
                            <Select
                              labelId={`mail-owner-label-${div.id}`}
                              id={`mail-owner-select-${div.id}`}
                              value={div.mo_account_id}
                              label="Mail Owner"
                              name="mail_owner_name"
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            >
                              {woDropdown?.map((item) => (
                                <MenuItem
                                  key={item.account_guid}
                                  value={item.account_guid}
                                  data-custom={item.name}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12}>
                        <Box>
                          <FormControl fullWidth size="small" sx={{ my: 2 }}>
                            <InputLabel id={`work-order-label-${div.id}`}>
                              Work Order
                            </InputLabel>
                            <Select
                              labelId={`work-order-label-${div.id}`}
                              id={`work-order-select-${div.id}`}
                              value={div.mo_work_order_id}
                              label="Work Order"
                              name="job_name"
                              onChange={(e) => handleChange(e, index)}
                            >
                              {woDropdown?.map((wo) => {
                                return wo.work_orders.map((item) => (
                                  <MenuItem
                                    key={item.work_order_id}
                                    value={item.work_order_id}
                                  >
                                    {item.job_name}
                                  </MenuItem>
                                ));
                              })}
                              {/* {listOfWo
                                ? listOfWo?.map((item) => (
                                    <MenuItem
                                      key={item.work_order_id}
                                      value={item.work_order_id}
                                    >
                                      {item.job_name}
                                    </MenuItem>
                                  ))
                                : woDropdown.map((wo) => {
                                    return wo.work_orders.map((item) => (
                                      <MenuItem
                                        key={item.work_order_id}
                                        value={item.work_order_id}
                                      >
                                        {item.job_name}
                                      </MenuItem>
                                    ));
                                  })} */}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12}>
                        <TextField
                          sx={{ my: 2 }}
                          fullWidth
                          id={`msp-site-${div.id}`}
                          label="MSP Site"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={div?.site_name || ""}
                          name={`msp_site_${div.id}`}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          sx={{ mb: 2 }}
                          label="Address"
                          variant="outlined"
                          multiline
                          value={div?.street || ""}
                          name={`address_${div.id}`}
                          rows={3}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12}>
                        <TextField
                          sx={{ my: 2 }}
                          fullWidth
                          id={`city-${div.id}`}
                          label="City"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={div?.city || ""}
                          name={`city_${div.id}`}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12}>
                        <TextField
                          sx={{ my: 2 }}
                          fullWidth
                          id={`state-${div.id}`}
                          label="State"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={div?.state || ""}
                          name={`state_${div.id}`}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12}>
                        <TextField
                          sx={{ my: 2 }}
                          fullWidth
                          id={`postal-code-${div.id}`}
                          label="Postal Code"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={div?.postal_code || ""}
                          name={`postal_code_${div.id}`}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item lg={1} md={1}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button className="remove" sx={{ p: 0 }}>
                        <Tooltip title="Delete Route">
                          <DeleteIcon className="deleteButton" />
                        </Tooltip>
                      </Button>
                    </Box>
                  </Grid> */}
                </Grid>
              </Box>
            ))}

            {/* <Box display="flex" justifyContent="end" sx={{ my: 4 }}>
              <Tooltip title="Add More Routes">
                <Button className="Btn" onClick={handleAddDiv}>
                  <AddCircleOutlineIcon /> Add
                </Button>
              </Tooltip>
            </Box> */}

            <Box display="flex" justifyContent="end" gap={2} sx={{ mb: 2 }}>
              <Button
                variant="outlined"
                type="button"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                className="Btn"
              >
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateModal;
