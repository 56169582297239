import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  Typography,
  Box,
  Modal,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as AddIcon } from "../../../assets/icons/Masked_Icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Trash Bin Trash.svg";
import { TaskAlt } from "@mui/icons-material";
import CopyIcon from "../../../assets/icons/File.svg";
import { apiBaseUrl } from "../../../config";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  border: "none",
};

export default function SiteEditor() {
  const [siteEditorList, setSiteEditorList] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [mspPermission, setMspPermission] = useState([]);
  const [count, setCount] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [deleteRowId, setDeleteRowId] = useState();

  const baseUrl = apiBaseUrl;
  const navigate = useNavigate();

  useEffect(() => {
    getSiteDetailsList();
    getPermissions();
  }, [page]);

  const handleDeleteOpen = (id) => {
    setDeleteModalOpen(true);
    setDeleteRowId(id);
  };
  const handleDeleteClose = () => setDeleteModalOpen(false);

  const getPermissions = () => {
    try {
      const token = localStorage.getItem("auth");
      const role_id = localStorage.getItem("role_id");
      axios
        .get(
          `${apiBaseUrl}msp_role_group_function_mapping_list?role_id=${role_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          res.data.msp_role_group_function_mapping_list?.map((obj) => {
            obj.role_function_name === "Receiving" && setMspPermission(obj);
          });
        });
    } catch (error) {
      console.log("Err==>", error);
    }
  };

  const getSiteDetailsList = async () => {
    try {
      axios
        .get(`${baseUrl}list_site_details`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
          params: {
            msp_guid: localStorage.getItem("msp_account_guid"),
            page: page + 1,
            page_size: pageSize,
          },
        })
        .then((response) => {
          setSiteEditorList(response.data.result);
          setCount(response.data.total_items);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      toast.error("Something went wrong", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageSize(event.target.value);
  };

  const onDelete = async () => {
    try {
      const response = await axios.delete(
        `${baseUrl}site_deletion?site_guid=${deleteRowId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data?.message);
        getSiteDetailsList();
        handleDeleteClose();
      }
    } catch (error) {
      toast.success(error?.data?.detail);
    }
  };

  return (
    <div className="machineTable">
      {mspPermission?.msp_role_group_function_mapping_list?.role_create_flag ===
        1 && (
        <div className="work_order_home_page">
          <Box className="workOrder_add_button">
            <Button
              className="Btn"
              onClick={() => navigate("/msp/sites-stepper")}
            >
              <AddIcon /> Site Creation
            </Button>
          </Box>
        </div>
      )}

      {deleteModalOpen && (
        <div>
          <Modal
            open={handleDeleteOpen}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: 1, p: 2 }}
              >
                <b>Are you sure you want to delete?</b>
              </Typography>
              <Box sx={{ mt: 1, px: 2 }}>
                <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
                  <Button onClick={handleDeleteClose} variant="outlined">
                    No
                  </Button>
                  <Button onClick={onDelete} variant="outlined" className="Btn">
                    Yes
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </div>
      )}

      <TableContainer component={Paper} className="machineDataTable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{
                  position: "sticky",
                  left: 0,
                  top: 0 /* Don't forget this, required for the stickiness */,
                  boxShadow: " 0 2px 2px -1px rgba(0, 0, 0, 0.4)",
                  zIndex: 800,
                  // boxShadow: "2px 0 5px -2px rgba(0,0,0,0.5)",
                }}
              >
                Site Name
              </TableCell>
              <TableCell>Contact Person</TableCell>
              <TableCell>Contact number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Admin Site</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {siteEditorList?.length > 0 ? (
              siteEditorList?.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{
                      position: "sticky",
                      left: 0,
                      background: "white",
                      zIndex: 800,
                      top: 0 /* Don't forget this, required for the stickiness */,
                      boxShadow: " 0 2px 2px -1px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    {row.site_name}
                  </TableCell>
                  <TableCell>{row.contact_person}</TableCell>
                  <TableCell>{row.phone}</TableCell>

                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    {row.is_admin_site === true && <TaskAlt />}
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      background: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {/* <img
                        src={CopyIcon}
                        className="actionIcons"
                        alt="actionIcons"
                      /> */}
                      <EditIcon
                        className="actionIcons"
                        onClick={() =>
                          navigate(`/msp/sites-stepper/${row.site_guid}`)
                        }
                      />
                      <DeleteIcon
                        onClick={() => handleDeleteOpen(row.site_guid)}
                        className="actionIcons"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>
                  <Typography variant="h6" align="center">
                    No records found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
