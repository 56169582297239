import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import { styled } from "@mui/system";
import CreateWorkOrder from "./CreateWorkOrder";
import WOPreview from "./UploadPreview";
import {
  fetchDataError,
  fetchDataStart,
  fetchDataStop,
  handleWorkOrderChange,
  reset,
} from "../../../../store/WorkOrderCreation";
import { useDispatch, useSelector } from "react-redux";
import DexUploadModal from "./DexUploadModal";
import moment from "moment";
import axios from "axios";
import { apiBaseUrl } from "../../../../config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StepIconRoot = styled("div")(({ ownerState }) => ({
  display: "flex",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    padding: "5px",
    borderRadius: "50%",
    color: "white",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    padding: "5px",
    borderRadius: "50%",
    color: "white",
  }),
}));
const GoBackIcon = styled(Box)(({}) => ({
  backgroundColor: "#f5f5f5",
  width: "45px",
  height: "45px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  transition: "background-color 0.3s ease",

  "&:hover": {
    backgroundColor: "#b0c4de",
  },
}));

const StepIcon = (props) => {
  const { active, completed, icon } = props;
  return (
    <StepIconRoot ownerState={{ active, completed }}>
      {completed ? <Check /> : icon}
    </StepIconRoot>
  );
};

const HorizontalLinearStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [workOrderModal, setWorkOrderModal] = useState(false);
  const [fileUpload, setFileUpload] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    msp_id,
    work_order_id,
    site_id,
    eps_account_number,
    reference_field_id,
    work_order_type_id,
    job_name,
    po_number,
    comments,
    work_order_upload_option_id,
    is_additional_services_present,
    pieces,
    trays,
    pallets,
    mailing_date,
    pick_up_date,
    special_processing_notes,
  } = useSelector((state) => state.workOrder);

  const handleNext = (isDraft) => {
    try {
      if (
        site_id == 0 ||
        msp_id == "" ||
        eps_account_number == "" ||
        reference_field_id == "" ||
        job_name == "" ||
        po_number == "" ||
        comments == "" ||
        mailing_date == "" ||
        pick_up_date == "" ||
        special_processing_notes == ""
      ) {
        toast.error("Please fill all the fields");
        return false;
      } else {
        dispatch(fetchDataStart());
        const headers = {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("auth"),
        };
        const formData = new FormData();
        formData.append("msp_id", msp_id);
        formData.append("site_id", site_id);
        formData.append("eps_account_number", eps_account_number);
        formData.append("eps_reference_field", reference_field_id);
        formData.append("work_order_type_id", work_order_type_id);
        formData.append("job_name", job_name);
        formData.append("po_number", po_number);
        formData.append("comments", comments);
        formData.append(
          "work_order_upload_option_id",
          work_order_upload_option_id
        );
        fileUpload && formData.append("file_upload", fileUpload);
        formData.append(
          "is_additional_services_present",
          is_additional_services_present === true ? 1 : 0
        );
        formData.append("pieces", pieces);
        pieces > 200 && formData.append("trays", trays);
        trays > 7 && formData.append("pallets", pallets);
        formData.append(
          "mailing_date",
          moment(mailing_date).format("YYYY-MM-DD")
        );
        formData.append(
          "pick_up_date",
          moment(pick_up_date).format("YYYY-MM-DD")
        );
        formData.append("special_processing_notes", special_processing_notes);

        if (work_order_id) {
          formData.append("work_order_no", work_order_id);
          !isDraft
            ? formData.append("isDraft", 1)
            : formData.append("isDraft", 0);
          axios
            .put(`${apiBaseUrl}work_order_update`, formData, { headers })
            .then((response) => {
              dispatch(fetchDataStop());
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              if (activeStep === steps.length - 1) {
                dispatch(reset());
                navigate("/customer-portal/workorder");
              }
            })
            .catch((e) => dispatch(fetchDataError(e)));
        } else {
          axios
            .post(`${apiBaseUrl}work_order_creation`, formData, { headers })
            .then((response) => {
              dispatch(fetchDataStop());
              dispatch(
                handleWorkOrderChange({
                  field: "work_order_id",
                  value: response.data.work_order_id,
                })
              );
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            })
            .catch((e) => dispatch(fetchDataError(e)));
        }
      }
    } catch (error) {
      dispatch(fetchDataError(error));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const openWorkOrderModal = () => setWorkOrderModal(true);
  const closeWorkOrderModal = () => setWorkOrderModal(false);

  const steps = [
    {
      label: "Step 1",
      icon: <AccountCircle />,
      component: (
        <CreateWorkOrder
          openWorkOrderModal={openWorkOrderModal}
          closeWorkOrderModal={closeWorkOrderModal}
        />
      ),
    },
    {
      label: "Step 2",
      icon: <AssignmentInd />,
      component: <WOPreview />,
    },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <GoBackIcon onClick={() => navigate("/customer-portal/workorder")}>
        <ArrowBackIcon />{" "}
      </GoBackIcon>

      <Stepper activeStep={activeStep} className="stepperHeader">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={() => (
                <StepIcon
                  active={activeStep === index}
                  completed={activeStep > index}
                  icon={step.icon}
                />
              )}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} variant="contained">
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography sx={{ mt: 2, mb: 1 }}>
              {steps[activeStep].component}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                pt: 2,
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant="outlined"
              >
                Back
              </Button>
              <Box sx={{ display: "flex", gap: 2 }}>
                {activeStep === steps.length - 1 && (
                  <Button
                    onClick={() => handleNext("isSave")}
                    variant="contained"
                  >
                    Save{" "}
                  </Button>
                )}
                <Button onClick={() => handleNext()} variant="contained">
                  {activeStep === steps.length - 1 ? "Submit" : "Next"}
                </Button>
              </Box>
            </Box>
          </div>
        )}
      </div>
      {/* Modal */}
      {workOrderModal && (
        <DexUploadModal
          open={openWorkOrderModal}
          onClose={closeWorkOrderModal}
          optionClicked={work_order_upload_option_id}
          setFileUpload={setFileUpload}
        />
      )}
    </Box>
  );
};

export default HorizontalLinearStepper;
