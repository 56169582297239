import React, { useEffect, useState } from "react";
import "./user.scss";
import { Box, Button, Typography, Modal, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Trash Bin Trash.svg";
import { ReactComponent as AddRoleIcon } from "../../../assets/icons/Masked_Icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import Loader from "../../../components/Loader";
import { MSPUserCreationService } from "../../../services/MSPUserCreation";
import CustomToolBar from "../../../components/customToolbar/customToolbar";
import CustomPagination from "../../../components/customPagination/custompagination";
import UserModal from "./UserModal";
import axios from "axios";
import { apiBaseUrl } from "../../../config";
import { MspService } from "../../../services/MspService";
import { PermissionList, RolesDetails } from "./type";
import DummyProfileImage from "../../../assets/images/hello_robot.png";

const UserCreation: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [rowCount, setRowCount] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    contact_email: "",
    role_name: "",
    contact_number: "",
  });
  const [rolesDetails, setRolesDetails] = useState<RolesDetails[]>([]);
  const [usersList, setUsersList] = useState([]);
  const [permissionList, setPermissionList] = useState<PermissionList | null>(
    null
  );
  const [deleteId, setDeleteId] = useState({ account_guid: "" });

  const columns = [
    {
      field: "contact_name",
      headerName: "MSP User Name",
      headerClassName: "pinned-header",
      cellClassName: "pinned-cell",
      pinned: true,
      width: 300,
      renderCell: (params: any) => (
        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
          <img
            src={
              params.row.image_base64
                ? `data:image/png;base64,${params.row.image_base64}`
                : DummyProfileImage
            }
            className="mspListImage"
            alt="profile_image"
          />
          <div>{params.value}</div>
        </Box>
      ),
    },
    { field: "contact_email", headerName: "Email", width: 300 },
    {
      field: "contact_number",
      headerName: "Contact Number",
      width: 250,
      renderCell: (params) => {
        return formatNumber(params.value);
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params: any) => {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            {permissionList != null &&
              permissionList.msp_role_group_function_mapping_list
                ?.role_update_flag === 1 && (
                <EditIcon
                  className="EditIcon"
                  onClick={() => handleEditOpen(params.row)}
                />
              )}

            {permissionList != null &&
              permissionList.msp_role_group_function_mapping_list
                ?.role_delete_flag === 1 &&
              !params.row.is_logged_user && (
                <DeleteIcon
                  className="deleteIcon"
                  onClick={() => handleDeleteOpen(params.row.account_guid)}
                />
              )}
          </Box>
        );
      },
      width: 300,
    },
  ];

  useEffect(() => {
    getUserList();
  }, [page, pageSize]);

  useEffect(() => {
    getRolePermissions();
    roleList();
  }, []);

  const formatNumber = (phoneNo: string) => {
    return phoneNo.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3");
  };

  const token = localStorage.getItem("auth");

  const getRolePermissions = async () => {
    const role_id = localStorage.getItem("role_id");

    const response = await axios.get(
      `${apiBaseUrl}msp_role_group_function_mapping_list?role_id=${role_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      response.data.msp_role_group_function_mapping_list?.map((obj) => {
        obj.role_function_name === "MSP Users" && setPermissionList(obj);
      });
    }
  };

  const getUserList = () => {
    try {
      setLoader(true);
      MSPUserCreationService.getMSPUserCreationList({
        page: page,
        page_size: pageSize,
      })
        .then((res) => {
          const userList = res.data.user_list;
          setUsersList(userList);
          setRowCount(res.data.total_items);
          setLoader(false);
        })
        .catch((e) => {
          toast.error("Something went wrong");
          setLoader(false);
        });
    } catch (error) {
      console.error("Err===>", error);
      setLoader(false);
    }
  };

  const roleList = () => {
    MspService.getOnlyMspRolesForUser().then((res) => {
      const roleList = res.data.msp_roles_list;
      setRolesDetails(roleList);
    });
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  const handleEditOpen = (data: any) => {
    setEditModal(true);
    setUserDetails(data);
  };

  

  const handleDeleteOpen = (userId: string) => {
    setDeleteModal(true);
    setDeleteId({ account_guid: userId });
  };
  const handleDeleteClose = () => setDeleteModal(false);

  const handleClose = (data?: string) => {
    setOpen(false);
    setEditModal(false);
    setUserDetails({
      first_name: "",
      last_name: "",
      contact_email: "",
      role_name: "",
      contact_number: "",
    });
    setDeleteModal(false);
    data && getUserList();
  };

  const deleteData = async () => {
    try {
      const formData = new FormData();

      formData.append("account_guid", deleteId.account_guid);
      MSPUserCreationService.deleteMSPUserList(formData)
        .then((res: any) => {
          if (res.status === 200) {
            toast.success("User Deleted Successfully");
            getUserList();
          }
        })
        .catch((e) => toast.error("Something went wrong"));
    } catch (error) {
      console.log("Err===>", error);
    }

    handleClose("success");
  };

  return (
    <div className="roles mspcreation">
      {loader && <Loader />}

      <Box display="flex" justifyContent="end">
        {permissionList != null &&
          permissionList.msp_role_group_function_mapping_list
            ?.role_create_flag === 1 && (
            <Button
              variant="contained"
              sx={{ fontWeight: "bold" }}
              onClick={() => setOpen(true)}
              className="Btn"
            >
              <AddRoleIcon /> User Creation
            </Button>
          )}
      </Box>

      <Grid container>
        <Grid item md={12}>
          <Box
            style={{
              height: "calc(100vh - 200px )",
              width: "100%",
              margin: "20px 0",
            }}
            className="userTable"
          >
            <DataGrid
              className="datagrid gridToolbar"
              rows={usersList}
              getRowId={(row) => row.account_guid}
              columns={columns}
              localeText={{
                noRowsLabel: "No records Found",
              }}
              slots={{
                toolbar: CustomToolBar,
                pagination: (props) => (
                  <CustomPagination
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    page={page}
                    pageSize={pageSize}
                    rowCount={rowCount}
                  />
                ),
              }}
              slotProps={{
                toolbar: {
                  toolbarWished: {
                    columns: true,
                    filter: true,
                    density: true,
                    export: true,
                  },
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {open && (
        <UserModal
          open={open}
          rolesDetails={rolesDetails}
          onClose={(data) => handleClose(data)}
        />
      )}

      {editModal && (
        <UserModal
          open={editModal}
          rolesDetails={rolesDetails}
          userDetails={userDetails}
          onClose={(data) => handleClose(data)}
        />
      )}

      {deleteModal && (
        <Modal
          open={deleteModal}
          onClose={handleDeleteClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="roleCreateModal"
        >
          <Box
            className="user-delete-modal"
            style={{ height: "auto", width: "30%" }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 1, p: 2 }}
            >
              <b>Confirm Delete ? </b>
            </Typography>
            <Box sx={{ mt: 1, px: 2 }}>
              <Box display="flex" justifyContent="end" gap={2} sx={{ my: 2 }}>
                <Button
                  onClick={handleDeleteClose}
                  variant="outlined"
                  // className="createBtn"
                  type="submit"
                >
                  No
                </Button>
                <Button
                  onClick={() => deleteData()}
                  variant="outlined"
                  className="Btn"
                  type="submit"
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};
export default UserCreation;
