import { get, deleteRequest, postFormData, putFormData } from "./BaseService";
import { apiBaseUrl } from "../config";
function siteDetails(params) {
  const url = `sites_list_v2`;
  return get(apiBaseUrl + url, params);
}
function siteCreation(data) {
  const url = `site_creation`;
  return postFormData(apiBaseUrl + url, data, true);
}

function siteDeletion(data) {
  const url = `site_delete`;
  return deleteRequest(apiBaseUrl + url, data);
}

function siteUpdation(data) {
  const url = `site_update`;
  return putFormData(apiBaseUrl + url, data, true);
}

function finalSiteList(params) {
  const url = `final_sites_list`;
  return get(apiBaseUrl + url, params);
}

export const sitesService = {
  siteDeletion,
  siteDetails,
  siteCreation,
  siteUpdation,
  finalSiteList,
};
