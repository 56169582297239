import * as React from "react";

import { styled } from "@mui/styles";
import {
  TextField,
  Stack,
  Typography,
  FormGroup,
  Checkbox,
  Box,
  Paper,
  IconButton,
  FormControl,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";

import Grid from "@mui/material/Unstable_Grid2";

const USPS = ({ handleChange, uspsValue }) => {
  const PermitInformationText = styled("p")({
    position: "absolute",
    top: "-30px",
    left: "25px",
    background: "white",
    padding: "0 10px",
    borderRadius: "5px",
    fontWeight: "bolder",
  });
  const PermitInformation = styled(Box)({
    position: "relative",
    border: "1px solid grey",
    display: "inline-block",
    padding: "20px",
    margin: "20px 0",
    borderRadius: "10px",
    background: "#f0fff0",
  });

  return (
    <Box>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        className="accountEditorTabs uspsTab "
      >
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <FormControl>
              <PermitInformation>
                <PermitInformationText>
                  Permit Information
                </PermitInformationText>
                <Stack direction="row" useFlexGap flexWrap="wrap" spacing={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={6} xl={4}>
                      <FormControl className="revert-option">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          label="Permit Name"
                          variant="outlined"
                          className="sequence"
                          name="permit_name"
                          defaultValue={uspsValue.permit_name}
                          onChange={(e) => handleChange(e, "usps")}
                          InputLabelProps={{ shrink: true }}
                        />
                        <IconButton size="small" className="revert-btn">
                          <UndoIcon />
                        </IconButton>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={4}>
                      <FormControl className="revert-option">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          label="Permit Number"
                          variant="outlined"
                          className="sequence"
                          name="permit_number"
                          defaultValue={uspsValue.permit_number}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => handleChange(e, "usps")}
                        />
                        <IconButton size="small" className="revert-btn">
                          <UndoIcon />
                        </IconButton>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={4}>
                      <FormControl className="revert-option">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          label="Permit Origin"
                          variant="outlined"
                          className="sequence"
                          name="permit_origin"
                          defaultValue={uspsValue.permit_origin}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => handleChange(e, "usps")}
                        />
                        <IconButton size="small" className="revert-btn">
                          <UndoIcon />
                        </IconButton>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Stack>
              </PermitInformation>

              <PermitInformation>
                <PermitInformationText>
                  USPS EPS Accounting Permit{" "}
                </PermitInformationText>
                <Stack direction="row" useFlexGap flexWrap="wrap" spacing={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12} xl={12} display="flex">
                      <Box>
                        <Checkbox />

                        <Typography variant="outlined" className="EPS-header">
                          USPS EPS accounting Permit Linked to EPS
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={6} display="flex">
                      <FormControl className="revert-option">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          label="Customer Ref ID"
                          variant="outlined"
                          className="sequence"
                          name="customer_ref_id"
                          defaultValue={uspsValue.customer_ref_id}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => handleChange(e, "usps")}
                        />
                        <IconButton size="small" className="revert-btn">
                          <UndoIcon />
                        </IconButton>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={6} display="flex">
                      <FormControl className="revert-option">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          label="Move Update PAF ID"
                          variant="outlined"
                          className="sequence"
                          InputLabelProps={{ shrink: true }}
                        />
                        <IconButton size="small" className="revert-btn">
                          <UndoIcon />
                        </IconButton>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Stack>
              </PermitInformation>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default USPS;
