import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import "./style/style.css";
import "./style/common.css";
import "./style/home.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Toaster from "./components/Toaster";

const App = () => {
  const theme = createTheme({
    typography: {
      fontFamily: ['"Noto Sans"', "sans-serif"].join(","),
    },
    palette: {
      mode: "light",
      primary: {
        main: "#020024",
      },
      secondary: {
        main: "#fc466b",
      },
    },
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "0 0 14px " + grey[300],
            borderRadius: 14,
            ":before": {
              background: "none",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            "&.paperDesign": {
              boxShadow: "0 0 14px " + grey[300],
              borderRadius: 14,
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root": {
              background:
                "linear-gradient(180deg, #00344E 39.88%, #092431 100%)",
              color: "#fff",
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            background:
              "linear-gradient(67deg, rgba(2,0,36,1) 0%, #811114  64%, #3a3c3c 100%)",
            color: "#fff",
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            body1: "p",
            body2: "span",
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // Disable ripple for all buttons
        },
      },
    },
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
      <Toaster />
    </BrowserRouter>
  );
};

export default App;
