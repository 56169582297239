import React from "react";
import { Modal, Box, Grid, Typography, Button } from "@mui/material";
import "../workOrder.scss";
import { useDispatch, useSelector } from "react-redux";
import { handleWorkOrderChange } from "../../../../store/WorkOrderCreation";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import CloseIcon from "../../../../assets/icons/Close Square.svg";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function DexUploadModal({
  open,
  onClose,
  optionClicked,
  setFileUpload,
}) {
  const dispatch = useDispatch();
  const extensionsAllowed =
    optionClicked === 2 ? ["dex", "csv"] : ["pdr", "mpu", "zip"];
  const { msp_id, job_name, po_number, file_name } = useSelector(
    (state) => state.workOrder
  );
  const handleFileChange = (event) => {
    const uploadFile = event.target.files[0];
    if (uploadFile) {
      const fileExtension = uploadFile.name.split(".").pop().toLowerCase();

      if (extensionsAllowed.includes(fileExtension)) {
        dispatch(
          handleWorkOrderChange({ field: "file_type", value: fileExtension })
        );
        dispatch(
          handleWorkOrderChange({ field: "file_name", value: uploadFile.name })
        );
        setFileUpload(uploadFile);
      } else {
        toast.error("Please upload the valid file format ");
      }
    }
  };

  return (
    <div className="workOrder dex-upload-modal">
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="modal-details">
          <Box className="workOrder_header">
            <Typography variant="p">
              {" "}
              {optionClicked === 2 ? " Wo-DEX Upload" : "Wo-Mail.dat Upload"}
            </Typography>
            <Button onClick={() => onClose()} className="closeButton">
              {" "}
              <img alt="closeIcon" src={CloseIcon} className="closeicon" />
            </Button>
          </Box>
          <Box>
            <Box className="customerDetails" sx={{ mt: 4 }}>
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Customer Details</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" className="customer-details">
                  Customer ID : {msp_id}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  Customer Name : {localStorage.getItem("user_name")}
                </Typography>
              </Box>
            </Box>
            <Box className="customerDetails" sx={{ mt: 1 }}>
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Wo Created</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" className="customer-details">
                  PO Number : {po_number}
                </Typography>
                <Typography variant="h6" className="customer-details">
                  Job Name : {job_name}
                </Typography>
              </Box>
            </Box>
            <Box className="customerDetails workinfo">
              <Box className="customerDetailsHeader">
                <Typography variant="p">
                  <b>Work Order Info</b>
                </Typography>
              </Box>
              <Box className="container">
                <Grid container spacing={2} sx={{ mt: 0.5 }}>
                  <Grid item xs={12} md={12}>
                    <Box className="searchParam">
                      <Grid
                        item
                        xs={4}
                        md={12}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box>
                          <label
                            htmlFor="select file"
                            className="searchparamLabel"
                          >
                            please Select File to Upload
                          </label>
                          {optionClicked === 2 ? (
                            <p>
                              Allowed extention is .dex, .csv <span>*</span>
                            </p>
                          ) : (
                            <p>
                              Allowed extentions are .mpu, .pdr, .zip{" "}
                              <span>*</span>
                            </p>
                          )}
                        </Box>

                        <Box display="flex" alignItems="center" gap={5}>
                          <p>{file_name}</p>
                          <Button
                            sx={{ my: 2 }}
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                            className="Btn"
                          >
                            Upload File *
                            <VisuallyHiddenInput
                              required
                              type="file"
                              accept={
                                optionClicked === 2
                                  ? ".dex,.csv"
                                  : ".mpu,.pdr,.zip"
                              }
                              onChange={(e) => handleFileChange(e)}
                            />
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box>
              <Box display="flex" justifyContent="end">
                <Button variant="contained" onClick={onClose}>
                  continue
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
