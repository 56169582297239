import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import DatePickerViews from "./yearPicker";
import { Box, Menu, MenuItem } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { exportChartAsPdf, exportChartAsPng } from "../../utils/exportFile";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Piece Count",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const getRandomNumber = () => Math.floor(Math.random() * 1000);

const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Dataset 1",
      data: labels.map(() => getRandomNumber()),
      borderColor: "rgb(243,81,83)",
      // backgroundColor: "rgb(249,179,185)",

      backgroundColor: (context) => {
        const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, "rgba(249,179,185, 1)");
        gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
        return gradient;
      },
      tension: 0.5,
    },
  ],
};

function PieceCountGraph({ isModalopen }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handlePopOverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {isModalopen && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box
            display="flex"
            gap="5px"
            alignItems="center"
            justifyContent="center"
            color="dodgerblue"
            sx={{ cursor: "pointer" }}
            onClick={handlePopOverClick}
          >
            <DownloadIcon />
            Export
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handlePopOverClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                exportChartAsPdf("PieceCountGraph");
                handlePopOverClose();
              }}
            >
              Export as PDF
            </MenuItem>
            <MenuItem
              onClick={() => {
                exportChartAsPng("PieceCountGraph");
                handlePopOverClose();
              }}
            >
              Export as PNG
            </MenuItem>
          </Menu>
          <DatePickerViews />
        </Box>
      )}
      <div style={{ height: "auto", overflow: "hidden" }} id="PieceCountGraph">
        <Line options={options} data={data} height={"240px"} />
      </div>
    </>
  );
}

export default PieceCountGraph;
//rgb(249,179,185)
