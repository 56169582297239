import React, {
  useState,
  useReducer,
  useEffect,
  useContext,
  createContext,
} from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import { styled } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SiteDetails from "./SiteDetails";
import ContactDetails from "./ContactDetails";
import Licence from "./Licenece";
import "./siteEditor.scss";
import SiteEditorMachine from "./SiteEditorMachine";
import SiteEditorUser from "./SiteEditorUser";
import SiteUserMailowner from "./MailOwner";
import {
  AdminPanelSettings,
  ErrorOutline,
  PrecisionManufacturing,
  Print,
  RotateLeft,
} from "@mui/icons-material";
import Printer from "./Printer";
import Alert from "./Alert";
import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../../../config";

const StepIconRoot = styled("div")(({ ownerState }) => ({
  display: "flex",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    padding: "5px",
    borderRadius: "50%",
    color: "white",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    padding: "5px",
    borderRadius: "50%",
    color: "white",
  }),
}));
const GoBackIcon = styled(Box)(({}) => ({
  backgroundColor: "#f5f5f5",
  width: "45px",
  height: "45px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  transition: "background-color 0.3s ease",

  "&:hover": {
    backgroundColor: "#b0c4de",
  },
}));
const StepIcon = (props) => {
  const { active, completed, icon } = props;
  return (
    <StepIconRoot ownerState={{ active, completed }}>
      {completed ? <Check /> : icon}
    </StepIconRoot>
  );
};

const initialState = {
  site_details: {
    msp_name: localStorage.getItem("user_name"),
    site_name: "",
    site_address_line_1: "",
    site_address_line_2: "",
    operation_id: "",
    city: "",
    postal_code: "",
    location: "",
    state: "",
    shift1_from_time: "",
    shift1_to_time: "",
    shift2_from_time: "",
    shift2_to_time: "",
    shift3_from_time: "",
    shift3_to_time: "",
    is_24_hours_active: 0,
    is_active: 1,
    is_admin_site: 0,
  },
  contact_details: {
    main_contact_first_name: "",
    main_contact_last_name: "",
    main_contact_phone: "",
    main_contact_email: "",
    mail_dat_contact_first_name: "",
    mail_dat_contact_last_name: "",
    mail_dat_contact_phone: "",
    mail_dat_contact_email: "",
    shift1_maintenance_contact_first_name: "",
    shift1_maintenance_contact_last_name: "",
    shift1_maintenance_contact_phone: "",
    shift1_maintenance_contact_email: "",
    shift2_maintenance_contact_first_name: "",
    shift2_maintenance_contact_last_name: "",
    shift2_maintenance_contact_phone: "",
    shift2_maintenance_contact_email: "",
    shift3_maintenance_contact_first_name: "",
    shift3_maintenance_contact_last_name: "",
    shift3_maintenance_contact_phone: "",
    shift3_maintenance_contact_email: "",
  },
  license_and_other_details: {
    msp_mid: "",
    msp_crid: "",
    csa_agreement_id: "",
    dtac_user_license: "",
    verification_facility_zip_code: "",
    verification_facility: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "APPEND":
      state[action.key][action.field] = action.payload;
      return state;
    case "POSTAL_CODE":
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          postal_code: action.payload.postalCode,
          city: action.payload.city,
          state: action.payload.state,
        },
      };
    case "SET_SITE_DETAILS":
      return {
        ...state,
        site_details: {
          ...state.site_details,
          ...action.payload.site_details,
        },
        contact_details: {
          ...state.contact_details,
          ...action.payload.contact_details,
        },
        license_and_other_details: {
          ...state.license_and_other_details,
          ...action.payload.license_and_other_details,
        },
      };
    default:
      return state;
  }
};

const SitesEditorStepper = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const [activate, setactivate] = useState(0);

  const { id } = useParams();
  const baseUrl = apiBaseUrl;

  useEffect(() => {
    if (id) {
      getMachineDetailsList();
    }
  }, [id]);

  useEffect(() => {}, [activate]);

  const validateStep = () => {
    let tempErrors = {};
    if (activeStep === 0) {
      if (!state.site_details.msp_name)
        tempErrors.msp_name = "MSP name is required";
      if (!state.site_details.site_name)
        tempErrors.site_name = "Site name is required";
      if (!state.site_details.site_address_line_1)
        tempErrors.site_address_line_1 = "Address is required";
      if (!state.site_details.operation_id)
        tempErrors.operation_id = "Operation details is required";
      if (!state.site_details.city) tempErrors.city = "City is required";
      if (!state.site_details.state) tempErrors.state = "State is required";
      if (!state.site_details.postal_code)
        tempErrors.postal_code = "Postal code is required";
      if (!state.site_details.shift1_from_time)
        tempErrors.shift1_from_time = "Shift 1 from time is required";
      if (!state.site_details.shift1_to_time)
        tempErrors.shift1_to_time = "Shift 1 to time is required";
      if (state.site_details.shift2_from_time)
        tempErrors.shift2_from_time = "Shift 2 to time is required";
      if (state.site_details.shift3_from_time)
        tempErrors.shift3_from_time = "Shift 3 to time is required";
    } else if (activeStep === 1) {
      if (!state.contact_details.main_contact_first_name)
        tempErrors.main_contact_first_name = "First name is required";
      if (!state.contact_details.main_contact_email)
        tempErrors.main_contact_email = "Email is required";
      if (!state.contact_details.main_contact_phone)
        tempErrors.main_contact_phone = "Contact Number is required";

      if (!state.contact_details.mail_dat_contact_first_name)
        tempErrors.mail_dat_contact_first_name = "First name is required";
      if (!state.contact_details.mail_dat_contact_email)
        tempErrors.mail_dat_contact_email = "Email is required";
      if (!state.contact_details.mail_dat_contact_phone)
        tempErrors.mail_dat_contact_phone = "Contact Number is required";

      if (!state.contact_details.shift1_maintenance_contact_first_name)
        tempErrors.shift1_maintenance_contact_first_name =
          "First name is required";
      if (!state.contact_details.shift1_maintenance_contact_email)
        tempErrors.shift1_maintenance_contact_email = "Email is required";
      if (!state.contact_details.shift1_maintenance_contact_phone)
        tempErrors.shift1_maintenance_contact_phone =
          "Contact Number is required";
    } else if (activeStep === 2) {
      if (!state.license_and_other_details.msp_mid)
        tempErrors.msp_mid = "MSP MID is required";
      if (!state.license_and_other_details.msp_name)
        tempErrors.msp_name = "MSP Name is required";
      if (!state.license_and_other_details.csa_agreement_id)
        tempErrors.csa_agreement_id = "CSA ID is required";
      if (!state.license_and_other_details.dtac_user_license)
        tempErrors.dtac_user_license = "DTAC ID is required";
      if (!state.license_and_other_details.verification_facility)
        tempErrors.verification_facility = "Verification ID is required";
      if (!state.license_and_other_details.verification_facility_zip_code)
        tempErrors.verification_facility_zip_code =
          "Verification zipcode is required";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const getMachineDetailsList = async () => {
    try {
      axios
        .get(`${baseUrl}get_site_editor_details`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
          params: {
            msp_guid: localStorage.getItem("msp_account_guid"),
            site_guid: id,
          },
        })
        .then((response) => {
          dispatch({
            type: "SET_SITE_DETAILS",
            payload: response.data,
          });
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleFormChange = (e, keyName, addressType, postalCodes) => {
    const { name, value, type, checked } = e.target;
    const data = type === "checkbox" ? checked : value;
    errors[name] = "";

    setErrors(errors);

    if (addressType === "postal_code" && postalCodes) {
      dispatch({
        type: "POSTAL_CODE",
        key: keyName,
        payload: postalCodes,
      });
    }

    setactivate(value);
    dispatch({
      type: "APPEND",
      key: keyName,
      field: name,
      payload: data,
    });
  };

  const updateTimeValue = (params) => {
    dispatch({
      type: "APPEND",
      key: params.key,
      field: params.field,
      payload: params.value,
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}create_site_editor_details`,
        state,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(response.data.message);
      navigate("/msp/site-editor");
    } catch (error) {
      toast.error(error?.data?.detail || "Something went wrong");
    }
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      await handleSubmit();
    } else if (validateStep()) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps = [
    {
      label: "Site Details",
      icon: <AccountCircle />,
      component: (
        <SiteDetails
          handleChange={handleFormChange}
          detailValue={state.site_details}
          updateTime={updateTimeValue}
          errorMessage={errors}
        />
      ),
    },
    {
      label: "Contact Details",
      icon: <AssignmentInd />,
      component: (
        <ContactDetails
          handleChange={handleFormChange}
          detailValue={state.contact_details}
          siteValue={state.site_details}
          errorMessage={errors}
        />
      ),
    },
    {
      label: "License and Others",
      icon: <AdminPanelSettings />,
      component: (
        <Licence
          handleChange={handleFormChange}
          detailValue={state.license_and_other_details}
          errorMessage={errors}
        />
      ),
    },
    {
      label: "Machines",
      icon: <PrecisionManufacturing />,
      component: <SiteEditorMachine detailValue={state.site_details} />,
    },
    {
      label: "Users",
      icon: <AccountCircle />,
      component: <SiteEditorUser detailValue={state.site_details} />,
    },
    {
      label: "Mail Owners",
      icon: <AccountCircle />,
      component: <SiteUserMailowner detailValue={state.site_details} />,
    },
    {
      label: "Printers",
      icon: <Print />,
      component: <Printer />,
    },
    {
      label: "Alerts",
      icon: <ErrorOutline />,
      component: <Alert />,
    },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <GoBackIcon onClick={() => navigate("/msp/site-editor")}>
        <ArrowBackIcon />{" "}
      </GoBackIcon>
      <Stepper
        activeStep={activeStep}
        sx={{ my: 2 }}
        className="site-editor-stepper"
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={() => (
                <StepIcon
                  active={activeStep === index}
                  completed={activeStep > index}
                  icon={step.icon}
                />
              )}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="stepperLastState">
        {activeStep === steps.length ? (
          <div>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button
              onClick={handleReset}
              variant="contained"
              className="resetButton"
            >
              <RotateLeft /> Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography sx={{ mt: 2, mb: 1 }}>
              {steps[activeStep].component}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                pt: 2,
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant="outlined"
              >
                Back
              </Button>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  sx={{
                    backgroundColor:
                      activeStep === steps.length - 1 ? "green" : "dodgerblue",
                    fontWeight: activeStep === steps.length - 1 && "bold",
                    "&:hover": {
                      backgroundColor:
                        activeStep === steps.length - 1
                          ? "darkgreen"
                          : "#1b75d0",
                    },
                  }}
                >
                  {activeStep === steps.length - 1 ? "Submit" : "Next"}
                </Button>
              </Box>
            </Box>
          </div>
        )}
      </div>
    </Box>
  );
};
export default SitesEditorStepper;
