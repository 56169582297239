import React, { useEffect, useReducer, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popover,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  TablePagination,
  InputBase,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import { MachineService } from "../../../services/MachineService";
import Loader from "../../../components/Loader";
import EditIcon from "../../../assets/icons/edit.svg";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import TuneIcon from "@mui/icons-material/Tune";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";

const initialState = {
  machineList: [],
  anchorEl: null,
  anchorFilter: null,
};

const showColumns = [
  {
    id: 1,
    name: "MSP Name",
    value: "mail_owner",
  },
  { id: 2, name: "Machine Name", value: "machine_name" },
  {
    id: 3,
    name: "Manufacturer",
    value: "manufacturer",
  },
  {
    id: 4,
    name: "Model",
    value: "model_id",
  },
  { id: 5, name: "Verifier", value: "verifier" },
  {
    id: 6,
    name: "Primary Printer",
    value: "primary_printer",
  },
  { id: 7, name: "Secondary Printer", value: "secondary Printer" },
];

const reducer = (state, action) => {
  switch (action.type) {
    case "show_Details":
      return {
        ...state,
        [action.field]: action.payload,
      };
    default:
      return state;
  }
};

export default function MachinesTable(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    machinesDetails();
  }, [page, pageSize, props.refresh]);

  const machinesDetails = () => {
    setLoader(true);
    MachineService.getSpecificMachinesList({
      page: page + 1,
      page_size: pageSize,
    }).then((res) => {
      // debugger;
      const rows = res.data.machines_data.map((item) => ({
        id: item.machine_config?.machine_id,
        machine_name: item.machine_config?.machine_name,
        serial: item.machine_config?.serial,
        location: item.machine_config?.location_id,
        machine_id: item.machine_config?.machine_id,
        owner_id: item.machine_config?.owner_id,
        owner_name: item.machine_config?.owner_name,

        manufacturer: item.machine_config?.manufacturer_name,
        manufacturer_id: item.machine_config?.manufacturer_id,

        model: item.machine_config?.model_name,
        model_id: item.machine_config?.model_id,

        service_number: item.machine_config?.service_number,
        mass_id: item.machine_config?.mass_id,
        move_update_id: item.machine_config?.move_update_id,

        scale_id: item.machine_config?.scale_id,
        verifier: item.machine_config?.verifier_name,
        verifier_id: item.machine_config?.verifier_id,

        primary: item.machine_config?.primary_ocr_name,
        primary_ocr_id: item.machine_config?.primary_ocr_id,

        secondry: item.machine_config?.secondary_ocr_name,
        secondary_ocr_id: item.machine_config?.secondary_ocr_id,

        number_of_bins: item.machine_config?.number_of_bins,
        bin_type_id: item.machine_config?.bin_type_id,
        doubles_detector_id: item.machine_config?.doubles_detector_id,
        thickness_detector_id: item.machine_config?.thickness_detector_id,

        imb_printer: item.machine_config?.imb_printer_name,
        imb_printer_id: item.machine_config?.imb_printer_id,

        coa_printer: item.machine_config?.coa_printer_name,
        coa_printer_id: item.machine_config?.coa_printer_id,

        endorsement_printer_id: item.machine_config?.endorsement_printer_id,
        graphics_printer_id: item.machine_config?.graphics_printer_id,
        labeler_id: item.machine_config?.labeler_id,
        feeder_id: item.machine_config?.feeder_id,

        max_throughput:
          item?.machine_config?.machine_productivity?.max_throughput,
        production_throughput:
          item?.machine_config?.machine_productivity?.production_throughput,
        actual_production_throughput:
          item?.machine_config?.machine_productivity
            ?.actual_production_throughput,
        percentage_realization_production:
          item?.machine_config?.machine_productivity
            ?.percentage_realization_production,
        machine_enabled_disable_for_production:
          item?.machine_config?.machine_productivity
            ?.machine_enabled_disable_for_production,
        preventive_maintenance_time_hrs:
          item?.machine_config?.machine_productivity
            ?.preventive_maintenance_time_hrs,
        capable_service:
          item?.machine_config?.machine_productivity
            ?.expected_capable_for_service,
        production_date_range:
          item?.machine_config.machine_productivity?.production_date_range,
        last_preventive:
          item?.machine_config?.machine_productivity
            ?.last_preventive_maintenance_completed,
        next_preventive:
          item?.machine_config?.machine_productivity
            ?.next_scheduled_preventive_maintenance,

        date: item?.machine_config?.machine_log?.date,
        logs_attached: item?.machine_config?.machine_log?.logs_attached,
        log: item?.machine_config?.machine_log?.log,
        first_name:
          item?.machine_config.machine_maintenance_contact?.first_name,
        last_name: item?.machine_config.machine_maintenance_contact?.last_name,
        shift_id:
          item?.machine_config.machine_maintenance_contact
            ?.machine_maintenance_shift_id,
        email: item?.machine_config.machine_maintenance_contact?.email,
        phone: item?.machine_config.machine_maintenance_contact?.phone,
        priority: item?.machine_config.machine_maintenance_contact?.priority,
      }));
      dispatch({
        type: "show_Details",
        field: "machineList",
        payload: rows,
      });
      setCount(res.data?.total_record);
      setLoader(false);
    });
  };

  const handleClick = (event) => {
    dispatch({
      type: "show_Details",
      field: "anchorEl",
      payload: event.currentTarget,
    });
  };

  const handleClickFilter = (event) => {
    dispatch({
      type: "show_Details",
      field: "anchorFilter",
      payload: event.currentTarget,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPageSize(event.target.value);
  };

  const handleClose = () => {
    dispatch({ type: "show_Details", field: "anchorEl", payload: 0 });
    dispatch({ type: "show_Details", field: "anchorFilter", payload: 0 });
  };

  const open = Boolean(state.anchorEl);
  const id = open ? "columns-popover" : undefined;
  const openFilter = Boolean(state.anchorFilter);
  const filterId = open ? "filter-popover" : undefined;

  return (
    <div className="machineTable">
      {loader && <Loader />}

      <Popover
        id={id}
        open={open}
        anchorEl={state.anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ mt: 2 }}
      >
        <List>
          {showColumns.map((column) => (
            <ListItem key={column.id}>
              <Checkbox className="showHideCheckbox" value={column.value} />
              <ListItemText primary={column.name} />
            </ListItem>
          ))}
        </List>
      </Popover>

      <Popover
        id={filterId}
        open={openFilter}
        anchorEl={state.anchorFilter}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ mt: 2 }}
      >
        <Paper
          component="form"
          sx={{
            p: 1,
            display: "flex",
            alignItems: "center",
            width: 600,
          }}
        >
          <IconButton sx={{ p: "10px" }} aria-label="menu">
            <CloseIcon onClick={handleClose} />
          </IconButton>
          <FormControl size="small" className="tableFilters">
            <InputLabel id="demo-simple-select-label">Columns</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Columns"
            >
              {showColumns.map((column) => (
                <MenuItem key={column.id} value={column.name}>
                  {column.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" className="tableFilters" sx={{ mx: 1 }}>
            <InputLabel id="demo-simple-select-label">Operator</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Operator"
            >
              <MenuItem value="contains">Contains</MenuItem>
              <MenuItem value="equals">Equals</MenuItem>
              <MenuItem value="startswith">Start With</MenuItem>
              <MenuItem value="endswith">Ends With</MenuItem>
              <MenuItem value="isempty">Is Empty</MenuItem>
              <MenuItem value="isnotempty">Is Not Empty</MenuItem>
              <MenuItem value="isanyof">Is Any Of</MenuItem>
            </Select>
          </FormControl>
          <InputBase
            sx={{ flex: 1 }}
            placeholder="Filter Value"
            inputProps={{ "aria-label": "value" }}
            className="tableFilters filterValue"
          ></InputBase>
        </Paper>
      </Popover>

      <div className="filters">
        <div className="showListOptions">
          <span className="columns">
            <Button className="closeIcon actionIcons" onClick={handleClick}>
              <ViewColumnIcon className="filterIcon" />
              Columns
            </Button>
          </span>
          <span className="columns">
            <Button
              className="closeIcon actionIcons"
              onClick={handleClickFilter}
            >
              <TuneIcon className="filterIcon" />
              Filter
            </Button>
          </span>
        </div>
        <div className="export">
          <Button className="actionIcons">
            <FileDownloadIcon className="filterIcon" />
            Export
          </Button>
        </div>
      </div>
      <TableContainer component={Paper} className="machineDataTable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{
                  position: "sticky",
                  left: 0,
                  top: 0 /* Don't forget this, required for the stickiness */,
                  boxShadow: " 0 2px 2px -1px rgba(0, 0, 0, 0.4)",
                  zIndex: 800,
                  // boxShadow: "2px 0 5px -2px rgba(0,0,0,0.5)",
                }}
              >
                Owner Name
              </TableCell>
              <TableCell>Machine Name</TableCell>
              <TableCell>Serial Number</TableCell>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Verifier</TableCell>
              <TableCell>Primary Printer</TableCell>
              <TableCell>Secondary Printer</TableCell>
              <TableCell>IMB Printer </TableCell>
              <TableCell>COA Printer</TableCell>
              <TableCell>Capable Service</TableCell>
              <TableCell>Production Date Range</TableCell>
              <TableCell>Last Preventive</TableCell>
              <TableCell>Next Preventive</TableCell>
              <TableCell
                align="left"
                style={{
                  position: "sticky",
                  right: 0,
                  zIndex: 800,
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.machineList.length > 0 ? (
              state.machineList.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{
                      position: "sticky",
                      left: 0,
                      background: "white",
                      zIndex: 800,
                      top: 0 /* Don't forget this, required for the stickiness */,
                      boxShadow: " 0 2px 2px -1px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    {row.owner_name}
                  </TableCell>
                  <TableCell>{row.machine_name}</TableCell>
                  <TableCell>{row.serial}</TableCell>
                  <TableCell>{row.manufacturer}</TableCell>
                  <TableCell>{row.model}</TableCell>
                  <TableCell>{row.verifier}</TableCell>
                  <TableCell>{row.primary}</TableCell>
                  <TableCell>{row.secondry}</TableCell>
                  <TableCell>{row.imb_printer}</TableCell>
                  <TableCell>{row.coa_printer}</TableCell>
                  <TableCell>{row.capable_service}</TableCell>
                  <TableCell>{row.production_date_range}</TableCell>
                  <TableCell>{row.last_preventive}</TableCell>
                  <TableCell>{row.next_preventive}</TableCell>
                  <TableCell
                    align="left"
                    style={{
                      position: "sticky",
                      right: 0,
                      zIndex: 800,
                      background: "white",
                    }}
                  >
                    <div className="actionIcons">
                      <Button
                        className="closeIcon"
                        onClick={() => props.handleOpenEdit(row)}
                      >
                        <img
                          src={EditIcon}
                          className="actionIcons"
                          alt="editIcon"
                        />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>
                  <Typography variant="h6" align="center">
                    No records found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
