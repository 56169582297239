import { get, postFormData, deleteRequest } from "./BaseService";
import { apiBaseUrl } from "../config";

export const MachineService = {
  getMachinesList,
  getSpecificMachinesList,
  getMachineManufacturerList,
  getMachineModelList,
  getMachineFeederList,
  getMachinePrimaryList,
  getMachineSecondaryList,
  getMachineVerifierList,
  getMachineBintypeList,
  getMachineDoubleDetectorList,
  getMachineImbPrinterList,
  getMachineCoaPrinterList,
  getMachinegraphicsList,
  getMachineEndorsementList,
  getMachinelabelerList,
  getMachineScaleList,
  getMachineMaintenanceList,
  postMachineDetails,
  deleteMachineDetails,
  getOwnerDetails,
  getMspList,
  getOwnerListDetails,
};

function postMachineDetails(data) {
  const url = `eii_machine_creation`;
  return postFormData(apiBaseUrl + url, data);
}
function getOwnerDetails(data) {
  const url = `get_machine_maintenance_contacts`;
  return get(apiBaseUrl + url, data);
}

function getOwnerListDetails(data) {
  const url = `msp_account_list_for_machines`;
  return get(apiBaseUrl + url, data);
}

function deleteMachineDetails(data) {
  const url = `eii_machine_delete`;
  return deleteRequest(apiBaseUrl + url, data);
}

function getMachinesList(params) {
  const url = `eii_machines_list`;
  return get(apiBaseUrl + url, params);
}

function getSpecificMachinesList(params) {
  const url = `specific_msp__machines_list`;
  return get(apiBaseUrl + url, params);
}

function getMspList(params) {
  const url = `msp_account_list`;
  return get(apiBaseUrl + url, params);
}

function getMachineManufacturerList() {
  const url = `get_machine_manufacturer`;
  return get(apiBaseUrl + url);
}

function getMachineModelList() {
  const url = `get_machine_models`;
  return get(apiBaseUrl + url);
}

function getMachinePrimaryList() {
  const url = `get_machine_primary_ocr`;
  return get(apiBaseUrl + url);
}

function getMachineSecondaryList() {
  const url = `get_machine_secondary_ocr`;
  return get(apiBaseUrl + url);
}

function getMachineVerifierList() {
  const url = `get_machine_verifier_model`;
  return get(apiBaseUrl + url);
}

function getMachineBintypeList() {
  const url = `get_machine_bin_type`;
  return get(apiBaseUrl + url);
}

function getMachineDoubleDetectorList() {
  const url = `get_machine_doubles_detector`;
  return get(apiBaseUrl + url);
}

function getMachineImbPrinterList() {
  const url = `get_machine_imb_printer`;
  return get(apiBaseUrl + url);
}

function getMachineCoaPrinterList() {
  const url = `get_machine_coa_printer`;
  return get(apiBaseUrl + url);
}

function getMachineEndorsementList() {
  const url = `get_machine_endorsement_printer`;
  return get(apiBaseUrl + url);
}

function getMachinegraphicsList() {
  const url = `get_machine_graphics_printer`;
  return get(apiBaseUrl + url);
}

function getMachinelabelerList() {
  const url = `get_machine_labeler`;
  return get(apiBaseUrl + url);
}

function getMachineScaleList() {
  const url = `get_machine_scale`;
  return get(apiBaseUrl + url);
}

function getMachineFeederList() {
  const url = `get_machine_feeder`;
  return get(apiBaseUrl + url);
}

function getMachineMaintenanceList() {
  const url = `get_machine_maintenance_shift`;
  return get(apiBaseUrl + url);
}
