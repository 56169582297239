import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  Typography,
  Button,
  TextField,
  IconButton,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Box,
  Paper,
  Checkbox,
  Select,
  FormControlLabel,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import MailIdModal from "./MailIdModal";
import axios from "axios";
import { apiBaseUrl } from "../../../config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Billing = [
  "First Class - Flat 1",
  "First Class - Flat 2",
  "First Class - Flat 3",
  "First Class - Flat 4",
  "First Class - Flat 5",
  "First Class - Flat 6",
];

const AllBilling = [
  "First Class - Flat 1",
  "First Class - Flat 2",
  "First Class - Flat 3",
  "First Class - Flat 4",
  "First Class - Flat 5",
  "First Class - Flat 6",
  "First Class - Letters 1",
  "First Class - Letters 2",
  "First Class - Letters 3",
  "First Class - Letters 4",
  "First Class - Letters 5",
  "First Class - Letters 6",
];

const imbservice = [
  "FirstClass Mail",
  "Periodicals",
  "USPS Marketing Mail",
  "Bound Printed Matter",
  "Political Mail",
  "Ballot Mail",
  "Miscellaneous",
];

export default function BasicTable({ handleChange, classValue }) {
  const [endorsement, setEndrosement] = useState([]);
  const [moveUpdateMethod, setMoveUpdate] = useState([]);
  const [mailClass, setMailClass] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const isAuth = localStorage.getItem("auth");
  const params = {
    headers: {
      Authorization: "Bearer " + isAuth,
    },
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchDropDowns = async () => {
    try {
      const [fetchEndrosement, fetchMoveUpdate, fetchMailClass] =
        await axios.all([
          axios.get(`${apiBaseUrl}get_endorsement`, params),
          axios.get(`${apiBaseUrl}get_move_update_method`, params),
          axios.get(`${apiBaseUrl}get_mail_class_handling_type`, params),
        ]);
      setEndrosement(fetchEndrosement.data.endorsement);
      setMoveUpdate(fetchMoveUpdate.data.move_update_method);
      setMailClass(fetchMailClass.data.mail_class_handling_type);
    } catch (error) {
      console.log("Err==>", error);
    }
  };
  useEffect(() => {
    fetchDropDowns();
  }, []);

  return (
    <div className="classtab accountEditorTabs">
      {open && (
        <MailIdModal
          close={handleClose}
          open={handleOpen}
          tableData={tableData}
          setTableData={setTableData}
        />
      )}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Box>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                  <FormControl className="revert-option">
                    <InputLabel
                      id="demo-multiple-name-label"
                      className="profilelable"
                      sx={{ pr: 1, top: "-7px" }}
                    >
                      Mail Class
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      size="small"
                      name="mailclass_handling_type_id"
                      defaultValue={classValue.mailclass_handling_type_id}
                      onChange={(e) => handleChange(e, "class_settings")}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                      sx={{ flexGrow: "1" }}
                    >
                      {mailClass.map((item) => (
                        <MenuItem
                          key={item.mail_class_handling_type_id}
                          value={item.mail_class_handling_type_id}
                        >
                          {item.mail_class}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton size="small" className="revert-btn">
                      <UndoIcon />
                    </IconButton>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormControl className="revert-option">
                    <InputLabel
                      id="demo-multiple-name-label"
                      className="profilelable"
                      sx={{ pr: 1, top: "-7px" }}
                    >
                      Billing Profile
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      value={"test"}
                      size="small"
                      name="billing_profile"
                      onChange={(e) => handleChange(e, "class_settings")}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                      sx={{ flexGrow: "1" }}
                    >
                      {Billing.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton size="small" className="revert-btn">
                      <UndoIcon />
                    </IconButton>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Checkbox
                    onChange={(e) => handleChange(e, "class_settings")}
                  />

                  <Typography variant="outlined">
                    Show All Billing Profile
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl className="revert-option">
                    <InputLabel
                      shrink
                      id="demo-multiple-name-label"
                      className="profilelable"
                      sx={{ pr: 1, top: "-7px" }}
                    >
                      Secondary Billing Profile
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      size="small"
                      defaultValue={"test"}
                      onChange={(e) => handleChange(e, "class_settings")}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                      sx={{ flexGrow: "1" }}
                    >
                      {AllBilling.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton size="small" className="revert-btn">
                      <UndoIcon />
                    </IconButton>
                  </FormControl>
                </Grid>
              </Grid>

              <Box className="additional-fields revert-option" sx={{ mt: 4 }}>
                <Typography variant="p" className="additional-fields-header">
                  Customer Metered Mail
                </Typography>
                <Grid container>
                  <Grid xs={12} md={12} lg={6}>
                    <Box display="flex">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="customer_metered_mail"
                            defaultChecked={classValue.customer_metered_mail}
                            onChange={(e) => handleChange(e, "class_settings")}
                          />
                        }
                        label="Customer Meter Mail"
                      />

                      <Box
                        display="flex"
                        alignItems="center"
                        className="undo-wrap revert-option-details"
                        sx={{ ml: 0 }}
                      >
                        <IconButton
                          size="small"
                          title="revert"
                          className="revert-btn"
                        >
                          <UndoIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs={12} md={12} lg={6}>
                    <Box display="flex">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="metered_at_full_service"
                            defaultChecked={classValue.metered_at_full_service}
                            onChange={(e) => handleChange(e, "class_settings")}
                          />
                        }
                        label=" Metered at Full Service"
                      />
                      <Box
                        display="flex"
                        alignItems="center"
                        className="undo-wrap revert-option-details"
                        sx={{ ml: 0 }}
                      >
                        <IconButton
                          size="small"
                          title="revert"
                          className="revert-btn"
                        >
                          <UndoIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Grid container alignItems="center" spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={12} lg={6}>
                  <FormControl className="revert-option">
                    <InputLabel
                      id="demo-multiple-name-label"
                      className="profilelable"
                      sx={{ pr: 1, top: "-7px" }}
                      shrink
                    >
                      Endorsement
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      size="small"
                      defaultValue={classValue.endorsement_id}
                      name="endorsement_id"
                      onChange={(e) => handleChange(e, "class_settings")}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                      sx={{ flexGrow: "1" }}
                    >
                      {endorsement.map((item) => (
                        <MenuItem
                          key={item.endorsement_id}
                          value={item.endorsement_id}
                        >
                          {item.name_of_endorsement}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton size="small" className="revert-btn">
                      <UndoIcon />
                    </IconButton>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormControl className="revert-option">
                    <InputLabel
                      id="demo-multiple-name-label"
                      className="profilelable"
                      sx={{ pr: 1, top: "-7px" }}
                    >
                      Move Update Method
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      size="small"
                      defaultValue={classValue.move_update_method_id}
                      onChange={(e) => handleChange(e, "class_settings")}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                      sx={{ flexGrow: "1" }}
                      name="move_update_method_id"
                    >
                      {moveUpdateMethod.map((item) => (
                        <MenuItem
                          key={item.move_update_method_id}
                          value={item.move_update_method_id}
                        >
                          {item.move_update_method_name}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton size="small" className="revert-btn">
                      <UndoIcon />
                    </IconButton>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="IMB Service Type Identifier"
                    variant="outlined"
                    className="sequence"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => handleChange(e, "class_settings")}
                    name="service_type_identifier"
                    defaultValue={classValue.service_type_identifier}
                    sx={{ mt: 2 }}
                  />
                </Grid>
              </Grid>

              <Box className="additional-fields">
                <Typography variant="p" className="additional-fields-header">
                  Metered at Full Service
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={6}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      label="Enable Full Service - CRID"
                      variant="outlined"
                      className="sequence"
                      InputLabelProps={{ shrink: true }}
                      sx={{ mt: 2 }}
                      onChange={(e) => handleChange(e, "class_settings")}
                      name="mail_owner_crid"
                      defaultValue={classValue.mail_owner_crid}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      label="Enable Full Service - MID"
                      variant="outlined"
                      className="sequence"
                      InputLabelProps={{ shrink: true }}
                      sx={{ mt: 2 }}
                      onChange={(e) => handleChange(e, "class_settings")}
                      name="mail_owner_mid"
                      defaultValue={classValue.mail_owner_mid}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={2} sx={{ my: 2 }} className="sequence">
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Sequence Start"
                    variant="outlined"
                    className="sequence"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Sequence Stop"
                    variant="outlined"
                    className="sequence"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Current Sequence"
                    variant="outlined"
                    className="sequence"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormControl className="revert-option">
                    <TextField
                      id="outlined-basic"
                      size="small"
                      label="IMB Mailer ID"
                      variant="outlined"
                      className="sequence"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => handleChange(e, "class_settings")}
                      name="mailer_id"
                      defaultValue={classValue.mailer_id}
                    />
                    <IconButton size="small" className="revert-btn">
                      <UndoIcon />
                    </IconButton>
                  </FormControl>
                </Grid>
              </Grid>

              <Box component="span">
                {" "}
                <Button
                  sx={{ my: 1.5, color: "white" }}
                  className="Btn select-mailer-id-btn"
                  variant="outlined"
                  onClick={handleOpen}
                >
                  Select Mailer ID
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
