import React, { useState } from "react";
import {
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Box,
  TableBody,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import TuneIcon from "@mui/icons-material/Tune";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ReactComponent as AddRoleIcon } from "../../../assets/icons/Masked_Icon.svg";
import AssignModal from "./AssignModal";
import { apiBaseUrl } from "../../../config";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import DeleteIcon from "../../../assets/icons/Trash Bin Trash.svg";
import EditIcon from "../../../assets/icons/edit.svg";
import { toast } from "react-toastify";

const AssignRoutes = () => {
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editId, setEditId] = useState({
    mo_route_driver_id: "",
    route_id: "",
    driver_id: "",
  });
  const [deleteId, setDeleteId] = useState("");
  const isAuth = localStorage.getItem("auth");

  const fetchAssignedRoutes = async () => {
    const { data } = await axios.get(`${apiBaseUrl}get_assigned_routes`, {
      headers: {
        Authorization: `Bearer ${isAuth}`,
      },
    });
    return data.assigned_routes_list;
  };
  const handleOpenEdit = (mo_route_driver_id, route_id, driver_id) => {
    setEditModal(true);
    setEditId((prev) => ({ ...prev, mo_route_driver_id, route_id, driver_id }));
  };
  const { data: allAssignedRoutes, refetch } = useQuery({
    queryKey: ["assignedRoutes"],
    queryFn: fetchAssignedRoutes,
    refetchOnWindowFocus: false,
  });
  const onClose = (data) => {
    setCreateModal(false);
    setEditModal(false);
    setDeleteModal(false);
    data && refetch();
  };
  const handleDelete = () => {
    try {
      const formData = new FormData();
      formData.append("mo_route__driver_id", deleteId);
      axios
        .delete(`${apiBaseUrl}delete_assigned_route`, {
          data: formData,
          headers: {
            Authorization: `Bearer ${isAuth}`,
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          onClose("Success");
        })
        .catch((e) => console.log("Err==>", e));
    } catch (error) {
      console.log("Err==>", error);
    }
  };
  return (
    <div className="roles mspcreation machineTable">
      <Box display="flex" justifyContent="end">
        <Button
          variant="contained"
          sx={{ fontWeight: "bold" }}
          onClick={() => setCreateModal(true)}
          className="Btn"
        >
          <AddRoleIcon /> Assign Route
        </Button>
      </Box>
      <div className="filters">
        <div className="showListOptions">
          <span className="columns">
            <Button className="actionIcons">
              <ViewColumnIcon className="filterIcon" />
              Columns
            </Button>
          </span>
          <span className="columns">
            <Button className="actionIcons">
              <TuneIcon className="filterIcon" />
              Filter
            </Button>
          </span>
        </div>
        <div className="export">
          <Button className="actionIcons">
            <FileDownloadIcon className="filterIcon" />
            Export
          </Button>
        </div>
      </div>
      <TableContainer component={Paper} className="machineDataTable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Routes</TableCell>

              <TableCell align="left">Drivers</TableCell>

              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allAssignedRoutes?.map((row) => (
              <TableRow key={row.mo_route_driver_id}>
                <TableCell key={row.route_id}>{row.route_name}</TableCell>
                <TableCell key={row.driver_id}>{row.driver_name}</TableCell>
                <TableCell align="left">
                  <div className="actionIcons">
                    <span>
                      <Button
                        className="closeButton"
                        onClick={() =>
                          handleOpenEdit(
                            row.mo_route_driver_id,
                            row.route_id,
                            row.driver_id
                          )
                        }
                      >
                        {" "}
                        <img
                          src={EditIcon}
                          className="actionIcons"
                          alt="editicon"
                        />
                      </Button>
                    </span>
                    <span>
                      <Button
                        className="closeButton"
                        onClick={() => {
                          setDeleteModal(true);
                          setDeleteId(row.mo_route_driver_id);
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          className="actionIcons"
                          alt="deleteIcon"
                        />
                      </Button>
                    </span>
                  </div>
                </TableCell>{" "}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {createModal && <AssignModal open={createModal} onClose={onClose} />}
      {editModal && (
        <AssignModal open={editModal} onClose={onClose} editId={editId} />
      )}
      {deleteModal && (
        <Dialog
          open={deleteModal}
          onClose={() => onClose()}
          className="route-delete-modal"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm delete?"}</DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleDelete()}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default AssignRoutes;
