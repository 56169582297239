import {
  Grid,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useState } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "#ffff",
  borderRadius: "5px",
  boxShadow: 24,
  padding: "20px",
};

const MailingEditorModal = ({ handleOpen, onClose }) => {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [mailingEditor, setMailingEditor] = useState({
    mailingDate: "",
    mailingClass: "",
    mailingProduct: "",
  });

  const listItem = [
    {
      id: "1",
      name: "Sample mailing data 1",
      list: [
        "Work Order 1",
        "Work Order 2",
        "Work Order 3",
        "Work Order 4",
        "Work Order 5",
      ],
    },
    {
      id: "2",
      name: "Sample mailing data 2",
      list: [
        "Work Order 1",
        "Work Order 2",
        "Work Order 3",
        "Work Order 4",
        "Work Order 5",
      ],
    },
    {
      id: "3",
      name: "Sample mailing data 3",
      list: [
        "Work Order 1",
        "Work Order 2",
        "Work Order 3",
        "Work Order 4",
        "Work Order 5",
      ],
    },
    {
      id: "4",
      name: "Sample mailing data 4",
      list: [
        "Work Order 1",
        "Work Order 2",
        "Work Order 3",
        "Work Order 4",
        "Work Order 5",
      ],
    },
  ];
  function handleChange(e) {
    setMailingEditor((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  return (
    <div>
      <Modal
        open={handleOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mailingEditorModal-box">
          <Grid container spacing={2}>
            <Grid item lg={4} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Mailing Date"
                    onChange={(newValue) =>
                      setMailingEditor((prev) => ({
                        ...prev,
                        mailingDate: newValue,
                      }))
                    }
                    slotProps={{ textField: { size: "small" } }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item lg={4} md={4}>
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <InputLabel id="demo-simple-select-label">
                  Mailing Class
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mailingEditor.mailingClass}
                  label="Mailing Class"
                  name="mailingClass"
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4}>
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <InputLabel id="demo-simple-select-label">
                  Mailing Product
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mailingEditor.mailingProduct}
                  label="Mailing Product"
                  name="mailingProduct"
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem value={10}>Mailing Product 1</MenuItem>
                  <MenuItem value={20}>Mailing Product 2</MenuItem>
                  <MenuItem value={30}>Mailing Product 3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box className="mailingEditorModal">
            <div className="modal-box">
              <ul>
                {listItem.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => setSelectedMenu(item.id)}
                    className={selectedMenu === item.id && "activate"}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-box-list-items">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {listItem
                  .filter((item) => item.id === selectedMenu)
                  .map((item) =>
                    item.list.map((listItem, index) => {
                      const labelId = `checkbox-list-label-${item?.id}`;
                      return (
                        <ListItem key={item.id} disablePadding>
                          <ListItemButton role={undefined} dense>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                // checked={checked.indexOf(value.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={`${listItem}`}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })
                  )}
              </List>
            </div>
          </Box>
          <Box className="assign-mailing-btn">
            <Button variant="contained" onClick={onClose}>
              Assign Mailing
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default MailingEditorModal;
