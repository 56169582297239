import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AccountManager from "./MailingView";
import BasicTable from "./MachineView";
import { ReactComponent as AddIcon } from "../../../assets/icons/Masked_Icon.svg";
import AddMailingModal from "./AddMailingEditor";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabResponse = [
  { id: "1", name: "Mailing View", component: <AccountManager /> },
  { id: "2", name: "Machine View", component: <BasicTable /> },
];

export default function MailingEditor() {
  const [value, setValue] = React.useState(0);
  const [selectedMailOwner, setSelectedMailOwner] = React.useState(null);
  const [mailingModalOpen, setMailingModalOpen] = useState(false);

  const handleModalOpen = () => setMailingModalOpen(true);
  const handleModalClose = () => setMailingModalOpen(false);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log(selectedMailOwner, "selectedMailOwner");
  }, [selectedMailOwner]);

  return (
    <Box>
      <Box display="flex" justifyContent="end" margin="15px 0">
        <Button variant="outlined" className="Btn" onClick={handleModalOpen}>
          <AddIcon /> Add
        </Button>
      </Box>
      <div className="profile-editor-wrap mailing_editor">
        <Box className="acc-editor-tab">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
              className="tab-headers"
            >
              {tabResponse.map((e, index) => (
                <Tab key={e.id} label={e.name} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Box>

          {tabResponse.map((e, index) => (
            <CustomTabPanel
              key={e.id}
              value={value}
              index={index}
              className="profile-editor-tab"
            >
              {e.component}
            </CustomTabPanel>
          ))}
        </Box>
      </div>

      {mailingModalOpen && (
        <AddMailingModal open={handleModalOpen} close={handleModalClose} />
      )}
    </Box>
  );
}
